import React from 'react'

import '../../staticFiles/cssFiles/History/rightHistory.css'
// import ref_logo from '../../staticFiles/images/refresh.svg' 
import exp_logo from '../../staticFiles/images/export.svg' 
// import in_progress from "../../staticFiles/images/Ellipsis-1.4s-24px.svg"

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { downloadHistory, getHistory } from '../../endppoints/endpoint';

import { Navigate } from 'react-router-dom';

import loading from "../../staticFiles/images/loading.svg"
import no_result_logo from "../../staticFiles/images/No_results.svg"
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { Tooltip } from '@mui/material';
import Cookies from 'js-cookie';
import { DateRangePicker } from 'rsuite';

import { PageAnimations } from '../GlobalPageAnimations.jsx/pageAnimations';


export const RightHistoryView = () => {
    const [page, setPage] = React.useState(1)

    const currentDate = new Date();
    const initialDate = new Date();
    initialDate.setDate(currentDate.getDate() - 30);

    const [selectedDateRange, setSelectedDateRange] = React.useState([initialDate, new Date()]);
    const [selectedDateRangeApplied, setSelectedDateRangeApplied] = React.useState([initialDate, new Date()]);
  
    const [fromDate, setFromDate] = React.useState(initialDate)
    const [toDate, setToDate] = React.useState(new Date())

    // const [fromDatePage, setFromDatePage] = React.useState(initialDate)
    // const [toDatePage, setToDatePage] = React.useState(new Date())

	const [sample_rows, setSample_rows] = React.useState([])

    const [total_data, setTotal_data] = React.useState(0) 
    const [curr_data, setCurr_data] = React.useState(0) 
    const [total_page, setTotal_page] = React.useState(0) 

    const [redirect, setRedirect] = React.useState(0)
    const [defaultKeyword, setDefaultKeyword] = React.useState(Cookies.get('userOrg'))

	const [map, setMap] = React.useState(0)

    React.useEffect(()=>{
        sendrequest()
    }, []);

    const check_error = (response) =>{
        if(response.status === 401){
            localStorage.clear()
            Cookies.remove('token');
            setTimeout(() => {
                setRedirect(1)    
            }, 1000);
            toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
        }
        else{
            toast.info("server error", { position: toast.POSITION.TOP_CENTER})
        }
    }

    const sendrequest =()=>{
		const message = {
			"pageNo": page,
			'fromDate': moment(fromDate).format("YYYY-MM-DD"),
            'toDate': moment(toDate).format("YYYY-MM-DD"),
		}
		
		get_History(message);
	}

    // const handleFromDate = event =>{
	// 	// event.preventDefault()
    //     console.log(event.target.value)
	// 	setFromDate(event.target.value)
    // }

	// const handleToDate  = event =>{
    //     console.log(event.target.value)
	// 	setToDate(event.target.value)
    // }

    const handlePage = (event, value) =>{
        console.log(value)
		setPage(value)

		const message = {
			"pageNo": value,
            'fromDate': selectedDateRangeApplied? moment(selectedDateRangeApplied[0]).format("YYYY-MM-DD"): '',
            'toDate': selectedDateRangeApplied !== '' ? moment(selectedDateRangeApplied[1]).format("YYYY-MM-DD"): '',
		}

        if( !(message['fromDate'] && message['toDate'])){
            setSample_rows([])
        }
        else{
            get_History(message);
        }

    }

    const finalSubmit = () =>{
        setPage(1)
		const message = {
			"pageNo": "1",
			'fromDate': selectedDateRange? moment(selectedDateRange[0]).format("YYYY-MM-DD"): '',
            'toDate': selectedDateRange? moment(selectedDateRange[1]).format("YYYY-MM-DD"): '',
		}

        if( !(message['fromDate'] && message['toDate'])){
            setSample_rows([])
            setTotal_data(0)
            setSelectedDateRangeApplied(selectedDateRange)
        }
        else{
            setSelectedDateRangeApplied(selectedDateRange)
            get_History(message);
        }
	}

    function createData(report, keyword, date, time, id, extension, autoGenerated, reportType, id_main) {
        return { report, keyword, date, time, id, extension, autoGenerated, reportType, id_main};
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const monthName = monthNames[monthIndex];
        const suffix = day === 1 ? 'st' : day === 2 ? 'nd' : day === 3 ? 'rd' : 'th';
        return `${day}${suffix} ${monthName} ${year}`;
      }
      

    const get_History = async(message)=>{
		let response = {};
		setMap(2)
        response = await getHistory(message)
		console.log(response);

        if(response.status === 200){
            setMap(1)
            let test_rows = []
            for(let i = 0; i < response.data['data'].length; i++){
                test_rows.push(createData(response.data.data[i].fileName, response.data.data[i].searchFilter,  formatDate(response.data.data[i].dateTime.slice(0,10)), response.data.data[i].dateTime.slice(11, 16), response.data.data[i].url, response.data.data[i].extension, response.data.data[i].autoGenerated, response.data.data[i].reportType, response.data.data[i]._id))
            }
            console.log(test_rows)
            setSample_rows(test_rows)
            setTotal_data(response.data['totalCount'])
            setCurr_data(response.data['data'].length)
            if(response.data['totalCount'] % 10 === 0){
                setTotal_page(Math.floor(response.data['totalCount'] / 10))
            }
            else{
                setTotal_page(Math.floor(response.data['totalCount'] / 10) + 1)
            }
        }
		else{
			check_error(response)
		}
	}

    const download_report = (value, extension)=>{
        console.log(value, extension)
        download_api_call(value, extension);
    }
    const download_api_call = async(message, extension) =>{
        let response = {}
        response = await downloadHistory(message, extension)
        console.log(response)

        if(response.hasOwnProperty('status') && response['status'] === '500'){
            alert('Token expired...login again !!!!')
            // setRedirect(1)
        }
    }

    const handleDateRangeChange = (value) => {
        setSelectedDateRange(value);
      };


    if(redirect === 1){
        // return <Navigate to = '/login' />
        window.location.href = '/login';
    }
  return (
    <div className='right_history_container'>
        <PageAnimations />
        <div className="top_filter_container">
            <h3 className="report_history_header">
                Report History
            </h3>

            <div className="date_range_container">
                <div className="date_range">
                    <DateRangePicker
                    placeholder="Select a Date Range"
                    value={selectedDateRange}
                    onChange={handleDateRangeChange}
                    className="custom-date-picker"
                    showOneCalendar />
                </div>
                {/* <div className="from_date input_date">
                   <fieldset>
                        <legend className='legend_from'>From Date</legend> 
                        <input type="date" className="from_date_input" value={fromDate !== ''? moment(fromDate).format("YYYY-MM-DD"): ''} onChange={handleFromDate}/>
                    </fieldset>
                </div>
                <div className="to_date input_date">
                   <fieldset>
                        <legend className='legend_from'>To Date</legend> 
                        <input type="date" className="from_date_input" value={toDate !== ''?moment(toDate).format("YYYY-MM-DD"): ''} onChange={handleToDate}/>
                    </fieldset>
                </div> */}
            </div>
            <div className="apply_filter_container">
                <button className="btn btn-primary apply_filter_button" onClick={finalSubmit}>
                    Apply
                </button>
            </div>
            <div className="auto_manual_view">
                <div className="auto_tag auto_manual_tags">
                    <div className="color_auto">

                    </div>
                    <div className="tag_name">
                        Auto Generated
                    </div>
                </div>
                <div className="manual_tag auto_manual_tags">
                    <div className="color_manual">
                    </div>
                    <div className="tag_name">
                        Manually Generated 
                    </div>
                </div>
            </div>
        </div>

        <div className="history_table_and_content">
            <div className="history_table_container">
                {map === 0 ? <div>No data to show</div>: 
                    map === 1 ? 
                    sample_rows.length === 0? 
                        <div className='no_results_container '> 
                            <img src={no_result_logo} alt="" className="no_results child_no_results" /> 
                            <div className="no_results_header child_no_results">
                                No results found
                            </div>
                            <div className="no_results_subheader child_no_results">
                                Try adjusting your filters to find out what you are looking for 
                            </div>
                        </div>:
                    <TableContainer sx={{  height: "70vh", boxShadow: '0px 20px 50px 0px #0000001A', borderRadius: '8px 8px 0px 0px', backdropFilter: 'blur(10px)', background: "transparent"}}>
                     <Table stickyHeader >
                         <TableHead sx = {{ fontFamily: "Inter"}}>
                             <TableRow>
                                 <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px'}}>Report Name</TableCell>
                                 <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px'}} align="center">Filter</TableCell>
                                 <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px'}} align="center">Date</TableCell>
                                 <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px'}} align="center">Time</TableCell>
                                 <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px'}} align="center">download</TableCell>
                             </TableRow>
                         </TableHead>
                         <TableBody sx={{ height: "max-content" }}>
                             {sample_rows.map((row, index) => (
                                 <TableRow 
                                 key={index}
                                 sx={{ '&:last-child td, &:last-child th': { border: 0 }, background: "rgba(233, 233, 233, 0.35)"}}
                                 >   
                                        <TableCell component="th" scope="row" sx = {{ fontFamily: "Inter", fontWeight: "bold",  fontSize: '15px', borderLeft: row.autoGenerated ? "6px solid #0ABCB2 !important" : "6px solid #D4D4D4 !important"}}>
                                            {row.report}

                                            {row.reportType === 'QA'? <span className="report_type_name">QA</span> : <></>}
                                        </TableCell>
                                        <TableCell align="center"  sx = {{ fontFamily: "Inter", fontWeight: "bold",  fontSize: '15px'}}>
                                            <div className={row.keyword === '' || row.keyword === null ?'no_report_type':'report_type'}>{row.keyword === ''|| row.keyword === null ? defaultKeyword:  
                                                row.keyword
                                            }</div>
                                            
                                            </TableCell>
                                        <TableCell align="center" sx = {{ fontFamily: "Inter", fontWeight: "400",  fontSize: '15px' }}>{row.date}</TableCell>
                                        <TableCell align="center" sx = {{ fontFamily: "Inter", fontWeight: "400",  fontSize: '15px' }}>{row.time}</TableCell>
                                        <TableCell align="center" sx = {{ fontFamily: "Inter", display: "flex", justifyContent: "center" }}>
                                            {/* { row.id === ""? <div> in progress <img src={in_progress} alt="" className="abc" /></div>: */}
                                            { row.id === ""? <div>Generation in progress ...</div>:
                                                <button type="button" className="btn btn-outline-secondary table_btn" onClick={() => download_report(row.id_main, row.extension)}><img src= {exp_logo} alt = "download icon" className='download_rep' /> &nbsp; {row.extension} </button>
                                            }
                                        </TableCell>
                                 </TableRow>
                             ))}
                         </TableBody>
                     </Table>
                    </TableContainer>:
                    map === 2 ? <div style={{display: "flex" , alignItems: "center", justifyContent: "center", height: '70vh'}}><img src={loading} alt="laoding svg" className="loading" /></div>: 
                    <div>some error ....try refreshing the page</div>   
                }
                
                {sample_rows.length === 0? 
                    <></>:
                <div className="pagination_container">
                    <div className="display_pages">
                        Showing &nbsp;<span>{total_page !== 0 ? (page-1)*10 + 1 : 0}</span>-<span>{(page-1)*10 + curr_data}</span>&nbsp;from&nbsp;<span>{total_data}</span>&nbsp;data 
                    </div>
                    <div className="nav_container">
                        <Stack spacing={2}>
                            <Pagination count={total_page} color='primary' page = {page} value={page} onChange={handlePage} variant="outlined" sx={{fontFamily: "'Fredoka', sans-serif"}} />
                        </Stack>
                    </div>
                </div>}
            </div>
            {/* <div className="history_meta_data_container">
                <div className="meta_date_range meta_main">
                    <div className="header">
                        Applied Date Range
                    </div>  
                    <div className="meta_content">
                    {fromDatePage === ''? "invalid date" :moment(fromDatePage).format("YYYY-MM-DD")}  <span style={{fontWeight: "bold"}}>-{`>`}</span> {toDatePage === '' ?"invalid date":moment(toDatePage).format("YYYY-MM-DD")}
                    </div>
                </div>
                <div className="meta_auto_gen meta_main">
                    <div className="header">
                        No of Auto-Generated Reports            
                    </div>      
                    <div className="meta_content">
                        0
                    </div>
                </div>
                <div className="meta_man_gen meta_main">
                    <div className="header">
                        No of Manually Generated Reports
                    </div>     
                    <div className="meta_content">
                        {total_data}
                    </div>
                </div>
                <div className="meta_auto_man_color meta_main">
                    <div className="auto">
                        <div className="green"></div>
                        <div className="color_content">Auto Generated </div>
                    </div>
                    <div className="manual">
                        <div className="yellow"></div>
                        <div className="color_content">Manually Generated</div>
                    </div>
                </div>

            </div> */}
        </div>
        <ToastContainer />
    </div>
  )
}