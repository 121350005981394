import React from 'react'
import { LeftDashboard } from '../Dashboard/left_dashboard'
import { RightHistoryView } from './rightHistoryView'
import '../../staticFiles/cssFiles/History/history.css'
import { Navigate } from 'react-router-dom'
import Cookies from 'js-cookie'

export const HistoryPage = () => {
  if(Cookies.get('token') === undefined){
    console.log("working")
    // return <Navigate to  = "/login" />
    window.location.href = '/login';
}
  return (
    <div className='history_container'>
        <LeftDashboard id = {'4_4'} />
        <RightHistoryView />
    </div>
  )
}
