import React from 'react'
import '../../staticFiles/cssFiles/adminPanel/rightAdminPanel.css'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { addOrganizations, deleteOrganizations, fetchOrganizations } from '../../endppoints/endpoint';
import { Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';

export const RightAdminPanel = () => {
	// const [toggle, setToggle] = React.useState(0) // 0 -> organization, 1 -> user
	const [addOrganization, setAddOrganization] = React.useState(0)
	const [redirect, setRedirect] = React.useState(0)
	const [organizationRows, setOrganizationRows] = React.useState([])
	const [newOrganization, setNewOrganization] = React.useState('')
	const [redirectOrg, setRedirectOrg] = React.useState('')
	
	React.useEffect(()=>{
		fetch_organizations()
	}, []);

	const create_payload = (pN, lI, sD, sT)=>{
		let message = {
			"pageNo":pN,
			"limit": lI,
			"sortDirection": sD,
			"searchText": sT
		}
		return message;
	}

	const fetch_organizations = async()=>{
		// let message = 
		// 	{
		// 		"pageNo":1,
		// 		"limit": 10,
		// 		"sortDirection": 1,
		// 		"searchText": ""
		// 	}

		let message = create_payload(1, 10, 1, "")
		
		let response = await fetchOrganizations(message);
		console.log(response)
		if(response.status === 200){
			let test_array = []
			console.log(response.data)
			for(let i = 0; i < response.data.data.length; i++){
				test_array.push(createData(response.data.data[i]._id))
			}
			console.log(test_array)
			setOrganizationRows(test_array)
		}
		else if(response.status === 401){
			// localStorage.clear()
            // Cookies.remove('token');
            // setTimeout(() => {
            //     setRedirect(1)    
            // }, 1000);
            toast.error("Unauthorized", { position: toast.POSITION.TOP_CENTER});
        }
        else{
            toast.info("server error", { position: toast.POSITION.TOP_CENTER})
        }
	}

	function createData(crud_organization) {
        return { crud_organization};
    }


	const handle_add_organization = ()=>{
		console.log(newOrganization)
		add_organization()
	}

	const add_organization = async()=>{
		// let message = {
		// 	"pageNo":1,
		// 	"limit": 10,
		// 	"sortDirection": 1,
		// 	"searchText": "",
		// 	"orgName": newOrganization,
		// }

		let message = create_payload(1, 10, 1, "")
		message["orgName"] = newOrganization

		let response = await addOrganizations(message)
		if(response.status === 200){
			let test_array = []
			console.log(response.data)
			for(let i = 0; i < response.data.data.length; i++){
				test_array.push(createData(response.data.data[i]._id))
			}
			console.log(test_array)
			setOrganizationRows(test_array)
            toast.info("ADDED "+ newOrganization, { position: toast.POSITION.TOP_CENTER, autoClose: 2000, closeButton: false})
			setAddOrganization(0)
		}
		else if(response.status === 401){
			localStorage.clear()
            Cookies.remove('token');
            setTimeout(() => {
                setRedirect(1)    
            }, 1000);
            toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
        }
        else{
            toast.info("server error", { position: toast.POSITION.TOP_CENTER})
        }
	}

	const handle_delete_organization = (org)=>{
		// event.preventDefault();
		delete_organization(org)
	}

	const delete_organization = async(org)=>{
		// let message = {
		// 	"pageNo":1,
		// 	"limit": 10,
		// 	"sortDirection": 1,
		// 	"searchText": "",
		// 	"orgName": org,
		// }

		let message = create_payload(1, 10, 1, "")
		message["orgName"] = org

		let response = await deleteOrganizations(message)
		if(response.status === 200){
			let test_array = []
			console.log(response.data)
			for(let i = 0; i < response.data.data.length; i++){
				test_array.push(createData(response.data.data[i]._id))
			}
			console.log(test_array)
			setOrganizationRows(test_array)
            toast.info("DELETED "+ org, { position: toast.POSITION.TOP_CENTER, autoClose: 2000, closeButton: false})
		}
		else if(response.status === 401){
			localStorage.clear()
            Cookies.remove('token');
            setTimeout(() => {
                setRedirect(1)    
            }, 1000);
            toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
        }
        else{
            toast.info("server error", { position: toast.POSITION.TOP_CENTER})
        }
	}

	const handle_toggle = (org) =>{
		setRedirectOrg(org)
		setRedirect(2)
		// fetch_users(org)
	}
	
	// const fetch_users = async(org)=>{
	// 	let message = create_payload(1, 10, 1, "")
	// 	message["orgId"] = org

	// 	let response = fetchUsers(message)
	// 	if(response.status === 200){
	// 		let test_array = []
	// 		console.log(response.data)
	// 		for(let i = 0; i < response.data.data.length; i++){
	// 			test_array.push(createData(response.data.data[i]._id))
	// 		}
	// 		console.log(test_array)
	// 		setOrganizationRows(test_array)
	// 	}
	// 	else if(response.status === 401){
	// 		localStorage.clear()
    //         Cookies.remove('token');
    //         setTimeout(() => {
    //             setRedirect(1)    
    //         }, 1000);
    //         toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
    //     }
    //     else{
    //         toast.info("server error", { position: toast.POSITION.TOP_CENTER})
    //     }
	// }

	if(redirect === 1){
		// return <Navigate to = "/login"/>
		window.location.href = '/login';
	}
	else if(redirect == 2){
		return <Navigate to = {'/adminPanel/' + redirectOrg} />
	}

  return (
    <div className='right_admin_panel_container'>
        <div className="admin_panel_data_container">
			{ addOrganization === 0 ?
				<>
					<div className="admin_panel_filters_container">
						<button className={"btn btn-primary toggle_1 toggle_buttons"}>
							Organizations
						</button>
						<input type="text" className="search_admin_panel" placeholder='Search' />
						<button className="add_organzation btn btn-sm btn-primary" onClick={()=>setAddOrganization(1)}>
							Add Organization
						</button>
					</div>
					<div className="admin_panel_table_container">
					{/* <TableContainer sx={{  height: "60vh", minHeight: "21vh", maxHeight: "62vh"}}> */}
					<TableContainer sx={{ minHeight: "21vh", maxHeight: "62vh"}}>
						<Table stickyHeader >
							<TableHead sx = {{ fontFamily: "Inter"}}>
								<TableRow>
									<TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px'}}>Organizations</TableCell>
									<TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px'}} align="left"></TableCell>
								</TableRow>
							</TableHead>
							<TableBody sx={{ height: "max-content" }}>
								{organizationRows.map((row, index) => (
									<TableRow 
									key={index}
									>   
										<TableCell component="th" scope="row" sx = {{ fontFamily: "Inter", fontWeight: "bold",  fontSize: '15px', color: "rgba(0, 0, 0, 0.87)", cursor: "pointer"}}  onClick={()=>handle_toggle(row.crud_organization)}>
											{row.crud_organization}
										</TableCell>
										<TableCell align="left" sx = {{ fontFamily: "Inter" }}>
											<button type="button" className="btn btn-outline-danger btn-sm delete_button" aria-label="Close" onClick={()=>handle_delete_organization(row.crud_organization)}>Delete</button>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					</div>
				</>:
				<div className="add_organization_container">
					<input type="text" placeholder='Enter Organization' className="input_organization common_input_add" onChange={(event)=>setNewOrganization(event.target.value)}/>
					<button className="btn btn-primary add_input_organization common_input_add" onClick={()=>handle_add_organization()}>Add Organization</button>
				</div>	
			}
        </div>
		<ToastContainer />

    </div>
  )
}
