import React from 'react'
import { RightAdminUsersPanel } from './rightAdminUsersPanel'
import "../../staticFiles/cssFiles/adminUsersPanel/adminUsersPanel.css"
import { LeftDashboard } from '../Dashboard/left_dashboard'

export const AdminUsersPanel = () => {
  return (
    <div className='admin_users_panel_container'>
        <LeftDashboard id = {'6'}/>
        <RightAdminUsersPanel />
    </div>
  )
}
