import React from 'react'
import { Navigate } from 'react-router-dom';
import '../../staticFiles/cssFiles/signUp.css'
import { send_user_cred } from '../../endppoints/testing_routes';

export const SignUp = () => {

    const [username, setUserName ] = React.useState("");
    const [password, setPassword ] = React.useState("");
    const [role, setRole ] = React.useState("");
    const [redirect, setRedirect ] = React.useState(0);

    const mySubmitHandler = (event) => {
        event.preventDefault();
        alert(`The creed you entered is: ${username}, ${password}`);
        goSignUp();
    }

    const goSignUp = async() =>{
        console.log("inside1");
        let response = {};
        let message = { 'user': username, 'psw': password, 'role': role }
        response = await send_user_cred(message);
        
        if (response['status'] === '12'){
            alert('Success......redirecting to login');
            setRedirect(1);
        }
        else if(response['status'] === '11'){
            alert('Email Already Exist....Try with a different email');
            setRedirect(0);
        }
        else if(response['status'] === '500'){
            alert('Internal server error...please try after sometime');
            setRedirect(0);
        }
    }

    if (redirect === 1) {
        console.log("inside status: " + redirect)
        // return <Navigate to='/login' />
        window.location.href = '/login';
    }
    else{
        return (
            <div className='signUp_container col-md-5'>
                <div className='topic'>ADVERSE MEDIA ✈️</div>
                <form onSubmit={mySubmitHandler}>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Email address</label>
                        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" value={username} onChange={(e) => setUserName(e.target.value)} required/>
                        <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Password</label>
                        <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputRole1">Role</label>
                        <input type="text" className="form-control" id="exampleInputRole1" placeholder="Password" value={role} onChange={(e) => setRole(e.target.value)} required/>
                    </div>
                    <button type="submit" className="btn btn-primary">Submit</button>
                </form>
            </div>
        )
    }
}
