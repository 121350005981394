import React, { useState } from 'react'
import '../../staticFiles/cssFiles/DraftArticles/rightDraftArticles.css'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Collapse, Pagination, Stack } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { RemoveArticlesFromDraft, categoriesDefaultGet, changeDraftArticlesOrder, cloneDraft, deleteDraft, exportPdf, fetchAllDraftArticleIds, getAllDraftArticlesForRearrangeApi, getCategories, getDraftArticles, getDraftArticlesNew, getExportData, getFrequentKeywords, getKeywordsPreviewArticles, getKeywordsPreviewArticlesNew } from '../../endppoints/endpoint';
// import Box from '@mui/material/Box';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import { ViewArticle } from '../onDemandPdf/viewArticle';
import { Navigate, useLocation } from 'react-router-dom';
// import CreatableSelect from 'react-select/creatable'; 
import loading from "../../staticFiles/images/loading.svg";
import export_loading from "../../staticFiles/images/loading_export.svg";
import no_result_logo from "../../staticFiles/images/No_results.svg";
// import scr_up from "../../staticFiles/images/arrow-Up-circle-filled.svg";
// import scr_down from "../../staticFiles/images/arrow-down-circle-filled.svg";
import expArw from "../../staticFiles/images/expArw.svg"
import colArw from "../../staticFiles/images/colArw.svg"
import { useParams } from 'react-router-dom'
// import deleteIcon from "../../staticFiles/images/deleteIcon.svg"
import delete_draft from "../../staticFiles/images/delete_draft.svg"

import moment from 'moment/moment';

import { Tooltip } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import reports_export from "../../staticFiles/images/Export_new_img.svg"
import expand_icon from "../../staticFiles/images/selections_expand.svg"
import collapse_icon from "../../staticFiles/images/selections_collapse.svg"
import delete_article_icon from "../../staticFiles/images/delete_draft_article.svg"
import { PageAnimations } from '../GlobalPageAnimations.jsx/pageAnimations';
import ReactModal from 'react-modal';
import Cookies from 'js-cookie';

import translate_icon from "../../staticFiles/images/translate_icon.svg"
import { Test2 } from '../test2/test2';
import { actionCreators } from '../../store';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useSelector } from 'react-redux';
import searchIcon from "../../staticFiles/images/searchIcon.svg"

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


export const RightDraftArticles = () => {

    const dispatch = useDispatch()
	const {updateStates} = bindActionCreators(actionCreators, dispatch);

    const {
        articlesDataForRearrangeComponent
      } = useSelector(state => state.dashboardState);

    const location = useLocation();
    let fromD = null;
    let toD = null;
    let keys = null;
    if(location.state !== null){
        fromD = location.state.fromD;
        toD = location.state.toD;
        keys = location.state.keys;
    }
    const { draft_id } = useParams();
    const [allId, setAllId] = React.useState([])
    const [currentIds, setCurrentIds] = React.useState([])
    const [selectedIds, setSelectedIds]  = React.useState({})

    const [exportAs, setExportAs] = React.useState('');
	const tableRef = React.useRef(null);
    const [expanded, setExpanded] = React.useState({});
    const [redirect, setRedirect] = React.useState(0)
    const [map, setMap] = React.useState(0)
    const [expmap, setExpMap] = React.useState(1)
    const [totalArticles, setTotalArticles] = React.useState(null)
    const [checkboxes, setCheckboxes] = React.useState([])
	const [promptToggle, setPromptToggle] = React.useState(false)
    const [sampleRows, setSampleRows] = React.useState([])
    const [category, setCategory] = React.useState(null)
    const [fromDate, setFromDate] = React.useState(fromD ? fromD.split("T")[0]: fromD)
    const [toDate, setToDate] = React.useState(toD ? toD.split("T")[0] : toD)
    const [reportKeywords, setReportKeywords] = React.useState(keys)
    const [totalData, setTotalData] = React.useState(0) 
    const [totalPage, setTotalPage] = React.useState(0) 
    const [page, setPage] = React.useState(1)
    const [selectionsExpand, setSelectionsExpand] = React.useState(true) 
    const [isOpenDraftArticleKeywords, setIsOpenDraftArticleKeywords] = useState(false)

    const [isOpenDraftDelete, setIsOpenDraftDelete] = useState(false)

    const [isOpenDraftArticleDelete, setIsOpenDraftArticleDelete] = useState(false)

    const [isOpenExportModal, setIsOpenExportModal] = useState(false)

    const [idToDelete, setIdToDelete] = useState("")

    const [translateToggle, setTranslateToggle] = useState([])
    const [draftType, setDraftType] = useState("")

    const [draftResponseMetaData, setDraftResponseMetaData] = useState({"fromDate": null, "toDate": null, "category": null})
    const [isOpenRearrageDraftArticles, setIsOpenRearrangeDraftArticles] = useState(false)
    const [allRearrangeArticles, setAllRearrangeArticles] = useState([])

    const [draftArticlesDeletePrompt, setDraftArticlesDeletePrompt] = useState("")

    const [isOpenSelectAllArticlesPopup, setIsOpenSelectAllArticlesPopup] = useState(false)
    const [isOpenDeSelectAllArticlesPopup, setIsOpenDeSelectAllArticlesPopup] = useState(false)
    const [checkBoxAllSelect, setCheckBoxAllSelect] = useState(false)
	const [draftArticlesSearch, setDraftArticlesSearch] = React.useState("")

    React.useEffect (()=>{
        console.log(fromDate, toDate, reportKeywords)
        if(fromDate != null && toDate !== null &&  reportKeywords != null){
            let message = {
                "keywords": reportKeywords,
                "fromDateTime": fromD,
                "toDateTime": toD 
            }
            get_preview_articles_data(message)
        }
        else{
            let message = {
                "draftId": draft_id
            }
            get_draft_articles_data(message);
        }
    }, [])

    const customStylesDraft = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			minWidth: '25%',
			maxHeight: '70%',
			background: '#F6F7F9',
			borderRadius: "8px",
			paddingTop: '5vh',
			paddingBottom: '5vh',
			paddingLeft: '5vw',
			paddingRight: '5vw',
            background: "linear-gradient(180deg, #B1D6FC 0%, rgba(195, 219, 244, 0.73) 100%)"
		},
		overlay: {
			backgroundColor: 'rgba(0, 0, 0, 0.71)',
            backdropFilter: "blur(2.5px)",
            zIndex: 1
		},
    };

    const customStylesDraftDelete = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			minWidth: '25%',
			minHeight: '20%',
			background: '#FFFFFF',
			borderRadius: "14px",
			paddingTop: '5vh',
			paddingBottom: '5vh',
			paddingLeft: '5vw',
			paddingRight: '5vw',
            display: "flex",
            justifyContent: "center"
		},
		overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.71)',
            backdropFilter: "blur(2.5px)",
            zIndex: 1
		},
	  };

      const customStylesDraftArticlesRearrange = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			minWidth: '100%',
			// minHeight: '100%',
			background: '#FFFFFF',
			borderRadius: "14px",
			paddingTop: '5vh',
			paddingBottom: '5vh',
			paddingLeft: '5vw',
			paddingRight: '5vw',
            overflow: "unset"
            // display: "flex",
            // justifyContent: "center"
		},
		overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.71)',
            backdropFilter: "blur(2.5px)",
            zIndex: 1
		},
	  };

    
    const closeModalSelectAllDraftArticles = () =>{
        setIsOpenSelectAllArticlesPopup(false)
    }
    const closeModalDeSelectAllDraftArticles = () =>{
        setIsOpenDeSelectAllArticlesPopup(false)
    }
    
    const closeRearrageDraftArticlesModal = () =>{
        setIsOpenRearrangeDraftArticles(false)
    }

    const closeModalDraftArticleKeywords = () =>{
        setIsOpenDraftArticleKeywords(false)
    }

    const closeModalDraftDelete = () =>{
        setIsOpenDraftDelete(false)
    }

    const closeModalDraftArticleDelete = () =>{
        setIsOpenDraftArticleDelete(false)
    }

    const closeModalExport = () =>{
        setIsOpenExportModal(false)
    }

    const handlePage = (event, pg) =>{
        setPage(pg)
        if(reportKeywords != null){
            let message = {
                "keywords": reportKeywords,
                "fromDateTime": fromD,
                "toDateTime": toD,
                "pageNo": pg
            }      
            get_preview_articles_data(message)
        }
        else{
            let message = {
                "draftId": draft_id,
                "pageNo": pg, 
                "itemsPerPage": 10,
                "searchText": draftArticlesSearch
            }      
            get_draft_articles_data(message)
        }
    }

    const get_preview_articles_data = async(message) =>{
        let response = {};
        setMap(2)
        // let message = {
        //     "keywords": reportKeywords,
        //     "fromDateTime": fromD,
        //     "toDateTime": toD 
        // }
        response = await getKeywordsPreviewArticlesNew(message)
        console.log(response)

        if(response.status === 200){
            setMap(1)
            let test_rows = []
            let ids = []
            for(let i = 0; i < response.data['data'].length; i++){
                test_rows.push(createData(response.data.data[i].Title, response.data.data[i].published_date, response.data.data[i].keywords_p.Location, response.data.data[i]._id, response.data.data[i].matched_keywords, response.data.data[i].Title_translated, response.data.data[i].domain_name))
                ids.push(response.data.data[i]._id)
                translateToggle.push(false)
            }
            setTotalData(response.data.totalCount)
            if(response.data['totalCount'] % 10 === 0){
				setTotalPage(Math.floor(response.data['totalCount'] / 10))
			}
			else{
				setTotalPage(Math.floor(response.data['totalCount'] / 10) + 1)
			}
            setSampleRows(test_rows)
            setAllId(ids)
            setCurrentIds(ids)
            setCheckboxes(Array(ids.length).fill(true))
            setCategory("Keywords Report")
            test_rows.length !== 0 ? setTotalArticles(test_rows.length): setTotalArticles(null) 
        }

        else if(response.status === 401){
			// alert("invalid token or token expired")
			setTimeout(() => {
				setRedirect(1)
			}, 1000);
			toast.info("invalid token or token expired",  { position: toast.POSITION.TOP_CENTER});
		}
		else{
			toast.error('server error',  { position: toast.POSITION.TOP_CENTER});
		}
    }

    const get_draft_articles_data =async(message)=>{
        let response = {};
        setMap(2)
        response = await getDraftArticlesNew(message)
        console.log(response)

        if(response.status === 200){
            setMap(1)
            let test_rows = []
            let ids = []
            let updatedArray = []
            for(let i = 0; i < response.data.data['articles'].length; i++){
                test_rows.push(
                    createData(
                        response.data.data.articles[i].Title,  
                        response.data.data.articles[i].published_date, 
                        response.data.data.articles[i].keywords_p.Location, 
                        response.data.data.articles[i]._id, 
                        response.data.data.articles[i].matched_keywords,
                        response.data.data.articles[i].Title_translated,
                        response.data.data.articles[i].domain_name
                    )
                )
                ids.push(response.data.data.articles[i]._id)
                updatedArray.push(true)
            }
            setTranslateToggle(updatedArray)
            console.log(test_rows)
            setTotalData(response.data.totalCount)
            if(response.data['totalCount'] % 10 === 0){
				setTotalPage(Math.floor(response.data['totalCount'] / 10))
			}
			else{
				setTotalPage(Math.floor(response.data['totalCount'] / 10) + 1)
			}
            let temp_meta_data = {}
            temp_meta_data["fromDate"] = response.data.data.fromDate
            temp_meta_data["toDate"] = response.data.data.toDate
            temp_meta_data["category"] = response.data.data.category
            console.log(temp_meta_data)
            setDraftResponseMetaData(temp_meta_data)
            setDraftType(response.data.data["draftType"])
            setSampleRows(test_rows)
            setAllId(ids)
            setCurrentIds(ids)
            setCheckboxes(Array(ids.length).fill(true))
            setCategory(response.data.data.draftName)
            const dateStrings = response.data.data.articles.map(article => article.published_date_IST);
            const dates = dateStrings.map(dateString => moment(dateString));
            setFromDate(moment.min(dates).format("YYYY-MM-DD"));
            setToDate(moment.max(dates).format("YYYY-MM-DD"));
            test_rows.length !== 0 ? setTotalArticles(test_rows.length): setTotalArticles(null) 
        }

        else if(response.status === 401){
			// alert("invalid token or token expired")
			setTimeout(() => {
				setRedirect(1)
			}, 1000);
			toast.info("invalid token or token expired",  { position: toast.POSITION.TOP_CENTER});
		}
		else{
			toast.error('server error',  { position: toast.POSITION.TOP_CENTER});
		}
    }

    function createData(article, date, region, id, matched_keywords, Title_translated, domain_name) {
        return { article, date, region, id, matched_keywords, Title_translated, domain_name};
      }

    // const handleCheckBox = (event, index, idx)=>{
    //     console.log(allId)
    //     if (event.target.checked === true){
    //         console.log('true')

    //         const newArray = [...checkboxes];
    //         newArray[idx] = !newArray[idx];
    //         setCheckboxes(newArray);

    //         setAllId([...allId, index])
    //         setTotalArticles(allId.length+1)
    //     }
    //     else if(event.target.checked === false){
    //         console.log("inside false")

    //         const newArray = [...checkboxes];
    //         newArray[idx] = !newArray[idx];
    //         setCheckboxes(newArray);

    //         // setAllId(allId.filter((_, i) => i !== index))
    //         setAllId(allId => allId.filter(item => item !== index))
    //         setTotalArticles(allId.length-1)
    //     }

    // }

    // const handleCheckBox_2 = (event) =>{
    //     setCheckboxes(Array(currentIds.length).fill(event.target.checked));
    //     if (event.target.checked === true){
    //         setAllId(currentIds)
    //         setTotalArticles(currentIds.length)
    //     }
    //     else if(event.target.checked === false){
    //         console.log("inside false")
    //         setAllId([])
    //         setTotalArticles(0)
    //     }
    // } 

    const handleCheckBox = (e, id) =>{
        const isChecked = e.target.checked;
        if (isChecked) {
            setSelectedIds(prevSelectedIds => ({
                ...prevSelectedIds,
                [id]: true,
            }));
            console.log("checked");
            } else {
            setSelectedIds(prevSelectedIds => {
                const { [id]: _, ...restIds } = prevSelectedIds;
                return restIds; // This is needed to update the state correctly
            });
            console.log("unchecked");
            }
	}


    const handleDeleteArticles = async() => {
        closeModalDraftArticleDelete()
        let message = {
            "draftId" : draft_id,
            "articleIds" : [idToDelete]
        }
        let response = await RemoveArticlesFromDraft(message)
        if(response.status === 200){
			toast.info("Article Deleted Successfully",  { position: toast.POSITION.TOP_CENTER, autoClose:1500});
            if(sampleRows.length > 1){
                // setPage(1)
                message={
                    "draftId": draft_id,
                    "pageNo": page, 
                    itemsPerPage: 10
                }
            }
            else{
                if(page === 1){
                    message={
                        "draftId": draft_id,
                        "pageNo": page, 
                        itemsPerPage: 10
                    }
                }
                else{
                    setPage(page-1)
                    message={
                        "draftId": draft_id,
                        "pageNo": (page-1), 
                        itemsPerPage: 10
                    }
                }
            }
            get_draft_articles_data(message)
        }

        else if(response.status === 401){
			// alert("invalid token or token expired")
			setTimeout(() => {
				setRedirect(1)
			}, 1000);
			toast.info("invalid token or token expired",  { position: toast.POSITION.TOP_CENTER});
		}
		else{
			toast.error('server error',  { position: toast.POSITION.TOP_CENTER});
		}
    } 

    const handleDeleteMultipleArticlesFromDraft = async() =>{
        closeModalDraftDelete()
        let message = {
            "draftId" : draft_id,
            "articleIds" : Object.keys(selectedIds)
        }
        let response = await RemoveArticlesFromDraft(message)
        if(response.status === 200){
			toast.info("Article Deleted Successfully",  { position: toast.POSITION.TOP_CENTER, autoClose:1500});
            if(sampleRows.length > Object.keys(selectedIds).length){
                // setPage(1)
                message={
                    "draftId": draft_id,
                    "pageNo": page, 
                    itemsPerPage: 10
                }
            }
            else{
                if(page === 1){
                    message={
                        "draftId": draft_id,
                        "pageNo": page, 
                        itemsPerPage: 10
                    }
                }
                else{
                    setPage(page-1)
                    message={
                        "draftId": draft_id,
                        "pageNo": (page-1), 
                        itemsPerPage: 10
                    }
                }
            }
            setSelectedIds({})
            get_draft_articles_data(message)
        }

        else if(response.status === 401){
			// alert("invalid token or token expired")
			setTimeout(() => {
				setRedirect(1)
			}, 1000);
			toast.info("invalid token or token expired",  { position: toast.POSITION.TOP_CENTER});
		}
		else{
			toast.error('server error',  { position: toast.POSITION.TOP_CENTER});
		}
    }


    const export_Pdf = async(message) =>{
        let response = {}
        // setExpMap(2)
        response = await exportPdf(message)
        
        if(response.hasOwnProperty('status') && response['status'] === 401){
            setTimeout(() => {
				setRedirect(1)
			}, 1000);
			toast.info("invalid token or token expired",  { position: toast.POSITION.TOP_CENTER});
        }
        if(response.hasOwnProperty('status') && response['status'] === 404){
            setExpMap(1)
            setExportAs('');
			toast.info("server error",  { position: toast.POSITION.TOP_CENTER});
        }

        if (promptToggle){
            setTimeout(() => {
                setRedirect(2)
			}, 5000);
            let str = "You are being redirected to Report History page !!! Please check it back after 10 minutes for the QA report to be ready"
            console.log(str)
            toast.info(str,  { position: toast.POSITION.TOP_CENTER})

        }
        else{
            setTimeout(() => {
                setRedirect(2)
			}, 5000);
            let str = "You are being redirected to Report History page !!! Please check it back in less than 5 minutes for the Articles report to be ready"
            console.log(str)
            toast.info(str,  { position: toast.POSITION.TOP_CENTER})
            // setExpMap(1)
            // setExportAs('');

            // if(response.hasOwnProperty('status') && response['status'] === 500){
            //     alert('Token expired...login again !!!!')
            //     setRedirect(1)
            // }
        }
    }

    const handleExportValidation = () =>{
        if(sampleRows.length === 0){
            toast.info('No data to export ... Try applying filters !!!!',  { position: toast.POSITION.TOP_CENTER});
            setExportAs('');
        }
        else if(Object.keys(selectedIds).length === 0){
            toast.info('Please Select Articles to Export !!!!',  { position: toast.POSITION.TOP_CENTER, autoClose: 1500});
            setExportAs('');
        }
        else{
            setIsOpenExportModal(true)
        }
    }

    const handleExport = (event) => {
        // console.log(event.target.value)
        // setExportAs(event.target.value);
        closeModalExport()
        console.log(draftResponseMetaData)
        let message = {}
        if(reportKeywords == null){
            message =  {
                'fromDate': draftResponseMetaData.fromDate !== null ? draftResponseMetaData.fromDate: fromDate,
                'toDate': draftResponseMetaData.toDate !== null ? draftResponseMetaData.toDate: toDate,
                "fileType": "pdf",
                "ids": Object.keys(selectedIds),
                "category": draftResponseMetaData.category !== null ? draftResponseMetaData.category: category,
                "reportFormat": promptToggle ? "qa":"articles"
            }
        }
        else{
            message =  {
                'fromDate': fromDate,
                'toDate': toDate,
                "fileType": "pdf",
                "ids": Object.keys(selectedIds),
                "category": category,
                "reportFormat": promptToggle ? "qa":"articles"
            }
        }
        console.log(message)
        if(sampleRows.length === 0){
        toast.info('No data to export ... Try applying filters !!!!',  { position: toast.POSITION.TOP_CENTER});
        setExportAs('');
        }
        else if(Object.keys(selectedIds).length === 0){
            toast.info('Please Select Articles to Export !!!!',  { position: toast.POSITION.TOP_CENTER, autoClose: 1500});
            setExportAs('');
        }

        else{
            export_Pdf(message);
            // console.log("exporting")
        }
    }; 


    // const handleSelectAll = () =>{
    //     let flag = true
    //     for(let i =0; i < sampleRows.length; i++){
    //         if(checkboxes[i] === false){
    //             flag = false
    //             break;
    //         }
    //     }
    //     return flag
    // }

    const handleSyncCheckBoxKeywords = () =>{
		let flag = true
		for(let i =0; i < sampleRows.length; i++){
			if(selectedIds[sampleRows[i]["id"]] === undefined){
				flag = false
				break;
			}
		}
        if (sampleRows.length === 0){
            return false
        }
		return flag
	}

    // const handleCheckBoxAll = (e) => {
	// 	const isChecked = e.target.checked;
	  
	// 	if (isChecked) {
	// 	  setSelectedIds(prevSelectedIds => {
	// 		const updatedSelectedIds = { ...prevSelectedIds };
	// 		sampleRows.forEach(item => {
	// 		  updatedSelectedIds[item.id] = true;
	// 		});
	// 		return updatedSelectedIds;
	// 	  });
	  
	// 	  console.log("checked");
	// 	} else {
	// 	  setSelectedIds(prevSelectedIds => {
	// 		const updatedSelectedIds = { ...prevSelectedIds };
	// 		sampleRows.forEach(item => {
	// 		  if (updatedSelectedIds[item.id]) {
	// 			delete updatedSelectedIds[item.id];
	// 		  }
	// 		});
	// 		return updatedSelectedIds;
	// 	  });
	  
	// 	  console.log("unchecked");
	// 	}
	//   };

    const handleExpandArticles = (val) =>{
        let expand_dict = {}
        if(val === "exp"){
            for(let i = 0; i < 10; i++){
                expand_dict[i] = true
            }
            setSelectionsExpand(false) 
        }
        else{
            for(let i = 0; i < 10; i++){
                expand_dict[i] = false
            } 
            setSelectionsExpand(true) 
        }
        setExpanded(expand_dict)
    }

    const handleDeleteDraft = async() =>{
		let response = await deleteDraft(draft_id)
		if(response.status === 200){
			setRedirect(3)
		}
		else if(response.status === 401){
			localStorage.clear()
			Cookies.remove('token');
			setTimeout(() => {
				setRedirect(2)    
			}, 1000);
			toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
		}
		else{
			toast.info("server error", { position: toast.POSITION.TOP_CENTER})
		}
	}

    const handleToggleTranslate = (index) =>{
        const updatedArray = [...translateToggle];
        updatedArray[index] = !translateToggle[index]; 
        setTranslateToggle(updatedArray);
    }

    const handleCloneDraft = async()=>{
        let message = {
                "draftId": draft_id,
        }

        let response = await cloneDraft(message)
        if(response.status === 200){
            toast.success("Draft Cloned.", { position: toast.POSITION.TOP_CENTER, autoClose: 1000});
            setRedirect(3)
        }
        else if(response.status === 401){
            localStorage.clear()
            Cookies.remove('token');
            setTimeout(() => {
                setRedirect(1)   
            }, 1000);
            toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
        }
        else{
            toast.info("server error", { position: toast.POSITION.TOP_CENTER})
        }
    }

    const handleRearrangePopupData = () => {
        // setIsOpenRearrangeDraftArticles(true)
        if(allRearrangeArticles.length === 0){
            fetchAllArticlesForRearrange()   
            setIsOpenRearrangeDraftArticles(true)        
        }
        else{
            fetchAllArticlesForRearrange()   
            setIsOpenRearrangeDraftArticles(true)
        }
    }

    const fetchAllArticlesForRearrange = async() =>{
        let message = draft_id

        let response = await getAllDraftArticlesForRearrangeApi(message)
            if(response.status === 200){
                updateStates({
                    "type": "reducerUpdates",
                    "payload": {
                        articlesDataForRearrangeComponent: response.data.data
                    }
                });
                setAllRearrangeArticles(response.data.data)
            }
            else if(response.status === 401){
                localStorage.clear()
                Cookies.remove('token');
                setTimeout(() => {
                    setRedirect(1)   
                }, 1000);
                toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
            }
            else{
                toast.info("server error", { position: toast.POSITION.TOP_CENTER})
            }
        }
    
    const handle_confirm_new_order = async() =>{
        let message = {
            "draftId": draft_id,
            "articleIds": articlesDataForRearrangeComponent.map(item => item._id),
            "itemsPerPage": 1
        }
        let response = await changeDraftArticlesOrder(message)
        if(response.status === 200){
            setPage(1)
            message = {
                "draftId": draft_id,
                "pageNo": 1, 
                itemsPerPage: 10
            }      
            get_draft_articles_data(message)
            setIsOpenRearrangeDraftArticles(false)
        }
        else if(response.status === 401){
            localStorage.clear()
            Cookies.remove('token');
            setTimeout(() => {
                setRedirect(1)   
            }, 1000);
            toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
        }
        else{
            toast.info("server error", { position: toast.POSITION.TOP_CENTER})
        }
    }

    const handle_open_delete_draft_popup = () =>{
        setIsOpenDraftDelete(true)
        if(Object.keys(selectedIds).length > 0){
            setDraftArticlesDeletePrompt("Delete " +Object.keys(selectedIds).length+" Articles")
        }
        else{
            setDraftArticlesDeletePrompt("Delete Draft")
        }
    }

    const handleCheckBoxCurrent = () => {
        setIsOpenSelectAllArticlesPopup(false)
        // setCheckBoxAllSelect(true)
		  setSelectedIds(prevSelectedIds => {
			const updatedSelectedIds = { ...prevSelectedIds };
			sampleRows.forEach(item => {
			  updatedSelectedIds[item.id] = true;
			});
			return updatedSelectedIds;
		  });
	  
		  console.log("checked");
	  };

      const handleCheckBoxEntireData = async() => {
        setIsOpenSelectAllArticlesPopup(false)
        let message = draft_id
        let response = await fetchAllDraftArticleIds(message)

        if(response.status === 200){
            // setCheckBoxAllSelect(true)
            let temp_ids = {}
            for(let i=0; i < response.data.data.length; i++){
                temp_ids[response.data.data[i]] = true
            }
            setSelectedIds(temp_ids)
            console.log("checked");
        }
        else if(response.status === 401){
            localStorage.clear()
            Cookies.remove('token');
            setTimeout(() => {
                setRedirect(1)   
            }, 1000);
            toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
        }
        else{
            toast.info("server error", { position: toast.POSITION.TOP_CENTER})
        }

        // setSelectedIds(prevSelectedIds => {
        //   const updatedSelectedIds = { ...prevSelectedIds };
        //   sampleRows.forEach(item => {
        //     updatedSelectedIds[item.id] = true;
        //   });
        //   return updatedSelectedIds;
        // });
    
    };

    const handleDeselectCheckboxAll = async(type) => {
        setIsOpenDeSelectAllArticlesPopup(false)
        if(type === "deselect_current"){
            setSelectedIds(prevSelectedIds => {
                const updatedSelectedIds = { ...prevSelectedIds };
                sampleRows.forEach(item => {
                  if (updatedSelectedIds[item.id]) {
                    delete updatedSelectedIds[item.id];
                  }
                });
                return updatedSelectedIds;
              });
          
              console.log("unchecked");
        }
        else{
            setSelectedIds({});
            console.log("unchecked");
            setCheckBoxAllSelect(false)
        }
        
	  };

    const handleMainCheckBox = (e) =>{
        const isChecked = e.target.checked;
        if(!isChecked){
            // setSelectedIds({});
            // console.log("unchecked");
            // setCheckBoxAllSelect(false)
            setIsOpenDeSelectAllArticlesPopup(true)
        }
        else{
            setIsOpenSelectAllArticlesPopup(true)
        }
    }

    const handleDraftArticlesSearch = (e) =>{
		// console.log(e.target.value)
		setPage(1)
		// setDraftArticlesSearch(e.target.value)
		let message = {
			"draftId": draft_id,
            "pageNo": "1", 
            "itemsPerPage": 10,
            "searchText": draftArticlesSearch	
		}
		get_draft_articles_data(message)
	}

    if(redirect === 1){
        // return <Navigate to = '/login' />
        window.location.href = '/login';
    }
    if(redirect === 2){
        return <Navigate to = '/history' />
    }
    if(redirect === 3){
        return <Navigate to = '/draft' />
    }

  return (
  
    <div className='right_draft_articles_container'> 
      {/* {console.log(Object.keys(selectedIds).length)}  */}
        <PageAnimations/>                   
        <div className="filter_container">
            <div className="filter_content_container">
                <div className="DraftName">
                    {reportKeywords === null && <div >Draft Preview : </div>}
                    <span style={{color: "#317ae7"}}>{category}</span>
                    {reportKeywords === null && <div className='tags'><span className='draft_type'>{draftType}</span> {draftType==="Auto Generated"? <span className='draft_type'>Read Only</span> : <></>}</div>}
                </div>
                {/* <div className="prompt_toggler">
                    <div className="toggler_main_ele">
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked = {promptToggle} onChange={() => setPromptToggle
                            (! promptToggle)} />
                        </div>
                    </div>
                    <div className='toggler_right_ele'>{promptToggle ? "Switch to Articles": "Switch to Q&A"}</div>
                </div> */}
                {reportKeywords &&
                <div className="date_filter_container filter_parts">
                    <div className="from_date input_date">
                        <div className="from_to_text">
                            From
                        </div>
                    </div>
                    <div className="from_to_text_read_only">
                        <div className="date_text date_time_text">
                            {moment(fromD).format("DD/MM/YYYY")}
                        </div>
                        <div className="time_text date_time_text">
                            {moment(fromD).format('h:mm a')}
                        </div>
                    </div>
                    <div className="to_date input_date">
                        <div className="from_to_text">
                            to
                        </div>
                    </div>
                    <div className="from_to_text_read_only">
                    <div className="date_text date_time_text">
                            {moment(toD).format("DD/MM/YYYY")}
                        </div>
                        <div className="time_text date_time_text">
                            {moment(toD).format('h:mm a')}
                        </div>
                    </div>
                </div>
                }

                { !reportKeywords && 
                    <div className="crud_search">
                        <img src={searchIcon} alt="" className="search_icon" />
                        <input type="text" className="crud_add" placeholder='Search' value={draftArticlesSearch} onChange = {(e)=>setDraftArticlesSearch(e.target.value)} onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleDraftArticlesSearch();
                            }
                        }}/>
                    </div>

                }

                <div className="prompt_toggler">
                    <div className='toggler_left_ele'>Switch to</div>

                    <div className="toggler_main_ele">
                        {/* <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked = {promptToggle} onChange={() => setPromptToggle
                            (! promptToggle)} />
                        </div> */}
                        <div className="toggler_main">
                            <button className={promptToggle ? "btn toggles left_toggle left_toggle_circle": "btn toggles left_toggle"} onClick={() => setPromptToggle(! promptToggle)} >
                                <div className='toggle_text'>QA</div>
                            </button>
                            {/* <div className="verticle_pipe">

                            </div> */}
                            <button className={ promptToggle ? "btn toggles right_toggle": "btn toggles right_toggle right_toggle_circle"} onClick={() => setPromptToggle(! promptToggle)}>
                                <div className='toggle_text'>Articles</div>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="export_pdf_container filter_parts">
                    {   expmap === 1 ? 
                        // <Box sx={{ minWidth: "6vw", alignItems: 'center'}}>
                        //     <FormControl sx = {{ background: '#FFFFFF', width: "6vw"}} size='small' >
                        //         <InputLabel id="demo-simple-select-label" >Export</InputLabel>
                        //         <Select
                        //             labelId="demo-simple-select-label"
                        //             id="demo-simple-select"
                        //             value={exportAs}
                        //             label="Export"
                        //             onChange = {handleExport}
                        //             disabled = {sampleRows.length === 0 || allId.length === 0? true: false}                                    
                        //             >
                        //             <MenuItem value='pdf'>pdf</MenuItem>
                        //             {!promptToggle && <MenuItem value='docx'>docx</MenuItem>}
                        //         </Select>
                        //     </FormControl>
                        // </Box>
                        <button className="Export_button btn btn-primary" onClick = {() => handleExportValidation()}>
                            Export
                            <img src = {reports_export} alt = "reports_export" className='export_image'/>
                        </button>
                        // <img src = {reports_export} className="Export_button" onClick = {() => handleExportValidation()} disabled = {sampleRows.length === 0 || Object.keys(selectedIds).length === 0? true: false} />
                        :
                        expmap === 2 ? <div><img src={export_loading} alt="exporting_svg" className="loadingExport"  /></div>: 
                        <div>some error ....try refreshing the page</div>   
                    }
                </div>
                {totalArticles !== 0 && reportKeywords === null && draftType !== "Auto Generated" &&
                    <div className="delete_draft ml-4" onClick={()=>handle_open_delete_draft_popup()}>
                        <img src={delete_draft} alt="delete articles icon" className="deleteIcon" />
                    </div>
                }
            </div>
        </div>

        <div className="applied_filters_container">
            {/* {
                totalArticles !== 0 && 
                <>
                    <div className="total_articles">
                        Total Selected Articles : &nbsp;&nbsp; <span>{Object.keys(selectedIds).length}</span>
                    </div>
                    {reportKeywords === null &&
                        <div className="delete_articles" onClick={()=>handleDeleteArticles()}>
                            <img src={deleteIcon} alt="delete articles icon" className="deleteIcon" />
                        </div>
                    }
                </>
            } */}
            <div className="expand_collapse">
                <div className={selectionsExpand?"expan" : "expan back_blue"} onClick={()=>handleExpandArticles("exp")}>
                    <img src={expand_icon} alt="expand_icon" className= {selectionsExpand?"expand_icon" : "expand_icon white_icon"} />   
                </div>
                <div className={selectionsExpand?"collaps back_blue" : "collaps "} onClick={()=>handleExpandArticles("col")}>
                    <img src={collapse_icon} alt="collapse_icon" className= {selectionsExpand?"collapse_icon" : "collapse_icon black_icon"}/>
                </div>
            </div>
            {totalArticles !== null  && <div className="total_articles">
                Selected Articles : &nbsp;&nbsp; <span>{Object.keys(selectedIds).length}</span>
            </div>}
            {   totalArticles !== null  &&
                <div className="delete_articles total_articles">
                    Total Articles :  &nbsp;&nbsp; <span>{totalData}</span>
                </div>
            }
            {reportKeywords !== null &&
                <div className="total_articles" onClick={()=>setIsOpenDraftArticleKeywords(true)}>
                    Keywords Selected :  &nbsp;&nbsp; <span>{keys.length}</span>
                </div>
            }
            <div className="right_cells">
                {reportKeywords===null &&
                    <button className="clone_draft btn btn-primary btn-sm" onClick={()=>handleCloneDraft(true)}>
                        Clone
                    </button>
                }
                {reportKeywords===null && 
                    <button className="rearrange_draft btn btn-primary btn-sm ml-2" onClick={()=>handleRearrangePopupData()}>
                        Rearrange Articles
                    </button>
                }
            </div>
           
        </div>
        <div className="table_wrapper">
            <div className="on_demand_table_container" ref={tableRef}>
                {   map === 0 ? <div style={{ textAlign: "center", marginTop: "12%", border: '1px solid #3B89FE', fontWeight: "Bold", padding: "1vh" }}>No data to show...try applying some filters</div>: 
                    map === 1 ? 

                    sampleRows.length === 0? 
                        <div className='no_results_container '> 
                            <img src={no_result_logo} alt="" className="no_results child_no_results" /> 
                            <div className="no_results_header child_no_results">
                                No results found
                            </div>
                            <div className="no_results_subheader child_no_results">
                                Try adjusting your filters to find out what you are looking for 
                            </div>
                        </div>:

                    <TableContainer component={Paper} sx={{ height: '70vh', boxShadow: '0px 20px 50px 0px #0000001A', borderRadius: '8px 8px 8px 8px', backdropFilter: 'blur(10px)', background: "transparent"}}>
                        <Table stickyHeader >
                            <TableHead sx = {{ fontFamily: "Inter"}}>
                                <TableRow sx={{ 
                                        background: '#F5F7FB'
                                    }}>
                                    <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '17.5px'}} align="left">
                                        <Checkbox checked= {handleSyncCheckBoxKeywords()} onChange = {(event)=>handleMainCheckBox(event)}/>
                                        {/* <Checkbox checked= {handleSyncCheckBoxKeywords()} onChange = {(event)=>handleCheckBoxAll(event)}/> */}
                                    </TableCell>
                                    <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '17.5px', width: "5%"}} align="center"></TableCell>
                                    <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '17.5px', width: "50%"}} align="left">Article</TableCell>
                                    <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '17.5px'}} align="left" >Date</TableCell>
                                    <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '17.5px'}} align="left">Region</TableCell>
                                    {reportKeywords && <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '17.5px'}} alin="left">Matched Keywords</TableCell>}
                                    {(!reportKeywords && draftType !== "Auto Generated") && <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '17.5px'}} align="left"></TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ height: "max-content" }}>
                                {sampleRows.map((row, index) => (
                                    <React.Fragment key={index}>
                                        <TableRow 
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, background: 'rgba(233, 233, 233, 0.35)', 
                                        }}
                                        >	
                                            <TableCell sx = {{'&:hover': { borderLeft: "4px solid #3B89FE" }}}><Checkbox className='unique_checkbox' {...label} onChange = {(event)=>handleCheckBox(event, row.id)} checked = {selectedIds[row.id] === undefined? false: true}/></TableCell>
                                            <TableCell align="center" sx = {{ cursor: "pointer" }} onClick={() => setExpanded({ ...expanded, [index]: !expanded[index] })}>{expanded[index] ? <img src={colArw} alt='colArw' className='collapseArrow arrow' /> : <img src={expArw} alt='' className='expandArrow arrow' />}</TableCell>
                                            <TableCell component="th" scope="row" sx = {{ fontFamily: "Inter",fontSize: '16px', fontWeight: "600", width: '25vw',}}>
                                                {row.Title_translated && 
                                                    <>
                                                        <img src={translate_icon} alt="translate_icon" className="translate_text_icon" onClick={()=>handleToggleTranslate(index)}/>
                                                        <>&nbsp;&nbsp;</>
                                                </>}
                                                {( row.Title_translated && translateToggle[index]) ? row.Title_translated: row.article}
                                                <div className="domain_name" style={{fontSize: "small"}}>
                                                    {row.domain_name}
                                                </div>
                                            </TableCell>
                                            <TableCell align="left" sx = {{ fontFamily: "Inter", fontSize: '16px' }}>{moment(row.date.slice(0, 10)).format('MM/DD/YYYY')}&nbsp;&nbsp;{row.date.slice(11, 16)}</TableCell>
                                            <TableCell align="left" sx = {{ fontFamily: "Inter", fontWeight: "600" ,fontSize: '16px' }}>
                                                <Tooltip title={ 
                                                    <div style={{ backgroundColor: '#ffffff', fontFamily: "Inter", fontWeight: "600" ,fontSize: '18px', color: "#000000", padding: "1vh 1vh 0vh 0vh", overflowY: "auto", maxHeight: "30vh"}}>
                                                        <ul >
                                                            {row.region.map((region, index) => (
                                                            <li key={index}>{region}</li>
                                                            ))}
                                                        </ul>
                                                    </div>		
                                                    } placement="top-start" arrow  >
                                                    
                                                    
                                                    <div>
                                                        {row.region.length !== 0 ? (row.region.length > 1 ? row.region[0] + " ..." : row.region[0]) : "No Regions"}
                                                    </div>
                                                </Tooltip>
                                            </TableCell>
                                            {reportKeywords && <TableCell align="left" sx = {{ fontFamily: "Inter", fontWeight: "600" ,fontSize: '16px' }}>
                                                <Tooltip title={ 
                                                    <div style={{ backgroundColor: '#ffffff', fontFamily: "Inter", fontWeight: "600" ,fontSize: '18px', color: "#000000", padding: "1vh 1vh 0vh 0vh", overflowY: "auto", maxHeight: "30vh"}}>
                                                        <ul >   
                                                            {row.matched_keywords.map((matched_keyword, index) => (
                                                            <li key={index}>{matched_keyword}</li>
                                                            ))}
                                                        </ul>
                                                    </div>		
                                                    } placement="top-start" arrow  >
                                                    
                                                    
                                                    <div>
                                                        {row.matched_keywords.length !== 0 ? (row.matched_keywords.length > 1 ? row.matched_keywords[0] + " ..." : row.matched_keywords[0]) : "No Regions"}
                                                    </div>
                                                </Tooltip>
                                            </TableCell>
                                            }
                                            {(!reportKeywords && draftType !== "Auto Generated") && <TableCell align="left" sx = {{ cursor: "pointer"}} onClick={() => {setIdToDelete(row.id);setIsOpenDraftArticleDelete(true)}}><img className = "draft_Article_delete_icon" src={delete_article_icon} alt="delete_draft_article_icon" onClick={()=>setIsOpenDraftArticleDelete(true)}/></TableCell>}
                                        </TableRow>

                                        <TableRow>
                                            <TableCell style={{ paddingBottom: 0, paddingTop: 0}} colSpan={7}>
                                                <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                                                    <div className="view_articles_heatmap">
                                                        {reportKeywords !== null ?
                                                                <ViewArticle sample_id = {row.id} keys = {row.matched_keywords}/> :
                                                                <ViewArticle sample_id = {row.id} />
                                                        }
                                                    </div>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>

                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>:
                    map === 2 ? <div style={{display: "flex" , alignItems: "center", justifyContent: "center", height: '70vh'}}><img src={loading} alt="laoding svg" className="loading" /></div>: 
                    <div>some error ....try refreshing the page</div>   
                }
                {	sampleRows.length === 0 ? <></>:
                        <div className="pagination_container">
                            <div className="display_pages">
                                Showing &nbsp;<span>{totalPage !== 0 ? (page-1)*10 + 1 : 0}</span>-<span>{(page-1)*10 + sampleRows.length}</span>&nbsp;from&nbsp;<span>{totalData}</span>&nbsp;data 	
                            </div>
                            <div className="nav_container">
                                <Stack spacing={2}>
                                    <Pagination count={totalPage} page = {page} color='primary' value={page} onChange={handlePage} variant="outlined" sx={{fontFamily: "Inter"}}/>
                                </Stack>
                            </div>
                        </div>
					}
            </div>
            {/* <div className="quick_scroll">
                { sampleRows.length >= 6 && map === 1 &&
                <>
                    <div className="bottom" onClick={() => tableRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })}>
                        <img src={scr_down} alt="" className="scroll_d_icon" />
                    </div>
                    <div className="top" onClick={() => tableRef.current.scrollIntoView({ behavior: 'smooth' })}>
                        <img src={scr_up} alt="" className="scroll_d_icon" />     
                    </div>
                </>
                }
            </div> */}
        </div>
			<ToastContainer />
            <div className="main_draft_articles_filter_popup">
                <ReactModal
                    isOpen={isOpenDraftArticleKeywords}
                    onRequestClose={closeModalDraftArticleKeywords}
                    contentLabel="Popup Modal"
                    style={customStylesDraft}
                >	
                <div className="article_draft_popup_content">
                   <div className="draft_keywords_header">
                        Selected Keywords
                   </div>
                   <div className="draft_keywords">
                        {keys && keys.map((item, index) =>(
                            <div className="draft_keywords_index">
                                {index} . <span>{item}</span>
                            </div>
                        ))}
                   </div>
                </div>
                </ReactModal>	
		    </div>	
            <div className="main_draft_articles_delete_popup">
                <ReactModal
                    isOpen={isOpenDraftDelete}
                    onRequestClose={closeModalDraftDelete}
                    contentLabel="Popup Modal"
                    style={customStylesDraftDelete}
                >	
                <div className="draft_delete_popup_content">
                    <div className="draft_delete_popup_header">
                        {draftArticlesDeletePrompt}
                    </div>
                    <div className="draft_delete_add_draft_button">
                        <button className="cancel btn btn-light" onClick={()=>closeModalDraftDelete()}>
                            Cancel
                        </button>
                        <button className="button_main btn btn-outline-danger" onClick={()=>{
                            Object.keys(selectedIds).length === 0 ? 
                                handleDeleteDraft() 
                                :
                                handleDeleteMultipleArticlesFromDraft()
                            }}>
                            Delete
                        </button>
                    </div>
                </div>
                </ReactModal>	
		    </div>	
            <div className="main_draft_articles_article_delete_popup">
                <ReactModal
                    isOpen={isOpenDraftArticleDelete}
                    onRequestClose={closeModalDraftArticleDelete}
                    contentLabel="Popup Modal"
                    style={customStylesDraftDelete}
                >	
                <div className="draft_delete_article_popup_content">
                    <div className="draft_delete_article_popup_header">
                        Remove Articles
                    </div>
                    <div className="draft_delete_article_add_draft_button">
                        <button className="cancel btn btn-light" onClick={()=>closeModalDraftArticleDelete()}>
                            Cancel
                        </button>
                        <button className="button_main btn btn-outline-danger" onClick={()=>handleDeleteArticles()}>
                            Delete
                        </button>
                    </div>
                </div>
                </ReactModal>	
		    </div>	
            <div className="main_draft_export_popup">
                <ReactModal
                    isOpen={isOpenExportModal}
                    onRequestClose={closeModalExport}
                    contentLabel="Popup Modal"
                    style={customStylesDraftDelete}
                >	
                <div className="export_articles_popup_content">
                    <div className="export_articles_popup_header">
                        Export Selected Articles
                    </div>
                    <div className="export_articles_add_draft_button">
                        <button className="cancel btn btn-light" onClick={()=>closeModalExport()}>
                            Cancel
                        </button>
                        <button className="button_main btn btn-primary" onClick={()=>handleExport()}>
                            Export
                        </button>
                    </div>
                </div>
                </ReactModal>	
		    </div>	
            <div className="main_draft_articles_rearrange_popup">
                <ReactModal
                    isOpen={isOpenRearrageDraftArticles}
                    onRequestClose={closeRearrageDraftArticlesModal}
                    contentLabel="Popup Modal"
                    style={customStylesDraftArticlesRearrange}
                >	
                <div className="draft_rearrange_popup">
                    <div className="btn btn-sm btn-outline-danger mb-2" style={{float: "right"}} onClick={()=>setIsOpenRearrangeDraftArticles(false)}>
                        close
                    </div>
                    <div className="btn btn-sm btn-primary mb-2 mr-2" style={{float: "right"}} onClick={()=>handle_confirm_new_order()}>
                        Reorder
                    </div>
                    <div className="rearrange_table_container">
                        {allRearrangeArticles.length > 0 && 
                            <Test2/>
                        }
                    </div>
                </div>
                </ReactModal>	
            </div>	
            <div className="main_draft_select_all_articles_popup">
                <ReactModal
                    isOpen={isOpenSelectAllArticlesPopup}
                    onRequestClose={closeModalSelectAllDraftArticles}
                    contentLabel="Popup Modal"
                    style={customStylesDraftDelete}
                >	
                <div className="export_articles_popup_content">
                    <div className="export_articles_popup_header">
                        Select Preference
                    </div>
                    <div className="export_articles_add_draft_button">
                        <button className="cancel btn btn-light" onClick={()=>handleCheckBoxEntireData()}>
                            Select All {totalData} Articles
                        </button>
                        <button className="button_main btn btn-primary" onClick={()=>handleCheckBoxCurrent()}>
                            Select Current {sampleRows.length} Articles
                        </button>
                    </div>
                </div>
                </ReactModal>	
		    </div>
            <div className="main_draft_de_select_all_articles_popup">
                <ReactModal
                    isOpen={isOpenDeSelectAllArticlesPopup}
                    onRequestClose={closeModalDeSelectAllDraftArticles}
                    contentLabel="Popup Modal"
                    style={customStylesDraftDelete}
                >	
                <div className="export_articles_popup_content">
                    <div className="export_articles_popup_header">
                        Select Preference
                    </div>
                    <div className="export_articles_add_draft_button">
                        <button className="cancel btn btn-light" onClick={()=>handleDeselectCheckboxAll("deselect_all")}>
                            Deselect All {totalData} Articles
                        </button>
                        <button className="button_main btn btn-primary" onClick={()=>handleDeselectCheckboxAll("deselect_current")}>
                            Deselect Current {sampleRows.length} Articles
                        </button>
                    </div>
                </div>
                </ReactModal>	
		    </div>
    </div>
  )
}
