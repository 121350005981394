import React from 'react'
import { LeftDashboard } from '../Dashboard/left_dashboard'
import { RightFactCheck } from './rightFactCheck'
import "../../staticFiles/cssFiles/factCheck/factCheck.css"

export const FactCheck = () => {
  return (
    <div className='fact_check_container'>
        <LeftDashboard id = {'3'}/>
        <RightFactCheck/>
    </div>
  )
}
