import React from 'react'
import "../../staticFiles/cssFiles/KeywordsReport/detailsKeywordsReport.css"
import { LeftDashboard } from '../Dashboard/left_dashboard'
import Cookies from 'js-cookie'
import { Navigate } from 'react-router-dom'
import { RightDetailsKeywordsReport } from './rightDetailsKeywordsReport'
export const DetailsKeywordsReport = () => {
  if(Cookies.get('token') === undefined){
    // return <Navigate to  = "/login" />
    window.location.href = '/login';
  }
  return (
    <div className='details_keywords_report_container'>
        <LeftDashboard id={'4_3'}/>
        <RightDetailsKeywordsReport />
    </div>
  )
}