import React from 'react'
import { Navigate } from 'react-router-dom';
import { decodeId } from '../../endppoints/testing_routes';
import { logout } from '../../endppoints/testing_routes';
import '../../staticFiles/cssFiles/profiles.css';


export const Profile3 = () => {
  const [redirect, setRedirect] = React.useState(2)

  React.useEffect(() => {
    let stoken = localStorage.getItem("token")
    const message = { token: stoken}
    console.log(message)
    
    if (message['token'] == null)
    {
        setRedirect(1)        // redirect to login
    }
    else
    {
        check_user_role();
    }

}, []);// eslint-disable-line react-hooks/exhaustive-deps

const check_user_role = async() =>{
    let response = {};
    response = await decodeId()  // decode the token
    
    if(response['status'] === "500"){
        alert("Internal server error")
        setRedirect(1)
    }
    else{
        if (response.data['role'] !== 3){
            // alert("Only type3 users can access this page");
            setRedirect(1)
        }
        else{
            setRedirect(0)
        }
    }       
}

const logoutHandler = async() =>{
    let response = {}
    response = await logout()

    if (response['status'] === '200'){
        localStorage.clear()
        alert("Logging you out....")
        setRedirect(1)            
    }
    else if(response['status'] === '500'){
        alert("Internal server error....try again")
    }
}

if(redirect === 1){
    // return <Navigate to = '/login'/>
    window.location.href = '/login';
}
else if(redirect === 0){
  return (
        <>
            <button type="button" className="logout btn btn-danger" onClick={() => logoutHandler()}>Logout</button>
            <div className='profiles'> Hi profile3 </div>
        </>
  )
  }
}
