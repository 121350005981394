import React from 'react'
import "../../../staticFiles/cssFiles/rootDashboard/rootAnalytics/rootAnalytics.css"
import { CartesianGrid, Legend } from 'recharts';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../store';

import dropDown_open from "../../../staticFiles/images/dashboard_article_drop_open.svg"
import no_result_logo from "../../../staticFiles/images/No_results.svg"
import { Test } from '../../test/test';

import moment from 'moment/moment';
import loading from "../../../staticFiles/images/loading.svg"
import LinearGradient from '../../test/LinearGradient';
import { WorldMapComponent } from '../../worldMap/worldMap';
import BubbleGraph from '../../Dashboard/bubbleChart';

import {AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, BarChart, Bar} from 'recharts'
import { useState } from 'react';
import { clusterArticlesFetch, TimeSeriesTableDataFetch } from '../../../endppoints/endpoint';
import ReactModal from 'react-modal';
import Cookies from 'js-cookie';
import { toast, ToastContainer } from 'react-toastify';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Collapse, Pagination, Stack } from '@mui/material';
import { ViewArticle } from '../../onDemandPdf/viewArticle';
import {Tooltip as MuiTooltip} from '@mui/material';

import expArw from "../../../staticFiles/images/expArw.svg"
import colArw from "../../../staticFiles/images/colArw.svg"
import zoomIndicator from "../../../staticFiles/images/zoom_indicator.svg"

export const RootAnalytics = () => {

    const dispatch = useDispatch()
	const {updateStates} = bindActionCreators(actionCreators, dispatch);
    const [isOpenClusterArticles,setIsOpenClusterArticles] = useState(false)
    const [sampleRows, setSampleRows] = useState([])
    const [totalClusterArticlesData, setTotalClusterArticlesData] = useState(0)
    const [totalPageClusterArticles, setTotalPageClusterArticles] = useState(0)
    const [clusterArticlespage, setClusterArticlesPage] = useState(1)
    const [expanded, setExpanded] = React.useState({});
    const [isOpenTimeSeriesData, setIsOpenTimeSeriesData] = React.useState(false)

    const [isOpenTimeSeriesTable,setIsOpenTimeSeriesTable] = useState(false)
    const [timeSeriesTableSampleRows, setTimeSeriesTableSampleRows] = useState([])
    const [totalTimeSeriesTableData, setTotalTimeSeriesTableData] = useState(0)
    const [totalPageTimeSeriesTable, setTotalPageTimeSeriesTable] = useState(0)
    const [timeSeriesTablepage, setTimeSeriesTablePage] = useState(1)

    const customStylesDraft = {
		content: {
			// top: '50%',
			// left: '50%',
			// right: 'auto',
			// bottom: 'auto',
			// marginRight: '-50%',
			// transform: 'translate(-50%, -50%)',
			maxWidth: '100%',
			// maxHeight: '80%',
			background: '#F6F7F9',
			borderRadius: "8px",
			paddingTop: '5vh',
			paddingBottom: '5vh',
			paddingLeft: '2.5vw',
			paddingRight: '2.5vw',
            // overflow: "unset"
		},
		overlay: {
			backgroundColor: 'rgba(0, 0, 0, 0.71)',
            backdropFilter: "blur(2.5px)",
            zIndex: 1
		},
    };

    const timeSeriesTableModalCustomStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			minWidth: '60%',
			// maxHeight: '80%',
			background: '#F6F7F9',
			borderRadius: "8px",
			paddingTop: '5vh',
			paddingBottom: '5vh',
			paddingLeft: '5vw',
			paddingRight: '5vw',
            overflow: "unset"
		},
		overlay: {
			backgroundColor: 'rgba(0, 0, 0, 0.71)',
            backdropFilter: "blur(2.5px)",
            zIndex: 1
		},
    };

    const closeModalClusterArticles = () => {
		setIsOpenClusterArticles(false)
    };

    const closeModalTimeSeriesData = () => {
		setIsOpenTimeSeriesTable(false)
    };

    // const {
	// 	isOpen,
	// 	fromDate,
	// 	toDate,
	// 	fromDatePage,
	// 	toDatePage,
    //     selectedDateRange,
    //     selectedDateRangeApplied,
	// 	page,
	// 	sample_rows,
	// 	status,
	// 	articleId,
	// 	total_data,
	// 	curr_data,
	// 	total_page,
	// 	selectedOptions,
	// 	expKey,
	// 	map,
	// 	section,
    //     sectionApplied,
	// 	sampleOptions,
    //     selectedOptionsApplied,
	// 	fetchedDomains,
	// 	logicToggle,
	// 	sequentialToggle,
	// 	matchToggle,
	// 	logicToggleApplied,
	// 	sequentialToggleApplied,
	// 	matchToggleApplied,
	// 	selectedOptionsDomain,
	// 	selectedOptionsDomainApplied,
	// 	selectedOptionsLocation,
	// 	selectedOptionsLocationApplied,
	// 	dropdownCatergory,
	// 	categories,
    //     category,
	// 	appliedCategory,
	// 	promptToggle,
	// 	promptQuery,
	// 	finalPromptQuery,
	// 	fetchedDrafts,
	// 	selectedValue,
	// 	isOpenDraft,
	// 	defaultCategory,
	// 	uniqueArticlesToggle,
	// 	appliedUniqueArticlesToggle,
	// 	showVerifyMap,
	// 	inputVerifyLocation,
	// 	inputVerifyLocationApplied,
	// 	fetchedCoordinates,
	// 	pinMapData,
	// 	inputVerifyLat,
	// 	inputVerifyLan,
	// 	inputVerifyLatApplied,
	// 	inputVerifyLanApplied,
	// 	invalidLocationError,
	// 	approvedLocation,
	// 	approvedLocationApplied,
	// 	inputVerifyLatLanByLocation,
	// 	inputVerifyLatLanByLocationApplied,
	// 	locationRadius,
	// 	locationRadiusApplied,
	// 	selectedOptionsSentiment,
	// 	selectedOptionsSentimentApplied,
	// 	chartData,
    //     keywordsCategory,
    //     appliedKeywordsCategory,
    //     keywordsDropdownCatergory,
    //     articlesAnalyticsSwitch,
    //     filtersEditMode,
    //     showDraftIcons,
    //     selectedArticles,
    //     regionDropdownCatergory,
    //     regionCategory,
    //     analyticMap,
    //     dataMap,
    //     gradData,
    //     dataCountryMap,
    //     gradCountryData,
    //     timeSeriesData,
    //     bubbleChartResultLoading,
    //     timeSeriesResultLoading,
    //     bubbleChartTopStat
    // } = useSelector(state => state.dashboardState);

    const {
        selectedDateRangeApplied,
		chartData,
        regionDropdownCatergory,
        regionCategory,
        analyticMap,
        dataMap,
        gradData,
        dataCountryMap,
        gradCountryData,
        timeSeriesData,
        bubbleChartResultLoading,
        timeSeriesResultLoading,
        timeSeriesDataTopStat,
        bubbleChartTopStat
    } = useSelector(state => state.dashboardState);

    dataMap?.sort((a, b) => b.value - a.value);

// Get the top three items
    const topThreeStates = dataMap?.slice(0, 3);

    dataCountryMap?.sort((a, b) => b.value - a.value);

// Get the top three items
    const topThreeCountries = dataCountryMap?.slice(0, 3);

    const handleDotClick = (data) =>{
        console.log(data)
        updateStates({
            "type": "reducerUpdates",
            "payload": {
                timeSeriesDataTopStat : data.payload.data
            }
        })
    }

    const handle_fetch_cluster_articles = async(message) =>{
        let response = await clusterArticlesFetch(message)
		if(response.status === 200){
		 	// console.log(response.data)
            setSampleRows(response.data.data)
            setTotalClusterArticlesData(response.data.totalCount)
            if(response.data['totalCount'] % 10 === 0){
				setTotalPageClusterArticles(Math.floor(response.data['totalCount'] / 10))
			}
			else{
				setTotalPageClusterArticles(Math.floor(response.data['totalCount'] / 10) + 1)
			}
            setIsOpenClusterArticles(true)
	 	}
	 	else if(response.status === 401){
		 	localStorage.clear()
		 	Cookies.remove('token');
		 	setTimeout(() => {
                window.location.href = '/login';  
		 	}, 1000);
		 	toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
	 	}
	 	else{
		 	toast.info("server error", { position: toast.POSITION.TOP_CENTER})
		}
    }

    const handleViewAllClusterArticles = () =>{
        let message = {
            "pageNo": 1,
            "itemsPerPage": 10,
            "cluster_id": bubbleChartTopStat.cluster_info.cluster_id
        }
        handle_fetch_cluster_articles(message)
    }

    const handleClusterArticlesPage = (event, pg) =>{
        setClusterArticlesPage(pg)
        
        let message = {
            "pageNo": pg,
            "itemsPerPage": 10,
            "cluster_id": bubbleChartTopStat.cluster_info.cluster_id
        }
        handle_fetch_cluster_articles(message)
    }

    const handleViewAllTimeSeriesTableData = () =>{
        let message = {
            "pageNo": 1,
            "itemsPerPage": 10,
            "date": timeSeriesDataTopStat?.x,
            "category": "", 
            "searchText":""
        }
        setTimeSeriesTablePage(1)
        handle_fetch_time_series_table_data(message)
    }

    const handle_fetch_time_series_table_data = async(message) =>{
        let response = await TimeSeriesTableDataFetch(message)
		if(response.status === 200){
            console.log(response.data)
            setTimeSeriesTableSampleRows(response.data.data)
            setTotalTimeSeriesTableData(response.data.totalCount)
            if(response.data['totalCount'] % 10 === 0){
				setTotalPageTimeSeriesTable(Math.floor(response.data['totalCount'] / 10))
			}
			else{
				setTotalPageTimeSeriesTable(Math.floor(response.data['totalCount'] / 10) + 1)
			}
            setIsOpenTimeSeriesTable(true)
	 	}
	 	else if(response.status === 401){
		 	localStorage.clear()
		 	Cookies.remove('token');
		 	setTimeout(() => {
                window.location.href = '/login';  
		 	}, 1000);
		 	toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
	 	}
	 	else{
		 	toast.info("server error", { position: toast.POSITION.TOP_CENTER})
		}
    }

    const handleTimeSeriesPage = (event, pg) =>{
        setTimeSeriesTablePage(pg)

        let message = {
            "pageNo": pg,
            "itemsPerPage": 10,
            "date": timeSeriesDataTopStat?.x,
            "category": "", 
            "searchText":""
        }
        handle_fetch_time_series_table_data(message)
    }

  return (
    <div className='root_analytics_container'>
        <div className="heat_map_container general_analytics_wrapper">
            <div className="region_selector heat_map_container_child">
                <div className="region_dropdown_container">
                    <div className="region_selections" onClick={()=>{
                        updateStates({
                            "type": "reducerUpdates",
                            "payload": {
                                regionDropdownCatergory: ! regionDropdownCatergory
                            }
                        })
                    }}>
                        <div className="region_main_text" >
                            {regionCategory}
                        </div>
                        <img src={dropDown_open} alt="exp_arw" className={regionDropdownCatergory === true ? "region_dropdown_close":"region_dropdown"}/>
                            {regionDropdownCatergory === true && 
                                <div className="dropdown_container">
                                    {	
                                        ["India", "World"].map((ctgry, index) => (
                                            <div className={`ct_${index+1} dropdown_main_category`} key={index} onClick={()=>{
                                                updateStates({
                                                        "type": "reducerUpdates",
                                                        "payload": {
                                                            regionCategory: ctgry
                                                        }
                                                    })
                                                }}>
                                                {ctgry}
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                    </div>
                </div>
                <div className="map_range">
				    <LinearGradient data={regionCategory === "India" ? gradData : gradCountryData} />
                </div>
            </div>
            <div className="map_container heat_map_container_child">
                <div className="title_analytics_data">
                    Spread of Articles
                </div>
                {regionCategory === "India" ?
                    <div className="heatmap_container">
                        
                        {   analyticMap === 0 ? 
                                <div className='no_results_container '> 
                                    <img src={no_result_logo} alt="no_result_logo" className="no_results child_no_results" /> 
                                    <div className="no_results_header child_no_results">
                                        No results found
                                    </div>
                                    <div className="no_results_subheader child_no_results">
                                        Try adjusting your filters to find out what you are looking for 
                                    </div>  
                                </div>: 
                            analyticMap === 1 ? 
                            dataMap.length !== 0 ?    
                                <Test datamap = {dataMap} date1 = {moment(selectedDateRangeApplied[0]).format("YYYY-MM-DD")} date2 = {moment(selectedDateRangeApplied[1]).format("YYYY-MM-DD")}/>:
                                <div className='no_results_container '> 
                                    <img src={no_result_logo} alt="no_result_logo" className="no_results child_no_results" /> 
                                    <div className="no_results_header child_no_results">
                                        No results found
                                    </div>
                                    <div className="no_results_subheader child_no_results">
                                        Try adjusting your filters to find out what you are looking for 
                                    </div>
                                </div>:
                            analyticMap === 2 ? <div style={{display: "flex" , alignItems: "center", justifyContent: "center"}}><img src={loading} alt="laoding svg" className="loading" style={{backgroundColor: "white"}} /></div>: 
                            <div>some error ....try refreshing the page</div>   
                        }
                    </div>
                :
                <div className="heatmap_container">
                    
                {   analyticMap === 0 ? 
                        <div className='no_results_container '> 
                            <img src={no_result_logo} alt="no_result_logo" className="no_results child_no_results" /> 
                            <div className="no_results_header child_no_results">
                                No results found
                            </div>
                            <div className="no_results_subheader child_no_results">
                                Try adjusting your filters to find out what you are looking for 
                            </div>  
                        </div>: 
                    analyticMap === 1 ? 
                    dataCountryMap.length !== 0 ?    
                        <WorldMapComponent datamap = {dataCountryMap} date1 = {moment(selectedDateRangeApplied[0]).format("YYYY-MM-DD")} date2 = {moment(selectedDateRangeApplied[1]).format("YYYY-MM-DD")}/>:
                        <div className='no_results_container '> 
                            <img src={no_result_logo} alt="" className="no_results child_no_results" /> 
                            <div className="no_results_header child_no_results">
                                No results found
                            </div>
                            <div className="no_results_subheader child_no_results">
                                Try adjusting your filters to find out what you are looking for 
                            </div>
                        </div>:
                    analyticMap === 2 ? <div style={{display: "flex" , alignItems: "center", justifyContent: "center"}}><img src={loading} alt="laoding svg" className="loading" style={{backgroundColor: "white"}} /></div>: 
                    <div>some error ....try refreshing the page</div>   
                }
            </div>

                }
            </div>
            <div className="top_stats heat_map_container_child">
                {analyticMap === 1 &&
                <>
                    <div className="top_stats_header">
                        Top {regionCategory === "India" ? topThreeStates.length + (" States"): topThreeCountries.length  + (" Countries")}
                    </div>
                    <div className="states_name"> 
                    {regionCategory === "India" ? topThreeStates.map((item, index) => (
                        <>
                            <div className="first_state state_name">
                                <div className="serial">
                                    {index+1}
                                </div> 
                                <div className="state">
                                    {item["state"]}
                                </div>
                            </div>
                        </>
                    )):
                        topThreeCountries.map((item, index) => (
                            <>
                                <div className="first_state state_name">
                                    <div className="serial">
                                        {index+1}
                                    </div> 
                                    <div className="state">
                                        {item["country"]}
                                    </div>
                                </div>
                            </>
                        ))
                    }
                    </div>
                </>
                }
            </div>
        </div>
        <div className="bubble_chart_container">
            <div className="bubble_graph_container general_analytics_wrapper">
                <div className="title_analytics_data">
                    Article Cluster Evolution
                </div>
                <img src={zoomIndicator} alt='zoom Indicator' className='expandArrow arrow' title='Graph is Zoomable' style={{height: "38px", width: "38px"}}/>
            {   (bubbleChartResultLoading === 0 || Object.keys(bubbleChartTopStat).length === 0 )? 
                    <div className='no_results_container '> 
                        <img src={no_result_logo} alt="no_result_logo" className="no_results child_no_results" /> 
                        <div className="no_results_header child_no_results">
                            No results found
                        </div>
                        <div className="no_results_subheader child_no_results">
                            Try adjusting your filters to find out what you are looking for 
                        </div>  
                    </div>: 
                bubbleChartResultLoading === 1 ? 
                    <BubbleGraph data = {chartData}/> : <></>
            }
            </div>
            <div className="bubble_graph_stats general_analytics_wrapper">
                <div className="header_bubble_graph_stats">
                    Article Cluster Details
                </div> 
                {   bubbleChartResultLoading === 1 && Object.keys(bubbleChartTopStat).length > 0 &&
                
                    <>
                        <div className="bubble_graph_stats_main_content">
                            <div className="stat_value" style={{textAlign: "justify", fontWeight: "bold"}}>
                                    {bubbleChartTopStat?.cluster_info?.title}
                                </div>
                            <div className="bubble_graph_stats_main_content_child">
                                <div className="stat_field">
                                    Total Articles:
                                </div>
                                <div className="stat_value">
                                    {bubbleChartTopStat.y}
                                </div>
                            </div>
                            <div className="bubble_graph_stats_main_content_child">
                                <div className="stat_field">
                                    Event Date: 
                                </div>
                                <div className="stat_value">
                                    {bubbleChartTopStat?.x1?.slice(0, 10)}
                                </div>
                            </div>
                        </div>
                        <button className="get_all_cluster_articles btn btn-primary" onClick={() => handleViewAllClusterArticles()}>
                            View all articles of this cluster
                        </button>
                    </>
                }
            </div>
        </div>
        <div className="time_series_graph_container">
            <div className="time_series_graph_wrapper general_analytics_wrapper">
                <div className="title_analytics_data">
                    Article Count Over Time
                </div>
            {   timeSeriesResultLoading === 0 || timeSeriesData.length === 0? 
                    <div className='no_results_container '> 
                        <img src={no_result_logo} alt="no_result_logo" className="no_results child_no_results" /> 
                        <div className="no_results_header child_no_results">
                            No results found
                        </div>
                        <div className="no_results_subheader child_no_results">
                            Try adjusting your filters to find out what you are looking for 
                        </div>  
                    </div>: 
                timeSeriesResultLoading === 1 ? 
                    // <ResponsiveContainer width="100%" height={400}>
                    //     <AreaChart
                    //         width={500}
                    //         height={300}
                    //         data={timeSeriesData}
                    //         margin={{ top: 10, right: 20, left: 0, bottom: 0 }}
                            
                    //         className = "recharts"
                    //     >
                    //         <defs>
                    //             <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    //                 <stop offset="5%" stopColor="rgba(159, 226, 255, 0.3)" stopOpacity={0.8}/>
                    //                 <stop offset="95%" stopColor="rgba(121, 149, 246, 0)" stopOpacity={0}/>
                    //             </linearGradient>
                    //         </defs>
                    //         <XAxis dataKey="name" minTickGap={0} />
                    //         <YAxis  />
                    //         <Tooltip className = "recharts_tooltip"/>
                    //         <Area
                    //             type='monotone'
                    //             dataKey='Articles'
                    //             stroke='#007bff'
                    //             strokeWidth={3} 
                    //             fill="url(#colorUv)"
                    //             // dot={{ r: 8 }}
                    //             dot={{ fill: '#007bff', strokeWidth: 5}}
                    //             activeDot = {{onClick: (event, payload) => handleDotClick(payload)}}
                    //         />
                    //     </AreaChart>
                    // </ResponsiveContainer> : <></>


                    <ResponsiveContainer width="100%" height={400}>
                        <BarChart
                            width={500}
                            height={300}
                            data={timeSeriesData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                            barSize={40}
                            >
                            <XAxis dataKey="name" scale="point" padding={{ left: 30, right: 30 }} />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Bar dataKey="Articles" fill="#007bff" background={{ fill: '#eee' }} onClick={handleDotClick}/>
                        </BarChart>
                    </ResponsiveContainer> : <></>
            }
            </div>
            <div className="time_series_graph_stats general_analytics_wrapper">
                <div className="header_time_series_graph_stats">
                    Articles of This Date
                </div>
                {   timeSeriesResultLoading === 1 && timeSeriesData.length > 0 &&
                    <>
                        <div className="time_series_graph_stats_main_content">
                            <div className="time_series_graph_stats_main_content_child">
                                <div className="stat_field">
                                    Selected Date:
                                </div>
                                <div className="stat_value">
                                    {timeSeriesDataTopStat?.x}
                                </div>
                            </div>
                            <div className="time_series_graph_stats_main_content_child">
                                <div className="stat_field">
                                    Total Articles: 
                                </div>
                                <div className="stat_value">
                                    {timeSeriesDataTopStat?.y}
                                </div>
                            </div>
                            <div className="time_series_graph_stats_main_content_child">
                                <div className="stat_field">
                                    Total Events: 
                                </div>
                                <div className="stat_value">
                                    {timeSeriesDataTopStat?.data_info?.cluster_ids?.length}    
                                </div>                        
                            </div>
                           
                        </div>
                        <button className="get_all_time_series_articles btn btn-outline-primary " onClick={() => handleViewAllTimeSeriesTableData()}>
                            View all events
                        </button>
                    </>
                }
            </div>
        </div>
        <ToastContainer />
        <div className="dashboard_draft_filter_popup">
            <ReactModal
                isOpen={isOpenClusterArticles}
                onRequestClose={closeModalClusterArticles}
                contentLabel="Popup Modal"
                style={customStylesDraft}
            >	
            <div className="article_cluster_content_from_root_bubble">
                <div className="article_cluster_popup_header">
                    Event : <span>{bubbleChartTopStat?.cluster_info?.title}</span>
                </div>
                <div className="articles_cluster_table">
                    <div className="table_container">
                    {sampleRows.length > 0 &&
                        <TableContainer component={Paper} sx={{ height: '70vh', overflowX: "none", boxShadow: '0px 20px 50px 0px #0000001A', borderRadius: '8px 8px 8px 8px'}}>
                            <Table stickyHeader >
                                <TableHead sx = {{ fontFamily: "Inter"}}>
                                    <TableRow sx={{ 
                                            background: '#F5F7FB'
                                        }}>
                                        <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '17.5px', width: "5%"}} align="center"></TableCell>
                                        <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '17.5px', width: "50%"}} align="left">Article</TableCell>
                                        <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '17.5px'}} align="left" >Date</TableCell>
                                        <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '17.5px'}} align="left">Region</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ height: "max-content" }}>
                                    {sampleRows.map((row, index) => (
                                        <React.Fragment key={index}>
                                            <TableRow 
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, background: "rgba(233, 233, 233, 0.35)"
                                            }}
                                            >	
                                                <TableCell align="center" sx = {{ cursor: "pointer" }} onClick={() => setExpanded({ ...expanded, [index]: !expanded[index] })}>{expanded[index] ? <img src={colArw} alt='colArw' className='collapseArrow arrow' /> : <img src={expArw} alt='expArw' className='expandArrow arrow' />}</TableCell>
                                                <TableCell component="th" scope="row" sx = {{ fontFamily: "Inter",fontSize: '16px', fontWeight: "600", width: '25vw',}}>
                                                    {row.Title}
                                                </TableCell>
                                                <TableCell align="left" sx = {{ fontFamily: "Inter", fontSize: '16px' }}>{moment(row.published_date.slice(0, 10)).format('MM/DD/YYYY')}&nbsp;&nbsp;{row.published_date.slice(11, 16)}</TableCell>
                                                <TableCell align="left" sx = {{ fontFamily: "Inter", fontWeight: "600" ,fontSize: '16px' }}>
                                                    <MuiTooltip title={ 
                                                        <div style={{ backgroundColor: '#ffffff', fontFamily: "Inter", fontWeight: "600" ,fontSize: '18px', color: "#000000", padding: "1vh 1vh 0vh 0vh", overflowY: "auto", maxHeight: "30vh"}}>
                                                            <ul >
                                                                {row.keywords_p.Location.map((region, index) => (
                                                                    <li key={index}>{region}</li>
                                                                ))}
                                                            </ul>
                                                        </div>		
                                                        } placement="top-start" arrow  >
                                                        
                                                        
                                                        <div>
                                                            {row.keywords_p.Location.length !== 0 ? (row.keywords_p.Location.length > 1 ? row.keywords_p.Location[0] + " ..." : row.keywords_p.Location[0]) : "No Regions"}
                                                        </div>
                                                    </MuiTooltip>
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell style={{ padding: 0}} colSpan={7}>
                                                    <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                                                        <div className="view_articles_heatmap">
                                                            <ViewArticle sample_id = {row._id} />
                                                        </div>
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>

                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {sampleRows.length > 0 &&
                        <div className="pagination_container">
                            <div className="display_pages">
                                Showing &nbsp;<span>{totalPageClusterArticles !== 0 ? (clusterArticlespage-1)*10 + 1 : 0}</span>-<span>{(clusterArticlespage-1)*10 + sampleRows.length}</span>&nbsp;from&nbsp;<span>{totalClusterArticlesData}</span>&nbsp;data 	
                            </div>
                            <div className="nav_container">
                                <Stack spacing={2}>
                                    <Pagination count={totalPageClusterArticles} page = {clusterArticlespage} color='primary' value={clusterArticlespage} onChange={handleClusterArticlesPage} variant="outlined" sx={{fontFamily: "Inter"}}/>
                                </Stack>
                            </div>
                        </div>
                    }
                    </div>
                </div>
            </div>
            </ReactModal>	
        </div>	

        <div className="dashboard_draft_filter_popup">
            <ReactModal
                isOpen={isOpenTimeSeriesTable}
                onRequestClose={closeModalTimeSeriesData}
                contentLabel="Popup Modal"
                style={timeSeriesTableModalCustomStyles}
            >	
            <div className="article_cluster_content_from_root_bubble">
                <div className="articles_cluster_table">
                    <div className="table_container">
                    {timeSeriesTableSampleRows.length > 0 &&
                        <TableContainer component={Paper} sx={{ height: '70vh', overflowX: "none", boxShadow: '0px 20px 50px 0px #0000001A', borderRadius: '8px 8px 8px 8px'}}>
                            <Table stickyHeader >
                                <TableHead sx = {{ fontFamily: "Inter"}}>
                                    <TableRow sx={{ 
                                            background: '#F5F7FB'
                                        }}>
                                        <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '17.5px', width: "50%"}} align="center">Event</TableCell>
                                        <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '17.5px', width: "50%"}} align="left">Articles</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ height: "max-content" }}>
                                    {timeSeriesTableSampleRows.map((row, index) => (
                                        <TableRow 
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, background: "rgba(233, 233, 233, 0.35)"
                                            }}
                                            >	
                                                <TableCell component="th" scope="row" sx = {{ fontFamily: "Inter",fontSize: '16px', fontWeight: "600", width: '25vw',}}>
                                                    {row.Title}
                                                </TableCell>
                                                <TableCell component="th" scope="row" sx = {{ fontFamily: "Inter",fontSize: '16px', fontWeight: "600", width: '25vw',}}>
                                                    {row.articles_count}
                                                </TableCell>
                                                
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {timeSeriesTableSampleRows.length > 0 &&
                        <div className="pagination_container">
                            <div className="display_pages">
                                Showing &nbsp;<span>{totalPageTimeSeriesTable !== 0 ? (timeSeriesTablepage-1)*10 + 1 : 0}</span>-<span>{(timeSeriesTablepage-1)*10 + timeSeriesTableSampleRows.length}</span>&nbsp;from&nbsp;<span>{totalTimeSeriesTableData}</span>&nbsp;data 	
                            </div>
                            <div className="nav_container">
                                <Stack spacing={2}>
                                    <Pagination count={totalPageTimeSeriesTable} page = {timeSeriesTablepage} color='primary' value={timeSeriesTablepage} onChange={handleTimeSeriesPage} variant="outlined" sx={{fontFamily: "Inter"}}/>
                                </Stack>
                            </div>
                        </div>
                    }
                    </div>
                </div>
            </div>
            </ReactModal>	
        </div>	
    </div>
  )
}
