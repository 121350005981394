import React from 'react'
import "../../staticFiles/cssFiles/Profile/profile.css"
import { LeftDashboard } from '../Dashboard/left_dashboard'
import { RightProfile } from './rightProfile'
import Cookies from 'js-cookie'
import { Navigate } from 'react-router-dom'

export const Profile = () => {
  if(Cookies.get('token') === undefined){
    console.log("working")
    // return <Navigate to  = "/login" />
    window.location.href = '/login';
  }
  return (
    <div className='profile_container'>
        <LeftDashboard id = {'5'}/>
        <RightProfile />
    </div>
  )
}