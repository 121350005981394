import React from 'react'
import "../../staticFiles/cssFiles/globalAnimations/pageAnimations.css"

export const PageAnimations = () => {
  return (
    <div className='page_animations_container'>
        <div className="circle_top"></div>                     
        <div className="circle_bottom"></div>    
    </div>
  )
}
