import React, { useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
// import * as echarts from 'echarts';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store';

const BubbleGraph = ({data}) => {
  // const [selectedDataIndex, setSelectedDataIndex] = React.useState(null);

  const dispatch = useDispatch()
	const {updateStates} = bindActionCreators(actionCreators, dispatch);

  const {
    bubbleChartHighlightBubble
    } = useSelector(state => state.dashboardState);

  // const minY = Math.min(...data.map((item) => item[1]));
  // const maxY = Math.max(...data.map((item) => item[1]));

  // const minTimestamp = Math.min(...data.map((item) => item[0]));
  // const maxTimestamp = Math.max(...data.map((item) => item[0]));
  // const option = {
  //   backgroundColor: "#FFFFFF",
    
  //   legend: {
  //     right: '10%',
  //     top: '3%',
  //     data: ['1990', '2015']
  //   },
  //   grid: {
  //     left: '8%',
  //     top: '10%',
  //   },
  //   xAxis: {
  //     // name: 'date',
  //     type: 'time',
  //     splitLine: {
  //       lineStyle: {
  //         type: 'solid',
  //         width: 1
  //       }
  //     },
      
  //     axisLabel: {
  //       formatter: "{dd} {MMM} {yyyy}",
  //       textStyle: {
  //         fontWeight: 'bold' // Make x-axis label bold
  //       }
  //     },
  //     // min: minX - 1,
  //     // max: maxX + 1
  //     min: new Date(minTimestamp - 3600000), // Set the minimum value for the x-axis with padding
  //     max: new Date(maxTimestamp + 3600000), // 1 hour padding (1 hour = 3600000 milliseconds)

  //   },
  //   yAxis: {
  //     // name: 'frequency',
  //     type:'value',
  //     splitLine: {
  //       lineStyle: {
  //         type: 'solid',
  //         width: 1
  //       }
  //     },
  //     scale: true,
  //     axisLabel: {
  //     textStyle: {
  //       fontWeight: 'bold' // Make x-axis label bold
  //     }
  //   },
  //   min: minY - 1, // Set the minimum value for the y-axis with padding
  //     max: maxY + 1,
  //   },
  //   series: [
  //     {
  //       name: '1',
  //       data: data,
  //       encode: {
  //         x: 'date',
  //         y: 'frequency'
  //       },
  //       type: 'scatter',
  //       symbolSize: function (data) {
  //         return data[2]*20 * 0.50
  //       },
  //       emphasis: {
  //         focus: 'series',
  //         label: {
  //           show: true,
  //           formatter: function (param) {
  //             return param.data[3];
  //           },
  //           position: 'bottom',
  //           fontWeight: 'bold',
  //           fontSize: 14,
  //           padding: 5, // Add padding to prevent cutting off
  //           borderRadius: 5, // Add a small border radius to the labels
  //           backgroundColor: '#fff',
  //           border: "1px solid black",
  //           width: "100%"
  //         }
  //       },
        
  //       itemStyle: {
  //         shadowBlur: 10,
  //         shadowColor: 'rgba(120, 36, 50, 0.5)',
  //         shadowOffsetY: 5,
  //         color: '#0d6efd'
  //       }
  //     }
  //   ]
  // };

    // Function to find the index of the largest bubble
    const findIndexOfLargestBubble = () => {
      let largestIndex = 0;
      let largestSize = 0;
  
      data.forEach((item, index) => {
        if (item[2] > largestSize) {
          largestSize = item[2];
          largestIndex = index;
        }
      });
  
      return largestIndex;
    };
  
    useEffect(() => {
      // Set the selectedDataIndex to the index of the largest bubble when the component mounts
      console.log("heredjf")
      const largestBubbleIndex = findIndexOfLargestBubble();
      updateStates({
        "type": "reducerUpdates",
        "payload": {
          bubbleChartHighlightBubble: largestBubbleIndex
        }
      })
    }, []); // The empty dependency array ensures this effect runs only once when the component mounts
  
  const minY = Math.min(...data.map((item) => item[1]));
  const maxY = Math.max(...data.map((item) => item[1]));

  const minTimestamp = Math.min(...data.map((item) => item[0]));
  const maxTimestamp = Math.max(...data.map((item) => item[0]));
  const option = {
  backgroundColor: null,
  
  // legend: {
  //   right: '10%',
  //   top: '3%',
  //   data: ['1990', '2015']
  // },
  grid: {
    left: '8%',
    top: '10%',
  },
  xAxis: {
    // name: 'date',
    type: 'time',
    splitLine: {
      lineStyle: {
        type: 'solid',
        width: 1
      }
    },
    
    axisLabel: {
      formatter: function (value) {
        // Format the timestamp to display date and time
        return moment(value).format('YYYY-MM-DD HH:mm:ss');
      },
      // formatter: "{dd} {MMM} {yyyy}",
      textStyle: {
        fontWeight: 'bold' // Make x-axis label bold
      },
      rotate: 25,
    },
    // min: minX - 1,
    // max: maxX + 1
    min: new Date(minTimestamp - 3600000), // Set the minimum value for the x-axis with padding
    max: new Date(maxTimestamp + 3600000), // 1 hour padding (1 hour = 3600000 milliseconds)

  },
  yAxis: {
    // name: 'frequency',
    type:'value',
    splitLine: {
      lineStyle: {
        type: 'solid',
        width: 1
      }
    },
    scale: true,
    axisLabel: {
      textStyle: {
        fontWeight: 'bold' // Make x-axis label bold
      }
    },
    min: minY - 1, // Set the minimum value for the y-axis with padding
    max: maxY + 1,
  },
  dataZoom: [
    {
      type: 'inside',
      xAxisIndex: 0,
      filterMode: 'empty',
    },

    {
      type: 'inside',
      yAxisIndex: 0,
      filterMode: 'empty',
    }
  ],
  series: [
    {
      name: '1',
      data: data,
      encode: {
        x: 'date',
        y: 'frequency'
      },
      type: 'scatter',
      symbolSize: function (data) {
        return (data[2]/maxY) * 10*7
      },
      itemStyle: {
        shadowBlur: 10,
        shadowColor: 'rgba(120, 36, 50, 0.5)',
        shadowOffsetY: 5,
        color: function (params) {
          // 'rgba(59, 137, 254, 0.8)'
          if (params.dataIndex === bubbleChartHighlightBubble) {
              return '#0d6efd'; // Change to the desired color for the selected bubble
            } else {
              return 'rgba(59, 137, 254, 0.65)'; // Default color for other bubbles
            }
        }
      }
    }
  ],

tooltip: {
  show: true,
  formatter: function (params) {
    const data = params.data;
    const title = data[3];
    const maxLines = 3; // Set the maximum number of lines before adding ellipses
    const maxWordsPerLine = 10; // Set the maximum words per line

    // Split the title into words
    const words = title.split(' ');

    // Split the words into lines based on the maximum words per line
    const lines = [];
    let currentLine = '';
    for (const word of words) {
      if ((currentLine + word).split(' ').length <= maxWordsPerLine) {
        currentLine += word + ' ';
      } else {
        lines.push(currentLine.trim()); // Add the current line to lines
        currentLine = word + ' '; // Start a new line
      }
    }
    // Add the last line
    lines.push(currentLine.trim());

    // Check if the number of lines exceeds the maximum
    if (lines.length > maxLines) {
      // Truncate the lines to the maximum number of lines (3 lines) and add ellipses to the last line (4th line)
      const truncatedLines = lines.slice(0, maxLines - 1);
      truncatedLines.push(lines[maxLines - 1].slice(0, 20) +  '    ...'); // Add ellipses to the last line
      // Join the lines with HTML line breaks
      const formattedTitle = truncatedLines.join('<br>');
      // return formattedTitle;
      return `<div style="font-weight: bold;">${formattedTitle}</div>`;
    } else {
      // If the number of lines doesn't exceed the maximum, no changes are needed
      // return lines.join('<br>');
      return  `<div style="font-weight: bold;">${lines.join('<br>')}</div>`;
    }
  },
  backgroundColor: 'rgba(255, 255, 255)',
  borderColor: 'rgb(236, 245, 255)',
  borderWidth: 1,
  padding: 15,
  fontWeight: "bolder",
  rich: {
    title: {
      overflow: 'hidden', // Hide overflow beyond the specified width
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
},



};

const handleBubbleClick = (params) => {
  if (params.componentType === 'series') {
    // Check if the clicked component is a series (scatter plot)
    const dataIndex = params.dataIndex; // Get the index of the clicked data point
    const clickedData = data[dataIndex]; // Get the data corresponding to the clicked point
    // setSelectedDataIndex(dataIndex);
    updateStates({
			"type": "reducerUpdates",
			"payload": {
				bubbleChartTopStat: clickedData[4],
        bubbleChartHighlightBubble: dataIndex
			}
		})
  }
};


  return <ReactEcharts option={option} style={{
      height: '100%',
      width: '100%',
    }}
    onEvents={{ 
      click: handleBubbleClick
    }} />;
};

export default BubbleGraph;
