const currentDate = new Date();
const initialDate = new Date();
initialDate.setDate(currentDate.getDate() - 2);

const initialState = {
    fact_promptQuery : "",
    fact_page : 1,
    fact_limitPerPage : 10,
    fact_totalData : 0,
    fact_articles : [],
    fact_totalPage : 0,
    fact_redirect : 0,
    fact_factCheckBool : null,
    fact_map : 0,
    fact_articleId : null,
    fact_translateToggle : []
}

const reducer = (state = initialState, action) =>{ //take initial state, what action to take
    if(action.type === 'factCheckReducerUpdates'){
        return {...state, ...action.payload}
    }
    else{
        return state
    }
}

export default reducer