import React, { useState } from 'react';
import { ComposableMap, Geographies, Geography, Graticule, Sphere, ZoomableGroup } from 'react-simple-maps';
import { scaleQuantile } from 'd3-scale';
import {Tooltip as ReactTooltip} from 'react-tooltip';

import LinearGradient from './LinearGradient.js';
import '../../staticFiles/cssFiles/worldMap.css'
import { Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/index.js';

/**
* Courtesy: https://rawgit.com/Anujarya300/bubble_maps/master/data/geography-data/india.topo.json
* Looking topojson for other countries/world? 
* Visit: https://github.com/markmarkoh/datamaps
*/
const WORLDTOPOJSON = require('./world.topo.json');

// const PROJECTION_CONFIG = {
//   scale: 350,
//   center: [78.9629, 22.5937] // always in [East Latitude, North Longitude]
// };

// Red Variants
// const COLOR_RANGE = [
//   '#ffedea',
//   '#ffcec5',
//   '#ffad9f',
//   '#ff8a75',
//   '#ff5533',
//   '#e2492d',
//   '#be3d26',
//   '#9a311f',
//   '#782618'
// ];

const COLOR_RANGE = [
	'#e6f7ff',
	'#bae7ff',
	'#91d5ff',
	'#69c0ff',
	'#40a9ff',
	'#1890ff',
	'#096dd9',
	'#0050b3',
	'#003a8c'
  ];

const DEFAULT_COLOR = '#EEE';

// const getRandomInt = () => {
//   return parseInt(Math.random() * 100);
// };

const geographyStyle = {
  default: {
    outline: 'none',
	stroke: 'white', 
	strokeWidth: '0.2'
  },
  hover: {
    // fill: '#808080',
    transition: 'all 250ms',
    outline: 'none',
    stroke: 'black',
    strokeWidth: 1,
  },
  pressed: {
    outline: 'none'
  }
};

const getRandomInt = () => {
  return parseInt(Math.random() * 100);
};

// will generate random heatmap data on every call
const getHeatMapData = () => {
//   console.log(ar)
  return [
    { id: 'US', state: 'United States', value: getRandomInt() },
    { id: 'CA', state: 'Canada', value: getRandomInt() },
    { id: 'GB', state: 'United Kingdom', value: getRandomInt() },
    { id: 'FR', state: 'France', value: getRandomInt() },
    { id: 'DE', state: 'Germany', value: getRandomInt() },
    { id: 'JP', state: 'Japan', value: getRandomInt() },
];
};

export const WorldMapComponent = (props) => {

	const dispatch = useDispatch()
	const {updateStates} = bindActionCreators(actionCreators, dispatch);

    const {
        gradCountryData
    } = useSelector(state => state.dashboardState);

	// const [data, setData] = useState([
    //     { id: 'USA', state: 'United States', value: getRandomInt() },
    //     { id: 'CAN', state: 'Canada', value: getRandomInt() },
    //     { id: 'GBR', state: 'United Kingdom', value: getRandomInt() },
    //     { id: 'FRA', state: 'France', value: getRandomInt() },
    //     { id: 'DEU', state: 'Germany', value: getRandomInt() },
    //     { id: 'JPN', state: 'Japan', value: getRandomInt() },
    // ]);
    

	// const [currentState, setCurrentState] = useState('')
	// const [redirect, setRedirect] = useState(0)
	// const [date1, setDate1] = useState('')
	// const [date2, setDate2] = useState('')

	const [tooltipContent, setTooltipContent] = useState('');
	const [data, setData] = useState([]);
	const [currentState, setCurrentState] = useState('')
	const [redirect, setRedirect] = useState(0)
	const [date1, setDate1] = useState('')
	const [date2, setDate2] = useState('')
	
	React.useEffect(()=>{
		setData(props.datamap)
		setDate1(props.date1)
		setDate2(props.date2)
		updateStates({
			"type": "reducerUpdates",
			"payload": {
				"gradCountryData": {
					fromColor: COLOR_RANGE[0],
					toColor: COLOR_RANGE[COLOR_RANGE.length - 1],
					min: 0,
					max: props.datamap.reduce((max, item) => (item.value > max ? item.value : max), 0)
				}
			}
		})
	  }, [props.datamap, props.date1, props.date2]);
  

  	const notify = (error) => {
		toast.error("No relevant articles found within " + error, { position: toast.POSITION.BOTTOM_RIGHT,});
	}

	const gradientData = {
	  fromColor: COLOR_RANGE[0],
	  toColor: COLOR_RANGE[COLOR_RANGE.length - 1],
	  min: 0,
	  max: data.reduce((max, item) => (item.value > max ? item.value : max), 0)
	};
  
	const colorScale = scaleQuantile()
	  .domain(data.map(d => d.value))
	  .range(COLOR_RANGE);
  
	const onMouseEnter = (geo, current = { value: 'NA' }) => {
	  return () => {
		setTooltipContent(`${geo.properties.name}: ${current.value}`);
	  };
	};
  
	const onMouseLeave = () => {
	  setTooltipContent('');
	};

	const handleGeo = (current, geo) =>{
		setCurrentState(current)
		if(current === undefined){
			notify(geo.properties.name)
		}
		else{
		    toast.info("Data found in "+ current.country, { position: toast.POSITION.BOTTOM_RIGHT,});	
		}
	}

	const handleFilter = ({ constructor: { name } }) => {
		return name !== "MouseEvent";
	  };

	if(redirect === 1){
		return <Navigate to = {"/dashboard/" + currentState.country + "/" + date1 + "/" + date2} />
	}

    return (
      	<div className="full-width-height container">
        
			{/* <h1 className="no-margin center">Heatmap Analysis</h1> */}
			{/* <ReactTooltip>{tooltipContent}</ReactTooltip> */}
		
			<ComposableMap
				// projectionConfig={PROJECTION_CONFIG}
                style={{width: "100%"}} 
                // width={300}
				// height={220}
                projectionConfig={{
                    rotate: [-10, 0, 0],
                    scale: 147
                  }}
				// projection="geoMercator"
                // scale={100}
                // rotate={[-10, 0, 0]}
				// width={100}
				// height={220}
				// data-tip=""
			>
                 {/* <Sphere stroke="#E4E5E6" strokeWidth={0.5} /> */}
                {/* <Graticule stroke="#E4E5E6" strokeWidth={0.5} /> */}
				<ZoomableGroup
					// filterZoomEvent={handleFilter}
					// translateExtent={[[0, -mapHeight], [mapWidth, mapHeight]]}
				>
					<Geographies geography={WORLDTOPOJSON}>
						{({ geographies }) =>
						// iterating india.topo.json 
							geographies.map((geo, projection) => {

								// get the data(a number here) for a particular geography by matching the id
								const current = data.find(s => s.id === geo.id);
								return (
								<Geography
									key={geo.rsmKey}
									geography={geo}
										projection={ projection }
									fill={current ? colorScale(current.value) : DEFAULT_COLOR}
									style={geographyStyle}
									onMouseEnter={onMouseEnter(geo, current)}
									onMouseLeave={onMouseLeave}
									// onClick = {()=>handleGeo(current, geo)}
								/>
								);
							})
						}
					</Geographies>
				</ZoomableGroup>
			
			</ComposableMap>
			<div className="footer_container" style={{display: "flex"}}>

				{/* <LinearGradient data={gradientData} /> */}
				{/* <div className="center">
				<button className="mt16" onClick={onChangeButtonClick}>Change</button>
				</div> */}
				<ReactTooltip style={{marginLeft: "auto", marginTop: "auto", background: "#808080", color : "#FFFFFF", paddingInline: "3vw", borderRadius: "6px"}}>{tooltipContent}</ReactTooltip>

			</div>

			<ToastContainer />
      </div>
    );
}