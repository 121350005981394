import React from 'react'
import Cookies from 'js-cookie'
import { Navigate } from 'react-router-dom'
import "../../staticFiles/cssFiles/Analytics/statsRightView.css"
import { ToastContainer, toast } from 'react-toastify';
import stat1 from "../../staticFiles/images/stat_1.svg"
import stat2 from "../../staticFiles/images/stat_2.svg"
import stat3 from "../../staticFiles/images/stat_3.svg"
import stat4 from "../../staticFiles/images/stat_4.svg"
import stat5 from "../../staticFiles/images/stat_5.svg"
import stat6 from "../../staticFiles/images/stat_6.svg"
import { fetchLastWeekArticles, fetchLastWeekDrafts, fetchLastWeekReports, fetchTotalArticles, fetchTotalCategories, fetchTotalReports } from '../../endppoints/endpoint'
import CountUp from 'react-countup';
import { PageAnimations } from '../GlobalPageAnimations.jsx/pageAnimations';


export const StatsRightView = () => {
    const [redirect, setRedirect] = React.useState(0)
    const [lwDrafts, setLwDrafts] = React.useState()
    const [lwArticles, setLwArticles] = React.useState()
    const [lwReports, setLwReports] = React.useState()
    const [totalReports, setTotalReports] = React.useState()
    const [autoReports, setAutoReports] = React.useState()
    const [manualReports, setManualReports] = React.useState()
    const [totalArticles, setTotalArticles] = React.useState()
    const [totalCategories, setTotalCategories] = React.useState()

    React.useEffect(()=>{
        fetch_stats();
    }, []);

    const check_error = (response) =>{
        if(response.status === 401){
            localStorage.clear()
            Cookies.remove('token');
            setTimeout(() => {
                setRedirect(1)    
            }, 1000);
            toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
        }
        else{
            toast.info("server error", { position: toast.POSITION.TOP_CENTER})
        }
    }

    const fetch_stats = async() =>{
        var response = {}
        response = await fetchLastWeekDrafts()
        if(response.status === 200){
            setLwDrafts(response.data.totalCount)
        }
        else{
            check_error(response)
        }

        response = await fetchLastWeekReports()
        if(response.status === 200){
            setLwReports(response.data.totalCount)
            setAutoReports(response.data.auto)
            setManualReports(response.data.manual)
        }
        else{
            check_error(response)
        }

        response = await fetchLastWeekArticles()
        if(response.status === 200){
            setLwArticles(response.data.totalCount)
        }
        else{
            check_error(response)
        }

        response = await fetchTotalReports()
        if(response.status === 200){
            setTotalReports(response.data.totalCount)
        }
        else{
            check_error(response)
        }

        response = await fetchTotalArticles()
        if(response.status === 200){
            setTotalArticles(response.data.totalCount)
        }
        else{
            check_error(response)
        }

        response = await fetchTotalCategories()
        if(response.status === 200){
            setTotalCategories(response.data.totalCount)
        }
        else{
            check_error(response)
        }
    }

    if(redirect === 1){
        // return <Navigate to = "/login" />
        window.location.href = '/login';
    }

  return (
    <div className='right_stats_container'>   
        <PageAnimations/>
        <div className="main_stats_wrapper">
            <div className="stat_section_1_wrapper stat_section_wrapper">
                <div className="stat_1 stat">
                    <div className="stat_content_wrapper">
                        <div className="title_stat">
                            Number of Articles Scrapped of your interest in the last 7 days
                        </div>
                        <div className="data_stat">
                            <div className="data_stat_left figure">
                                <CountUp end={Number(lwArticles)} separator="," duration={1} />
                            </div>
                            <img src = {stat1} className="data_stat_right" alt='stat_1'/>
                        </div>
                    </div>
                </div>
                <div className="stat_2 stat">
                    <div className="stat_content_wrapper">
                        <div className="title_stat">
                            Number of Categories present for you
                        </div>
                        <div className="data_stat">
                            <div className="data_stat_left figure">
                                <CountUp end={Number(totalCategories)} separator="," duration={1} />
                            </div>
                            <img src = {stat4} className="data_stat_right" alt='stat_4'/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="stat_section_2_wrapper stat_section_wrapper">
                <div className="stat_3 stat">
                    <div className="stat_content_wrapper">
                        <div className="title_stat">
                            Total Number of Reports generated in the last week
                        </div>
                        <div className="data_stat">
                            <div className="data_stat_left figure">
                                <CountUp end={Number(lwReports)} separator="," duration={1} />
                            </div>
                            <img src = {stat2} className="data_stat_right" alt='stat_2'/>
                        </div>
                        <div className="data_stat">
                            <div className="data_stat_left desc">
                                Manually-generated Reports
                            </div>
                            <div className="data_stat_right figure">
                                <CountUp end={Number(manualReports)} separator="," duration={1} />
                            </div>
                        </div>
                        <div className="data_stat">
                            <div className="data_stat_left desc">
                                Auto-generated Reports
                            </div>
                            <div className="data_stat_right figure">
                                <CountUp end={Number(autoReports)} separator="," duration={1} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="stat_4 stat">
                    <div className="stat_content_wrapper">
                        <div className="title_stat">
                            Total Number of Reports generated for you
                        </div>
                        <div className="data_stat">
                            <div className="data_stat_left figure">
                                <CountUp end={Number(totalReports)} separator="," duration={1} />
                            </div>
                            <img src = {stat5} className="data_stat_right" alt='stat_5'/>
                        </div>
                    </div>  
                </div>
            </div>
            <div className="stat_section_3_wrapper stat_section_wrapper">   
                <div className="stat_5 stat">
                    <div className="stat_content_wrapper">
                        <div className="title_stat">
                            Total Number of Articles in NewsMon
                        </div>
                        <div className="data_stat">
                            <div className="data_stat_left figure">
                                <CountUp end={Number(totalArticles)} separator="," duration={1} />
                            </div>
                            <img src = {stat3} className="data_stat_right" alt='stat_3'/>
                        </div>
                    </div>
                </div>
                <div className="stat_6 stat">
                    <div className="stat_content_wrapper">
                        <div className="title_stat">
                            Total Number of Drafts created by you in the current week
                        </div>
                        <div className="data_stat">
                            <div className="data_stat_left figure">
                                <CountUp end={Number(lwDrafts)} separator="," duration={1} />
                            </div>
                            <img src = {stat6} className="data_stat_right" alt='stat_6'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ToastContainer />
    </div>
  )
}
