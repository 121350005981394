import axios from 'axios'
import { saveAs } from 'file-saver';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

const instance = axios.create();

instance.interceptors.request.use((config) => {
    const ciphertext = Cookies.get('token');
    const secretKey = 'some_secret_key';
    const bytes  = CryptoJS.AES.decrypt(ciphertext.toString(), secretKey);
    const token = bytes.toString(CryptoJS.enc.Utf8);
    config.headers.Authorization = `Bearer ${token}`;
    return config;
});

// instance.interceptors.response.use(
//     (response) => response,
//     (error) => {
//       if (error.response.status === 401) {
//         // refresh token logic
//         const refreshToken = Cookies.get('refreshToken');
//         return axiosInstance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/auth/refreshToken', { refreshToken }).then(({ data }) => {
//           Cookies.set('token', data.token);
//           // retry the failed request with the new token
//           error.config.headers.Authorization = `Bearer ${data.token}`;
//           return instance(error.config);
//         }).catch(refreshTokenError => {
//           // handle refresh token error, such as redirecting to login page
//           console.error(refreshTokenError);
//           window.location.href = '/login';
//         });
//       }
//       return Promise.reject(error);
//     }
//   );  


export const getArticles = async(message) =>{

    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
      };

    console.log(message, process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/list/new')
    let data_rec = {}
    await instance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/list/new', message, config)
        // .then(response => {
        //     data_rec = response.data
        //     console.log(data_rec)
        // })
        
        // .catch(error => {
        //     data_rec['status'] = '500';
        //     console.log(error)
        // });

            .then(response => {
            console.log('axios_inside', response)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const getArticlesFromPrompt = async(message) =>{

    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
      };

    console.log(message, process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/semanticSearch')
    let data_rec = {}
    await instance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/semanticSearch', message, config)
        .then(response => {
            console.log('axios_inside', response)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const getParticularArticle = async(article_id) =>{

    const config = {
        headers: {
              'Content-Type': 'application/json',
        }
      };  

    console.log(article_id, process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/id/'+article_id)
    let data_rec = {}
    await instance.get(process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/id/'+article_id, config)
        // .then(response => {
        //     data_rec = response.data
        //     console.log(data_rec)
        // })
        
        // .catch(error => {
        //     data_rec['status'] = '500';
        //     console.log(error)
        // });
        // return data_rec;
        .then(response => {
            console.log('axios_inside', response)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const getRelatedArticles = async(message) =>{

    const config = {
        headers: {
              'Content-Type': 'application/json',
        }
      };  

    console.log(message,process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/relatedArticles')
    let data_rec = {}
    await instance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/relatedArticles', message ,config)
        .then(response => {
            console.log('axios_inside', response)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}


export const exportPdf = async(message) =>{

    console.log('axios_outside', message, process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/generateReport')
    let data_rec = {};
    const config = {
        headers: {
              'Content-Type': 'application/json',
        }
      };
    await instance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/generateReport', message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            // if(message.reportFormat === "articles"){
            //     if(message.fileType === "docx"){
            //         // saveAs(response.data, 'Sarvagya-Report.docx', { autoBom: false });
            //         saveAs(new Blob([new Uint8Array(response.data.file.data).buffer], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }), response.data.fileName, { autoBom: false });
            //     }
            //     else if(message.fileType === "pdf"){
            //         // saveAs(response.data, 'Sarvagya-Report.pdf');
            //         saveAs(new Blob([new Uint8Array(response.data.file.data).buffer]), response.data.fileName, { autoBom: false });
            //     }
            // }
            // else{
                data_rec['data'] = response.data
                data_rec['status'] = response.status
            // }
        })
        
        .catch(error => {
            data_rec['status'] = error.response.status;
            console.log(error)
        });
        return data_rec;
}

export const getHistory = async(message) =>{

    const config = {
        headers: {
              'Content-Type': 'application/json',
        }
      };


    console.log('axios_outside', message, process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/history')
    let data_rec = {}
    await instance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/history', message, config)
        // .then(response => {
        //     // console.log('axios_inside', response.data)
        //     data_rec = response.data
        // })
        
        // .catch(error => {
        //     data_rec['status'] = '500';
        //     console.log(error)
        // });
        // return data_rec;
        .then(response => {
            console.log('axios_inside', response)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const downloadHistory = async(message, extension) =>{

    console.log('axios_outside', message, extension, process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/download/report/'+message)
    let data_rec
    await instance.get(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/download/report/'+message, { headers: {
        'Content-Type': 'application/json',
        },
        // responseType: 'blob'
      })
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec = response.data.file.data

            if(extension === 'pdf'){
                saveAs(new Blob([new Uint8Array(response.data.file.data).buffer]), response.data.fileName, { autoBom: false });
            }
            else if(extension === 'docx'){
                saveAs(new Blob([new Uint8Array(response.data.file.data).buffer]), response.data.fileName, { autoBom: false });
            }
        })
        
        .catch(error => {
            data_rec['status'] = '500';
            console.log(error)
        });
        return data_rec;
}

export const heatmapData = async(message) =>{

    const config = {
        headers: {
              'Content-Type': 'application/json',
        }
      };  

    console.log('axios_heatmap', message, process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/heatmap/state')
    let data_rec = {}
    await instance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/heatmap/state', message, config)
        // .then(response => {
        //     // console.log('axios_inside', response.data)
        //     data_rec = response.data
        // })
        
        // .catch(error => {
        //     data_rec['status'] = '500';
        //     console.log(error)
        // });

        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const graphData = async(message) =>{

    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
      };  

    console.log('axios_outside', message, process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/timeSeriesGraph/'+message)
    let data_rec = {}
    await instance.get(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/timeSeriesGraph/'+message, config)
        // .then(response => {
        //     console.log('axios_inside')
        //     console.log(response.data)
        //     data_rec = response.data
        // })
        
        // .catch(error => {
        //     data_rec['status'] = '500';
        //     console.log(error)
        // });
        // return data_rec;
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

// export const getExportData = async(message) =>{

//     console.log(message, process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/onDemand')

//     const config = {
//         headers: {
//               'Content-Type': 'application/json',
//         }
//       };  

//     let data_rec = {}
//     await instance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/onDemand', message, config)
//         // .then(response => {
//         //     // console.log('axios_inside', response.data)
//         //     data_rec = response.data
//         // })
        
//         // .catch(error => {
//         //     data_rec['status'] = '500';
//         //     console.log(error)
//         // });
//         // return data_rec;

//         .then(response => {
//             // console.log('axios_inside', response.data)
//             data_rec['data'] = response.data
//             data_rec['status'] = response.status
//         })
        
//         .catch(error => {
//             console.log(error)
//             data_rec['data'] = error.response.data
//             data_rec['status'] = error.response.status
//         });
//         return data_rec;
// }

export const getExportData = async(message) =>{

    console.log(message, process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/onDemandNew')

    const config = {
        headers: {
              'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/onDemandNew', message, config)
        // .then(response => {
        //     // console.log('axios_inside', response.data)
        //     data_rec = response.data
        // })
        
        // .catch(error => {
        //     data_rec['status'] = '500';
        //     console.log(error)
        // });
        // return data_rec;

        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const getExportDataNew = async(message) =>{

    console.log(message, process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/onDemandNew')

    const config = {
        headers: {
              'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/onDemandNew', message, config)

        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const getUserDetails = async() =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/auth/userDetails')

    const config = {
        headers: {
             'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.get(process.env.REACT_APP_SERVER_HOST+'/api/v1/auth/userDetails', config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const resetPassword = async(message) =>{

    console.log(message, process.env.REACT_APP_SERVER_HOST+'/api/v1/auth/resetPassword')

    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/auth/resetPassword', message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data;
            data_rec['status'] = error.response.status;
        });
        return data_rec;
}

export const getFrequentKeywords = async() =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/frequentKeywords')

    const config = {
        headers: {
             'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.get(process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/frequentKeywords', config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const getDomains = async() =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/domains')

    const config = {
        headers: {
             'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.get(process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/domains', config)
        .then(response => {
            console.log('axios_inside')
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const getProfileDomains = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/domainSources', message)

    const config = {
        headers: {
             'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/domainSources', message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const changeConcernDomains = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/domainSources/blacklist', message)

    const config = {
        headers: {
             'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.put(process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/domainSources/blacklist', message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const getCategories = async() =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/categories')

    const config = {
        headers: {
             'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.get(process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/categories', config)
        .then(response => {
            console.log('axios_inside')
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const getProfileCategories = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/categorySources', message)

    const config = {
        headers: {
             'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/categorySources', message, config)
        .then(response => {
            console.log('axios_inside')
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const categoriesFavourite = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/categorySources/favourite', message)

    const config = {
        headers: {
             'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.put(process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/categorySources/favourite', message, config)
        .then(response => {
            console.log('axios_inside')
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const categoriesDefault = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/categorySources/default', message)

    const config = {
        headers: {
             'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.put(process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/categorySources/default', message, config)
        .then(response => {
            console.log('axios_inside')
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const categoriesDefaultGet = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/categorySources/default')

    const config = {
        headers: {
             'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.get(process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/categorySources/default', config)
        .then(response => {
            console.log('axios_inside')
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}



export const addFrequentKeywords = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/frequentKeywords/add', message)

    const config = {
        headers: {
             'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.put(process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/frequentKeywords/add', message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const deleteFrequentKeywords = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/frequentKeywords/delete', message)

    const config = {
        headers: {
             'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.put(process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/frequentKeywords/delete', message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const fetchOrganizations = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/auth/organizations', message)

    const config = {
        headers: {
             'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/auth/organizations',message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const addOrganizations = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/auth/organizations/add', message)

    const config = {
        headers: {
             'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.put(process.env.REACT_APP_SERVER_HOST+'/api/v1/auth/organizations/add', message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const deleteOrganizations = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/auth/organizations/remove', message)

    const config = {
        headers: {
             'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.put(process.env.REACT_APP_SERVER_HOST+'/api/v1/auth/organizations/remove', message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const fetchUsers = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/auth/users', message)

    const config = {
        headers: {
                'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/auth/users', message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}


export const addUsers = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/auth/users/add', message)

    const config = {
        headers: {
                'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/auth/users/add', message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const deleteUsers = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/auth/users/remove', message)

    const config = {
        headers: {
                'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/auth/users/remove', message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

// export const validateToken = async() =>{

//     console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/auth/validateToken')

//     const config = {
//         headers: {
//             'Content-Type': 'application/json',
//         }
//       };  

//     let data_rec = {}
//     await instance.get(process.env.REACT_APP_SERVER_HOST+'/api/v1/auth/validateToken', config)
//         .then(response => {
//             // console.log('axios_inside', response.data)
//             data_rec['status'] = response.status
//         })
        
//         .catch(error => {
//             console.log(error)
//             data_rec['status'] = error.response.status;
//         });
//         return data_rec;
// }

// DRAFTS APIS

export const getDraftsList = async() =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/draft/all')

    const config = {
        headers: {
             'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.get(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/draft/all', config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const createDraft = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/draft', message)

    const config = {
        headers: {
                'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/draft', message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const fetchDraft = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/draft/pagination/all', message)

    const config = {
        headers: {
                'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/draft/pagination/all', message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const deleteDraft = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/draft/'+message)

    const config = {
        headers: {
                'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.delete(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/draft/'+ message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const AddArticleToDraft = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/draft/addArticles', message)

    const config = {
        headers: {
                'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.put(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/draft/addArticles', message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const getDraftArticles = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/draft/'+message)

    const config = {
        headers: {
             'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.get(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/draft/'+message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const getDraftArticlesNew = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/draft/contents', message)

    const config = {
        headers: {
             'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/draft/contents', message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const getKeywordsPreviewArticles = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/keywordsList/preview',message)

    const config = {
        headers: {
             'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/keywordsList/preview',message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const getKeywordsPreviewArticlesNew = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/keywordsList/preview',message)

    const config = {
        headers: {
             'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/keywordsList/preview',message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const RemoveArticlesFromDraft = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/draft/removeArticles', message)

    const config = {
        headers: {
                'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.put(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/draft/removeArticles', message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}


export const fetchOrgKeywords = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/keywords', message)

    const config = {
        headers: {
                'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/keywords', message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const deleteOrgKeywords = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/keywords/delete/', message["keyword"])

    const config = {
        headers: {
                'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.delete(process.env.REACT_APP_SERVER_HOST+'/api/v1/keywords/delete/'+message["keyword"], config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const addOrgKeywords = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/keywords/add', message)

    const config = {
        headers: {
                'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/keywords/add', message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const updateOrgKeywords = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/keywords/update', message)

    const config = {
        headers: {
                'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.put(process.env.REACT_APP_SERVER_HOST+'/api/v1/keywords/update', message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const getVerificationCoordinates = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/getLatLongByLocation/', message)

    const config = {
        headers: {
                'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.get(process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/getLatLongByLocation/'+message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const fetchScheduledReports = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/scheduledReports', message)

    const config = {
        headers: {
                'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/scheduledReports', message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}


export const fetchEventBubbleData = async(event_message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/analytics/eventBubble', event_message)

    const config = {
        headers: {
                'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/analytics/eventBubble', event_message, config)
        .then(response => {
            // console.log('bubble data', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

// Stats

export const fetchLastWeekDrafts = async() =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/stats/lastWeek/drafts')

    const config = {
        headers: {
                'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.get(process.env.REACT_APP_SERVER_HOST+'/api/v1/stats/lastWeek/drafts', config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const fetchLastWeekArticles = async() =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/stats/lastWeek/articles')

    const config = {
        headers: {
                'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.get(process.env.REACT_APP_SERVER_HOST+'/api/v1/stats/lastWeek/articles', config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const fetchLastWeekReports = async() =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/stats/lastWeek/reports')

    const config = {
        headers: {
                'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.get(process.env.REACT_APP_SERVER_HOST+'/api/v1/stats/lastWeek/reports', config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const fetchTotalReports = async() =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/stats/total/reports')

    const config = {
        headers: {
                'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.get(process.env.REACT_APP_SERVER_HOST+'/api/v1/stats/total/reports', config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const fetchTotalArticles = async() =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/stats/total/articles')

    const config = {
        headers: {
                'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.get(process.env.REACT_APP_SERVER_HOST+'/api/v1/stats/total/articles', config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const fetchTotalCategories = async() =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/stats/total/categories')

    const config = {
        headers: {
                'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.get(process.env.REACT_APP_SERVER_HOST+'/api/v1/stats/total/categories', config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const fetchGeneratedReports = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/scheduledReports/generatedReports/', message)

    const config = {
        headers: {
                'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/scheduledReports/generatedReports/', message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

// Analytics

export const fetchStateMapData = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/analytics/heatmap/state', message)

    const config = {
        headers: {
                'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/analytics/heatmap/state', message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const fetchCountryMapData = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/analytics/heatmap/country', message)

    const config = {
        headers: {
                'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/analytics/heatmap/country', message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const fetchScheduleInfo = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/scheduledReports/info', message)

    const config = {
        headers: {
                'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/scheduledReports/info', message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}


export const cloneDraft = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/draft/clone', message)

    const config = {
        headers: {
                'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/draft/clone', message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const getAllDraftArticlesForRearrangeApi = async(message) =>{
    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/draft/getAllArticles/'+message)

    const config = {
        headers: {
                'Content-Type': 'application/json',
        }
    };  

    let data_rec = {}
    await instance.get(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/draft/getAllArticles/' + message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const changeDraftArticlesOrder = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/draft/updateOrder', message)

    const config = {
        headers: {
                'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.put(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/draft/updateOrder', message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const fetchAllDraftArticleIds = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/draft/getArticleIds/' + message)

    const config = {
        headers: {
                'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.get(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/draft/getArticleIds/' + message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}


export const searchDraftArticles = async(message) =>{

    console.log('http://65.1.232.65:81/api/v1/reports/draft/contents', message)

    const config = {
        headers: {
                'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.post('http://65.1.232.65:81/api/v1/reports/draft/contents', message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const renameDraft = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/draft/rename', message)

    const config = {
        headers: {
                'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.put(process.env.REACT_APP_SERVER_HOST+'/api/v1/reports/draft/rename', message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const rootTimeSeriesGraphFetch = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/analytics/timeSeriesGraph', message)

    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/analytics/timeSeriesGraph', message, config)
    // await instance.post('https://newsmon.saptanglabs.com'+'/api/v1/analytics/timeSeriesGraph', message, config)
        .then(response => {
            console.log('axios_inside time series', response)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log("fkjsdjf", error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const clusterArticlesFetch = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/cluster', message)

    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/cluster', message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const TimeSeriesTableDataFetch = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/events', message)

    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/articles/events', message, config)
        .then(response => {
            // console.log('axios_inside', response.data)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}


export const factCheckArticles = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST + "/api/v1/articles/semanticSearch_factCheck", message)

    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.post(process.env.REACT_APP_SERVER_HOST + "/api/v1/articles/semanticSearch_factCheck", message, config)
        .then(response => {
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}

export const fetchReportArticlesNew = async(message) =>{

    console.log(process.env.REACT_APP_SERVER_HOST + "/api/v1/reports/filters", message)

    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await instance.post(process.env.REACT_APP_SERVER_HOST + "/api/v1/reports/filters", message, config)
        .then(response => {
            console.log(response)
            data_rec['data'] = response.data
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['data'] = error.response.data
            data_rec['status'] = error.response.status
        });
        return data_rec;
}