import React from 'react'
import '../../staticFiles/cssFiles/reportGenArticles/rightReportGenArticles.css'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Collapse, Pagination, Slider, Stack } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { categoriesDefaultGet, exportPdf, fetchOrgKeywords, fetchReportArticlesNew, getCategories, getDomains, getExportDataNew, getFrequentKeywords, getVerificationCoordinates } from '../../endppoints/endpoint';
import { ViewArticle } from '../onDemandPdf/viewArticle';
import { Navigate } from 'react-router-dom';
import loading from "../../staticFiles/images/loading.svg";
import export_loading from "../../staticFiles/images/loading_export.svg";
import no_result_logo from "../../staticFiles/images/No_results.svg";
import expArw from "../../staticFiles/images/expArw.svg"
import colArw from "../../staticFiles/images/colArw.svg"
import reports_export from "../../staticFiles/images/Export_new_img.svg"
import no_filters_logo from "../../staticFiles/images/No_filters.svg"
import no_articles_logo from "../../staticFiles/images/No_articles.svg"
import close_filter from "../../staticFiles/images/close_filter_modal.svg"
import approved_tick from  "../../staticFiles/images/approved-round-svgrepo-com.svg"
import Select from 'react-select'

import moment from 'moment/moment';

import { Tooltip } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dropDown_open from "../../staticFiles/images/reports_article_drop_open.svg"
import expand_icon from "../../staticFiles/images/selections_expand.svg"
import collapse_icon from "../../staticFiles/images/selections_collapse.svg"
import filter_icon from "../../staticFiles/images/filter_icon_report.svg"

import Cookies from 'js-cookie';
import { PageAnimations } from '../GlobalPageAnimations.jsx/pageAnimations';
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from 'rsuite';

import ReactModal from 'react-modal';
import CreatableSelect from 'react-select/creatable';
import searchIcon from "../../staticFiles/images/searchIcon.svg"
import temp_filter_icon from "../../staticFiles/images/selected_filter_dot.svg"
import { LeafletMaps } from '../test3/leaflet_maps';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export const RightReportGenArticles = () => {

    const [allId, setAllId] = React.useState([])
    const [currentIds, setCurrentIds] = React.useState([])
    const [selectedIds, setSelectedIds]  = React.useState({})
    const currentDate = new Date();
    const initialDate = new Date();
    initialDate.setDate(currentDate.getDate() - 3);
    const [selectedDateRange, setSelectedDateRange] = React.useState([initialDate, new Date()])
    const [exportAs, setExportAs] = React.useState('');
	const tableRef = React.useRef(null);
    const [sampleRows, setSampleRows] = React.useState([]);
    const [expanded, setExpanded] = React.useState({});
    const [expandedSea, setExpandedSea] = React.useState({});
    const [redirect, setRedirect] = React.useState(0)
    const [map, setMap] = React.useState(0)
    const [expmap, setExpMap] = React.useState(1)
    const [totalArticles, setTotalArticles] = React.useState(null)
    const [checkboxes, setCheckboxes] = React.useState([])
	const [categories, setCategories] = React.useState([])
	const [appliedCategory, setAppliedCategory] = React.useState("")
	const [dropdownCatergory, setDropdownCatergory] = React.useState(false)
	const [promptToggle, setPromptToggle] = React.useState(false)
	const [sampleOptions, setSampleOptions] = React.useState([])
    const [selectionsExpand, setSelectionsExpand] = React.useState(true) 
    const [page, setPage] = React.useState(1)
    const [totalData, setTotalData] = React.useState(0) 
    const [totalPage, setTotalPage] = React.useState(0) 

    // tabs switch handling states
    const [tab, setTab] = React.useState(1) // 1 -> filter results  , 2 -> selected articles

    const [rpaflDefaultOrgCategory, setRpaflDefaultOrgCategory] = React.useState(null)

    // applied filters related states .... starts with rpafl(report applied filters)
    // const [rpaflSelectedFilter, setRpaflSelectedFilter] = React.useState(1)
    // const [rpaflActiveModal, setRpaflActiveModal] = React.useState(false)
    // const [rpaflSelectedDateRange, setRpaflSelectedDateRange] = React.useState([])
    // const [rpaflSelectCategory, setRpaflSelectedCategory] = React.useState(null)
    // const [rpaflSampleOptionsCategory, setRpaflSampleOptionsCategory] = React.useState([])
    // const [rpaflKeywordSelectorCheck, setRpaflKeywordSelectorCheck] = React.useState(0) // 1 -> custom , 2 -> keyword list 
    // const [rpaflKeywordsCustomHeader, setRpaflKeuwordsCustomHeader] = React.useState({value: "Title & Body", label: "Title & Body"})

    const rpaflKeywordsCustomHeaderSampleOptions = [
        {value: "Title & Body", label: "Title & Body"},
        {value: "Body", label: "Body"} 
    ]

    const rpaflSampleOptionsLanguage = [
        {"label":"ENGLISH","value":"en"},
        {"label":"HINDI","value":"hi"},
        {"label":"TELUGU","value":"te"},
        {"label":"PUNJABI","value":"pa"},
        {"label":"MARATHI","value":"mr"},
        {"label":"FRENCH","value":"fr"},
        {"label":"MALAY","value":"ms"},
        {"label":"ORIYA","value":"or"},
        {"label":"SANSKRIT","value":"sa"},
        {"label":"TAGALOG","value":"tl"},
        {"label":"SERBIAN","value":"sr"},
        {"label":"TAMIL","value":"ta"},
        {"label":"DANISH","value":"da"},
        {"label":"KHASI","value":"kha"},
        {"label":"INDONESIAN","value":"id"},
        {"label":"PORTUGUESE","value":"pt"},
        {"label":"ARABIC","value":"ar"},
        {"label":"GREEK","value":"el"},
        {"label":"SPANISH","value":"es"},
        {"label":"MAURITIAN_CREOLE","value":"mfe"},
        {"label":"SWAHILI","value":"sw"},
        {"label":"VIETNAMESE","value":"vi"},
        {"label":"DUTCH","value":"nl"},
        {"label":"MALAYALAM","value":"ml"},
        {"label":"URDU","value":"ur"},
        {"label":"ESTONIAN","value":"et"},
        {"label":"SCOTS","value":"sco"},
        {"label":"SLOVENIAN","value":"sl"},
        {"label":"GERMAN","value":"de"},
        {"label":"KANNADA","value":"kn"},
        {"label":"TURKISH","value":"tr"},
        {"label":"WARAY_PHILIPPINES","value":"war"},
        {"label":"ZULU","value":"zu"},
        {"label":"MALAGASY","value":"mg"},
        {"label":"HAUSA","value":"ha"},
        {"label":"NEPALI","value":"ne"},
        {"label":"HEBREW","value":"iw"},
        {"label":"ZHUANG","value":"za"},
        {"label":"KINYARWANDA","value":"rw"},
        {"label":"Chinese","value":"zh"},
        {"label":"MANX","value":"gv"},
        {"label":"BENGALI","value":"bn"},
        {"label":"CZECH","value":"cs"},
        {"label":"JAVANESE","value":"jw"},
        {"label":"ITALIAN","value":"it"},
        {"label":"Japanese","value":"ja"},
        {"label":"UZBEK","value":"uz"},
        {"label":"POLISH","value":"pl"},
        {"label":"ChineseT","value":"zh-Hant"},
        {"label":"SESELWA","value":"crs"},
        {"label":"WELSH","value":"cy"},
        {"label":"AYMARA","value":"ay"},
        {"label":"BASQUE","value":"eu"},
        {"label":"LATIN","value":"la"},
        {"label":"UKRAINIAN","value":"uk"},
        {"label":"X_KLINGON","value":"tlh"},
        {"label":"X_PIG_LATIN","value":"zzp"},
        {"label":"RUSSIAN","value":"ru"},
        {"label":"NORWEGIAN","value":"no"},
        {"label":"WOLOF","value":"wo"},
        {"label":"HUNGARIAN","value":"hu"},
        {"label":"AFRIKAANS","value":"af"},
        {"label":"NORWEGIAN_N","value":"nn"},
        {"label":"RUNDI","value":"rn"},
        {"label":"SOMALI","value":"so"},
        {"label":"BIHARI","value":"bh"},
        {"label":"YORUBA","value":"yo"},
        {"label":"Korean","value":"ko"},
        {"label":"AFAR","value":"aa"},
        {"label":"OROMO","value":"om"},
        {"label":"TONGA","value":"to"},
        {"label":"GUJARATI","value":"gu"},
        {"label":"XHOSA","value":"xh"},
        {"label":"THAI","value":"th"},
        {"label":"CORSICAN","value":"co"},
        {"label":"BRETON","value":"br"},
        {"label":"OCCITAN","value":"oc"},
        {"label":"SLOVAK","value":"sk"},
        {"label":"TURKMEN","value":"tk"},
        {"label":"SCOTS_GAELIC","value":"gd"},
        {"label":"MAORI","value":"mi"},
        {"label":"CATALAN","value":"ca"},
        {"label":"QUECHUA","value":"qu"},
        {"label":"PERSIAN","value":"fa"},
        {"label":"ASSAMESE","value":"as"},
        {"label":"IRISH","value":"ga"},
        {"label":"AZERBAIJANI","value":"az"},
        {"label":"X_Buginese","value":"xx-Bugi"},
        {"label":"CEBUANO","value":"ceb"},
        {"label":"ROMANIAN","value":"ro"},
        {"label":"LATVIAN","value":"lv"},
        {"label":"ESPERANTO","value":"eo"},
        {"label":"INUKTITUT","value":"iu"},
        {"label":"HAITIAN_CREOLE","value":"ht"},
        {"label":"GREENLANDIC","value":"kl"},
        {"label":"ICELANDIC","value":"is"},
        {"label":"CROATIAN","value":"hr"},
        {"label":"VENDA","value":"ve"},
        {"label":"INTERLINGUA","value":"ia"},
        {"label":"FINNISH","value":"fi"},
        {"label":"NYANJA","value":"ny"},
        {"label":"IGBO","value":"ig"},
        {"label":"GALICIAN","value":"gl"},
        {"label":"AKAN","value":"ak"},
        {"label":"SWEDISH","value":"sv"},
        {"label":"ALBANIAN","value":"sq"},
        {"label":"TIBETAN","value":"bo"},
        {"label":"SUNDANESE","value":"su"},
        {"label":"TSWANA","value":"tn"},
        {"label":"SANGO","value":"sg"},
        {"label":"SISWANT","value":"ss"},
        {"label":"CHEROKEE","value":"chr"},
        {"label":"GUARANI","value":"gn"},
        {"label":"MALTESE","value":"mt"},
        {"label":"FAROESE","value":"fo"},
        {"label":"FRISIAN","value":"fy"},
        {"label":"SINDHI","value":"sd"},
        {"label":"SESOTHO","value":"st"},
        {"label":"INTERLINGUE","value":"ie"},
        {"label":"PASHTO","value":"ps"},
        {"label":"TSONGA","value":"ts"},
        {"label":"TATAR","value":"tt"},
        {"label":"SAMOAN","value":"sm"},
        {"label":"INUPIAK","value":"ik"},
        {"label":"KHMER","value":"km"},
        {"label":"FIJIAN","value":"fj"},
        {"label":"HMONG","value":"hmn"},
        {"label":"LINGALA","value":"ln"},
        {"label":"ARMENIAN","value":"hy"},
        {"label":"RHAETO_ROMANCE","value":"rm"},
        {"label":"BISLAMA","value":"bi"},
        {"label":"GANDA","value":"lg"},
        {"label":"LUXEMBOURGISH","value":"lb"},
        {"label":"SYRIAC","value":"syr"},
        {"label":"NAURU","value":"na"},
        {"label":"AMHARIC","value":"am"},
        {"label":"SHONA","value":"sn"},
        {"label":"HAWAIIAN","value":"haw"},
        {"label":"BULGARIAN","value":"bg"},
        {"label":"VOLAPUK","value":"vo"},
        {"label":"BURMESE","value":"my"},
        {"label":"DHIVEHI","value":"dv"}
    ] 
    const rpaflOrgKeywordsLimitPerPage = 10
    
    const closeModalExport = () =>{
        setIsOpenExportModal(false)
    }    
    const customStylesDraftDelete = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			minWidth: '25%',
			minHeight: '20%',
			background: '#FFFFFF',
			borderRadius: "14px",
			paddingTop: '5vh',
			paddingBottom: '5vh',
			paddingLeft: '5vw',
			paddingRight: '5vw',
            display: "flex",
            justifyContent: "center"
		},
		overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.71)',
            backdropFilter: "blur(2.5px)",
            zIndex: 1
		},
    };

    
    const [isOpenExportModal, setIsOpenExportModal] = React.useState(false)

    const rpaflLocationsMainMenu = [
        {value: "By Location", label: "By Location"},
        {value: "By Coordinates", label: "By Coordinates"}, 
        {value: "By States", label: "By States"}
    ]
    const rpaflSampleOptionsSentiment = [
        {value: "POSITIVE", label: "POSITIVE"}, {value: "NEUTRAL", label: "NEUTRAL"}, {value: "NEGATIVE", label: "NEGATIVE"}
    ]

    const rpaflSampleOptionsStateFilter = [
        {"label":"ANDHRA PRADESH","value":"AP"},
        {"label":"ARUNACHAL PRADESH","value":"AR"},
        {"label":"ASSAM","value":"AS"},
        {"label":"BIHAR","value":"BR"},
        {"label":"CHHATTISGARH","value":"CT"},
        {"label":"GOA","value":"GA"},
        {"label":"GUJARAT","value":"GJ"},
        {"label":"HARYANA","value":"HR"},
        {"label":"HIMACHAL PRADESH","value":"HP"},
        {"label":"JHARKHAND","value":"JH"},
        {"label":"KARNATAKA","value":"KA"},
        {"label":"KERALA","value":"KL"},
        {"label":"MADHYA PRADESH","value":"MP"},
        {"label":"MAHARASHTRA","value":"MH"},
        {"label":"MANIPUR","value":"MN"},
        {"label":"MEGHALAYA","value":"ML"},
        {"label":"MIZORAM","value":"MZ"},
        {"label":"NAGALAND","value":"NL"},
        {"label":"ODISHA","value":"OR"},
        {"label":"PUNJAB","value":"PB"},
        {"label":"RAJASTHAN","value":"RJ"},
        {"label":"SIKKIM","value":"SK"},
        {"label":"TAMIL NADU","value":"TN"},
        {"label":"TELANGANA","value":"TG"},
        {"label":"TRIPURA","value":"TR"},
        {"label":"UTTARAKHAND","value":"UT"},
        {"label":"UTTAR PRADESH","value":"UP"},
        {"label":"WEST BENGAL","value":"WB"},
        {"label":"ANDAMAN AND NICOBAR ISLANDS","value":"AN"},
        {"label":"CHANDIGARH","value":"CH"},
        {"label":"DADRA AND NAGAR HAVELI","value":"DN"},
        {"label":"DAMAN AND DIU","value":"DD"},
        {"label":"DELHI","value":"DL"},
        {"label":"JAMMU AND KASHMIR","value":"JK"},
        {"label":"LADAKH","value":"LA"},
        {"label":"LAKSHADWEEP","value":"LD"},
        {"label":"PUDUCHERRY","value":"PY"}
    ]

    const [rpaflDateRangeInitialValues, setRpaflDateRangeInitialValues] = React.useState({
        rpaflSelectedDateRange: [initialDate, new Date()]
    })
    const [rpaflDateRangeInitialValuesDefault, setRpaflDateRangeInitialValuesDefault] = React.useState({
        rpaflSelectedDateRange: [initialDate, new Date()]
    })

    const [rpaflDateRangeInitialValuesApplied, setRpaflDateRangeInitialValuesApplied] = React.useState({
        rpaflSelectedDateRange: [initialDate, new Date()]
    })

    const [rpaflCategoryInitialValues, setRpaflCategoryInitialValues] = React.useState({
        rpaflSelectCategory: null,
        rpaflSampleOptionsCategory: [],
        rpaflDefaultOrgCategory: null 
    })
    const [rpaflCategoryInitialValuesDefault, setRpaflCategoryInitialValuesDefault] = React.useState({
        rpaflSelectCategory: null,
        rpaflSampleOptionsCategory: [],
    })
    const [rpaflCategoryInitialValuesApplied, setRpaflCategoryInitialValuesApplied] = React.useState({
        rpaflSelectCategory: null,
        rpaflSampleOptionsCategory: [],
        rpaflDefaultOrgCategory: null
    })

    const [rpaflKeywordInitialValues, setRpaflKeywordInitialValues] = React.useState({
        rpaflKeywordSelectorCheck: 0, // 1 -> custom , 2 -> keyword list 
        rpaflKeywordsCustomHeader: {value: "Title & Body", label: "Title & Body"},
        rpaflKeywordsCustomKeywords: null,
        rpaflKeywordsCustomKeywordsSampleOptions: [],
        rpaflConditionOr: true,
        rpaflConditionSeq: true,
        rpaflConditionMatch: true
    })
    const [rpaflKeywordInitialValuesDefault, setRpaflKeywordInitialValuesDefault] = React.useState({
        rpaflKeywordSelectorCheck: 0, // 1 -> custom , 2 -> keyword list 
        rpaflKeywordsCustomHeader: {value: "Title & Body", label: "Title & Body"},
        rpaflKeywordsCustomKeywords: null,
        rpaflKeywordsCustomKeywordsSampleOptions: [],
        rpaflConditionOr: true,
        rpaflConditionSeq: true,
        rpaflConditionMatch: true
    })
    const [rpaflKeywordInitialValuesApplied, setRpaflKeywordInitialValuesApplied] = React.useState({
        rpaflKeywordSelectorCheck: 0, // 1 -> custom , 2 -> keyword list 
        rpaflKeywordsCustomHeader: {value: "Title & Body", label: "Title & Body"},
        rpaflKeywordsCustomKeywords: null,
        rpaflKeywordsCustomKeywordsSampleOptions: [],
        rpaflConditionOr: true,
        rpaflConditionSeq: true,
        rpaflConditionMatch: true
    })

    const [rpaflOrgKeywordInitialValues, setRpaflOrgKeywordInitialValues] = React.useState({
        rpaflOrgKeywords: [],
        rpaflTotalOrgKeywords: 0,
        rpaflOrgKeywordsTotalPage: 0,
        rpaflOrgKeywordsPage: 1,
        rpaflOrgKeywordsSearchText: "",
        rpaflSelectedOrgKeywords: {},
    })
    const [rpaflOrgKeywordInitialValuesDefault, setRpaflOrgKeywordInitialValuesDefault] = React.useState({
        rpaflOrgKeywords: [],
        rpaflTotalOrgKeywords: 0,
        rpaflOrgKeywordsTotalPage: 0,
        rpaflOrgKeywordsPage: 1,
        rpaflOrgKeywordsSearchText: "",
        rpaflSelectedOrgKeywords: {},
    })
    const [rpaflOrgKeywordInitialValuesApplied, setRpaflOrgKeywordInitialValuesApplied] = React.useState({
        rpaflOrgKeywords: [],
        rpaflTotalOrgKeywords: 0,
        rpaflOrgKeywordsTotalPage: 0,
        rpaflOrgKeywordsPage: 1,
        rpaflOrgKeywordsSearchText: "",
        rpaflSelectedOrgKeywords: {},
    })


    const [rpaflLocationInitialValues, setRpaflLocationInitialValues] = React.useState({
        rpaflSelectedOptionsLocationMainMenu: null,
        rpaflLocationTextInput: "",
        rpaflLocationLatInput: "",
        rpaflLocationLanInput: "",
        rpaflSelectedOptionsStateFilter: null,
        rpaflInvalidLocationError: false,
        rpaflMapCoordinates: [], 
        rpaflLocationRadius: 50,
        rpaflApprovedLocation: false,
    })
    const [rpaflLocationInitialValuesDefault, setRpaflLocationInitialValuesDefault] = React.useState({
        rpaflSelectedOptionsLocationMainMenu: null,
        rpaflLocationTextInput: "",
        rpaflLocationLatInput: "",
        rpaflLocationLanInput: "",
        rpaflSelectedOptionsStateFilter: null,
        rpaflInvalidLocationError: false,
        rpaflMapCoordinates: [], 
        rpaflLocationRadius: 50,
        rpaflApprovedLocation: false,
    })
    const [rpaflLocationInitialValuesApplied, setRpaflLocationInitialValuesApplied] = React.useState({
        rpaflSelectedOptionsLocationMainMenu: null,
        rpaflLocationTextInput: "",
        rpaflLocationLatInput: "",
        rpaflLocationLanInput: "",
        rpaflSelectedOptionsStateFilter: null,
        rpaflInvalidLocationError: false,
        rpaflMapCoordinates: [], 
        rpaflLocationRadius: 50,
        rpaflApprovedLocation: false,
    })

    const [rpaflLanguageInitialValues, setRpaflLanguageInitialValues] = React.useState({
        rpaflSelectedLanguage: null,
    })
    const [rpaflLanguageInitialValuesDefault, setRpaflLanguageInitialValuesDefault] = React.useState({
        rpaflSelectedLanguage: null,
    })
    const [rpaflLanguageInitialValuesApplied, setRpaflLanguageInitialValuesApplied] = React.useState({
        rpaflSelectedLanguage: null,
    })

    const [rpaflDomainInitialValues, setRpaflDomainInitialValues] = React.useState({
        rpaflSelectedDomains: null,
        rpaflSampleOptionsDomain: [],
    })
    const [rpaflDomainInitialValuesDefault, setRpaflDomainInitialValuesDefault] = React.useState({
        rpaflSelectedDomains: null,
        rpaflSampleOptionsDomain: [],
    })
    const [rpaflDomainInitialValuesApplied, setRpaflDomainInitialValuesApplied] = React.useState({
        rpaflSelectedDomains: null,
        rpaflSampleOptionsDomain: [],
    })

    const [rpaflUniqueArticleInitialValues, setRpaflUniqueArticleInitialValues] = React.useState({
        rpaflUniqueArticlesToggle: false,
    })
    const [rpaflUniqueArticleInitialValuesDefault, setRpaflUniqueArticleInitialValuesDefault] = React.useState({
        rpaflUniqueArticlesToggle: false,
    })
    const [rpaflUniqueArticleInitialValuesApplied, setRpaflUniqueArticleInitialValuesApplied] = React.useState({
        rpaflUniqueArticlesToggle: false,
    })

    const [rpaflSentimentInitialValues, setRpaflSentimentInitialValues] = React.useState({
        rpaflSelectedSentiment: null
    })
    const [rpaflSentimentInitialValuesDefault, setRpaflSentimentInitialValuesDefault] = React.useState({
        rpaflSelectedSentiment: null
    })
    const [rpaflSentimentInitialValuesApplied, setRpaflSentimentInitialValuesApplied] = React.useState({
        rpaflSelectedSentiment: null
    })

    const [rpaflInitialValues, setRpaflInitialValues] = React.useState({
        rpaflSelectedFilter: 1,
        rpaflActiveModal: false,
    }) 

    const [floInitialValues, setFloInitialValues] = React.useState({  // flo => filtered output
        pageNo: 1,
        limitPerPage: 15,
        totalData: 0,
        totalPage: 0,
        rows: [],
        dataLoading: 0, // 0: default, 1: data loaded
        selectedArticle: {},
        selectionsExpand: true,
        activeVerifyExportModal: false
    })
    const [floInitialValuesDefault, setFloInitialValuesDefault] = React.useState({  // flo => filtered output
        pageNo: 1,
        limitPerPage: 15,
        totalData: 0,
        totalPage: 0,
        rows: [],
        dataLoading: 0 // 0: default, 1: data loaded
    })

    const [rpaflChangeSignalInitialValues, setRpaflChangeSignalInitialValues] = React.useState({
        date : false,
        category: false, 
        keyword: false,
        location: false,
        language: false,
        domain: false,
        uniqueArticle: false,
        sentiment: false
    })
    const [rpaflChangeSignalInitialValuesDefault, setRpaflChangeSignalInitialValuesDefault] = React.useState({
        date : false,
        category: false, 
        keyword: false,
        location: false,
        language: false,
        domain: false,
        uniqueArticle: false,
        sentiment: false
    })
    const [rpaflChangeSignalInitialValuesApplied, setRpaflChangeSignalInitialValuesApplied] = React.useState({
        date : false,
        category: false, 
        keyword: false,
        location: false,
        language: false,
        domain: false,
        uniqueArticle: false,
        sentiment: false
    })

    const [seaInitialValues, setSeaInitialValues] = React.useState({
        pageNo: 1,
        rows: [],
        totalPage: 0,
        totalData: 0,
        selectedArticle: {},
        dataLoading: 0,
        selectionsExpand: true,
        limitPerPage: 15,
    })

    const check_error = (response) =>{
        if(response.status === 401){
            localStorage.clear()
            Cookies.remove('token');
            setTimeout(() => {
               setRedirect(1)
            }, 1000);
            toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
        }
        else{
            toast.info("server error", { position: toast.POSITION.TOP_CENTER})
        }
    }

    React.useEffect (()=>{
        // fetch_categories()
        fetch_default_categories()
        fetch_domains();
        fetch_frequent_keywords()
        fetch_org_keywords()
    }, [])

    const fetch_default_categories = async() =>{
        let response = await categoriesDefaultGet()
    		if(response.status === 200){
                console.log(response.data.defaultCategory)
    			setRpaflDefaultOrgCategory(response.data.defaultCategory)
    			fetch_categories(response.data.defaultCategory);
                let fromD = initialDate
                let message = {
                    "pageNo": 1,
                    "countPerPage": floInitialValues.limitPerPage,
                    "fromDate": rpaflDateRangeInitialValues.rpaflSelectedDateRange? moment(rpaflDateRangeInitialValues.rpaflSelectedDateRange[0]).format("YYYY-MM-DD"): ''.format("YYYY-MM-DD"),
                    "toDate": rpaflDateRangeInitialValues.rpaflSelectedDateRange? moment(rpaflDateRangeInitialValues.rpaflSelectedDateRange[1]).format("YYYY-MM-DD"): ''.format("YYYY-MM-DD"),
                    "category": response.data.defaultCategory,
                    "domain_name": null,
                    "keyword_search": null,
                    "location": null,
                    "languages": null,
                    "sentiment": null,
                    "uniqueArticles": false
                }
                rpaflHandleFilterApiCall(message);
            }
            else{
                check_error()
            }
    }

    const fetch_categories = async(cat) => {
		let response = await getCategories()
		if(response.status === 200){
             const temporary_options = response.data.map(item => {
				return {value: item, label: item}
			});
            console.log(temporary_options)
            let defaultCategory = {"value": cat, "label": cat} 
            setRpaflCategoryInitialValues({ ...rpaflCategoryInitialValues, rpaflSampleOptionsCategory: temporary_options})
            setRpaflCategoryInitialValuesApplied({ ...rpaflCategoryInitialValuesApplied, rpaflSampleOptionsCategory: temporary_options})
            
            setRpaflCategoryInitialValues(prevState => {
                return {
                    ...prevState,
                    rpaflSampleOptionsCategory: temporary_options,
                    rpaflDefaultOrgCategory: defaultCategory,
                    rpaflSelectCategory: defaultCategory
                };
            });
            setRpaflCategoryInitialValuesApplied(prevState => {
                return {
                    ...prevState,
                    rpaflSampleOptionsCategory: temporary_options,
                    rpaflDefaultOrgCategory: defaultCategory,
                    rpaflSelectCategory: defaultCategory
                };
            });
        }
	 	else {
            check_error(response)
        }
	}

    const sendrequest = (cat)=>{
        setAppliedCategory(cat);
		handleApplyFilter(cat);
	}

    const fetch_frequent_keywords = async() =>{
        let response = await getFrequentKeywords()
        if(response.status === 200){
		   	let test_array = []
		   	for(let i = 0; i < response.data.length; i++){
				test_array.push(response.data[i].keyword.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()))
		   	}

		   	
		   	const temporary_options = test_array.map(item => {
				return {value: item, label: item}
			});
            setRpaflKeywordInitialValues({ ...rpaflKeywordInitialValues, rpaflKeywordsCustomKeywordsSampleOptions: temporary_options })
            setRpaflKeywordInitialValuesApplied({ ...rpaflKeywordInitialValuesApplied, rpaflKeywordsCustomKeywordsSampleOptions: temporary_options })
        }
        else {
            check_error(response)
        }
    }

    const fetch_domains = async()=>{
		let response = await getDomains()
		if(response.status === 200){
			let test_array = []
			for(let i = 0; i < response.data.length; i++){
			 test_array.push(response.data[i])
			}

			const temporary_options = test_array.map(item => {
			 return {value: item, label: item}
		 	});
            setRpaflDomainInitialValues({ ...rpaflDomainInitialValues, rpaflSampleOptionsDomain: temporary_options })
            setRpaflDomainInitialValuesApplied({ ...rpaflDomainInitialValuesApplied, rpaflSampleOptionsDomain: temporary_options })
	 	}
	 	else {
            check_error(response)
        }
	}

    const fetch_org_keywords = async(message)=>{
        let response = await fetchOrgKeywords(message)
        if(response.status === 200){
            console.log(response.data)
            let totalPage = 0
            // setRpaflOrgKeywordInitialValues({ ...rpaflOrgKeywordInitialValues, rpaflOrgKeywords: response.data.data })
            // setRpaflOrgKeywordInitialValues({ ...rpaflOrgKeywordInitialValues, rpaflTotalOrgKeywords: response.data.totalCount })
            if(response.data['totalCount'] % rpaflOrgKeywordsLimitPerPage === 0){
                totalPage = Math.floor(response.data['totalCount'] / rpaflOrgKeywordsLimitPerPage)
			}
			else{
                totalPage = Math.floor(response.data['totalCount'] / rpaflOrgKeywordsLimitPerPage) + 1
			}

            setRpaflOrgKeywordInitialValues(prevState => {
                return {
                    ...prevState,
                    rpaflOrgKeywords: response.data.data,
                    rpaflTotalOrgKeywords: response.data.totalCount,
                    rpaflOrgKeywordsTotalPage: totalPage
                };
            });
            setRpaflOrgKeywordInitialValuesApplied(prevState => {
                return {
                    ...prevState,
                    rpaflOrgKeywords: response.data.data,
                    rpaflTotalOrgKeywords: response.data.totalCount,
                    rpaflOrgKeywordsTotalPage: totalPage
                };
            });

        }
        else {
            check_error(response)
        }
    }

    const handlePage = (event, pg) =>{
        setPage(pg)
        let message = {
            'fromDate': selectedDateRange? moment(selectedDateRange[0]).format("YYYY-MM-DD"): '',
            'toDate': selectedDateRange? moment(selectedDateRange[1]).format("YYYY-MM-DD"): '',
            'category': appliedCategory,
            'pageNo': pg,
            'itemsPerPage': 10             
        }

        if( !(message['fromDate'] && message['toDate'])){
            setSampleRows([])
            setTotalArticles(0)
        }
        else{
            get_data_for_export_view(message)
        }
    }

    const get_data_for_export_view =async(message)=>{
        let response = {};
        setMap(2)
        response = await getExportDataNew(message)

        if(response.status === 200){
            setMap(1)
            let test_rows = []
            let ids = []
            for(let i = 0; i < response.data['data'].length; i++){
                test_rows.push(createData(response.data.data[i].Title, response.data.data[i].keywords_n[0],  response.data.data[i].published_date, response.data.data[i].keywords_p.Location, response.data.data[i]._id))
                ids.push(response.data.data[i]._id)
            }
            setTotalData(response.data.totalCount)
            if(response.data['totalCount'] % 15 === 0){
				setTotalPage(Math.floor(response.data['totalCount'] / 15))
			}
			else{
				setTotalPage(Math.floor(response.data['totalCount'] / 15) + 1)
			}
            setSampleRows(test_rows)
            setAllId(ids)
            setCurrentIds(ids)
            setCheckboxes(Array(ids.length).fill(true))
            test_rows.length !== 0 ? setTotalArticles(test_rows.length): setTotalArticles(null) 
            fetch_frequent_keywords()
        }

        else if(response.status === 401){
			
			setTimeout(() => {
				setRedirect(1)
			}, 1000);
			toast.info("invalid token or token expired",  { position: toast.POSITION.TOP_CENTER});
		}
		else{
			toast.error('server error',  { position: toast.POSITION.TOP_CENTER});
		}  
    }

    function createData(article, keyword, date, region, id) {
        return { article, keyword, date, region, id };
    }

    const handleCheckBox = (e, id) =>{
        const isChecked = e.target.checked;
        if (isChecked) {
            setSelectedIds(prevSelectedIds => ({
                ...prevSelectedIds,
                [id]: true,
            }));
            console.log("checked");
            } else {
            setSelectedIds(prevSelectedIds => {
                const { [id]: _, ...restIds } = prevSelectedIds;
                return restIds; // This is needed to update the state correctly
            });
            console.log("unchecked");
            }
	}

    const handleApplyFilter =(cat)=>{
        let message = {
            'fromDate': selectedDateRange? moment(selectedDateRange[0]).format("YYYY-MM-DD"): '',
            'toDate': selectedDateRange? moment(selectedDateRange[1]).format("YYYY-MM-DD"): '',
            'category': cat,
            'pageNo': page,
            'itemsPerPage': 10             
        }

        if( !(message['fromDate'] && message['toDate'])){
            setSampleRows([])
            setTotalArticles(0)
        }
        else{
            get_data_for_export_view(message)
        }
    }

    const export_Pdf = async(message) =>{
        let response = {}
        response = await exportPdf(message)
        
        if(response.hasOwnProperty('status') && response['status'] === 401){
            setTimeout(() => {
				setRedirect(1)
			}, 1000);
			toast.info("invalid token or token expired",  { position: toast.POSITION.TOP_CENTER});
        }
        if(response.hasOwnProperty('status') && response['status'] === 404){
			toast.info("server error",  { position: toast.POSITION.TOP_CENTER});
        }


        if (promptToggle){
            setTimeout(() => {
                setRedirect(2)
			}, 5000);
            let str = "You are being redirected to Report History page !!! Please check it back after 10 minutes for the QA report to be ready"
            toast.info(str,  { position: toast.POSITION.TOP_CENTER})

        }
        else{
            setTimeout(() => {
                setRedirect(2)
			}, 5000);
            let str = "You are being redirected to Report History page !!! Please check it back in less than 5 minutes for the Articles report to be ready"
            toast.info(str,  { position: toast.POSITION.TOP_CENTER})  
        }
    }

    const handleExportValidation = () =>{
        if(sampleRows.length === 0){
            toast.info('No data to export ... Try applying filters !!!!',  { position: toast.POSITION.TOP_CENTER});
            setExportAs('');
        }
        else if(Object.keys(selectedIds).length === 0){
            toast.info('Please Select Articles to Export !!!!',  { position: toast.POSITION.TOP_CENTER, autoClose: 1500});
            setExportAs('');
        }
        else{
            setIsOpenExportModal(true)
        }
    }

    const handleExport = () => {
        let message = {}
        if(tab === 1){
            message =  {
                'fromDate': rpaflDateRangeInitialValues.rpaflSelectedDateRange? moment(rpaflDateRangeInitialValues.rpaflSelectedDateRange[0]).format("YYYY-MM-DD"): '',
                'toDate': rpaflDateRangeInitialValues.rpaflSelectedDateRange? moment(rpaflDateRangeInitialValues.rpaflSelectedDateRange[1]).format("YYYY-MM-DD"): '',            
                "fileType": "pdf",
                "ids": Object.keys(floInitialValues.selectedArticle),
                "category": rpaflCategoryInitialValues.rpaflSelectCategory? rpaflCategoryInitialValues.rpaflSelectCategory.value : null,            
                "reportFormat": promptToggle ? "qa":"articles"
            }
        }
        export_Pdf(message);
    }; 

    const handleExportTab2 = () => {
        let message =  {
                'fromDate': rpaflDateRangeInitialValues.rpaflSelectedDateRange? moment(rpaflDateRangeInitialValues.rpaflSelectedDateRange[0]).format("YYYY-MM-DD"): '',
                'toDate': rpaflDateRangeInitialValues.rpaflSelectedDateRange? moment(rpaflDateRangeInitialValues.rpaflSelectedDateRange[1]).format("YYYY-MM-DD"): '',            
                "fileType": "pdf",
                "ids": Object.keys(seaInitialValues.selectedArticle),
                "category": rpaflCategoryInitialValues.rpaflSelectCategory? rpaflCategoryInitialValues.rpaflSelectCategory.value : null,            
                "reportFormat": promptToggle ? "qa":"articles"
            }
        export_Pdf(message);
    }

    const apply_category_filter = (ct)=>{
        setPage(1)
		setAppliedCategory(ct)		
        handleApplyFilter(ct)
        setDropdownCatergory(false)
	}

    const test_dropdown_click = ()=>{
		setDropdownCatergory(!dropdownCatergory)
		fetch_categories()
	}

    const handleSyncCheckBoxKeywords = () =>{
		let flag = true
		for(let i =0; i < sampleRows.length; i++){
			if(selectedIds[sampleRows[i]["id"]] === undefined){
				flag = false
				break;
			}
		}
        if (sampleRows.length === 0){
            return false
        }
		return flag
	}

    const handleCheckBoxAll = (e) => {
		const isChecked = e.target.checked;
	  
		if (isChecked) {
		  setSelectedIds(prevSelectedIds => {
			const updatedSelectedIds = { ...prevSelectedIds };
			sampleRows.forEach(item => {
			  updatedSelectedIds[item.id] = true;
			});
			return updatedSelectedIds;
		  });
	  
		  console.log("checked");
		} else {
		  setSelectedIds(prevSelectedIds => {
			const updatedSelectedIds = { ...prevSelectedIds };
			sampleRows.forEach(item => {
			  if (updatedSelectedIds[item.id]) {
				delete updatedSelectedIds[item.id];
			  }
			});
			return updatedSelectedIds;
		  });
	  
		  console.log("unchecked");
		}
	  };

    const handleDateRangeChange = (value) => {
        setSelectedDateRange(value);
        setPage(1)
        console.log(value) 

        let message = {
            'fromDate': value? moment(value[0]).format("YYYY-MM-DD"): '',
            'toDate': value? moment(value[1]).format("YYYY-MM-DD"): '',
            'category': appliedCategory
        }

        if( !(message['fromDate'] && message['toDate'])){
            setSampleRows([])
            setTotalArticles(0)
        }
        else{
            get_data_for_export_view(message)
        }
      };

    const handleExpandArticles = (val) =>{
        let expand_dict = {}
        if(val === "exp"){
            for(let i = 0; i < floInitialValues.rows.length; i++){
                expand_dict[floInitialValues.rows[i]["_id"]] = true
            }
            setFloInitialValues({...floInitialValues, selectionsExpand : false}) 
        }
        else{
            for(let i = 0; i < floInitialValues.rows.length; i++){
                expand_dict[floInitialValues.rows[i]["_id"]] = false
            } 
            setFloInitialValues({...floInitialValues, selectionsExpand : true}) 
 
        }
        setExpanded(expand_dict)
    }

    const seaHandleExpandArticles = (val) =>{
        let expand_dict = {}
        if(val === "exp"){
            for(let i = 0; i < seaInitialValues.rows.length; i++){
                expand_dict[seaInitialValues.rows[i]["_id"]] = true
            }
            setSeaInitialValues({...seaInitialValues, selectionsExpand : false}) 
        }
        else{
            for(let i = 0; i < seaInitialValues.rows.length; i++){
                expand_dict[seaInitialValues.rows[i]["_id"]] = false
            } 
            setSeaInitialValues({...seaInitialValues, selectionsExpand : true}) 
 
        }
        setExpandedSea(expand_dict)
    }

    // tab_switch
    const handle_tab_switch = (val) =>{
        setTab(val)
        if(val === 2){
            seaHandleGetSelectedArticles(seaInitialValues.pageNo)
        }
    }
    
    // rpafl

    const rpaflHandleDateRangeChange = (value) => {
        setRpaflDateRangeInitialValues({ ...rpaflDateRangeInitialValues, rpaflSelectedDateRange: value })
        rpaflHandleFilterChangeTemporary("date")
    };

    const rpaflHandleCategoryChange = (value) =>{
        setRpaflCategoryInitialValues({ ...rpaflCategoryInitialValues, rpaflSelectCategory: value })
        rpaflHandleFilterChangeTemporary("category")
    }

    const rpaflHandleChangeKeywordSelectorCheck = (value, e) =>{
        if(e.target.checked === true){
            setRpaflKeywordInitialValues({ ...rpaflKeywordInitialValues, rpaflKeywordSelectorCheck: value })
        }
        else{
            setRpaflKeywordInitialValues({ ...rpaflKeywordInitialValues, rpaflKeywordSelectorCheck: 0 })
        }
    }

    const rpaflHandleKeywordsCustomHeader = (value) =>{
        setRpaflInitialValues({ ...rpaflInitialValues, rpaflKeuwordsCustomHeader: value })
    }

    const handleRpaflHandleKeywordsCustomKeywords = (value) =>{
        setRpaflKeywordInitialValues({ ...rpaflKeywordInitialValues, rpaflKeywordsCustomKeywords: value })
        rpaflHandleFilterChangeTemporary("keyword")
    }

    const rpaflHandleLanguageChange = (value) =>{
        setRpaflLanguageInitialValues({ ...rpaflLanguageInitialValues, rpaflSelectedLanguage: value })
        rpaflHandleFilterChangeTemporary("language")
    }

    const rpaflHandleDomainChange = (value) =>{
        setRpaflDomainInitialValues({ ...rpaflDomainInitialValues, rpaflSelectedDomains: value })
        rpaflHandleFilterChangeTemporary("domain")
    }

    const handleRpaflUniqueToggleChange = (e) =>{
        setRpaflUniqueArticleInitialValues({ ...rpaflUniqueArticleInitialValues, rpaflUniqueArticlesToggle: e.target.checked })
        rpaflHandleFilterChangeTemporary("uniqueArticle")
    }

    
    const rpaflHandleSyncCheckBoxOrgKeywords = () =>{
		let flag = true
		for(let i =0; i < rpaflOrgKeywordInitialValues.rpaflOrgKeywords.length; i++){
			if(rpaflOrgKeywordInitialValues.rpaflSelectedOrgKeywords[rpaflOrgKeywordInitialValues.rpaflOrgKeywords[i]] === undefined){
				flag = false
				break;
			}
		}
        if (rpaflOrgKeywordInitialValues.rpaflOrgKeywords.length === 0){
            return false
        }
		return flag
	}

    const rpaflHandleCheckBoxAllOrgKeywords = (e) => {
		const isChecked = e.target.checked;
	  
		if (isChecked) {
            // setRpaflSelectedOrgKeywords(prevSelectedIds => {
			const updatedSelectedIds = rpaflOrgKeywordInitialValues.rpaflSelectedOrgKeywords;
			rpaflOrgKeywordInitialValues.rpaflOrgKeywords.forEach(item => {
            updatedSelectedIds[item] = true;
			});
			// return updatedSelectedIds;
		//   });
            setRpaflOrgKeywordInitialValues({ ...rpaflOrgKeywordInitialValues, rpaflSelectedOrgKeywords: updatedSelectedIds })

	  
		  console.log("checked");
		} else {
            // setRpaflSelectedOrgKeywords(prevSelectedIds => {
            const updatedSelectedIds = rpaflOrgKeywordInitialValues.rpaflSelectedOrgKeywords;
			rpaflOrgKeywordInitialValues.rpaflOrgKeywords.forEach(item => {
			  if (updatedSelectedIds[item]) {
				delete updatedSelectedIds[item];
			  }
			});
			// return updatedSelectedIds;
		//   });
          setRpaflOrgKeywordInitialValues({ ...rpaflOrgKeywordInitialValues, rpaflSelectedOrgKeywords: updatedSelectedIds })
	  
		  console.log("unchecked");
		}
        rpaflHandleFilterChangeTemporary("keyword")
    };

    const rpaflHandleCheckBoxOrgKeywords = (e, id) =>{

		const isChecked = e.target.checked;

		if (isChecked) {
            const updatedSelectedIds = rpaflOrgKeywordInitialValues.rpaflSelectedOrgKeywords;
			updatedSelectedIds[id] = true
            setRpaflOrgKeywordInitialValues({ ...rpaflOrgKeywordInitialValues, rpaflSelectedOrgKeywords: updatedSelectedIds })

			// setRpaflSelectedOrgKeywords(prevSelectedIds => ({
			//   ...prevSelectedIds,
			//   [id]: true,
			// }));
			console.log("checked");
		  } else {
			// setRpaflSelectedOrgKeywords(prevSelectedIds => {
			//   const { [id]: _, ...restIds } = prevSelectedIds;
			//   return restIds; // This is needed to update the state correctly
			// });
            
            const updatedSelectedIds = rpaflOrgKeywordInitialValues.rpaflSelectedOrgKeywords;
            if (updatedSelectedIds.hasOwnProperty(id)) {
                delete updatedSelectedIds[id];
            }
            setRpaflOrgKeywordInitialValues({ ...rpaflOrgKeywordInitialValues, rpaflSelectedOrgKeywords: updatedSelectedIds })

			console.log("unchecked");
		  }
          rpaflHandleFilterChangeTemporary("keyword")
	}

    const rpaflHandleOrgKeywordsSearch = (event) =>{
        setRpaflOrgKeywordInitialValues(prevState => {
            return {
                ...prevState,
                rpaflOrgKeywordsSearchText: event.target.value,
                rpaflOrgKeywordsPage: 1
            };
        });
        let message = {
            "pageNo": 1,
            "itemsPerPage": 10,
            "searchText": event.target.value
        }
        fetch_org_keywords(message)  
    }

    const rpaflHandleOrgKeywordsPage = (event, pg)=>{
        setRpaflOrgKeywordInitialValues({ ...rpaflOrgKeywordInitialValues, rpaflOrgKeywordsPage: pg })
        const message = {
            "pageNo": pg,
            "itemsPerPage": 10,
            "searchText": rpaflOrgKeywordInitialValues.rpaflOrgKeywordsSearchText
        }
        fetch_org_keywords(message)
    }

    const rpaflHandleLocationsMainMenu = (value) =>{
        setRpaflLocationInitialValues(prevState => {
            return {
                ...prevState,
                rpaflSelectedOptionsLocationMainMenu: value,
                rpaflLocationTextInput: "",
                rpaflLocationLatInput: "",
                rpaflLocationLanInput: "",
                rpaflSelectedOptionsStateFilter: null,
                rpaflInvalidLocationError: false,
                rpaflMapCoordinates: [], 
                rpaflLocationRadius: 50,
                rpaflApprovedLocation: false,
            };
        });
        rpaflHandleFilterChangeTemporary("location")
    }

    const rpaflHandleStateFilter = (value) =>{
        setRpaflLocationInitialValues({ ...rpaflLocationInitialValues, rpaflSelectedOptionsStateFilter: value })
        rpaflHandleFilterChangeTemporary("location")
    }

    const rpaflHandleVerifyLocation = async(type) =>{
        if(type === "location"){
            let response = await getVerificationCoordinates(rpaflLocationInitialValues.rpaflLocationTextInput)
            if(response.status === 200){
                if(response.data.data.length === 0){
                    setRpaflLocationInitialValues({ ...rpaflLocationInitialValues, rpaflInvalidLocationError: true })
                }
                else{
                    console.log(response.data)
                    let tempCoordinates = [{
                        "Id": 0, "Location": rpaflLocationInitialValues.rpaflLocationTextInput, "Latitude": response.data.data[0], "Longitude": response.data.data[1]
                    }] 
                    setRpaflLocationInitialValues(prevState => {
                        return {
                            ...prevState,
                            rpaflInvalidLocationError: false,
                            rpaflMapCoordinates: tempCoordinates
                        };
                    });
                }
                }
                else{
                    check_error(response)
            }
        }
        else if(type === "coordinates"){
            let tempCoordinates = [{
                "Id": 0, "Location": "Unknown Location", "Latitude":rpaflLocationInitialValues.rpaflLocationLatInput, "Longitude":rpaflLocationInitialValues.rpaflLocationLanInput
            }]
            setRpaflLocationInitialValues(prevState => {
                return {
                    ...prevState,
                    rpaflInvalidLocationError: false,
                    rpaflMapCoordinates: tempCoordinates
                };
            });
        }
    }

    const rpaflHandleSentimentChange = (value) =>{
        setRpaflSentimentInitialValues({ ...rpaflSentimentInitialValues, rpaflSelectedSentiment: value })
        rpaflHandleFilterChangeTemporary("sentiment")
    }

    const rpaflHandleFilterChangeTemporary = (value) =>{
        setRpaflChangeSignalInitialValues(prevState => {
            return {
                ...prevState,
                [value]: true
            };
        });
    }

    const rpaflHandleFilterApiCall = async(message) =>{
        setFloInitialValues({...floInitialValues, dataLoading : 2})
        let response = await fetchReportArticlesNew(message)
        if(response.status === 200){
            let test_rows = []
            let ids = []
            for(let i = 0; i < response.data['data'].length; i++){
                test_rows.push(createData(response.data.data[i].Title, response.data.data[i].keywords_n[0],  response.data.data[i].published_date, response.data.data[i].keywords_p.Location, response.data.data[i]._id))
            }
            setTotalData(response.data.totalCount)
            let totalPageTemp = 0
            if(response.data['totalCount'] % floInitialValues.limitPerPage === 0){
				totalPageTemp= Math.floor(response.data['totalCount'] / floInitialValues.limitPerPage)
			}
			else{
				totalPageTemp = Math.floor(response.data['totalCount'] / floInitialValues.limitPerPage) + 1
			}
            setFloInitialValues(prevState => {
                return {
                    ...prevState,
                    totalPage: totalPageTemp,
                    totalData: response.data.totalCount,
                    rows: response.data.data,
                    pageNo: message["pageNo"],
                    dataLoading : 1
                };
            });
            fetch_frequent_keywords()
        }
        else if(response.status === 401){
            localStorage.clear()
            Cookies.remove('token');
            setTimeout(() => {
                setRedirect(1)
            }, 1000);
            toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
        }
        else{
            // toast.info("server error here", { position: toast.POSITION.TOP_CENTER})
            setFloInitialValues({...floInitialValuesDefault})
        }
    }

    const rpaflhandleApplyFilters = () =>{
        setRpaflDateRangeInitialValuesApplied({ ...rpaflDateRangeInitialValues });
        setRpaflCategoryInitialValuesApplied({ ...rpaflCategoryInitialValues });
        setRpaflKeywordInitialValuesApplied({ ...rpaflKeywordInitialValues });
        setRpaflOrgKeywordInitialValuesApplied({ ...rpaflOrgKeywordInitialValues });
        setRpaflLocationInitialValuesApplied({ ...rpaflLocationInitialValues });
        setRpaflLanguageInitialValuesApplied({ ...rpaflLanguageInitialValues });
        setRpaflDomainInitialValuesApplied({ ...rpaflDomainInitialValues });
        setRpaflUniqueArticleInitialValuesApplied({ ...rpaflUniqueArticleInitialValues });
        setRpaflSentimentInitialValuesApplied({ ...rpaflSentimentInitialValues });

        setRpaflInitialValues({ ...rpaflInitialValues, rpaflActiveModal: false });

        // Calculating final keyword_search field value
        let keyword_search_sec = rpaflKeywordInitialValues.rpaflKeywordSelectorCheck
        let keyword_search_val = {}
        if(keyword_search_sec === 0){
           keyword_search_val = null
        }
        else if(keyword_search_sec === 1){
            if(rpaflKeywordInitialValues.rpaflKeywordsCustomKeywords){
                keyword_search_val = {
                    "searchType": "custom",
                    "searchInput": {
                        'searchText': rpaflKeywordInitialValues.rpaflKeywordsCustomKeywords.map(item => item.value).join(' '),
                        'allWordMatch': rpaflKeywordInitialValues.rpaflConditionOr,
                        'sequentialMatch': rpaflKeywordInitialValues.rpaflConditionSeq,
                        'exactMatch': rpaflKeywordInitialValues.rpaflConditionMatch
                    }
                }
            }
            else{
                keyword_search_val = null
            }
        } 
        else{
            if(Object.keys(rpaflOrgKeywordInitialValues.rpaflSelectedOrgKeywords).length > 0){
                keyword_search_val = {
                    "searchType": "keywordsList",
                    "searchInput": {
                        'keywordsList': Object.keys(rpaflOrgKeywordInitialValues.rpaflSelectedOrgKeywords)
                        // 'keywordsList': rpaflOrgKeywordInitialValues.rpaflSelectedOrgKeywords.map(item => item.key)
                    }
                }
            }
            else{
                keyword_search_val = null
            }
        }
        // Calculating final keyword_search field value
        // Calculating final location field vale
        let location_value = {}
        if(rpaflLocationInitialValues.rpaflSelectedOptionsLocationMainMenu){
            if (rpaflLocationInitialValues.rpaflSelectedOptionsLocationMainMenu === "By Location"){
                if(rpaflLocationInitialValues.rpaflApprovedLocation){
                    location_value = {
                        "searchType": "radius",
                        "searchInput":{
                        "coordinates": [rpaflLocationInitialValues.rpaflMapCoordinates[0], rpaflLocationInitialValues.rpaflMapCoordinates[1]],
                        "radius": rpaflLocationInitialValues.rpaflLocationRadius
                        }
                    }
                }
                else{
                    location_value = null
                }
            }  
            else if(rpaflLocationInitialValues.rpaflSelectedOptionsLocationMainMenu === "By Coordinates"){
                if(rpaflLocationInitialValues.rpaflApprovedLocation){
                    location_value = {
                        "searchType": "radius",
                        "searchInput":{
                        "coordinates": [rpaflLocationInitialValues.rpaflMapCoordinates[0], rpaflLocationInitialValues.rpaflMapCoordinates[1]],
                        "radius": rpaflLocationInitialValues.rpaflLocationRadius
                        }
                    }
                }
                else{
                    location_value = null
                }
            }
            else{
                if(rpaflLocationInitialValues.rpaflSelectedOptionsStateFilter){
                    location_value =  {
                        "searchType": "stateCode", 
                        "searchInput": {
                            "stateCodes": rpaflLocationInitialValues.rpaflSelectedOptionsStateFilter.map(item => item.value)
                        }
                    }
                }
                else{
                    location_value = null
                }
            }
        }
        else{
            location_value = null
        }
        // Calculating final location field vale
        let message = {
            "pageNo": 1,
            "countPerPage": floInitialValues.limitPerPage,
            "fromDate": rpaflDateRangeInitialValues.rpaflSelectedDateRange? moment(rpaflDateRangeInitialValues.rpaflSelectedDateRange[0]).format("YYYY-MM-DD"): '',
            "toDate": rpaflDateRangeInitialValues.rpaflSelectedDateRange? moment(rpaflDateRangeInitialValues.rpaflSelectedDateRange[1]).format("YYYY-MM-DD"): '',    
            "category": rpaflCategoryInitialValues.rpaflSelectCategory ? rpaflCategoryInitialValues.rpaflSelectCategory.value: null,
            "domain_name": rpaflDomainInitialValues.rpaflSelectedDomains ? rpaflDomainInitialValues.rpaflSelectedDomains.map(item => item.value):null,
            "keyword_search": keyword_search_val,
            "location": location_value,
            "languages": rpaflLanguageInitialValues.rpaflSelectedLanguage ? rpaflLanguageInitialValues.rpaflSelectedLanguage.map(item => item.value): null,
            "sentiment": rpaflSentimentInitialValues.rpaflSelectedSentiment ? rpaflSentimentInitialValues.rpaflSelectedSentiment.value: null,
            "uniqueArticles": rpaflUniqueArticleInitialValues.rpaflUniqueArticlesToggle
        }
        // console.lof(message.countPerPage, message.fromDate, message.toDate, message.domain_name, message.category, message.domain_name, message.keyword_search, message.location, message.languages, message.sentiment, message.uniqueArticles)
        if(rpaflDateRangeInitialValues.rpaflSelectedDateRange === null){
            toast.error("date range can not be null", { position: toast.POSITION.TOP_CENTER});
        }
        else{
            rpaflHandleFilterApiCall(message)
        }
    }


    const floHandlePage = (event, pg) =>{
        let keyword_search_sec = rpaflKeywordInitialValuesApplied.rpaflKeywordSelectorCheck
        let keyword_search_val = {}
        if(keyword_search_sec === 0){
           keyword_search_val = null
        }
        else if(keyword_search_sec === 1){
            if(rpaflKeywordInitialValuesApplied.rpaflKeywordsCustomKeywords){
                keyword_search_val = {
                    "searchType": "custom",
                    "searchInput": {
                        'searchText': rpaflKeywordInitialValuesApplied.rpaflKeywordsCustomKeywords.map(item => item.value).join(' '),
                        'allWordMatch': rpaflKeywordInitialValuesApplied.rpaflConditionOr,
                        'sequentialMatch': rpaflKeywordInitialValuesApplied.rpaflConditionSeq,
                        'exactMatch': rpaflKeywordInitialValuesApplied.rpaflConditionMatch
                    }
                }
            }
            else{
                keyword_search_val = null
            }
        } 
        else{
            if(Object.keys(rpaflOrgKeywordInitialValuesApplied.rpaflSelectedOrgKeywords).length > 0){
                keyword_search_val = {
                    "searchType": "keywordsList",
                    "searchInput": {
                        'keywordsList': rpaflOrgKeywordInitialValuesApplied.rpaflSelectedOrgKeywords.map(item => item.value)
                    }
                }
            }
            else{
                keyword_search_val = null
            }
        }
        // Calculating final keyword_search field value

        // Calculating final location field vale
        let location_value = {}
        if(rpaflLocationInitialValuesApplied.rpaflSelectedOptionsLocationMainMenu){
            if (rpaflLocationInitialValuesApplied.rpaflSelectedOptionsLocationMainMenu === "By Location"){
                if(rpaflLocationInitialValuesApplied.rpaflApprovedLocation){
                    location_value = {
                        "searchType": "radius",
                        "searchInput":{
                        "coordinates": [rpaflLocationInitialValuesApplied.rpaflMapCoordinates[0], rpaflLocationInitialValuesApplied.rpaflMapCoordinates[1]],
                        "radius": rpaflLocationInitialValuesApplied.rpaflLocationRadius
                        }
                    }
                }
                else{
                    location_value = null
                }
            }  
            else if(rpaflLocationInitialValuesApplied.rpaflSelectedOptionsLocationMainMenu === "By Coordinates"){
                if(rpaflLocationInitialValuesApplied.rpaflApprovedLocation){
                    location_value = {
                        "searchType": "radius",
                        "searchInput":{
                        "coordinates": [rpaflLocationInitialValuesApplied.rpaflMapCoordinates[0], rpaflLocationInitialValuesApplied.rpaflMapCoordinates[1]],
                        "radius": rpaflLocationInitialValuesApplied.rpaflLocationRadius
                        }
                    }
                }
                else{
                    location_value = null
                }
            }
            else{
                if(rpaflLocationInitialValuesApplied.rpaflSelectedOptionsStateFilter){
                    location_value =  {
                        "searchType": "stateCode", 
                        "searchInput": {
                            "stateCodes": rpaflLocationInitialValuesApplied.rpaflSelectedOptionsStateFilter.map(item => item.value)
                        }
                    }
                }
                else{
                    location_value = null
                }
            }
        }
        else{
            location_value = null
        }

        let message = {
            "pageNo": pg,
            "countPerPage": floInitialValues.limitPerPage,
            "fromDate": rpaflDateRangeInitialValuesApplied.rpaflSelectedDateRange? moment(rpaflDateRangeInitialValuesApplied.rpaflSelectedDateRange[0]).format("YYYY-MM-DD"): '',
            "toDate": rpaflDateRangeInitialValuesApplied.rpaflSelectedDateRange? moment(rpaflDateRangeInitialValuesApplied.rpaflSelectedDateRange[1]).format("YYYY-MM-DD"): '',    
            "category": rpaflCategoryInitialValuesApplied.rpaflSelectCategory ? rpaflCategoryInitialValuesApplied.rpaflSelectCategory.value: null,
            "domain_name": rpaflDomainInitialValuesApplied.rpaflSelectedDomains ? rpaflDomainInitialValuesApplied.rpaflSelectedDomains.map(item => item.value):null,
            "keyword_search": keyword_search_val,
            "location": location_value,
            "languages": rpaflLanguageInitialValuesApplied.rpaflSelectedLanguage ? rpaflLanguageInitialValuesApplied.rpaflSelectedLanguage.map(item => item.value): null,
            "sentiment": rpaflSentimentInitialValuesApplied.rpaflSelectedSentiment ? rpaflSentimentInitialValuesApplied.rpaflSelectedSentiment.value: null,
            "uniqueArticles": rpaflUniqueArticleInitialValuesApplied.rpaflUniqueArticlesToggle
        }
        rpaflHandleFilterApiCall(message)
    }

    const rpaflFilterCloseHandle = () =>{
        setRpaflInitialValues({...rpaflInitialValues, rpaflActiveModal: !rpaflInitialValues.rpaflActiveModal});
        setRpaflDateRangeInitialValues({ ...rpaflDateRangeInitialValuesApplied });
        setRpaflCategoryInitialValues({ ...rpaflCategoryInitialValuesApplied });
        setRpaflKeywordInitialValues({ ...rpaflKeywordInitialValuesApplied });
        setRpaflOrgKeywordInitialValues({ ...rpaflOrgKeywordInitialValuesApplied });
        setRpaflLocationInitialValues({ ...rpaflLocationInitialValuesApplied });
        setRpaflLanguageInitialValues({ ...rpaflLanguageInitialValuesApplied });
        setRpaflDomainInitialValues({ ...rpaflDomainInitialValuesApplied });
        setRpaflUniqueArticleInitialValues({ ...rpaflUniqueArticleInitialValuesApplied });
        setRpaflSentimentInitialValues({ ...rpaflSentimentInitialValuesApplied });
        setRpaflChangeSignalInitialValues({...rpaflChangeSignalInitialValuesApplied});

        setFloInitialValues({...floInitialValues, activeVerifyExportModal: false})

    }

    const rpaflHandleFilterClearAll = () =>{
        setRpaflInitialValues({...rpaflInitialValues, rpaflActiveModal: false});
        setRpaflDateRangeInitialValues({ ...rpaflDateRangeInitialValuesDefault });
        setRpaflCategoryInitialValues({ ...rpaflCategoryInitialValuesDefault });
        setRpaflKeywordInitialValues({ ...rpaflKeywordInitialValuesDefault });
        setRpaflOrgKeywordInitialValues({ ...rpaflOrgKeywordInitialValuesDefault });
        setRpaflLocationInitialValues({ ...rpaflLocationInitialValuesDefault });
        setRpaflLanguageInitialValues({ ...rpaflLanguageInitialValuesDefault });
        setRpaflDomainInitialValues({ ...rpaflDomainInitialValuesDefault });
        setRpaflUniqueArticleInitialValues({ ...rpaflUniqueArticleInitialValuesDefault });
        setRpaflSentimentInitialValues({ ...rpaflSentimentInitialValuesDefault });
        setRpaflChangeSignalInitialValues({...rpaflChangeSignalInitialValuesApplied});

        setRpaflDateRangeInitialValuesApplied({ ...rpaflDateRangeInitialValuesDefault });
        setRpaflCategoryInitialValuesApplied({ ...rpaflCategoryInitialValuesDefault });
        setRpaflKeywordInitialValuesApplied({ ...rpaflKeywordInitialValuesDefault });
        setRpaflOrgKeywordInitialValuesApplied({ ...rpaflOrgKeywordInitialValuesDefault });
        setRpaflLocationInitialValuesApplied({ ...rpaflLocationInitialValuesDefault });
        setRpaflLanguageInitialValuesApplied({ ...rpaflLanguageInitialValuesDefault });
        setRpaflDomainInitialValuesApplied({ ...rpaflDomainInitialValuesDefault });
        setRpaflUniqueArticleInitialValuesApplied({ ...rpaflUniqueArticleInitialValuesDefault });
        setRpaflSentimentInitialValuesApplied({ ...rpaflSentimentInitialValuesDefault });
        let message = {
            "pageNo": 1,
            "countPerPage": floInitialValues.limitPerPage,
            "fromDate": '',
            "toDate": '',    
            "category": null,
            "domain_name": null,
            "keyword_search": null,
            "location": null,
            "languages": null,
            "sentiment": null,
            "uniqueArticles": false
        }
        rpaflHandleFilterApiCall(message)
    }

    const floHandleSyncCheckBox = () =>{
		let flag = true
		for(let i =0; i < floInitialValues.rows.length; i++){
			if(floInitialValues.selectedArticle[floInitialValues.rows[i]["_id"]] === undefined){
				flag = false
				break;
			}
		}
        if (floInitialValues.rows.length === 0){
            return false
        }
		return flag
	}

    const floHandleCheckBoxAll = (e, rows) => {
		const isChecked = e.target.checked;
	  
		if (isChecked) {
			const updatedSelectedIds = floInitialValues.selectedArticle;
			floInitialValues.rows.forEach(item => {
            updatedSelectedIds[item._id] = [true, item];
			});
            setFloInitialValues({ ...floInitialValues, selectedArticle: updatedSelectedIds })

	  
		  console.log("checked");
		} else {
            const updatedSelectedIds = floInitialValues.selectedArticle;
			floInitialValues.rows.forEach(item => {
			  if (updatedSelectedIds[item._id]) {
				delete updatedSelectedIds[item._id];
			  }
			});
          setFloInitialValues({ ...floInitialValues, selectedArticle: updatedSelectedIds })
	  
		  console.log("unchecked");
		}
    };

    const floHandleCheckBoxIndi = (e, id, row) =>{
		const isChecked = e.target.checked;
		if (isChecked) {
            const updatedSelectedIds = floInitialValues.selectedArticle;
			updatedSelectedIds[id] = [true, row] 
            setFloInitialValues({ ...floInitialValues, selectedArticle: updatedSelectedIds })
			console.log("checked");
		  } else {
            const updatedSelectedIds = floInitialValues.selectedArticle;
            if (updatedSelectedIds.hasOwnProperty(id)) {
                delete updatedSelectedIds[id];
            }
            setFloInitialValues({ ...floInitialValues, selectedArticle: updatedSelectedIds })

			console.log("unchecked");
		  }
	}

    const seaHandleGetSelectedArticles = (pg) =>{
        let allSelectedArticles = Object.values(floInitialValues.selectedArticle).map(([, nestedObject]) => nestedObject);
        let skip = seaInitialValues.limitPerPage * (pg - 1)
        let response = allSelectedArticles.slice(skip, skip + seaInitialValues.limitPerPage);
        console.log(response, pg, allSelectedArticles, skip, skip + seaInitialValues.limitPerPage)
        let totalPageTemp = 0
        if(allSelectedArticles.length % seaInitialValues.limitPerPage === 0){
            totalPageTemp= Math.floor(allSelectedArticles.length / seaInitialValues.limitPerPage);
        }
        else{
            totalPageTemp = Math.floor(allSelectedArticles.length / seaInitialValues.limitPerPage) + 1;
        }
        setSeaInitialValues(prevState => {
            return {
                ...prevState,
                dataLoading : 1,
                totalPage: totalPageTemp,
                totalData: allSelectedArticles.length,
                rows: response,
                pageNo: pg
            };
        });
        setFloInitialValues({...floInitialValues, activeVerifyExportModal: false})
        setRpaflInitialValues({...rpaflInitialValues, rpaflActiveModal: false})
    }

    const seaHandlePageChange = (event, pg) =>{
        seaHandleGetSelectedArticles(pg);
    }

    const seaHandleSyncCheckBox = () =>{
		let flag = true
		for(let i =0; i < seaInitialValues.rows.length; i++){
			if(seaInitialValues.selectedArticle[seaInitialValues.rows[i]["_id"]] === undefined){
				flag = false
				break;
			}
		}
        if (seaInitialValues.rows.length === 0){
            return false
        }
		return flag
	}

    const seaHandleCheckBoxAll = (e, rows) => {
		const isChecked = e.target.checked;
	  
		if (isChecked) {
			const updatedSelectedIds = seaInitialValues.selectedArticle;
			seaInitialValues.rows.forEach(item => {
            updatedSelectedIds[item._id] = true;
			});
            setSeaInitialValues({ ...seaInitialValues, selectedArticle: updatedSelectedIds })

	  
		  console.log("checked");
		} else {
            const updatedSelectedIds = seaInitialValues.selectedArticle;
			seaInitialValues.rows.forEach(item => {
			  if (updatedSelectedIds[item._id]) {
				delete updatedSelectedIds[item._id];
			  }
			});
          setSeaInitialValues({ ...seaInitialValues, selectedArticle: updatedSelectedIds })
	  
		  console.log("unchecked");
		}
    };

    const seaHandleCheckBoxIndi = (e, id, row) =>{
		const isChecked = e.target.checked;
		if (isChecked) {
            const updatedSelectedIds = seaInitialValues.selectedArticle;
			updatedSelectedIds[id] = true
            setSeaInitialValues({ ...seaInitialValues, selectedArticle: updatedSelectedIds })
			console.log("checked");
		  } else {
            const updatedSelectedIds = seaInitialValues.selectedArticle;
            if (updatedSelectedIds.hasOwnProperty(id)) {
                delete updatedSelectedIds[id];
            }
            setSeaInitialValues({ ...seaInitialValues, selectedArticle: updatedSelectedIds })

			console.log("unchecked");
		  }
	}

    const floHandleOpenExportVerifyModal = () =>{
        if(floInitialValues.rows.length == 0){
            toast.info("No articles present to export!!! try Applying filters", { position: toast.POSITION.TOP_CENTER, autoClose: 1500})
        }
        else if(Object.keys(floInitialValues.selectedArticle).length == 0){
            toast.info("Please Select Articles to Export", { position: toast.POSITION.TOP_CENTER, autoClose: 1500})
        }
        else if(tab === 1){
            setFloInitialValues({...floInitialValues, activeVerifyExportModal: true})
            setRpaflInitialValues({...rpaflInitialValues, rpaflActiveModal: false})
        }
        else{
            setIsOpenExportModal(true)
        }
    }

    const handleExportValidationMain = (curr_tab) =>{
        if(curr_tab === 1){
            if(Object.keys(floInitialValues.selectedArticle).length == 0){
                toast.info("Please Select Articles to Export", { position: toast.POSITION.TOP_CENTER, autoClose: 1500})
            }
            else{
                setIsOpenExportModal(true)
            }
        }
    }   

    if(redirect === 1){
        window.location.href = '/login';
    }
    if(redirect === 2){
        return <Navigate to = '/history' />
    }

  return (
    <div className='right_report_gen_articles_container'> 
        <PageAnimations />
        <div className="filter_container">
            <div className="filter_content_container">
                <div className="rep_gen_header">
                    Reports Generation
                </div>
                <div className="report_gen_filter">
                    <div className="filter_title" onClick={()=>rpaflFilterCloseHandle()}>
                        Filter
                    </div>
                    <img className="filter_icon" src = {filter_icon} alt = {filter_icon} onClick={()=>rpaflFilterCloseHandle()} />
                    {rpaflInitialValues.rpaflActiveModal && 
                        <div className="absolute_filters_main">
                            <div className="header_section">
                                <div className="header_title">
                                    Applied Filters
                                </div>
                                <img src={close_filter} alt="close filter icon" className="cross_modal" onClick={()=>rpaflFilterCloseHandle()}/>
                            </div>
                            <div className="main_filters_report">
                                <div className="left_main_filters_report">
                                    <div className={"filter_1 indi_filter" + (rpaflInitialValues.rpaflSelectedFilter === 1 ? " highlight_indi_filter": "")} onClick={()=>setRpaflInitialValues({ ...rpaflInitialValues, rpaflSelectedFilter: 1 })}>Date Range {rpaflChangeSignalInitialValues.date && <img src={temp_filter_icon} alt="selected_filter_temporary" className="selected_filter_temporary"/>}</div>
                                    <div className={"filter_2 indi_filter" + (rpaflInitialValues.rpaflSelectedFilter === 2 ? " highlight_indi_filter": "")} onClick={()=>setRpaflInitialValues({ ...rpaflInitialValues, rpaflSelectedFilter: 2 })}>Category {rpaflChangeSignalInitialValues.category &&<img src={temp_filter_icon} alt="selected_filter_temporary" className = "selected_filter_temporary"/>}</div>
                                    <div className={"filter_3 indi_filter" + (rpaflInitialValues.rpaflSelectedFilter === 3 ? " highlight_indi_filter": "")} onClick={()=>setRpaflInitialValues({ ...rpaflInitialValues, rpaflSelectedFilter: 3 })}>Keyword Search {rpaflChangeSignalInitialValues.keyword &&<img src={temp_filter_icon} alt="selected_filter_temporary" className = "selected_filter_temporary"/>}</div>
                                    <div className={"filter_4 indi_filter" + (rpaflInitialValues.rpaflSelectedFilter === 4 ? " highlight_indi_filter": "")} onClick={()=>setRpaflInitialValues({ ...rpaflInitialValues, rpaflSelectedFilter: 4 })}>Location {rpaflChangeSignalInitialValues.location &&<img src={temp_filter_icon} alt="selected_filter_temporary" className = "selected_filter_temporary"/>}</div>
                                    <div className={"filter_5 indi_filter" + (rpaflInitialValues.rpaflSelectedFilter === 5 ? " highlight_indi_filter": "")} onClick={()=>setRpaflInitialValues({ ...rpaflInitialValues, rpaflSelectedFilter: 5 })}>Language {rpaflChangeSignalInitialValues.language &&<img src={temp_filter_icon} alt="selected_filter_temporary" className = "selected_filter_temporary"/>}</div>
                                    <div className={"filter_6 indi_filter" + (rpaflInitialValues.rpaflSelectedFilter === 6 ? " highlight_indi_filter": "")} onClick={()=>setRpaflInitialValues({ ...rpaflInitialValues, rpaflSelectedFilter: 6 })}>Domain {rpaflChangeSignalInitialValues.domain &&<img src={temp_filter_icon} alt="selected_filter_temporary" className = "selected_filter_temporary"/>}</div>
                                    <div className={"filter_7 indi_filter" + (rpaflInitialValues.rpaflSelectedFilter === 7 ? " highlight_indi_filter": "")} onClick={()=>setRpaflInitialValues({ ...rpaflInitialValues, rpaflSelectedFilter: 7 })}>Unique Articles {rpaflChangeSignalInitialValues.uniqueArticle &&<img src={temp_filter_icon} alt="selected_filter_temporary" className = "selected_filter_temporary"/>}</div>
                                    <div className={"filter_8 indi_filter" + (rpaflInitialValues.rpaflSelectedFilter === 8 ? " highlight_indi_filter": "")} onClick={()=>setRpaflInitialValues({ ...rpaflInitialValues, rpaflSelectedFilter: 8 })}>Sentiment {rpaflChangeSignalInitialValues.sentiment &&<img src={temp_filter_icon} alt="selected_filter_temporary" className = "selected_filter_temporary"/>}</div>
                                </div>
                                <div className="right_main_filters_report">
                                    {rpaflInitialValues.rpaflSelectedFilter === 1 && 
                                        <div className="filter_value_1 filter_indi_value">
                                            <DateRangePicker 
                                                placeholder="Select a Date Range"
                                                className='filter_value_1_input'
                                                value={rpaflDateRangeInitialValues.rpaflSelectedDateRange}
                                                onChange={rpaflHandleDateRangeChange}
                                                // open = {true}
                                                preventOverflow = {true}
                                                showOneCalendar 
                                            />
                                        </div>
                                    }
                                    {rpaflInitialValues.rpaflSelectedFilter === 2 &&
                                        <div className="filter_value_2 filter_indi_value">
                                            <Select
                                                className='filter_value_2_input'
                                                value={rpaflCategoryInitialValues.rpaflSelectCategory}
                                                onChange={rpaflHandleCategoryChange}
                                                options={rpaflCategoryInitialValues.rpaflSampleOptionsCategory}
                                                placeholder = "Search for Category"
                                                isClearable
                                            />
                                        </div>
                                    }
                                    {rpaflInitialValues.rpaflSelectedFilter === 3 && 
                                        <div className="filter_value_3 filter_indi_value">
                                            <div className="search_selector">
                                                <div className="custom_search search_types">
                                                    <Checkbox checked = {rpaflKeywordInitialValues.rpaflKeywordSelectorCheck === 1 ? true: false} onChange={(e) => rpaflHandleChangeKeywordSelectorCheck(1, e)}/>
                                                    <div className={rpaflKeywordInitialValues.rpaflKeywordSelectorCheck === 1 ? "selector_text selector_text_highlight": "selector_text selector_text_no_highlight"}>
                                                        Custom Search
                                                    </div>
                                                </div>
                                                <div className="keywords_list search_types">
                                                    <Checkbox checked = {rpaflKeywordInitialValues.rpaflKeywordSelectorCheck === 2 ? true: false} onChange={(e) => rpaflHandleChangeKeywordSelectorCheck(2, e)}/>
                                                    <div className={rpaflKeywordInitialValues.rpaflKeywordSelectorCheck !== 2 ? "selector_text selector_text_no_highlight": "selector_text selector_text_highlight"}>
                                                        Keywords List
                                                    </div>
                                                </div>
                                            </div>
                                            {rpaflKeywordInitialValues.rpaflKeywordSelectorCheck === 1 &&
                                            <>
                                                <div className="search_filters_selections">
                                                    <div className="search_filters_selections_first">
                                                        <Select
                                                            className='search_filters_selections_first_value'
                                                            value={rpaflKeywordInitialValues.rpaflKeywordsCustomHeader}
                                                            options={rpaflKeywordsCustomHeaderSampleOptions} 
                                                            onChange={rpaflHandleKeywordsCustomHeader}
                                                        />
                                                    </div>
                                                    <div className="search_filters_selections_second">
                                                        <CreatableSelect
                                                            value={rpaflKeywordInitialValues.rpaflKeywordsCustomKeywords}
                                                            onChange={handleRpaflHandleKeywordsCustomKeywords}
                                                            options={rpaflKeywordInitialValues.rpaflKeywordsCustomKeywordsSampleOptions}
                                                            placeholder = "Search for keywords"
                                                            isMulti
                                                        />
                                                    </div>
                                                </div>
                                                <div className="search_keyword_conditions">
                                                    <div className="header_condition">
                                                        Conditions
                                                    </div>
                                                    <div className="conditions_main">
                                                        <div className="condition_1 condition_wrapper">
                                                            <div className="left_new radio_wrapper">
                                                                <input type="radio" className="left_radio" checked = {rpaflKeywordInitialValues.rpaflConditionOr} onChange={() =>{ 
                                                                    setRpaflInitialValues({ ...rpaflKeywordInitialValues, rpaflConditionOr: !rpaflKeywordInitialValues.rpaflConditionOr })
                                                                    rpaflHandleFilterChangeTemporary("keyword")
                                                                }}/>
                                                                Or
                                                            </div>
                                                            <div className="right_new radio_wrapper">
                                                                <input type="radio" className="right_radio" checked = {!rpaflKeywordInitialValues.rpaflConditionOr} onChange={() =>{ 
                                                                    setRpaflInitialValues({ ...rpaflKeywordInitialValues, rpaflConditionOr: !rpaflKeywordInitialValues.rpaflConditionOr })
                                                                    rpaflHandleFilterChangeTemporary("keyword")
                                                                }}/>
                                                                And                                         
                                                            </div>
                                                        </div>
                                                        <div className="condition_2 condition_wrapper">
                                                            <div className="left_new radio_wrapper">
                                                                <input type="radio" className="left_radio" checked = {rpaflKeywordInitialValues.rpaflConditionSeq} onChange={() =>{ 
                                                                    setRpaflInitialValues({ ...rpaflKeywordInitialValues, rpaflConditionSeq: !rpaflKeywordInitialValues.rpaflConditionSeq })
                                                                    rpaflHandleFilterChangeTemporary("keyword")
                                                                }}/>
                                                                Sequential
                                                            </div>
                                                            <div className="right_new radio_wrapper">
                                                                <input type="radio" className="right_radio" checked = {!rpaflKeywordInitialValues.rpaflConditionSeq} onChange={() =>{ 
                                                                    setRpaflInitialValues({ ...rpaflKeywordInitialValues, rpaflConditionSeq: !rpaflKeywordInitialValues.rpaflConditionSeq })
                                                                    rpaflHandleFilterChangeTemporary("keyword")
                                                                }}/>
                                                                Non-Sequential                                         
                                                            </div>
                                                        </div>
                                                        <div className="condition_3 condition_wrapper">
                                                            <div className="left_new radio_wrapper">
                                                                <input type="radio" className="left_radio" checked = {rpaflKeywordInitialValues.rpaflConditionMatch} onChange={() =>{ 
                                                                    setRpaflInitialValues({ ...rpaflKeywordInitialValues, rpaflConditionMatch: !rpaflKeywordInitialValues.rpaflConditionMatch })
                                                                    rpaflHandleFilterChangeTemporary("keyword")
                                                                }}/>
                                                                Similar Match
                                                            </div>
                                                            <div className="right_new radio_wrapper">
                                                                <input type="radio" className="right_radio" checked = {!rpaflKeywordInitialValues.rpaflConditionMatch} onChange={() =>{ 
                                                                    setRpaflInitialValues({ ...rpaflKeywordInitialValues, rpaflConditionMatch: !rpaflKeywordInitialValues.rpaflConditionMatch })
                                                                    rpaflHandleFilterChangeTemporary("keyword")
                                                                }}/>
                                                                Exact Match                                       
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            }
                                            {rpaflKeywordInitialValues.rpaflKeywordSelectorCheck === 2 &&
                                            <>
                                                <div className="crud_org_keywords">    
                                                    <div className="crud_search">
                                                        <img src={searchIcon} alt="" className="search_icon" />
                                                        <input type="text" className="crud_add" placeholder='Search Keywords' value={rpaflOrgKeywordInitialValues.rpaflOrgKeywordsSearchText} onChange={rpaflHandleOrgKeywordsSearch}/>
                                                    </div>
                                                    <div className="crud_table_org_keywords">
                                                        <TableContainer sx={{  height: "20vh", border: "1px solid rgba(205, 205, 205, 1)", borderRadius: "4px", backdropFilter: 'blur(10px)', background: "transparent"}}>
                                                            <Table stickyHeader >
                                                                <TableHead sx = {{ fontFamily: "Inter"}}>
                                                                    <TableRow>
                                                                        <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px', zIndex: 0, background: "#F0F4FC"}} align="left" ><input style={{outline: "none"}} type = "checkbox" className='unique_checkbox' checked= {rpaflHandleSyncCheckBoxOrgKeywords()} onChange = {(event)=>rpaflHandleCheckBoxAllOrgKeywords(event)}/></TableCell>
                                                                        <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px' , zIndex: 0, background: "#F0F4FC"}}>Keyword</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody sx={{ height: "max-content" }}>
                                                                    {rpaflOrgKeywordInitialValues.rpaflOrgKeywords.map((row, index) => (
                                                                        <TableRow 
                                                                        key={index}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, background: index%2 ? ' linear-gradient(205.16deg, rgba(213, 213, 213, 0.09) 36.82%, rgba(226, 226, 226, 0) 155.82%)': 'transparent'}}
                                                                        >   
                                                                            <TableCell><input style={{outline: "none"}} type="checkbox" className='unique_checkbox' onChange = {(event)=>rpaflHandleCheckBoxOrgKeywords(event, row)} checked = {rpaflOrgKeywordInitialValues.rpaflSelectedOrgKeywords[row] === undefined? false: true}/></TableCell>
                                                                            <TableCell component="th" scope="row" sx = {{ fontFamily: "Inter", fontWeight: "bold",  fontSize: '15px', color: "#3B89FE"}} >
                                                                                {row}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </div>
                                                    <div className="nav_container">
                                                        <Stack spacing={2}>
                                                            <Pagination count={rpaflOrgKeywordInitialValues.rpaflOrgKeywordsTotalPage} page = {rpaflOrgKeywordInitialValues.rpaflOrgKeywordsPage} color='primary' value={rpaflOrgKeywordInitialValues.rpaflOrgKeywordsPage} onChange={rpaflHandleOrgKeywordsPage} variant="outlined" sx={{fontFamily: "Inter"}}/>
                                                        </Stack>
                                                    </div>
                                                    <div className="selected_count">
                                                        <div className="total_selected_display">
                                                            Total Selected Keywords <span>{Object.keys(rpaflOrgKeywordInitialValues.rpaflSelectedOrgKeywords).length}</span>
                                                        </div>
                                                        <button className="reset_feature btn btn-primary">
                                                            Reset
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                            }
                                        </div>
                                    }
                                    {rpaflInitialValues.rpaflSelectedFilter === 4 && 
                                        <div className="filter_value_4 filter_indi_value">
                                            <div className="location_value_new">
                                                <div className="location_popup_input input_search_filter_input">						
                                                    <div className="select_location_dropdown">
                                                        <Select
                                                            isClearable 
                                                            value={rpaflLocationInitialValues.rpaflSelectedOptionsLocationMainMenu}
                                                            options={rpaflLocationsMainMenu} 
                                                            placeholder = "Select Search Type for Location"
                                                            onChange={rpaflHandleLocationsMainMenu}
                                                        />
                                                    </div>
                                                    {
                                                        rpaflLocationInitialValues.rpaflSelectedOptionsLocationMainMenu?.value === "By Location" && 
                                                        <div className="verify_location_inputs_container">
                                                            <input type="text" className="location_inputs" placeholder='Enter Location Name' value = {rpaflLocationInitialValues.rpaflLocationTextInput} onChange={(e)=>
                                                                    setRpaflLocationInitialValues({ ...rpaflLocationInitialValues, rpaflLocationTextInput: e.target.value })
                                                                }/>
                                                            <button className="verify_location_btn btn btn-primary btn-sm" onClick={()=>rpaflHandleVerifyLocation("location")} disabled={rpaflLocationInitialValues.rpaflLocationTextInput === null || rpaflLocationInitialValues.rpaflLocationTextInput === "" ? true: false}>
                                                                Verify
                                                            </button>
                                                        </div>
                                                    }	
                                                    {
                                                        rpaflLocationInitialValues.rpaflSelectedOptionsLocationMainMenu?.value === "By Coordinates" && 
                                                        <div className="verify_coordinates_inputs_container">
                                                            <input type="text" className="coordinates_inputs" placeholder='Enter x-coordinate' value = {rpaflLocationInitialValues.rpaflLocationLatInput} onChange={(e)=> 
                                                                    setRpaflLocationInitialValues({ ...rpaflLocationInitialValues, rpaflLocationLatInput: e.target.value })
                                                                }/>
                                                            <input type="text" className="coordinates_inputs" placeholder='Enter y-coordinate'value = {rpaflLocationInitialValues.rpaflLocationLanInput} onChange={(e)=>
                                                                    setRpaflLocationInitialValues({ ...rpaflLocationInitialValues, rpaflLocationLanInput: e.target.value })
                                                                }/>
                                                            <button className="verify_location_btn btn btn-primary btn-sm" onClick={()=>rpaflHandleVerifyLocation("coordinates")} disabled = {((rpaflInitialValues.rpaflLocationLocationLatInput === null || rpaflLocationInitialValues.rpaflLocationLatInput === "" || rpaflLocationInitialValues.rpaflLocationLatInput === undefined) || (rpaflLocationInitialValues.rpaflLocationLanInput === null || rpaflLocationInitialValues.rpaflLocationLanInput === "" || rpaflLocationInitialValues.rpaflLocationLanInput === undefined)) ? true:false}>
                                                                Verify
                                                            </button>
                                                        </div>
                                                    }
                                                    {
                                                        rpaflLocationInitialValues.rpaflSelectedOptionsLocationMainMenu?.value === "By States" && 
                                                        <div className="verify_states_inputs_container">
                                                                <Select
                                                                    className='abcabc'
                                                                    value={rpaflLocationInitialValues.rpaflSelectedOptionsStateFilter}
                                                                    onChange={rpaflHandleStateFilter}
                                                                    options={rpaflSampleOptionsStateFilter}
                                                                    placeholder = "Search for States"
                                                                    isMulti
                                                                />
                                                        </div>
                                                    }
                                                    {
                                                        rpaflLocationInitialValues.rpaflInvalidLocationError && <div className='invalideLocationError'>Please Enter a valid Location....</div>
                                                    }
                                                    {   rpaflLocationInitialValues.rpaflMapCoordinates.length !== 0 &&
                                                        <>		
                                                            <div className="radius_selector">
                                                                <div className="radius_tag">
                                                                    Radius
                                                                </div>
                                                                <Slider value={rpaflLocationInitialValues.rpaflLocationRadius} aria-label="Default" valueLabelDisplay="auto" max={200} sx={{padding: "initial", margin: "initial"}} onChange={(e)=>{
                                                                    setRpaflLocationInitialValues(prevState => {
                                                                        return {
                                                                            ...prevState,
                                                                            rpaflApprovedLocation: false,
                                                                            rpaflLocationRadius: e.target.value
                                                                        };
                                                                    });
                                                                }
                                                                }/>
                                                            </div>
                                                            <div className='Map_approval_button'>
                                                                <div className="heat_map_verify_location">
                                                                    <LeafletMaps key={JSON.stringify(rpaflLocationInitialValues.rpaflMapCoordinates)} dataForMap = {rpaflLocationInitialValues.rpaflMapCoordinates} radius = {rpaflLocationInitialValues.rpaflLocationRadius} zoom = {6}/>
                                                                </div>
                                                                <div className="approval_locaion_wrapper">
                                                                    {rpaflLocationInitialValues.rpaflApprovedLocation && 
                                                                        <img src={approved_tick} alt="approved tick" className="approval_tick" />
                                                                    }
                                                                    <button className="approval_locaion_wrapper btn btn-primary btn-sm" onClick={() =>{ 
                                                                        setRpaflLocationInitialValues({ ...rpaflLocationInitialValues, rpaflApprovedLocation: true })
                                                                        rpaflHandleFilterChangeTemporary("location")
                                                                    }}>Confirm Location</button>
                                                                </div>												
                                                            </div>
                                                        </>
                                                    }	
                                                </div>
                                            </div>
                                        </div>}
                                    {rpaflInitialValues.rpaflSelectedFilter === 5 && 
                                        <div className="filter_value_5 filter_indi_value">
                                            <Select
                                                value={rpaflLanguageInitialValues.rpaflSelectedLanguage}
                                                className='filter_value_5_input'
                                                onChange={rpaflHandleLanguageChange}
                                                options={rpaflSampleOptionsLanguage}
                                                placeholder = "Search for Languages"
                                                isMulti
                                            />
                                        </div>
                                    }
                                    {rpaflInitialValues.rpaflSelectedFilter === 6 && 
                                        <div className="filter_value_6 filter_indi_value">
                                            <Select
                                                value={rpaflDomainInitialValues.rpaflSelectedDomains}
                                                className='filter_value_6_input'
                                                onChange={rpaflHandleDomainChange}
                                                options={rpaflDomainInitialValues.rpaflSampleOptionsDomain}
                                                placeholder = "Search for Domans"
                                                isMulti
                                            />
                                        </div>
                                    }
                                    {rpaflInitialValues.rpaflSelectedFilter === 7 && 
                                        <div className="filter_value_7 filter_indi_value">
                                            <div className="filter_value_7_value_1">
                                                <span>Unique Articles</span>
                                                <div className="unique_articles_value_new">
                                                    <div className="form-check form-switch">
                                                        <input className="form-check-input toggle_input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked = {rpaflUniqueArticleInitialValues.rpaflUniqueArticlesToggle} onChange={(e) =>{
                                                                handleRpaflUniqueToggleChange(e)
                                                            }
                                                        } />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="filter_value_7_value_2">
                                                <span>*</span> Text
                                            </div>
                                        </div>
                                    }
                                    {rpaflInitialValues.rpaflSelectedFilter === 8 && 
                                        <div className="filter_value_8 filter_indi_value">
                                            <Select
                                                className='filter_value_8_input'
                                                value={rpaflSentimentInitialValues.rpaflSelectedSentiment}
                                                onChange={rpaflHandleSentimentChange}
                                                options={rpaflSampleOptionsSentiment}
                                                placeholder = {"Search for Sentiment"}
                                                isClearable
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="cancel_apply_filters">
                                <div className="buttons_container">
                                    <button className="cancel btn btn-primary" onClick={()=>rpaflHandleFilterClearAll()}>
                                        Clear All
                                    </button>
                                    <button className="apply btn btn-primary" onClick={()=>rpaflhandleApplyFilters()}>
                                        Apply All
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="prompt_toggler">
                    <div className='toggler_left_ele'>Switch to</div>

                    <div className="toggler_main_ele">
                       
                        <div className="toggler_main">
                            <button className={promptToggle ? "btn toggles left_toggle left_toggle_circle": "btn toggles left_toggle"} onClick={() => setPromptToggle(! promptToggle)} >
                                <div className='toggle_text'>QA</div>
                            </button>
                            <button className={ promptToggle ? "btn toggles right_toggle": "btn toggles right_toggle right_toggle_circle"} onClick={() => setPromptToggle(! promptToggle)}>
                                <div className='toggle_text'>Articles</div>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="export_pdf_container filter_parts">
                    <button className="Export_button btn btn-primary" onClick = {() => floHandleOpenExportVerifyModal()}>
                        Export
                        <img src = {reports_export} alt = "reports_export" className='export_image'/>
                    </button> 
                    {floInitialValues.activeVerifyExportModal && 
                        <div className="absolute_verify_filters">
                            <img src={close_filter} alt="close filter icon" className="cross_modal"onClick={()=>setFloInitialValues({...floInitialValues, activeVerifyExportModal: false})}/>
                            <div className="details_container">
                                <div className="TotalArticles">Total Articles Selected<span>{Object.keys(floInitialValues.selectedArticle).length}</span></div>
                                <div className="ReportFormat">Report Format<span>{promptToggle ? "QA" : "Articles"}</span></div>
                            </div>
                            <div className="verify_buttons">
                                <button className="verify btn btn-primary btn-sm" onClick = {()=>handle_tab_switch(2)}>Verify</button>
                                <button className="proceed btn btn-primary btn-sm" onClick = {()=>handleExportValidationMain(1)}>Proceed</button>
                            </div>
                        </div>
                    }    
                </div>
            </div>
        </div>

        {/* tabs wrapper */}
        <div className="tabs_section_wrapper">
            <div className="tabs_container">
                {floInitialValues.rows.length > 0 && tab === 1 && <div className="expand_collapse">
                    <div className={floInitialValues.selectionsExpand?"expan" : "expan back_blue"} onClick={()=>handleExpandArticles("exp")}>
                        <img src={expand_icon} alt="" className= {floInitialValues.selectionsExpand?"expand_icon" : "expand_icon white_icon"} />   
                    </div>
                    <div className={floInitialValues.selectionsExpand?"collaps back_blue" : "collaps "} onClick={()=>handleExpandArticles("col")}>
                        <img src={collapse_icon} alt="" className= {floInitialValues.selectionsExpand?"collapse_icon" : "collapse_icon black_icon"}/>
                    </div>
                </div>}
                {seaInitialValues.rows.length > 0 && tab === 2 &&<div className="expand_collapse">
                    <div className={seaInitialValues.selectionsExpand?"expan" : "expan back_blue"} onClick={()=>handleExpandArticles("exp")}>
                        <img src={expand_icon} alt="" className= {seaInitialValues.selectionsExpand?"expand_icon" : "expand_icon white_icon"} />   
                    </div>
                    <div className={seaInitialValues.selectionsExpand?"collaps back_blue" : "collaps "} onClick={()=>handleExpandArticles("col")}>
                        <img src={collapse_icon} alt="" className= {seaInitialValues.selectionsExpand?"collapse_icon" : "collapse_icon black_icon"}/>
                    </div>
                </div>}
                <div className={"filter_results_tab" + (tab === 1 ? " selected" : " not_selected")} onClick={()=>handle_tab_switch(1)}>
                    Filter Results
                </div>
                <div className={"selected_articles_tab" + (tab === 2 ? " selected" : " not_selected")} onClick={()=>handle_tab_switch(2)}>
                    Selected Articles {tab === 2 &&<span style={{ color: "black", border: "1px solid #1C70C8", padding: "2px", borderRadius: "4px" }}>{Object.keys(seaInitialValues.selectedArticle).length}</span >}
                </div>
            </div>
        </div>
        { tab === 1 && floInitialValues.rows.length > 0 && 
            <div className="info_text_for_selected_articles">
                The selected articles will be added to the Selected Articles section
            </div>
        }
        {/* tabs wrapper */}

        {map !== 0 &&
            <div className="applied_filters_container">
                <div className="expand_collapse">
                    <div className={selectionsExpand?"expan" : "expan back_blue"} onClick={()=>handleExpandArticles("exp")}>
                        <img src={expand_icon} alt="" className= {selectionsExpand?"expand_icon" : "expand_icon white_icon"} />   
                    </div>
                    <div className={selectionsExpand?"collaps back_blue" : "collaps "} onClick={()=>handleExpandArticles("col")}>
                        <img src={collapse_icon} alt="" className= {selectionsExpand?"collapse_icon" : "collapse_icon black_icon"}/>
                    </div>
                </div>
                {totalArticles !== null && <div className="total_articles">
                    Selected Articles : &nbsp;&nbsp; <span>{Object.keys(selectedIds).length}</span>
                </div>}
            </div>
        }
        {
            tab === 1 && 
            <div className="table_wrapper">
                <div className="on_demand_table_container" ref={tableRef}>
                    {   floInitialValues.dataLoading === 0 ? <div className='no_filters_logo_wrapper'>
                            <img src={no_filters_logo} alt="no filters logo" className="no_filters_logo" />
                            <div className="no_filters_text">
                                Apply filters to view results
                            </div>
                        </div>: 
                        floInitialValues.dataLoading === 1 ? 

                        floInitialValues.rows.length === 0? 
                            <div className='no_results_container '> 
                                <img src={no_result_logo} alt="" className="no_results child_no_results" /> 
                                <div className="no_results_header child_no_results">
                                    No results found
                                </div>
                                <div className="no_results_subheader child_no_results">
                                    Try adjusting your filters to find out what you are looking for 
                                </div>
                            </div>:

                            <TableContainer component={Paper} sx={{ height: '70vh', boxShadow: '0px 20px 50px 0px #0000001A', borderRadius: '8px 8px 8px 8px' , backdropFilter: 'blur(10px)', background: "transparent"}}>
                            <Table stickyHeader >
                                <TableHead sx = {{ fontFamily: "Inter"}}>
                                    <TableRow sx={{ 
                                            background: '#F5F7FB'
                                        }}>
                                        <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '17.5px'}} align="left"><Checkbox checked= {floHandleSyncCheckBox()} onChange = {(event)=>floHandleCheckBoxAll(event, floInitialValues.rows)}/></TableCell>
                                        <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '17.5px', width: "5%"}} align="center"></TableCell>
                                        <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '17.5px', width: "50%"}} align="left">Article</TableCell>
                                        <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '17.5px'}} align="left" >Date</TableCell>
                                        <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '17.5px'}} align="left">Region</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ height: "max-content" }}>
                                    {floInitialValues.rows.map((row, index) => (
                                        <React.Fragment key={index}>
                                            <TableRow 
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, background: "rgba(233, 233, 233, 0.35)"
                                            }}
                                            >	
                                                <TableCell sx = {{'&:hover': { borderLeft: "4px solid #3B89FE" }}}><Checkbox className='unique_checkbox' {...label}  onChange = {(event)=>floHandleCheckBoxIndi(event, row._id, row)} checked = {floInitialValues.selectedArticle[row._id] === undefined? false: true}/></TableCell>
                                                <TableCell align="center" sx = {{ cursor: "pointer" }} onClick={() => setExpanded({ ...expanded, [row._id]: !expanded[row._id] })}>{expanded[row._id] ? <img src={colArw} alt='' className='collapseArrow arrow' /> : <img src={expArw} alt='' className='expandArrow arrow' />}</TableCell>
                                                <TableCell component="th" scope="row" sx = {{ fontFamily: "Inter",fontSize: '16px', fontWeight: "600", width: '25vw',}}>
                                                    {row.Title}
                                                </TableCell>
                                                <TableCell align="left" sx = {{ fontFamily: "Inter", fontSize: '16px' }}>{moment(row.published_date.slice(0, 10)).format('MM/DD/YYYY')}&nbsp;&nbsp;{row.published_date.slice(11, 16)}</TableCell>
                                                <TableCell align="left" sx = {{ fontFamily: "Inter", fontWeight: "600" ,fontSize: '16px' }}>
                                                        <Tooltip title={ 
                                                        <div style={{ backgroundColor: '#ffffff', fontFamily: "Inter", fontWeight: "600" ,fontSize: '18px', color: "#000000", padding: "1vh 1vh 0vh 0vh", overflowY: "auto", maxHeight: "30vh"}}>
                                                            <ul >
                                                                {row.keywords_p.Location.map((region, index) => (
                                                                <li key={index}>{region}</li>
                                                                ))}
                                                            </ul>
                                                        </div>		
                                                        } placement="top-start" arrow  >
                                                        
                                                        
                                                        <div>
                                                            {row.keywords_p.Location.length !== 0 ? (row.keywords_p.Location.length > 1 ? row.keywords_p.Location[0] + " ..." : row.keywords_p.Location[0]) : "No Regions"}
                                                        </div>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell style={{ padding: 0}} colSpan={7}>
                                                    <Collapse in={expanded[row._id]} timeout="auto" unmountOnExit>
                                                        <div className="view_articles_heatmap">
                                                            <ViewArticle sample_id = {row._id} />
                                                        </div>
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>

                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                            </TableContainer>:
                        floInitialValues.dataLoading === 2 ? <div style={{display: "flex" , alignItems: "center", justifyContent: "center", height: '70vh'}}><img src={loading} alt="laoding svg" className="loading" /></div>: 
                        <div className='no_filters_logo_wrapper'>
                            <img src={no_filters_logo} alt="no filters logo" className="no_filters_logo" />
                            <div className="no_filters_text">
                                Some Error. Try refresing the page
                            </div>
                        </div>  
                    }
                    {	(floInitialValues.rows.length === 0) ? <></>:
                        <div className="pagination_container">
                            <div className="display_pages">
                                Showing &nbsp;<span>{floInitialValues.totalPage !== 0 ? (floInitialValues.pageNo-1)*floInitialValues.limitPerPage + 1 : 0}</span>-<span>{(floInitialValues.pageNo-1)*15 + floInitialValues.rows.length}</span>&nbsp;from&nbsp;<span>{floInitialValues.totalData}</span>&nbsp;data 	
                            </div>
                            <div className="nav_container">
                                <Stack spacing={2}>
                                    <Pagination count={floInitialValues.totalPage} page = {floInitialValues.pageNo} color='primary' value={floInitialValues.pageNo} onChange={floHandlePage} variant="outlined" sx={{fontFamily: "Inter"}}/>
                                </Stack>
                            </div>
                        </div>
                    }
                </div>
            </div>
        }

        {/* table wrapper for selected articles */}
        {
            tab === 2 &&        
            <div className="selected_articles_table_wrapper">
                <div className="on_demand_table_container" ref={tableRef}>
                    {   seaInitialValues.dataLoading === 0 ? 
                        <div className='no_articles_logo_wrapper'>
                            <img src={no_articles_logo} alt="no articles logo" className="no_articles_logo" />
                            <div className="no_articles_text">
                                No Articles for Preview
                            </div>
                        </div>: 
                        seaInitialValues.dataLoading === 1 ? 

                        seaInitialValues.rows.length === 0? 
                        <div className='no_articles_logo_wrapper'>
                            <img src={no_articles_logo} alt="no articles logo" className="no_articles_logo" />
                            <div className="no_articles_text">
                                No Articles for Preview
                            </div>
                        </div>:

                            <TableContainer component={Paper} sx={{ height: '70vh', boxShadow: '0px 20px 50px 0px #0000001A', borderRadius: '8px 8px 8px 8px' , backdropFilter: 'blur(10px)', background: "transparent"}}>
                            <Table stickyHeader >
                                <TableHead sx = {{ fontFamily: "Inter"}}>
                                    <TableRow sx={{ 
                                            background: '#F5F7FB'
                                        }}>
                                        <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '17.5px'}} align="left"><Checkbox checked= {seaHandleSyncCheckBox()} onChange = {(event)=>seaHandleCheckBoxAll(event, seaInitialValues.rows)}/></TableCell>
                                        <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '17.5px', width: "5%"}} align="center"></TableCell>
                                        <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '17.5px', width: "50%"}} align="left">Article</TableCell>
                                        <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '17.5px'}} align="left" >Date</TableCell>
                                        <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '17.5px'}} align="left">Region</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ height: "max-content" }}>
                                    {seaInitialValues.rows.map((row, index) => (
                                        <React.Fragment key={index}>
                                            <TableRow 
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, background: "rgba(233, 233, 233, 0.35)"
                                            }}
                                            >	
                                                <TableCell sx = {{'&:hover': { borderLeft: "4px solid #3B89FE" }}}><Checkbox className='unique_checkbox' {...label}  onChange = {(event)=>seaHandleCheckBoxIndi(event, row._id, row)} checked = {seaInitialValues.selectedArticle[row._id] === undefined? false: true}/></TableCell>
                                                <TableCell align="center" sx = {{ cursor: "pointer" }} onClick={() => setExpandedSea({ ...expandedSea, [row._id]: !expandedSea[row._id] })}>{expandedSea[row._id] ? <img src={colArw} alt='' className='collapseArrow arrow' /> : <img src={expArw} alt='' className='expandArrow arrow' />}</TableCell>
                                                <TableCell component="th" scope="row" sx = {{ fontFamily: "Inter",fontSize: '16px', fontWeight: "600", width: '25vw',}}>
                                                    {row.Title}
                                                </TableCell>
                                                <TableCell align="left" sx = {{ fontFamily: "Inter", fontSize: '16px' }}>{moment(row.published_date.slice(0, 10)).format('MM/DD/YYYY')}&nbsp;&nbsp;{row.published_date.slice(11, 16)}</TableCell>
                                                <TableCell align="left" sx = {{ fontFamily: "Inter", fontWeight: "600" ,fontSize: '16px' }}>
                                                        <Tooltip title={ 
                                                        <div style={{ backgroundColor: '#ffffff', fontFamily: "Inter", fontWeight: "600" ,fontSize: '18px', color: "#000000", padding: "1vh 1vh 0vh 0vh", overflowY: "auto", maxHeight: "30vh"}}>
                                                            <ul >
                                                                {row.keywords_p.Location.map((region, index) => (
                                                                <li key={index}>{region}</li>
                                                                ))}
                                                            </ul>
                                                        </div>		
                                                        } placement="top-start" arrow  >
                                                        
                                                        
                                                        <div>
                                                            {row.keywords_p.Location.length !== 0 ? (row.keywords_p.Location.length > 1 ? row.keywords_p.Location[0] + " ..." : row.keywords_p.Location[0]) : "No Regions"}
                                                        </div>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell style={{ padding: 0}} colSpan={7}>
                                                    <Collapse in={expandedSea[row._id]} timeout="auto" unmountOnExit>
                                                        <div className="view_articles_heatmap">
                                                            <ViewArticle sample_id = {row._id} />
                                                        </div>
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>

                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                            </TableContainer>:
                        seaInitialValues.dataLoading === 2 ? <div style={{display: "flex" , alignItems: "center", justifyContent: "center", height: '70vh'}}><img src={loading} alt="laoding svg" className="loading" /></div>: 
                        <div className='no_filters_logo_wrapper'>
                            <img src={no_filters_logo} alt="no filters logo" className="no_filters_logo" />
                            <div className="no_filters_text">
                                Some Error. Try refresing the page
                            </div>
                        </div>  
                    }
                    {	(seaInitialValues.rows.length === 0) ? <></>:
                        <div className="pagination_container">
                            <div className="display_pages">
                                Showing &nbsp;<span>{seaInitialValues.totalPage !== 0 ? (seaInitialValues.pageNo-1)*seaInitialValues.limitPerPage + 1 : 0}</span>-<span>{(seaInitialValues.pageNo-1)*15 + seaInitialValues.rows.length}</span>&nbsp;from&nbsp;<span>{seaInitialValues.totalData}</span>&nbsp;data 	
                            </div>
                            <div className="nav_container">
                                <Stack spacing={2}>
                                    <Pagination count={seaInitialValues.totalPage} page = {seaInitialValues.pageNo} color='primary' value={seaInitialValues.pageNo} onChange={seaHandlePageChange} variant="outlined" sx={{fontFamily: "Inter"}}/>
                                </Stack>
                            </div>
                        </div>
                    }
                </div>
            </div>
        }
        {/* table wrapper for selected articles */}
			<ToastContainer />
            <div className="main_draft_export_popup">
                <ReactModal
                    isOpen={isOpenExportModal}
                    onRequestClose={closeModalExport}
                    contentLabel="Popup Modal"
                    style={customStylesDraftDelete}
                >	
                <div className="export_articles_popup_content report_gen_popup_export">
                    <div className="export_articles_popup_header">
                        Confirm Export
                    </div>
                    <div className="export_articles_add_draft_button">
                        <button className="cancel btn btn-light" onClick={()=>closeModalExport()}>
                            Cancel
                        </button>
                        <button className="button_main btn btn-primary" onClick={()=>{handleExport()}}>
                            Export All Articles
                        </button>
                        {(tab === 2 && Object.keys(seaInitialValues.selectedArticle).length > 0) && 
                            <button className="button_main btn btn-primary" onClick={()=>{handleExportTab2()}}>
                                {"Export Selected " + Object.keys(seaInitialValues.selectedArticle).length + " Articles" }  
                            </button>}
                    </div>
                </div>
                </ReactModal>	
		    </div>	
    </div>
  )
}
