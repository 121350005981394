import React from 'react'
import "../../staticFiles/cssFiles/KeywordsReport/rightDetailsKeywordsReport.css"
import report_download from "../../staticFiles/images/generated_download.svg"
import { Pagination, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { PageAnimations } from '../GlobalPageAnimations.jsx/pageAnimations'
import { useState } from 'react'
import { downloadHistory, fetchGeneratedReports, fetchScheduleInfo, fetchScheduledReports } from '../../endppoints/endpoint'
import { ToastContainer, toast } from 'react-toastify'
import Cookies from 'js-cookie'
import { Navigate, useParams } from 'react-router-dom'

import info_1 from "../../staticFiles/images/s_time.svg"
import info_2 from "../../staticFiles/images/s_interval.svg"
import info_3 from "../../staticFiles/images/s_freq.svg"
import info_5 from "../../staticFiles/images/s_gen_mode.svg"
import info_4 from "../../staticFiles/images/s_keys.svg"
import moment from 'moment/moment'

import searchIcon from "../../staticFiles/images/searchIcon.svg"


export const RightDetailsKeywordsReport = () => {
	const { schedule_id } = useParams();
	const [rowsSchedule, setRowsSchedule] = useState([])
	const [redirect, setRedirect] = useState(0)
	const [limitPerPage, setLimitPerPage]=useState(10)
	const [totalData, setTotalData] = React.useState(0) 
	const [totalPage, setTotalPage] = React.useState(0) 
	const [page, setPage] = React.useState(1)

	const [tab, setTab] = React.useState("info")


	const [pageInfo, setPageInfo] = React.useState(1)
	const [limitPerPageInfo, setLimitPerPageInfo] = React.useState(10)
	const [searchTextInfo, setSearchTextInfo] = React.useState("")
	const [rowsInfo, setRowsInfo] = React.useState([])
	const [totalDataInfo, setTotalDataInfo] = React.useState(0)
	const [totalPageInfo, setTotalPageInfo] = React.useState(0)
	const [infoData, setInfoData] = React.useState({})

	const check_error = (response) =>{
		if(response.status === 401){
			localStorage.clear()
			Cookies.remove('token');
			setTimeout(() => {
				setRedirect(1)    
			}, 1000);
			toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
		}
		else{
			toast.info("server error", { position: toast.POSITION.TOP_CENTER})
		}
	}

	React.useEffect(()=>{
		console.log("here")
		let message = {
			"scheduleId": schedule_id,
			"pageNo": page, 
			"itemsPerPage": limitPerPage
		}
		fetch_schedule_details(message);
		handle_info()

	}, [schedule_id]);

	
	const handle_info = () =>{
		let message = {
			"scheduleId": schedule_id,
			"pageNo": pageInfo, 
			"itemsPerPage": limitPerPageInfo
		}
		fetch_schedule_info(message)
	}
	const fetch_schedule_info = async(message) =>{
		let response = {}
		response = await fetchScheduleInfo(message)
		console.log(response);

		if(response.status === 200){
			console.log("YAY")
			setInfoData(response.data)
			setRowsInfo(response.data.reportInput.keywords.data)
			setTotalDataInfo(response.data.reportInput.keywords.totalCount)
			if(response.data.reportInput.keywords['totalCount'] % limitPerPageInfo === 0){
				setTotalPageInfo(Math.floor(response.data.reportInput.keywords['totalCount'] / limitPerPageInfo))
			}
			else{
				setTotalPageInfo(Math.floor(response.data.reportInput.keywords['totalCount'] / limitPerPageInfo) + 1)
			}
		}
		else{
				check_error(response)
		}
	}

	const download_report = (value, extension)=>{
        console.log(value, extension)
        download_api_call(value, extension);
    }
    const download_api_call = async(message, extension) =>{
        let response = {}
        response = await downloadHistory(message, extension)
        console.log(response)

        if(response.hasOwnProperty('status') && response['status'] === '500'){
            alert('Token expired...login again !!!!')
        }
    }

  	const fetch_schedule_details = async(message) =>{
		let response = {}
		response = await fetchGeneratedReports(message)
		console.log(response);

		if(response.status === 200){
			setRowsSchedule(response.data.data)
			setTotalData(response.data.totalCount)
			if(response.data['totalCount'] % 10 === 0){
				setTotalPage(Math.floor(response.data['totalCount'] / 10))
			}
			else{
				setTotalPage(Math.floor(response.data['totalCount'] / 10) + 1)
			}
		}
		else{
				check_error(response)
		}
  	}

	const handlePage = (event, pageNo) =>{
		setPage(pageNo)
		let message = {
			"scheduleId": schedule_id,
			"pageNo": pageNo, 
			"itemsPerPage": limitPerPage
		}
		fetch_schedule_details(message)
	}

	const handle_tabs = (tab) =>{
		setTab(tab)
	}

	const handleSearchInfo = (event) =>{
		console.log(event.target.value)
		setSearchTextInfo(event.target.value)
		setPage(1)
		let message = {
			"scheduleId": schedule_id,
			"pageNo": 1, 
			"itemsPerPage": limitPerPageInfo,
			"searchText": event.target.value
		}
		fetch_schedule_info(message)
	}

	const handlePageInfo = (e, val) =>{
		setPageInfo(val)
		let message = {
			"scheduleId": schedule_id,
			"pageNo": val, 
			"itemsPerPage": limitPerPageInfo,
			"searchText": searchTextInfo
		}
		fetch_schedule_info(message)
	}

  	if(redirect === 1){
		// return <Navigate to = '/login' />
		window.location.href = '/login';
	}

//   const rowsOrgKeywords = [{fileName :"abc ", date: "13/09/2023", time: "08:10 am"}, {fileName :"abc ", date: "13/09/2023", time: "08:10 am"}]
  return (
    <div className='right_details_keywords_report_container'>
		<PageAnimations />
		<div className="tab_details">
			<div className={"tab_1" + (tab === "info" ? " highlighted_tab": "")} onClick={()=>handle_tabs("info")}>
				Schedule Info
			</div>
			<div className={"tab_2" + (tab === "reports" ? " highlighted_tab": "")} onClick={()=>handle_tabs("reports")}>
				Generated Reports
			</div>
		</div>
		{tab === "reports"? 
			<div className="generated_reports_table_container">
				<TableContainer sx={{  maxHeight: "70vh", border: "1px solid rgba(205, 205, 205, 1)", borderRadius: "4px", backdropFilter: 'blur(10px)', background: "transparent"}}>
				<Table stickyHeader >
				<TableHead sx = {{ fontFamily: "Inter"}}>
					<TableRow>
						<TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px', zIndex: 0, background: "#F0F4FC"}} align="center" >File Name</TableCell>
						<TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px', zIndex: 0, background: "#F0F4FC"}} align="center" >Date</TableCell>
						<TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px', zIndex: 0, background: "#F0F4FC"}} align="center" >Time</TableCell>
						<TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px' , zIndex: 0, background: "#F0F4FC"}} align="center">Download</TableCell>
					</TableRow>
				</TableHead>
				<TableBody sx={{ height: "max-content" }}>
					{rowsSchedule.map((row, index) => (
						<TableRow 
						key={index}
						sx={{ '&:last-child td, &:last-child th': { border: 0 }, background: index%2 ? ' linear-gradient(205.16deg, rgba(213, 213, 213, 0.09) 36.82%, rgba(226, 226, 226, 0) 155.82%)': 'transparent'}}
						>   
							<TableCell component="th" scope="row" sx = {{ fontFamily: "Inter", fontWeight: "bold",  fontSize: '15px'}} align="center">
								{row.fileName}
							</TableCell>
							<TableCell component="th" scope="row" sx = {{ fontFamily: "Inter", fontWeight: "bold",  fontSize: '15px'}} align="center">
								{row.dateTime.slice(0,10)}
							</TableCell>
							<TableCell component="th" scope="row" sx = {{ fontFamily: "Inter", fontWeight: "bold",  fontSize: '15px'}} align="center">
								{row.dateTime.slice(11, 16)}
							</TableCell>
							<TableCell component="th" scope="row" sx = {{ fontFamily: "Inter", fontWeight: "bold",  fontSize: '15px'}} align="center">
								<img src={report_download} alt="" className="download_reports" onClick={()=>download_report(row.url, row.extension)}/>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
				</Table>
				</TableContainer>
				{	rowsSchedule.length === 0 ? <></>:
					<div className="pagination_container">
						<div className="display_pages">
							Showing &nbsp;<span>{totalPage !== 0 ? (page-1)*limitPerPage + 1 : 0}</span>-<span>{(page-1)*limitPerPage + rowsSchedule.length}</span>&nbsp;from&nbsp;<span>{totalData}</span>&nbsp;data 	
						</div>
						<div className="nav_container">
							<Stack spacing={2}>
								<Pagination count={totalPage} page = {page} color='primary' value={page} onChange={handlePage} variant="outlined" sx={{fontFamily: "Inter"}}/>
							</Stack>
						</div>
					</div>
				}
			</div>
		:
			<div className="schedule_info_container">
				<div className="schedule_info_tracker">
					<div className="info_sections">
						<img src={info_1} alt="" className="info_icon" />
						<div className="info_name">Scheduled Time</div>
						<div className="info_val">{moment(infoData["scheduledTime"], 'HH:mm').format('h:mm A')}</div>
					</div>
					<div className="info_sections">
						<img src={info_2} alt="" className="info_icon" />
						<div className="info_name">Scheduled Interval</div>
						<div className="info_val">{infoData["scheduledInterval"]}</div>
					</div>
					<div className="info_sections">
						<img src={info_3} alt="" className="info_icon" />
						<div className="info_name">Scheduled Frequency</div>
						<div className="info_val">{infoData["scheduledFrequency"]}</div>
					</div>
					<div className="info_sections">
						<img src={info_4} alt="" className="info_icon" />
						<div className="info_name">Report Generation Mode</div>
						<div className="info_val">{infoData["reportGenerationMode"]}</div>
					</div>
					<div className="info_sections">
						<img src={info_5} alt="" className="info_icon" />
						<div className="info_name">No. of keywords</div>
						<div className="info_val">11</div>
					</div>
				</div>
				<div className="keywords_display">
					<div className="keywords_header">
						<div className="tag_header">
							Keywords
						</div>
						<div className="crud_search">
							<img src={searchIcon} alt="" className="search_icon" />
							<input type="text" className="crud_add" placeholder='Search Keywords' value={searchTextInfo} onChange = {handleSearchInfo}/>
						</div>
					</div>
					<div className="keywords_display_main_data">
						{rowsInfo.map((ele, index)=>(
							<div key={index} className="keywords_main_content">
								{ele}
							</div>
						))}
					</div>
					{	rowsInfo.length === 0 ? <></>:
					<div className="keywords_pagination_container">
						
							{/* <div className="display_pages">
								Showing &nbsp;<span>{totalPageInfo !== 0 ? (page-1)*limitPerPageInfo + 1 : 0}</span>-<span>{(page-1)*limitPerPageInfo + rowsInfo.length}</span>&nbsp;from&nbsp;<span>{totalDataInfo}</span>&nbsp;data 	
							</div> */}
							<div className="nav_2_container">
								<Stack spacing={2}>
									<Pagination count={totalPageInfo} color='primary' page = {pageInfo} value={pageInfo} onChange={handlePageInfo} variant="outlined" sx={{fontFamily: "Inter"}}/>
								</Stack>
							</div>

						{/* <div className="nav_container">
							<Stack spacing={2}>
							</Stack>
						</div> */}
						
					</div>}
				</div>
			</div>
		}
	  <ToastContainer />
    </div>
  )
}
