import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable as Droppable } from "./Dropable";

import moment from 'moment/moment';

import { Tooltip } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import React from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store";
import { useSelector } from "react-redux";

import translate_icon from "../../staticFiles/images/translate_icon.svg"


const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// The style of a question about drag and drop
const getItemStyle = (isDragging, draggableStyle) => ({
  background: isDragging ? "#757ce8" : "white",
  ...draggableStyle
});

export const Test2 = () => {    

  const [translateToggle, setTranslateToggle] = useState([])
  const dispatch = useDispatch()
	const {updateStates} = bindActionCreators(actionCreators, dispatch);

  const {
      articlesDataForRearrangeComponent
    } = useSelector(state => state.dashboardState);
	// console.log(articlesData)
  // const [allData, setAllData] = useState(articlesData);

  const onDragEnd = (result) => {
    // No drop target available
    if (!result.destination) {
      return;
    }
    let movedItems = reorder(
      articlesDataForRearrangeComponent,
      result.source.index, // Original array position
      result.destination.index // Destination array position
    );
    updateStates({
      "type": "reducerUpdates",
      "payload": {
          articlesDataForRearrangeComponent: movedItems
      }
  });
    // setAllData(movedItems);
  };

  const handleToggleTranslate = (index) =>{
    const updatedArray = [...translateToggle];
    updatedArray[index] = !translateToggle[index]; 
    setTranslateToggle(updatedArray)
    }

  return (
    // <TableContainer component={Paper}>
    //   <TableContainer component={Paper} sx={{ height: '82vh', overflowY: "scroll", boxShadow: '0px 20px 50px 0px #0000001A', borderRadius: '8px 8px 8px 8px', background: "transparent"}}>

    //   <Table stickyHeader aria-label="simple table">
    //     <TableHead>
    //       <TableRow>
    //         <TableCell>ID</TableCell>
    //         <TableCell>question</TableCell>
    //       </TableRow>
    //     </TableHead>
    //     {/* <TableBody> */}
    //     <DragDropContext onDragEnd={onDragEnd}>
    //       <Droppable droppableId="droppable">
    //         {(provided, snapshot) => (
    //           <TableBody
    //             {...provided.droppableProps}
    //             ref={provided.innerRef}
    //             // style={getListStyle(snapshot.isDraggingOver)}
    //           >
    //             {questions.map((question, index) => (
    //               <Draggable
    //                 key={question.id}
    //                 draggableId={"q-" + question.id}
    //                 index={index}
    //               >
    //                 {(provided, snapshot) => (
    //                   <TableRow
    //                     ref={provided.innerRef}
    //                     {...provided.draggableProps}
    //                     {...provided.dragHandleProps}
    //                     style={getItemStyle(
    //                       snapshot.isDragging,
    //                       provided.draggableProps.style
    //                     )}
    //                   >
    //                     <TableCell
    //                       component="th"
    //                       scope="row"
    //                       style={{ width: "50%" }}
    //                     >
    //                       {question.id}
    //                     </TableCell>
    //                     <TableCell style={{ width: "50%" }}>
    //                       {question.title}
    //                     </TableCell>
    //                   </TableRow>
    //                 )}
    //               </Draggable>
    //             ))}
    //             {provided.placeholder}
    //           </TableBody>
    //         )}
    //       </Droppable>
    //     </DragDropContext>
    //     {/* </TableBody> */}
    //   </Table>
    // </TableContainer>

    <TableContainer component={Paper} sx={{ height: '82vh', overflowY: "scroll" , boxShadow: '0px 20px 50px 0px #0000001A', borderRadius: '8px 8px 8px 8px'}}>
      <Table stickyHeader aria-label="simple table">
      <TableHead>
        <TableRow>
          {/* <TableCell>ID</TableCell> */}
          {/* <TableCell>question</TableCell> */}

          <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '17.5px', width: "50%"}} align="left">Article</TableCell>
          <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '17.5px'}} align="left" >Date</TableCell>
          <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '17.5px'}} align="left">Region</TableCell>
        </TableRow>
      </TableHead>
      {/* <TableBody> */}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <TableBody
          {...provided.droppableProps}
          ref={provided.innerRef}
          // style={getListStyle(snapshot.isDraggingOver)}
          >
          {articlesDataForRearrangeComponent.map((row, index) => (
            <Draggable
            key={row._id}
            draggableId={"q-" + row._id}
            index={index}
            >
            {(provided, snapshot) => (
              <React.Fragment key={index}>
                <TableRow
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
                )}
                >
                  {/* <TableCell
                    component="th"
                    scope="row"
                    style={{ width: "50%" }}
                  >
                    {question.id}
                  </TableCell>
                  <TableCell style={{ width: "50%" }}>
                    {question.title}
                  </TableCell> */}
                    <TableCell component="th" scope="row" sx = {{ fontFamily: "Inter",fontSize: '16px', fontWeight: "600", width: '25vw',}}>
                    {row.Title_translated && 
                          <>
                              <img src={translate_icon} alt="translate_icon" className="translate_text_icon" style={{width: "3.8%"}} onClick={()=>handleToggleTranslate(index)}/>
                              <>&nbsp;&nbsp;</>
                      </>}
                      {( row.Title_translated && !translateToggle[index]) ? row.Title_translated: row.Title}
                      <div className="domain_name" style={{fontSize: "small"}}>
                        {row.domain_name}
                      </div>
                    </TableCell>
                    <TableCell align="left" sx = {{ fontFamily: "Inter", fontSize: '16px' }}>
                      {moment(row.published_date.slice(0, 10)).format('MM/DD/YYYY')}&nbsp;&nbsp;{row.published_date.slice(11, 16)}
                    </TableCell>
                    <TableCell align="left" sx = {{ fontFamily: "Inter", fontWeight: "600" ,fontSize: '16px' }}>
                      <Tooltip title={ 
                        <div style={{ backgroundColor: '#ffffff', fontFamily: "Inter", fontWeight: "600" ,fontSize: '18px', color: "#000000", padding: "1vh 1vh 0vh 0vh", overflowY: "auto", maxHeight: "30vh"}}>
                          <ul >
                            {row.keywords_p.Location.map((region, index) => (
                            <li key={index}>{region}</li>
                            ))}
                          </ul>
                        </div>		
                        } placement="top-start" arrow  >
                        
                        
                        <div>
                          {row.keywords_p.Location.length !== 0 ? (row.keywords_p.Location.length > 1 ? row.keywords_p.Location[0] + " ..." : row.keywords_p.Location[0]) : "No Regions"}
                        </div>
                      </Tooltip>
                    </TableCell>
                </TableRow>
              </React.Fragment>
            )}
            </Draggable>
          ))}
          {provided.placeholder}
          </TableBody>
        )}
        </Droppable>
      </DragDropContext>
      {/* </TableBody> */}
      </Table>
    </TableContainer>
  );
};
