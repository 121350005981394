import axios from "axios";
export const forgotPassword = async(message) =>{
    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/auth/forgotPassword', message)

    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
      };  

    let data_rec = {}
    await axios.post(process.env.REACT_APP_SERVER_HOST+'/api/v1/auth/forgotPassword', message, config)
        .then(response => {
            console.log('axios_inside', response.data)
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['status'] = error.response.status;
        });
        return data_rec;
}

// export const testing = async() =>{
//     const config = {
//         headers: {
//             'Access-Control-Allow-Origin': '*',
//             'Content-Type': 'application/json',
//         }
//       };  
//     let data_rec = {}
//     await axios.get("http://192.168.1.64:5000/", config)
//         .then(response => {
//             console.log(response)
//             // console.log(response.headers['set-cookie'])
//         })
        
//         .catch(error => {
//             console.log(error)
//         })
  
//         return data_rec;
//   }