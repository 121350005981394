import React from 'react'
import '../../staticFiles/cssFiles/maps.css'
import { MapContainer, TileLayer, Marker, Popup, Circle } from 'react-leaflet';
import pin from "../../staticFiles/images/pin_map.svg"
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
// import covidData from './data.json';

// const covidData = [
//     {
//       "Id": 33,
//       "Location": "Mizoram",
//       "Total_Cases": "4,747",
//       "New_Cases_Per_Day": "25",
//       "Cases_Per_1_Million_People": "4,255",
//       "Deaths": "12",
//       "Latitude": 23.727106,
//       "Longitude": 92.717636
//     },
//     {
//       "Id": 34,
//       "Location": "Dadra and Nagar Haveli and Daman and Diu",
//       "Total_Cases": "4,523",
//       "New_Cases_Per_Day": "103",
//       "Cases_Per_1_Million_People": "7,722",
//       "Deaths": "4",
//       "Latitude": 20.397373,
//       "Longitude": 72.832802
//     },
//     {
//       "Id": 35,
//       "Location": "Lakshadweep",
//       "Total_Cases": "901",
//       "New_Cases_Per_Day": "56",
//       "Cases_Per_1_Million_People": "13,984",
//       "Deaths": "1",
//       "Latitude": 11.098209,
//       "Longitude": 72.152466
//     }
// ]

const focus = [20.5937, 78.9629]
// const pins = [13.0827, 80.2707]
const customIcon = new Icon({
    iconUrl: pin,
    iconSize: [20, 20]
  })


export const LeafletMaps = ({dataForMap, radius, zoom}) => {
    const [ activeCovid, setActiveCovid ] = React.useState( null ); 
    const [covidData, setCovidData] = React.useState([])
    const [latState, setLatState] = React.useState(dataForMap[0].Latitude)
    const [longState, setLongState] = React.useState(dataForMap[0].Longitude)
    React.useEffect(()=>{
        setCovidData(dataForMap)
        // if(dataForMap.length !== 0){
        //     console.log(dataForMap[0].Latitude, dataForMap[0].Longitude)
        //     setLatState(dataForMap[0].Latitude)
        //     setLongState(dataForMap[0].Longitude)
        // }
    }, [dataForMap])

  return (
    <section className='map-component' >
    <div className="map" id = "map">
        {/* {console.log(latState, longState)} */}
            <MapContainer center={[latState, longState]} zoom={zoom ? zoom : 4} scrollWheelZoom={true} >
                <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {/* <TileLayer
                attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
                url='https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png'
                /> */}
                {
                    covidData.map(eachData => (
                        <React.Fragment key={eachData.Id}>
                            <Marker 
                                position = {[eachData.Latitude, eachData.Longitude]} 
                                eventHandlers={{
                                    mouseover: () =>{
                                        setActiveCovid(eachData)
                                    }, 
                                    mouseout: () =>{
                                        setActiveCovid(null)
                                    }
                                }}
                                icon={customIcon}>
                            </Marker>
                            {radius && 
                                <Circle
                                    center={[eachData.Latitude, eachData.Longitude]}
                                    radius={radius*1000} // 1000 km in meters
                                    fillColor='blue' // Change the color as needed
                                    fillOpacity={0.2}
                                    stroke={false}
                                />
                            }
                        </React.Fragment>
                ))}
                {
                    activeCovid && (
                        <Popup
                            position={[activeCovid.Latitude, activeCovid.Longitude]}
                            eventHandlers={{
                                click: () =>{
                                    setActiveCovid(null)
                                }
                            }}
                        >   <div className='popup_container'>  
                                <h5>{activeCovid.Location}</h5>
                                {/* <p>data1:      {activeCovid.Total_Cases}</p>
                                <p>data2:      {activeCovid.New_Cases_Per_Day}</p>
                                <p>data3:      {activeCovid.Cases_Per_1_Million_People}</p> */}
                            </div>
                        </Popup>
                )}
                {/* <Marker position={pins} icon={customIcon}>
                    <Popup>
                        Some information
                    </Popup>
                </Marker> */}
            </MapContainer>
        </div>
	</section>
  )
}
