import React from 'react'
import { useParams } from 'react-router-dom'
import '../../staticFiles/cssFiles/Article/rightArticle.css'
import art_logo from "../../staticFiles/images/art_logo.svg"
import { AddArticleToDraft, createDraft, getCategories, getDraftsList, getParticularArticle, getRelatedArticles } from '../../endppoints/endpoint'
import moment from 'moment/moment';
import { Navigate } from 'react-router-dom'
import { LeafletMaps } from '../test3/leaflet_maps'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { Test } from '../test/test'
import prev_btn from "../../staticFiles/images/carousal_prev.svg"
import Cookies from 'js-cookie'
import draft from "../../staticFiles/images/draft-button (3).png"
import ReactModal from 'react-modal';
import CreatableSelect from 'react-select/creatable'; 
import { useDispatch } from 'react-redux'
import { actionCreators } from '../../store'
import { bindActionCreators } from 'redux'
import { PageAnimations } from '../GlobalPageAnimations.jsx/pageAnimations'
import translate_icon from "../../staticFiles/images/translate_icon.svg"

export const RightArticleView = () => {
    const dispatch = useDispatch()
    const {updateStates} = bindActionCreators(actionCreators, dispatch);

    const { article_id } = useParams();

    const [date, setDate] = React.useState()
    const [title, setTitle] = React.useState()
    const [summary, setSummary] = React.useState()
    const [text, setText] = React.useState()
    const [locations, setLocations] = React.useState([])
    const [organizations, setOrganizations] = React.useState([])
    const [persons, setPersons] = React.useState([])
    const [redirect, setRedirect] = React.useState(0)
    const [articleLink, setArticleLink] = React.useState('')
    const [domainName, setDomainName] = React.useState('')

    const [pinMapData, setPinMapData] = React.useState([])
    const [relatedArticles, setRelatedArticles] = React.useState([])
    const [startIndex, setStartIndex] = React.useState(0)
    const [displayedElements, setDisplayedElements] = React.useState([]);
    const [direction, setDirection] = React.useState('');
    const [categories, setCategories] = React.useState([])
    const [filterInfo, setFilterInfo] = React.useState([])
    // const [keyword, setKeyword] = React.useState('')
    const [isOpen, setIsOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState(null);
    const [fetchedDrafts, setFetchedDrafts] = React.useState({})
    
    const [exactMatches, setExactMatches] = React.useState([])
    const [partialMatches, setPartialMatches] = React.useState([])
    const [totalMatches, setTotalMatches] = React.useState(new Set());
    const [sentiment, setSentiment] = React.useState("")
    const [fls, setFls] = React.useState(null)

    const [translateToggleTitle, setTranslateToggleTitle] = React.useState(true)
    const [translateToggleText  , setTranslateToggleText  ] = React.useState(true)
    const [translateTitle, setTranslateTitle] = React.useState(null)
    const [translateText  , setTranslateText] = React.useState(null)

	const openModal = () => {
		setIsOpen(true);
	  };
	
	const closeModal = () => {
	setIsOpen(false);
	};

    const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			minWidth: '25%',
			minHeight: '20%',
			background: '#FFFFFF',
			borderRadius: "14px",
			paddingTop: '5vh',
			paddingBottom: '5vh',
			paddingLeft: '5vw',
			paddingRight: '5vw',
            display: "flex",
            justifyContent: "center"
		},
		overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.71)',
            backdropFilter: "blur(2.5px)",
            zIndex: 1
		},
	  };



    React.useEffect(()=>{
        get_particular_article(article_id);
        get_related_articles(article_id);
        fetch_categories();
    }, [article_id]);

    const get_particular_article = async(id)=>{

        
        updateStates({
            "type": "reducerUpdates",
            "payload": {
                status: 0
            }
        })

        updateStates({
            "type": "factCheckReducerUpdates",
            "payload": {
                fact_redirect: 0
            }
        })

        let response = {}
        response = await getParticularArticle(id)
        console.log(response);

        if(response.status === 200){
            setDate(response.data.published_date)
            setTitle(response.data.Title)
            setSummary(response.data.Summary)
            setText(response.data.Text)
            setLocations(response.data.keywords_p.Location)
            setOrganizations(response.data.keywords_p.Organization)
            setPersons(response.data.keywords_p.Person)
            setArticleLink(response.data._source.source)
            setDomainName(response.data.domain_name)
            setFilterInfo(response.data.filter_info)
            setSentiment(response.data.sentiment)
            setFls(response.data.fls)
            setTranslateTitle(response.data.Title_translated)
            setTranslateText(response.data.Text_translated)

            let samplePinArray = []
            // for(let i = 0; i < response.data.lat_long.length; i++){
            //     samplePinArray.push({"Id": i, "Location": response.data.keywords_p.Location[i], "Latitude": response.data.lat_long[i][0], "Longitude": response.data.lat_long[i][1]})
            // }
            // setPinMapData(samplePinArray)
            
            Object.entries(response.data.lat_long_map).map((entry, index) => {
                samplePinArray.push({"Id": index, "Location": entry[0], "Latitude": entry[1][0], "Longitude": entry[1][1]})
                // let key = entry[0];
                // let value = entry[1];
                // console.log(key, value);
            });
            // console.log(samplePinArray)
            setPinMapData(samplePinArray)


            // console.log(response.data.Text_translated ? response.data.Text_translated : response.data.Text)
            fetch_client_keywords(response.data.filter_info, response.data.Text_translated ? response.data.Text_translated : response.data.Text)
            // setKeyword(response.keywords_n[0])
        }

        // else if(response.status === 401){
        //     alert("invalid token or token expired")
        //     setRedirect(1)
        // }
        // else{
        //     alert("server error")
        //     // setRedirect(1)
        // }

        else if(response.status === 401){
			// alert("invalid token or token expired")
            setTimeout(() => {
                setRedirect(1)    
            }, 1000);
			toast.info("invalid token or token expired",  { position: toast.POSITION.TOP_CENTER});
			// setStatus(2)
		}
		else{
			toast.error('server error',  { position: toast.POSITION.TOP_CENTER});
			// alert('server error')
		}

        // if(response.hasOwnProperty('status') && response['status'] === '500'){
        //     alert('Token expired...login again !!!!')
        //     setRedirect(1)
        // }
        // else{
        //     setDate(response.published_date)
        //     setTitle(response.Title)
        //     setSummary(response.Summary)
        //     setText(response.Text)
        //     setLocations(response.keywords_p.Location)
        //     setOrganizations(response.keywords_p.Organization)
        //     setPersons(response.keywords_p.Person)
        //     setArticleLink(response._source.source)
        //     setDomainName(response.domain_name.substring(4, response.domain_name.length - 4))

        //     let samplePinArray = []
        //     for(let i = 0; i < response.lat_long.length; i++){
        //         samplePinArray.push({"Id": i, "Location": response.keywords_p.Location[i], "Latitude": response.lat_long[i][0], "Longitude": response.lat_long[i][1]})
        //     }
        //     setPinMapData(samplePinArray)
        //     // setKeyword(response.keywords_n[0])
        // }
    }

    const get_related_articles = async(id)=>{
        let message = {
            'articleId': id
        }
        let response = {}
        response = await getRelatedArticles(message)
        console.log(response);

        if(response.status === 200){
            setRelatedArticles(response.data)
        }

        else if(response.status === 401){
			// alert("invalid token or token expired")
            setTimeout(() => {
                setRedirect(1)    
            }, 1000);
			toast.info("invalid token or token expired",  { position: toast.POSITION.TOP_CENTER});
			// setStatus(2)
		}
		else{
			toast.error('server error',  { position: toast.POSITION.TOP_CENTER});
			// alert('server error')
		}
    }

    const handlePrev = () => {
        // console.log("prev")
        if (startIndex > 0) {
            // setDirection('prev');
            setStartIndex(startIndex - 4);
        }
    };
    
    const handleNext = () => {
        // console.log("next")
        if (startIndex < relatedArticles.length - 4) {
            // setDirection('next');       
            setStartIndex(startIndex + 4);
        }
    };

       // Function to check if a word contains any of the keywords as substrings
    // const shouldHighlight = (word, keywords) => {
    //     return keywords.some((keyword) => word.includes(keyword.toLowerCase()));
    // };

    // // Function to highlight keywords in the summary
    // const highlightedSummary = (summary) => {
    //     console.log(filterInfo)
    //     const keywords = {"partial_match_keywords": ["army"], "word_bound_match_keywords": []}
    //     return summary.split(/\b/).map((word, index) =>
    //         shouldHighlight(word.toLowerCase(), keywords["partial_match_keywords"]) ? (
    //             <span key={index} style={{ backgroundColor: "yellow" }}>
    //             {word}
    //             </span>
    //         ) : (
    //             word
    //         )
    //     );
    // };


      
    // const highlightedSummary = (text) => {
    //     const value = "The Army";
    //     if (!value || /^\s*$/.test(value)) {
    //       return <span>{text}</span>;
    //     } else {
    //       const trimmedValue = value.trim(); // Remove leading and trailing spaces from the keyword
    //       const escapedValue = RegExp.escape(trimmedValue); // Escape special characters in the search value
    //       const re = new RegExp(`(\\S*${escapedValue}\\S*)`, 'gi'); // Match the phrase containing the search value
    //       const children = [];
      
    //       let before, highlighted, match, pos = 0;
    //       const matches = text.match(re);
      
    //       if (matches != null) {
    //         for (match of matches) {
    //           match = re.exec(text);
      
    //           if (pos < match.index) {
    //             before = text.substring(pos, match.index);
      
    //             if (before.length) {
    //               children.push(before);
    //             }
    //           }
      
    //           highlighted = <span style={{ backgroundColor: 'coral' }}>{match[1]}</span>;
    //           children.push(highlighted);
      
    //           pos = match.index + match[1].length;
    //         }
    //       }
      
    //       if (pos < text.length) {
    //         const last = text.substring(pos);
    //         children.push(last);
    //       }
      
    //       return <span>{children}</span>;
    //     }
    //   };
      
    //   // Function to escape special characters in the search value
    //   RegExp.escape = function (string) {
    //     return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    //   };

        // const highlightedSummary_partial = (text, value) => {
        //     // const value = value;
        //     console.log(value)
        //     if (!value || /^\s*$/.test(value)) {
        //     return <span>{text}</span>;
        //     } else {
        //     const trimmedValue = value.trim();
        //     const escapedValue = RegExp.escape(trimmedValue);
        //     const re = new RegExp(`(\\S*${escapedValue}\\S*)`, 'gi');
            
        //     const children = [];
        //     let match;
        //     let pos = 0;
        
        //     while ((match = re.exec(text)) !== null) {
        //         const before = text.substring(pos, match.index);
        //         if (before.length) {
        //         children.push(before);
        //         }
        
        //         const highlighted = <span style={{ backgroundColor: 'yellow' }}>{match[1]}</span>;
        //         children.push(highlighted);
        
        //         pos = match.index + match[1].length;
        //     }
        
        //     const last = text.substring(pos);
        //     children.push(last);
        
        //     return <span>{children}</span>;
        //     }
        // };
        const getMatchedIndexes = (text, keywords) => {
            const indexes = [];

            keywords.forEach(keyword => {
                const pattern = new RegExp(keyword, 'gi');
                // indexes[keyword] = [];
                let match;
                while ((match = pattern.exec(text)) !== null) {
                    indexes.push([match.index, match.index + match[0].length - 1]);
                }
            });
    
            return indexes;
        };    
        
        const highlightedSummary_partial = (text) => {
            const partial_match = ["pposit", "et strong"];
            const exact_match = ["the Congress", "walked"];
            // Create a copy of the text to avoid modifying the original
            // Process partial matches (within a word)
            // let highlightedText = text;
            // partial_match.forEach(keyword => {
            //     highlightedText = highlightedText.replace(new RegExp(keyword, 'gi'), match => `<span style="background: yellow">${match}</span>`);
            // });

            // // Process exact matches
            // exact_match.forEach(keyword => {
            //     highlightedText = highlightedText.replace(new RegExp(`\\b${keyword}\\b`, 'gi'), match => `<span style="background: yellow">${match}</span>`);
            // });

            // return <div dangerouslySetInnerHTML={{ __html: highlightedText }} />;
            const matchedIndexes = {
                partial: getMatchedIndexes(summary, partial_match),
                exact: getMatchedIndexes(summary, exact_match.map(keyword => `\\b${keyword}\\b`)),
            }; 
            // console.log("Matched Indexes - Partial:", matchedIndexes.partial);
            // console.log("Matched Indexes - Exact:", matchedIndexes.exact);
            
            let partialMatches = matchedIndexes["partial"]
            let exactMatches = matchedIndexes["exact"]
            console.log(partialMatches, exactMatches)
            
            const allMatches = [
                ...partialMatches.map(match => ({ type: 'partialMatch', index: match })),
                ...exactMatches.map(match => ({ type: 'exactMatch', index: match }))
            ];
        
            const sortedMatches = allMatches.sort((a, b) => a.index[0] - b.index[0]);
        
            let markedText = "";
            let currentIndex = 0;
        
            for (const match of sortedMatches) {
                const type = match.type;
                const [start, end] = match.index;
        
                markedText += summary.substring(currentIndex, start);
        
                if (type === 'exactMatch') {
                    markedText += `<span style= "background: yellow">${summary.substring(start, end + 1)}</span>`;
                } else if (type === 'partialMatch') {
                    if (start >= currentIndex) {
                        const partialText = summary.substring(start, end + 1);
                        const beforePartial = summary.substring(currentIndex, start);
                        console.log(beforePartial)
        
                        // Split beforePartial into words
                        const wordsBeforePartial = beforePartial;
        
                        if (wordsBeforePartial.length > 0) {
                            const lastWordBeforePartial = wordsBeforePartial[wordsBeforePartial.length - 1];
                            const adjustedStart = start - lastWordBeforePartial.length;
                            markedText += summary.substring(adjustedStart, start-1);
                        } else {
                            markedText += summary.substring(currentIndex, start-1);
                        }
        
                        markedText += `<span style= "background: yellow">${partialText}</span>`;
                        currentIndex = end + 1;
                    }
                }
        
                currentIndex = end + 1;
            }
        
            markedText += summary.substring(currentIndex);
        
            return markedText;
        }
         

    
      // Function to escape special characters in the search value
    //   RegExp.escape = function (string) {
    //     return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    //   };
      


    const highlightedSummary_exact = (text) => {
        let keywords = Array.from(totalMatches)
        console.log("work", totalMatches, keywords)
            // keywords= ["stresses on", "bal un"]
            const regexPattern = new RegExp(`\\b(${keywords.map(keyword => keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|')})\\b`, 'gi');
            const parts = text.split(regexPattern);
            const highlightedParts = parts.map((part, index) =>
              regexPattern.test(part) ? <span key={index} style= {{background: "yellow"}} className="highlighted">{part}</span> : part
            );
            return highlightedParts;
            
      };
      
    //   // Function to escape special characters in the search value
    //   RegExp.escape = function (string) {
    //     return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    //   };
      
      const highlightedSummary = (text) =>{
        console.log(filterInfo, categories)
        if(filterInfo && filterInfo.length > 0){
            const client123Array = filterInfo.filter(item => categories.includes(item.client));
            console.log(client123Array);
            if(client123Array.length <= 0){
                return text;
            }
            else{
                console.log("jere")
                for(let i = 0; i < client123Array.length; i++){
                    console.log(categories.includes(client123Array[i]["client"]))
                    console.log(client123Array[i])
                    if(categories.includes(client123Array[i]["client"])){
                        let keyAll = client123Array[i]["keywords"];
                        let partial_keys = keyAll["partial_match_keywords"]
                        let exact_keys = keyAll["word_bound_match_keywords"]
                        // console.log(partial_keys)
                        text = highlightedSummary_partial(text)
                    }
                }
                return <div dangerouslySetInnerHTML={{ __html: text }} />;
            }
        }
        else{
            return text;
        }
      } 

      const fetch_client_keywords = async(filter_info_details, text) => {
        let response = await getCategories()
		if(response.status === 200){
		 	setCategories(response.data)
		 	let cat = response.data
            if(filter_info_details && filter_info_details.length > 0){
                 const client123Array = filter_info_details.filter(item => cat.includes(item.client));
                 console.log(client123Array);
                 if(client123Array.length <= 0){
                     console.log("No Keywords Found")
                 }
                 else{
                     console.log("here")
                     let final_partial = []
                     let final_exact = []
                     for(let i = 0; i < client123Array.length; i++){
                         console.log(cat.includes(client123Array[i]["client"]))
                         console.log(client123Array[i])
                         if(cat.includes(client123Array[i]["client"])){
                             let keyAll = client123Array[i]["keywords"];
                             final_partial = [...keyAll["partial_match_keywords"]]
                             final_exact = [...keyAll["word_bound_match_keywords"]]
                         }
                     }
                    //  perform logic for partialMatches

                    const matchingPhrasesSet = new Set();
                    
                    // Loop through each partial match
                    for (const pm of final_partial) {
                        // Create a regular expression pattern for the partial match
                        const pattern = new RegExp('\\b' + escapeRegExp(pm) + '\\w*\\b', 'gi');
                        
                        // Find all matches in the text using the pattern
                        const matches = text.match(pattern);
                        
                        // Add the found matches to the matchingPhrasesSet
                        if (matches) {
                            console.log(matches)
                            matches.forEach(match => matchingPhrasesSet.add(match));
                        }
                    }

                    // Function to escape special characters for regex
                    function escapeRegExp(string) {
                        return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
                    }
                    let matchingPhrases = []
                    matchingPhrases = Array.from(matchingPhrasesSet);

                    
                    //  setPartialMatches([...matchingPhrases]);
                    //  setExactMatches([...final_exact])
                    const mergedArray = [...final_exact, ...matchingPhrases];
                    const uniqueSet = new Set(mergedArray);
                    setTotalMatches(uniqueSet);
                    // setTotalMatches([...final_exact, ...matchingPhrases])
                 }
             }
	 	}
	 	else if(response.status === 401){
		 	localStorage.clear()
		 	Cookies.remove('token');
		 	setTimeout(() => {
			 	setRedirect(1)    
		 	}, 1000);
		 	toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
	 	}
	 	else{
		 	toast.info("server error", { position: toast.POSITION.TOP_CENTER})
		}
      }
      
      
      

    const fetch_categories = async() => {
		let response = await getCategories()
		if(response.status === 200){
			console.log("domains fetched")
		 	setCategories(response.data)
		 	console.log(response.data)
	 	}
	 	else if(response.status === 401){
		 	localStorage.clear()
		 	Cookies.remove('token');
		 	setTimeout(() => {
			 	setRedirect(1)    
		 	}, 1000);
		 	toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
	 	}
	 	else{
		 	toast.info("server error", { position: toast.POSITION.TOP_CENTER})
		}
	}

    const handle_draft_popup = async() =>{
        console.log("working")
        setIsOpen(true);
        console.log('lksjfalksfjdls')
        let response = await getDraftsList()
        console.log(response, response.data.length)
        if(response.status === 200){
            console.log("drafts fetched")
            let test_array = []
            for(let i = 0; i < response.data.length; i++){
                test_array.push([response.data[i]["draftName"], response.data[i]["_id"]])
            }

            // console.log(test_array)
            const temportary_options = test_array.map((item) => {
                return {value: item[1], label: item[0]}
                });
            console.log(temportary_options)
            
            setFetchedDrafts(temportary_options)
                // console.log(temportary_options)
            }
        else if(response.status === 401){
            localStorage.clear()
            Cookies.remove('token');
            setTimeout(() => {
                setRedirect(1)   
            }, 1000);
            toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
        }
        else{
            toast.info("server error", { position: toast.POSITION.TOP_CENTER})
        }
    }

    const handleSelectChange = async(newValue, actionMeta) => {
        console.log(newValue)
        setSelectedValue(newValue);
      };

    const handle_create_draft = async() =>{
        console.log(selectedValue)
        if(selectedValue.hasOwnProperty('__isNew__') && selectedValue.__isNew__ === true){
            let message = {
                draftName: selectedValue["value"],
                articleIds: [article_id],
                itemsPerPage: 1
            }
            let response = await createDraft(message)
            console.log(response, response.data.length)
            if(response.status === 200){
                toast.success("Draft "+ selectedValue["value"] + " Created with new Articles", { position: toast.POSITION.TOP_CENTER, autoClose: 1000});
                setRedirect(2)   
                handle_draft_popup()
            }
            else if(response.status === 401){
                localStorage.clear()
                Cookies.remove('token');
                setTimeout(() => {
                    setRedirect(1)   
                }, 1000);
                toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
            }
            else{
                toast.info("server error", { position: toast.POSITION.TOP_CENTER})
            }
        }
        else{
            let message = {
                draftId: selectedValue["value"],
                articleIds: [article_id],
            }
            let response = await AddArticleToDraft(message)
            console.log(response, response.data.length)
            if(response.status === 200){
               toast.success("Article Added to Draft "+ selectedValue["label"], { position: toast.POSITION.TOP_CENTER});
               setRedirect(2)   
               handle_draft_popup()
            }
            else if(response.status === 401){
                localStorage.clear()
                Cookies.remove('token');
                setTimeout(() => {
                    setRedirect(1)   
                }, 1000);
                toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
            }
            else{
                toast.info("server error", { position: toast.POSITION.TOP_CENTER})
            }
        }
    }

    // const fls = [{'label': 'Specific FLS', 'score': 0.7384161353111267}, {'label': 'Non-specific FLS', 'score': 0.7384161353111267},{'label': 'Not FLS', 'score': 0.7384161353111267}]


    if(redirect === 1){
        // return <Navigate to = '/login' />
        window.location.href = '/login';
    }
    if(redirect === 2){
        return <Navigate to = '/draft' />
    }
// console.log(startIndex)
  return (
    <div className='right_article_container'>
        <PageAnimations/>
        <div className="top_navigation_container">
            <h3 className="home_article_view_container">
                Home / Article View 
            </h3>
            <button className="absolute_draft btn btn-primary btn-sm" onClick={() => handle_draft_popup()}>
                {/* <img src={draft} alt="draft" className="draft" /> */}
                Push to Draft
            </button>
        </div>

        <div className="article_content_container">
            <div className="left_article_content_container">
                <div className="tags_date_container">
                    <div className="tags_container">
                        {/* <div className="tag">
                            {keyword}
                        </div> */}
                    </div>
                    <div className="date_container">
                        {date !== undefined? moment(date.slice(0, 10)).format('DD/MM/YYYY'): ''}&nbsp;&nbsp;{date !== undefined? date.slice(11, 16): ''}
                    </div>
                </div>

                <div className="article_topic_container">
                {translateTitle && <><img src={translate_icon} alt="translate_icon" className="translate_text_icon" onClick={()=>setTranslateToggleTitle(!translateToggleTitle)}/><>&nbsp;&nbsp;</></>}
                    {(translateTitle && translateToggleTitle) ? translateTitle: title }
                    {/* {title} */}
                </div>
                {
                    Array.from(totalMatches).length > 0 &&
                    <div className="article_keywords_container">
                        <div className="exact_matched_words article_keywords_container_wrapper">
                            <div className='key'>Matched Keywords</div> : 
                            {
                                Array.from(totalMatches).map((key, index) =>(
                                    <div className="keywords" key={index}>
                                        {key}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                }
                {
                    (Cookies.get("userOrg") === "SEBI" && sentiment !== "") &&
                    <div className="article_keywords_container">
                        <div className="exact_matched_words article_keywords_container_wrapper">
                            <div className='key'>Sentiment</div> : 
                                {sentiment==='NEGATIVE' && <div className="dot_negative keywords">{sentiment.charAt(0).toUpperCase() + sentiment.slice(1).toLowerCase()}</div>}
                                {sentiment==='POSITIVE' && <div className="dot keywords">{sentiment.charAt(0).toUpperCase() + sentiment.slice(1).toLowerCase()}</div>}					
                                {sentiment==='NEUTRAL' && <div className="dot_neutral keywords">{sentiment.charAt(0).toUpperCase() + sentiment.slice(1).toLowerCase()}</div>}					
                        </div>
                    </div>
                }

                <div className="source_link_container">
                    <div className="source">
                        {/* <img src={art_logo} alt="article logo" /> */}
                        {domainName}
                        <a href={articleLink} target = '_blank' rel='noreferrer'>Article Link</a>
                    </div>
                </div>

                <div className="article_summary">
                    <h4 className="summary_header header">
                        Article Summary
                    </h4>
                    <div className="summery_content">
                        <pre>{Array.from(totalMatches).length <= 0 ? summary : highlightedSummary_exact(summary, totalMatches)}</pre>
                    </div>
                </div>

                <div className="full_article">
                    <h4 className="article_header header">
                        Full Article
                    </h4>
                    <div className="full_article_content">
                        {translateText && <><img src={translate_icon} alt="translate_icon" className="translate_text_icon" onClick={()=>setTranslateToggleText(!translateToggleText)}/><>&nbsp;&nbsp;</></>}
                        <pre>{Array.from(totalMatches).length <= 0 ? ((translateToggleText && translateText) ? translateText: text)  : (translateToggleText && translateText)  ? highlightedSummary_exact(translateText, totalMatches): highlightedSummary_exact(text, totalMatches)}</pre>
                    </div>
                </div>  

                <div className="heat_map_article">
                    {pinMapData.length !== 0 ?
                    <LeafletMaps key={JSON.stringify(pinMapData)} dataForMap = {pinMapData}/>:
                    <></>}
                </div>
            </div>


            <div className="right_article_conatainer">
                {
                    (Cookies.get("userOrg") === "SEBI") &&
                    <div className="fls_container lop_containers">
                        <div className="heading">
                            Forward Looking Statements  
                        </div>
                        <hr/>
                        {   fls && fls.length !== 0 ?
                                <div className="fls_progress">
                                    {
                                        fls.map((l, index)=>(
                                            <div className='fls_data' key = {index}>
                                                <div className="fls_data_va">
                                                    
                                                <div className="progress">
                                                    <div
                                                        className="progress-bar progress_bar_manual"
                                                        role="progressbar"
                                                        style={{ width: (l.score * 100).toFixed(2) +'%' }}
                                                        aria-valuenow={(l.score * 100).toFixed(2)}
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    >
                                                        {(l.score * 100).toFixed(2) +'%'} 
                                                    </div>
                                                </div>
                                                <div className="fls_data_label">{l["label"]}</div>

                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>:
                                <div className = "no_report_type">No Relevant Modal Data</div>
                        }
                    </div>
                }
                <div className="locations_container lop_containers">
                    <div className="heading">
                        Identified Locations   
                    </div>
                    <hr/>
                    {   locations.length !== 0 ?
                            <div className="locations lops">
                                {
                                    locations.map((l, index)=>(
                                        <li key = {index}>{l}</li>
                                    ))
                                }
                            </div>:
                            <div className = "no_report_type">No Relevant Location</div>
                    }
                </div>
                <div className="organizations_container lop_containers">
                    <div className="heading">
                        Identified organizations 
                    </div>
                    <hr/>
                    {   organizations.length !== 0 ?
                        <div className="organizations lops">
                            {
                                organizations.map((o, index)=>(
                                    <li key = {index}>{o}</li>
                                ))
                            }
                        </div>:
                        <div className = "no_report_type">No Relevant Organizations</div>
                    }
                </div>
                <div className="persons_container lop_containers">
                    <div className="heading">
                        Identified persons
                    </div>
                    <hr/>
                    {   persons.length !== 0 ?
                        <div className="persons lops">
                            {
                                persons.map((p, index)=>(
                                    <li key = {index}>{p}</li>
                                ))
                            }
                        </div>:
                        <div className = "no_report_type">No Relevant Persons</div>
                    }
                </div>
            </div>
        </div>

        <div className="related_articles_container">
            {relatedArticles.length !==0 && (
                <h4 className="related_articles_header header">
                    Related Articles
                </h4>
            )}
            <div className="related_articles_cards">
                {/* {console.log(relatedArticles.length)} */}
                <button className={relatedArticles.length === 0 ? "prev_next_wrapper_hidden" :"prev_next_wrapper"} onClick={handlePrev} disabled = {startIndex === 0}>
                    <img src = {prev_btn} className = {startIndex === 0 ? "prev_next_button prev_button pre_next_faded": "prev_next_button prev_button"}  />
                </button>
                <div className="all_cards">
                {   
                    relatedArticles.slice(startIndex, startIndex + 4).map((art, index)=>(
                    // <a href={'/article/' + art._id} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#000000'}} key = {index}>
                        <a href={'/article/' + art._id} style={{ textDecoration: 'none', color: '#000000'}} className="article_card" key = {index} >
                            {/* <a href={'/article/' + art._id} style={{ textDecoration: 'none', color: '#000000'}}> */}
                            {/* <div className="card_text"> */}
                                <div className="card_title card_contents">
                                    {art.Title}
                                </div>
                                <div className="card_domain_name card_contents">
                                    {art.domain_name}
                                </div>
                                <div className="card_published_date card_contents">
                                    {/* {art.published_date} */}
                                    {(moment(art.published_date.slice(0, 10)).format('DD/MM/YYYY'))}
                                </div>
                            {/* </div> */}
                        </a>
                        // </div>
                    ))
                } 
                </div>
                <button className={relatedArticles.length === 0 ? "prev_next_wrapper_hidden" :"prev_next_wrapper"} disabled = {startIndex >= relatedArticles.length - 4} onClick={handleNext}>  
                    <img src = {prev_btn} className = {startIndex >= relatedArticles.length - 4 ? "prev_next_button next_button pre_next_faded": "prev_next_button next_button"} />
                </button>
            </div>
        </div>
			<ToastContainer />
            <div className="filter_popup">
                <ReactModal
                    isOpen={isOpen}
                    onRequestClose={closeModal}
                    contentLabel="Popup Modal"
                    style={customStyles}
                >	
                <div className="article_draft_individual_popup_content">
                    <div className="article_draft_popup_header">
                        Add Article to Draft
                    </div>
                    <div className="article_draft_search_bar_container">
                        <CreatableSelect
                            // className='abcabc'
                            classNamePrefix="custom-dropdown"
                            options={fetchedDrafts}
                            placeholder="Select Existing Draft or Create New"
                            isClearable
                            value={selectedValue}
                            onChange={handleSelectChange}
                            styles={{
                                menu: (provided) => ({
                                  ...provided,
                                    maxHeight: '20vh', // Set the desired height for the dropdown
                                  overflowY: 'hidden',  // Enable scrolling if options exceed maxHeight
                                }),
                              }}
                    
                        />
                    </div>
                    <div className="article_add_draft_button">
                        <button className="cancel btn btn-light" onClick={()=>closeModal()}>
                            Cancel
                        </button>
                        <button className="button_main btn btn-primary" onClick={()=>handle_create_draft()} disabled = {selectedValue?false:true}>
                            Add to Draft
                        </button>
                    </div>
                </div>
                </ReactModal>	
            </div>	
    </div>
  )
}