import React from 'react'
import '../../staticFiles/cssFiles/error.css'
import img1 from '../../staticFiles/images/error-404.svg'

export const Error = () => {
  return (
    <div className = "Error404_container">
        <img src={img1} alt="404" className="404_svg" />
    </div>
  )
}
