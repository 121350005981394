import React from 'react'
// import { useParams } from 'react-router-dom'
import { LeftDashboard } from '../Dashboard/left_dashboard'
import { RightArticleView } from './rightArticleView'
import '../../staticFiles/cssFiles/Article/article.css'
import Cookies from 'js-cookie'
import { Navigate } from 'react-router-dom'

export const Article = () => {
  // const { article_id } = useParams();
  // // console.log(id)
  // React.useEffect(()=>{
  //   console.log(article_id)
  // }, [article_id]);

  if(Cookies.get('token') === undefined){
      console.log("working")
      // return <Navigate to  = "/login" />
      window.location.href = '/login';
  }

  return (
    <div className='article_container'>
        <LeftDashboard id = {"1"}/>    
        <RightArticleView/>
    </div>
  )
}
