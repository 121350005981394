import React from 'react'
import {LeftDashboard} from "../Dashboard/left_dashboard"
import {RightRootDashboard} from "./rightRootDashboard"
import "../../staticFiles/cssFiles/rootDashboard/rootDashboard.css"
import { Navigate } from 'react-router-dom'
import Cookies from 'js-cookie'

export const RootDashboard = () => {
  if(Cookies.get('token') === undefined){
    // return <Navigate to  = "/login" />
    window.location.href = '/login';
}
  return (
    <div className='root_dashboard_container'>
      <LeftDashboard id = {'1'}/>
      <RightRootDashboard/>
    </div>
  )
}
