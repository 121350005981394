import axios from "axios";
export const validateToken = async(stoken) =>{

    console.log(process.env.REACT_APP_SERVER_HOST+'/api/v1/auth/validateToken')

    const config = {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${stoken}`,
        }
      };  

    let data_rec = {}
    await axios.get(process.env.REACT_APP_SERVER_HOST+'/api/v1/auth/validateToken', config)
        .then(response => {
            console.log('axios_inside', response.data)
            data_rec['status'] = response.status
        })
        
        .catch(error => {
            console.log(error)
            data_rec['status'] = error.response.status;
        });
        return data_rec;
}