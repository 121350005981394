import React from 'react'
import '../../staticFiles/cssFiles/Draft/draft.css'
import Cookies from 'js-cookie' 
// import { Navigate } from 'react-router-dom'
import { RightDraft } from './right_draft'
import { LeftDashboard } from '../Dashboard/left_dashboard'

export const Draft = () => {

  if(Cookies.get('token') === undefined){
    // return <Navigate to = "/login" /> 
    window.location.href = '/login';
  }
  return (
    <div className='draft_container'>
        <LeftDashboard id={'7'}/>
        <RightDraft />
    </div>
  )
}
