import axios from 'axios'

export const send_user_cred = async(message) =>{
    console.log("inside2");
    const config = {
      headers: {
          'Access-Control-Allow-Origin': '*',
          'Content_Type': 'application/json',
      }
    };
    let data_rec = {}
    await axios.post("http://localhost:5000/getCreds", message, config)
        .then(response => {
            data_rec = response.data;
            console.log(response.data)
        })
        
        .catch(error => {
            data_rec['status'] = '500';
            console.log(error)
        });
        return data_rec;
}

export const check_user_cred = async(message) =>{
    console.log("inside2");
    const config = {
      headers: {
          'Access-Control-Allow-Origin': '*',
          'Content_Type': 'application/json',
      }
    };
    let data_rec = {}
    await axios.post("http://localhost:5000/checkCreds", message, config)
        .then(response => {
            data_rec = response.data;
            console.log(response.data)
        })
        
        .catch(error => {
            data_rec['status'] = '500';
            console.log(error)
        });
        return data_rec;
}

export const decodeId = async() =>{
    let stoken = localStorage.getItem("token")
    const message = { token: stoken}
    const config = {
        headers: {
            "Authorization": `Bearer ${stoken}`,
            'Content_Type': 'application/json'
        }
    };
    let data_rec = {}
    await axios.post("http://localhost:5000/decodeId", message, config)
        .then(response => {
            data_rec = response.data
            console.log(data_rec)
        })
        
        .catch(error => {
            data_rec['status'] = '500';
            console.log(error)
        });
        return data_rec;
}

export const logout = async() =>{
    let stoken = localStorage.getItem("token")
    const message = { token: stoken }
    const config = {
        headers: {
            "Authorization": `Bearer ${stoken}`,
            'Content_Type': 'application/json'
        }
    };
    let data_rec = {}
    await axios.post("http://localhost:5000/logout", message, config)
        .then(response => {
            data_rec = response.data
            console.log(data_rec)
        })
        
        .catch(error => {
            data_rec['status'] = '500';
            console.log(error)
        });
        return data_rec;
}