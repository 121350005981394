import React from 'react'
import { RightReportGenTracker } from './rightReportGenTracker'
import "../../staticFiles/cssFiles/reportGenTracker/reportGenTracker.css"
import { LeftDashboard } from '../Dashboard/left_dashboard'

export const ReportGenTracker = () => {
  return (
    <div className='report_gen_tracker_container'>
        <LeftDashboard id={'4_3'}/>
        <RightReportGenTracker />
    </div>
  )
}
