import axios from 'axios'


function parseCookies(setCookieHeader) {
    const cookies = {};
    setCookieHeader.split(';').forEach(cookie => {
      const parts = cookie.split('=');
      cookies[parts[0].trim()] = parts[1].trim();
    });
    return cookies;
  }

export const handle_auth = async(message) =>{
    let data_rec = {}
    await axios.post(process.env.REACT_APP_SERVER_HOST+ "/api/v1/auth/login", message, { withCredentials: true })
        .then(response => {
            console.log(response, response.data.jwt, "abc")
            // console.log(response.headers['set-cookie'])
            data_rec = response.data;
            data_rec["data"] = response.data;
            data_rec["status"] = response.status;
        })
        
        .catch(error => {
            console.log(error)
            data_rec["data"] = error.response.data;
            data_rec["status"] = error.response.status;
        });
        return data_rec;
}


