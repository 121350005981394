import React from 'react'
import "../../staticFiles/cssFiles/factCheck/rightFactCheck.css"
import { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import Cookies from 'js-cookie'
import {factCheckArticles} from "../../endppoints/endpoint"
import loading from "../../staticFiles/images/loading.svg"
import no_result_logo from "../../staticFiles/images/No_results.svg"
import { PageAnimations } from '../GlobalPageAnimations.jsx/pageAnimations';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment/moment'
import { Navigate } from 'react-router-dom'
import { Checkbox, Pagination, Tooltip, Stack } from '@mui/material';
import translate_icon from "../../staticFiles/images/translate_icon.svg"
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../store'

export const RightFactCheck = () => {
    // const [fact_promptQuery, setPromptQuery] = useState("")
    // const [fact_page, setPage] = useState(1)
    // const [fact_limitPerPage, setLimitPerPage] = useState(10)
    // const [fact_totalData, setTotalData] = useState(0)
    // const [fact_articles, setArticles] = useState([])
    // const [fact_totalPage, setTotalPage] = useState(0)
    // const [fact_redirect, setRedirect] = useState(0)
    // const [fact_factCheckBool, setFactCheckBool] = useState(null)
    // const [fact_map, setMap] = useState(0)
    // const [fact_articleId, setArticleId] = useState(null)
    // const [fact_translateToggle, setTranslateToggle] = useState([])

    const dispatch = useDispatch()
	const {updateStates} = bindActionCreators(actionCreators, dispatch);

    const {
        fact_promptQuery,
        fact_page,
        fact_limitPerPage,
        fact_totalData,
        fact_articles,
        fact_totalPage,
        fact_redirect,
        fact_factCheckBool,
        fact_map,
        fact_articleId,
        fact_translateToggle
    }=useSelector(state => state.factCheckState);


    const check_error = (response) =>{
        if(response.status === 401){
            localStorage.clear()
            Cookies.remove('token');
            setTimeout(() => {
                updateStates({
                    "type": "factCheckReducerUpdates",
                    "payload": {
                        fact_redirect: 1
                    }
                })    
            }, 1000);
            toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
        }
        else{
            toast.info("server error", { position: toast.POSITION.TOP_CENTER})
        }
    }

    const get_fact_check_data = async(message) =>{
        updateStates({
            "type": "factCheckReducerUpdates",
            "payload": {
                fact_map: 2
            }
        })  
        let response = await factCheckArticles(message)
        console.log(response)
        if(response.status === 200){
            updateStates({
                "type": "factCheckReducerUpdates",
                "payload": {
                    fact_map: 1
                }
            })  
            if(response.data.factCheckResult === false){
                updateStates({
                    "type": "factCheckReducerUpdates",
                    "payload": {
                        fact_articles: [],
                        fact_totalData: 0,
                        fact_factCheckBool: false,
                        fact_totalPage: 0
                    }
                })  
            }
            else{
                updateStates({
                    "type": "factCheckReducerUpdates",
                    "payload": {
                        fact_articles: response.data.data,
                        fact_totalData: response.data.totalCount,
                        fact_factCheckBool: response.data.factCheckBool
                    }
                })  
                console.log("2")
                if(response.data['totalCount'] % fact_limitPerPage === 0){
                    console.log("here1")
                    updateStates({
                        "type": "factCheckReducerUpdates",
                        "payload": {
                            fact_totalPage: Math.floor(response.data['totalCount'] / fact_limitPerPage)
                        }
                    })  
                }
                else{
                    console.log("here2")
                    updateStates({
                        "type": "factCheckReducerUpdates",
                        "payload": {
                            fact_totalPage: (Math.floor(response.data['totalCount'] / fact_limitPerPage) + 1)
                        }
                    })  
                }
                console.log("here3")
            }
        }
        else {
            console.log("here4")
            check_error(response)
        }
    }
    const handle_fact_check_on_enter = (event) => {
	    if (event.key === 'Enter'){
		    handle_fact_check_prompt()
	    }
    }
    const handle_fact_check_prompt = () =>{
        // if (event.key === 'Enter'){
			if(fact_promptQuery === ''){
				toast.error("Please enter some prompt to check facts", { position: toast.POSITION.TOP_CENTER});
			}
			else{
                updateStates({
                    "type": "factCheckReducerUpdates",
                    "payload": {
                        fact_page: 1
                    }
                })  
				const message = {
					"pageNo": fact_page,
					"itemsPerPage": fact_limitPerPage,
					"query": fact_promptQuery,
                    "interfaceCall": true
				}
				get_fact_check_data(message)
			}
		// }
    }

    const handlePage = (event, pg) =>{
        updateStates({
            "type": "factCheckReducerUpdates",
            "payload": {
                fact_page: pg
            }
        })  
        let message = {
            "pageNo": pg,
            "itemsPerPage": fact_limitPerPage,
            "query": fact_promptQuery,
            "interfaceCall": true
        }      
        get_fact_check_data(message)
    }   

    const handleToggleTranslate = (index) =>{
        const updatedArray = [...fact_translateToggle];
        updatedArray[index] = !fact_translateToggle[index]; 
        updateStates({
            "type": "factCheckReducerUpdates",
            "payload": {
                fact_translateToggle: updatedArray
            }
        })  
    }

    const navArticle =(art_id)=>{
        updateStates({
            "type": "factCheckReducerUpdates",
            "payload": {
                fact_articleId: art_id,
                fact_redirect: 2
            }
        }) 
	}
    
    if(fact_redirect === 1){
        window.location.href = '/login';
    }
    if(fact_redirect === 2){
		return <Navigate to = {'/article/' + fact_articleId} />
        // window.location.href = '/article/' + fact_articleId
	}

    return (
        <div className='right_fact_check_container'>
            <PageAnimations/>
            <div className="prompt_container">
                {/* <div className="prompt_header">
                    Check Facts
                </div> */}
                <div className="prompt_container_main">                    
                    <textarea rows="2" cols="50" type="text" value={fact_promptQuery} className="input_prompt" placeholder='Type the prompt here' onChange={(e)=>
                            updateStates({
                                "type": "factCheckReducerUpdates",
                                "payload": {
                                    fact_promptQuery: e.target.value
                                }
                            }) 
                        } onKeyDown={(e) => handle_fact_check_on_enter(e)}
                        >
                    </textarea>
                    <button className="btn btn-primary check_button" onClick={() => handle_fact_check_prompt()}>
                        check
                    </button>
                </div>
            </div>
            {fact_factCheckBool !== null && 
                <div className="fact_check_result_display">
                        <div className="fact_check_result">
                            <div className="fact_check_key">
                                {fact_factCheckBool ? 
                                    "Verified" : 
                                    "Unverified"
                                } 
                            </div>
                            : 
                            <div className="fact_check_value">
                                {fact_factCheckBool ? 
                                    fact_totalData +" sources found" : 
                                    "Not Enough Data Available"
                                } 
                            </div>
                        </div>
                </div>
            }
            {/* {fact_factCheckBool !== null && 
                <div className="fact_check_result_display">
                    <div className="fact_check_result">
                        SECTION UNDER DEVELOPMENT
                    </div>
                </div>
            } */}
            <div className="fact_articles_container">
                <div className="table_container">
                    {   fact_map === 0 ?
                        <div className='no_results_container'>
                            <img src={no_result_logo} alt="" className="no_results child_no_results" /> 
                            <div className="no_results_header child_no_results">
                                Please use the prompt to get results
                            </div>
                        </div>: 
                        fact_map === 1 ? 
                        fact_articles.length === 0? 
                        
                            <div className='no_results_container '> 
                                <img src={no_result_logo} alt="" className="no_results child_no_results" /> 
                                <div className="no_results_header child_no_results">
                                    No results found
                                </div>
                            </div> :

                            <TableContainer component={Paper} sx={{  height: '100%' , boxShadow: '0px 20px 50px 0px #0000001A', borderRadius: '8px 8px 0px 0px', backdropFilter: 'blur(10px)', background: "transparent"}}>
                                
                                <Table stickyHeader>
                                    <TableHead sx = {{ fontFamily: "Inter"}}>
                                        <TableRow>
                                            <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px', width: "45%"}} align="left">Article</TableCell>
                                            <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px', width: "30%", paddingLeft: "5vw"}} align="left">Date</TableCell>
                                            <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px', width: "25%"}} align="left">Region</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody sx={{ height: "max-content" }}>
                                        {fact_articles && fact_articles.map((row, index) => (
                                            <TableRow 
                                                key={row._id}
                                                sx={{ background: 'rgba(233, 233, 233, 0.35)', cursor: "pointer", '&:hover': {borderLeft: "4px solid #3B89FE !important"}}}
                                                    onClick={(event) => {
                                                        navArticle(row._id);
                                                    }
                                                }
                                                >	
                                                <TableCell component="th" scope="row" 
                                                    sx = {{fontFamily: "Inter",fontSize: '16px', fontWeight: "600", '&:hover': {borderLeft: "4px solid #3B89FE !important"}}}  
                                                    >
                                                    {row.Title_translated && <><img src={translate_icon} alt="translate_icon" className="translate_text_icon" onClick={(e)=>{e.stopPropagation();handleToggleTranslate(index);}}/><>&nbsp;&nbsp;</></>}
                                                    {(row.Title_translated && fact_translateToggle[index]) ? 
                                                        <>{row.Title_translated.slice(0, 88)}{(row.Title_translated.length <= 88 ? '' : '...')}</>
                                                        : 
                                                        <>{row.Title.slice(0, 88)}{(row.Title.length <= 88 ? '' : '...')}
                                                        </>
                                                    }
                                                    <div className="domain_name" style={{fontSize: "small"}}>
                                                        {row.source_name}
                                                    </div>
                                                    
                                                </TableCell>
                                                <TableCell align="left" 
                                                    sx = {{ fontFamily: "Inter", fontSize: '16px', paddingLeft: "5vw" }}>
                                                        
                                                        <div>{(moment(row.published_date.slice(0, 10)).format('DD/MM/YYYY'))}&nbsp;&nbsp;{row.published_date.slice(11, 16)}</div>
                                                        
                                                </TableCell>
                                                <TableCell align="left" 
                                                    sx = {{ fontFamily: "Inter", fontWeight: "600" ,fontSize: '16px' }}>
                                                    <Tooltip title={ 
                                                        <div style={{ backgroundColor: '#ffffff', fontFamily: "Inter", fontWeight: "600" ,fontSize: '18px', color: "#000000", padding: "1vh 1vh 0vh 0vh", overflowY: "auto", maxHeight: "30vh"}}>
                                                            <ul >
                                                                {row.keywords_p.Location.map((region, index) => (
                                                                <li key={index}>{region}</li>
                                                                ))}
                                                            </ul>
                                                        </div>		
                                                        } placement="top-start" arrow  >
                                                          
                                                        <div>
                                                            {row.keywords_p.Location.length !== 0 ? (row.keywords_p.Location.length > 1 ? row.keywords_p.Location[0] + " ..." : row.keywords_p.Location[0]) : "No Regions"}
                                                        </div>
                                                    </Tooltip>	
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>:
                        fact_map === 2 ? <div style={{display: "flex" , alignItems: "center", justifyContent: "center", height: '65vh'}}><img src={loading} alt="laoding svg" className="loading" /></div>: 
                        <div>some error ....try refreshing the fact_page</div>   
                    }

                    {	fact_articles.length === 0 ? <></>:
                            <div className="pagination_container">
                                <div className="display_pages">
                                    Showing &nbsp;<span>{fact_totalPage !== 0 ? (fact_page-1)*fact_limitPerPage + 1 : 0}</span>-<span>{(fact_page-1)*fact_limitPerPage + fact_articles.length}</span>&nbsp;from&nbsp;<span>{fact_totalData}</span>&nbsp;data 	
                                </div>
                                <div className="nav_container">
                                    <Stack spacing={2}>
                                        <Pagination count={fact_totalPage} fact_page = {fact_page} color='primary' value={fact_page} onChange={handlePage} variant="outlined" sx={{fontFamily: "Inter"}}/>
                                    </Stack>
                                </div>
                            </div>
                    }
                </div>
            </div>
            <ToastContainer/>    
        </div>
  ) 
}
