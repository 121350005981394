import React, { useState } from 'react';
import { ComposableMap, Geographies, Geography, ZoomableGroup } from 'react-simple-maps';
import { scaleQuantile } from 'd3-scale';
import {Tooltip as ReactTooltip} from 'react-tooltip';

import LinearGradient from './LinearGradient.js';
import '../../staticFiles/cssFiles/test.css'
import { Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/index.js';

/**
* Courtesy: https://rawgit.com/Anujarya300/bubble_maps/master/data/geography-data/india.topo.json
* Looking topojson for other countries/world? 
* Visit: https://github.com/markmarkoh/datamaps
*/
const INDIA_TOPO_JSON = require('./india.topo.json');

const PROJECTION_CONFIG = {
  scale: 350,
  center: [78.9629, 22.5937] // always in [East Latitude, North Longitude]
};

// Red Variants
// const COLOR_RANGE = [
//   '#ffedea',
//   '#ffcec5',
//   '#ffad9f',
//   '#ff8a75',
//   '#ff5533',
//   '#e2492d',
//   '#be3d26',
//   '#9a311f',
//   '#782618'
// ];

const COLOR_RANGE = [
	'#e6f7ff',
	'#bae7ff',
	'#91d5ff',
	'#69c0ff',
	'#40a9ff',
	'#1890ff',
	'#096dd9',
	'#0050b3',
	'#003a8c'
  ];

const DEFAULT_COLOR = '#EEE';

// const getRandomInt = () => {
//   return parseInt(Math.random() * 100);
// };

const geographyStyle = {
  default: {
    outline: 'none',
	stroke: 'white', 
	strokeWidth: '0.2'
  },
  hover: {
    // fill: '#808080',
    transition: 'all 250ms',
    outline: 'none',
    stroke: 'black',
    strokeWidth: 1,
  },
  pressed: {
    outline: 'none'
  }
};

// will generate random heatmap data on every call
// const getHeatMapData = (ar) => {
//   console.log(ar)
//   // return [
//   //   { id: 'AP', state: 'Andhra Pradesh', value: getRandomInt() },
//   //   { id: 'AR', state: 'Arunachal Pradesh', value: getRandomInt() },
//   //   { id: 'AS', state: 'Assam', value: getRandomInt() },
//   //   { id: 'BR', state: 'Bihar', value: getRandomInt() },
//   //   { id: 'CT', state: 'Chhattisgarh', value: getRandomInt() },
//   //   { id: 'GA', state: 'Goa', value: 21 },
//   //   { id: 'GJ', state: 'Gujarat', value: 22 },
//   //   { id: 'HR', state: 'Haryana', value: getRandomInt() },
//   //   { id: 'HP', state: 'Himachal Pradesh', value: 24 },
//   //   { id: 'JH', state: 'Jharkhand', value: 26 },
//   //   { id: 'KA', state: 'Karnataka', value: 27 },
//   //   { id: 'KL', state: 'Kerala', value: getRandomInt() },
//   //   { id: 'MP', state: 'Madhya Pradesh', value: getRandomInt() },
//   //   { id: 'MH', state: 'Maharashtra', value: getRandomInt() },
//   //   { id: 'MN', state: 'Manipur', value: getRandomInt() },
//   //   { id: 'ML', state: 'Meghalaya', value: 59 },
//   //   { id: 'MZ', state: 'Mizoram', value: getRandomInt() },
//   //   { id: 'NL', state: 'Nagaland', value: 59 },
//   //   { id: 'OR', state: 'Odisha', value: 59 },
//   //   { id: 'PB', state: 'Punjab', value: getRandomInt() },
//   //   { id: 'RJ', state: 'Rajasthan', value: getRandomInt() },
//   //   { id: 'SK', state: 'Sikkim', value: getRandomInt() },
//   //   { id: 'TN', state: 'Tamil Nadu', value: getRandomInt() },
//   //   { id: 'TG', state: 'Telangana', value: getRandomInt() },
//   //   { id: 'TR', state: 'Tripura', value: 14 },
//   //   { id: 'UT', state: 'Uttarakhand', value: getRandomInt() },
//   //   { id: 'UP', state: 'Uttar Pradesh', value: 15 },
//   //   { id: 'WB', state: 'West Bengal', value: 17 },
//   //   { id: 'WB', state: 'West Bengal', value: 17 },
//   //   { id: 'AN', state: 'Andaman and Nicobar Islands', value: getRandomInt() },
//   //   { id: 'CH', state: 'Chandigarh', value: getRandomInt() },
//   //   { id: 'DN', state: 'Dadra and Nagar Haveli', value: 19 },
//   //   { id: 'DD', state: 'Daman and Diu', value: 20 },
//   //   { id: 'DL', state: 'Delhi', value: 59 },
//   //   { id: 'JK', state: 'Jammu and Kashmir', value: 25 },
//   //   { id: 'LA', state: 'Ladakh', value: getRandomInt() },
//   //   { id: 'LD', state: 'Lakshadweep', value: getRandomInt() },
//   //   { id: 'PY', state: 'Puducherry', value: getRandomInt() }
//   // ];
//   // return ar;
//   return [];
// };

export const Test = (props) => {

	const dispatch = useDispatch()
	const {updateStates} = bindActionCreators(actionCreators, dispatch);

    const {
        gradData
    } = useSelector(state => state.dashboardState);

	const [tooltipContent, setTooltipContent] = useState('');
	const [data, setData] = useState([]);
	const [currentState, setCurrentState] = useState('')
	const [redirect, setRedirect] = useState(0)
	const [date1, setDate1] = useState('')
	const [date2, setDate2] = useState('')
  
  React.useEffect(()=>{
    setData(props.datamap)
	setDate1(props.date1)
	setDate2(props.date2)
	updateStates({
		"type": "reducerUpdates",
		"payload": {
			"gradData": {
				fromColor: COLOR_RANGE[0],
				toColor: COLOR_RANGE[COLOR_RANGE.length - 1],
				min: 0,
				max: props.datamap.reduce((max, item) => (item.value > max ? item.value : max), 0)
			}
		}
	})
  }, [props.datamap, props.date1, props.date2]);
  

  	const notify = (error) => {
		toast.error("No relevant articles found within " + error, { position: toast.POSITION.BOTTOM_RIGHT,});
	}

	const gradientData = {
	  fromColor: COLOR_RANGE[0],
	  toColor: COLOR_RANGE[COLOR_RANGE.length - 1],
	  min: 0,
	  max: data.reduce((max, item) => (item.value > max ? item.value : max), 0)
	};
  
	const colorScale = scaleQuantile()
	  .domain(data.map(d => d.value))
	  .range(COLOR_RANGE);
  
	const onMouseEnter = (geo, current = { value: 'NA' }) => {
	  return () => {
		setTooltipContent(`${geo.properties.name}: ${current.value}`);
	  };
	};
  
	const onMouseLeave = () => {
	  setTooltipContent('');
	};

	const handleGeo = (current, geo) =>{
		setCurrentState(current)
		if(current === undefined){
			notify(geo.properties.name)
		}
		else{
			setRedirect(1)
		}
	}

	const handleFilter = ({ constructor: { name } }) => {
		return name !== "MouseEvent";
	  };

	if(redirect === 1){
		return <Navigate to = {"/dashboard/" + currentState.state + "/" + date1 + "/" + date2} />
	}

    return (
      	<div className="full-width-height container">
        
			{/* <h1 className="no-margin center">Heatmap Analysis</h1> */}
			{/* <ReactTooltip>{tooltipContent}</ReactTooltip> */}
		
			<ComposableMap
				projectionConfig={PROJECTION_CONFIG}
				projection="geoMercator"
				width={300}
				height={220}
				data-tip=""
			>
				<ZoomableGroup
					// filterZoomEvent={handleFilter}
				>
					<Geographies geography={INDIA_TOPO_JSON}>
						{({ geographies }) =>
						// iterating india.topo.json 
							geographies.map((geo, projection) => {

								// get the data(a number here) for a particular geography by matching the id
								const current = data.find(s => s.id === geo.id);
								return (
								<Geography
									key={geo.rsmKey}
									geography={geo}
										projection={ projection }
									fill={current ? colorScale(current.value) : DEFAULT_COLOR}
									style={geographyStyle}
									onMouseEnter={onMouseEnter(geo, current)}
									onMouseLeave={onMouseLeave}
									// onClick = {()=>handleGeo(current, geo)}
								/>
								);
							})
						}
					</Geographies>
				</ZoomableGroup>
			
			</ComposableMap>
			<div className="footer_container" style={{display: "flex"}}>

				{/* <LinearGradient data={gradientData} /> */}
				{/* <div className="center">
				<button className="mt16" onClick={onChangeButtonClick}>Change</button>
				</div> */}
				<ReactTooltip style={{marginLeft: "auto", marginTop: "auto", background: "#808080", color : "#FFFFFF", paddingInline: "3vw", borderRadius: "6px"}}>{tooltipContent}</ReactTooltip>

			</div>

			<ToastContainer />
      </div>
    );
}