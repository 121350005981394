import React from 'react'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import '../../staticFiles/cssFiles/Draft/right_draft.css'
import { cloneDraft, createDraft, deleteDraft, fetchDraft, renameDraft } from '../../endppoints/endpoint';
import { Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'js-cookie';
import moment from 'moment';
// import deleteIcon from "../../staticFiles/images/deleteIcon.svg"
import newDraft from "../../staticFiles/images/new_draft.svg"
import ReactModal from 'react-modal';
import sortDropdownLogo from "../../staticFiles/images/sort_dropdown_logo.svg"
import searchIcon from "../../staticFiles/images/searchIcon.svg"
import sortUp from "../../staticFiles/images/sortUp.svg"
import sortDown from "../../staticFiles/images/sortDown.svg"
import draftArticleIcon from "../../staticFiles/images/draft_article_icon.svg"
// import { PageAnimations } from '../GlobalPageAnimations.jsx/pageAnimations';

import { useDispatch } from 'react-redux';
import { actionCreators } from '../../store';
import { bindActionCreators } from 'redux';

import edit_profile_key from "../../staticFiles/images/edit_org_keywords.svg"

export const RightDraft = () => {

	const dispatch = useDispatch()
    const {updateStates} = bindActionCreators(actionCreators, dispatch);

	const [status, setStatus] = React.useState(0)
	const [totalPage, setTotalPage] = React.useState(0)
	const [totalData, setTotalData] = React.useState(0)
	const [currData, setCurrData] = React.useState(null)
	const [page, setPage] = React.useState(1)
	const [searchText, setSearchText] = React.useState("")
	const [draftItems, setDraftItems] = React.useState([])
	const [limitPerPage, setLimitPerPage] = React.useState(9)
	const [articleId, setArticleId] = React.useState(null)
	const [selectedValue, setSelectedValue] = React.useState(null) 
	const [isOpenDraft, setIsOpenDraft] = React.useState(false)
	const [sortDrop, setSortDrop] = React.useState(false)
	const [draftSearch, setDraftSearch] = React.useState("")
	const [sortField, setSortField] = React.useState("updatedTime")
	const [sortDirection, setSortDirection] = React.useState(-1)
	const [selectedDraftRenameValue, setSelectedDraftRenameValue] = React.useState(null)
	const [isOpenDraftRename, setIsOpenDraftRename] = React.useState(false)
	const [renameDraftId, setRenameDraftId] = React.useState("")

	React.useEffect(()=>{
		let message = {
			"pageNo": page,
			"itemsPerPage": limitPerPage,
			"searchText": searchText,
			"sortField": sortField,
			"sortDirection": sortDirection	
		}
		fetch_drafts(message)
	}, []);

	const closeModalDraft = () => {
		setSelectedValue("")
		setIsOpenDraft(false);
	};

	const closeModalDraftRename = () => {
		setSelectedDraftRenameValue("")
		setIsOpenDraftRename(false);
	};

	const customStylesDraft = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			minWidth: '30%',
			minHeight: '30%',
			background: '#FFFFFF',
			borderRadius: "14px",
			paddingTop: '5vh',
			paddingBottom: '5vh',
			paddingLeft: '5vw',
			paddingRight: '5vw'
		},
		overlay: {
			backgroundColor: 'rgba(0, 0, 0, 0.71)',
            backdropFilter: "blur(2.5px)",
            zIndex: 1
		},
		};

	const fetch_drafts = async(message)=>{

		updateStates({
            "type": "reducerUpdates",
            "payload": {
                status: 0
            }
        })

		let response = await fetchDraft(message);
		if(response.status === 200){
			console.log(response.data.data)
			// setAppliedCategory(response.data.defaultCategory)
			setDraftItems(response.data.data)
			setTotalData(response.data.totalCount)
			setCurrData(response.data.data.length)

			if(response.data['totalCount'] % limitPerPage === 0){
				setTotalPage(Math.floor(response.data['totalCount'] / limitPerPage))
			}
			else{
				setTotalPage(Math.floor(response.data['totalCount'] / limitPerPage) + 1)
			}
		}
		else if(response.status === 401){
			localStorage.clear()
			Cookies.remove('token');
			setTimeout(() => {
				setStatus(2)    
			}, 1000);
			toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
		}
		else{
			toast.info("server error", { position: toast.POSITION.TOP_CENTER})
		}
	}

	// const handleDeleteDraft = async(id) =>{
	// 	let response = await deleteDraft(id)
	// 	if(response.status === 200){
	// 		console.log(response.data)
	// 		toast.error("Draft Deleted", { position: toast.POSITION.TOP_CENTER, autoClose: 1500});
	// 		let message = {
	// 			"pageNo": page,
	// 			"itemsPerPage": limitPerPage,
	// 			"searchText": searchText
	// 		}
	// 		fetch_drafts(message)
	// 	}
	// 	else if(response.status === 401){
	// 		localStorage.clear()
	// 		Cookies.remove('token');
	// 		setTimeout(() => {
	// 			setStatus(2)    
	// 		}, 1000);
	// 		toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
	// 	}
	// 	else{
	// 		toast.info("server error", { position: toast.POSITION.TOP_CENTER})
	// 	}
	// }

	const handlePage = (event, value) =>{
		setPage(value)
		console.log(value, page)
		const message = {
			"pageNo": value,
			"itemsPerPage": limitPerPage,
			"searchText": searchText,
			"sortField": sortField,
			"sortDirection": sortDirection
		}
		fetch_drafts(message);
	}

	const handleArticlesRedirect = (row) =>{
		if(row["totalArticlesCount"] === 0){
			toast.info("Please add articles to draft for preview", { position: toast.POSITION.TOP_CENTER, autoClose: 1000});
		}
		else{
			setArticleId(row["_id"]);
			window.open(window.location.origin + '/draft/articles/' + row["_id"], '_blank', 'noreferrer');
		}
	}

	const handleSearch = (e) =>{
		console.log(e.target.value)
		setPage(1)
		setDraftSearch(e.target.value)
		let message = {
			"pageNo": 1,
			"itemsPerPage": limitPerPage,
			"searchText": e.target.value,
			"sortField": sortField,
			"sortDirection": sortDirection	
		}
		fetch_drafts(message)
	}

	const handle_create_draft = async() =>{
        console.log(selectedValue)
		let message = {
			draftName: selectedValue,
			articleIds: []
			// itemsPerPage: 1
		}
		let response = await createDraft(message)
		console.log(response, response.data.length)
		if(response.status === 200){
			toast.success("Draft "+ selectedValue + " Created.", { position: toast.POSITION.TOP_CENTER, autoClose: 1000});
			setStatus(3)
			// if()
			let message = {
				"pageNo": page,
				"itemsPerPage": limitPerPage,
				"searchText": searchText,	
				"sortField": sortField,
				"sortDirection": sortDirection
			}
			fetch_drafts(message)
			setIsOpenDraft(false)
		}
		else if(response.status === 401){
			localStorage.clear()
			Cookies.remove('token');
			setTimeout(() => {
				setStatus(2)   
			}, 1000);
			toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
		}
		else{
			toast.info("server error", { position: toast.POSITION.TOP_CENTER})
		}
    }

	const handle_rename_draft = async() => {
		let message = {
			draftName: selectedDraftRenameValue,
			draftId: renameDraftId
		}
		let response = await renameDraft(message)
		if(response.status === 200){
			let message = {
				"pageNo": page,
				"itemsPerPage": limitPerPage,
				"searchText": searchText,
				"sortField": sortField,
				"sortDirection": sortDirection
			}
			fetch_drafts(message)
			setIsOpenDraftRename(false)
		}
		else if(response.status === 401){
			localStorage.clear()
			Cookies.remove('token');
			setTimeout(() => {
				setStatus(2)   
			}, 1000);
			toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
		}
		else{
			toast.info("server error", { position: toast.POSITION.TOP_CENTER})
		}
	}

	const handle_sort = (val) =>{
		setSortField(val)
		setPage(1)
		let message = {}
		if(val === "draftName"){
			message = {
				"pageNo": 1,
				"itemsPerPage": limitPerPage,
				"searchText": searchText,
				"sortField": val,
				"sortDirection": 1	
			}
		}
		else{
			message = {
				"pageNo": 1,
				"itemsPerPage": limitPerPage,
				"searchText": searchText,
				"sortField": val,
				"sortDirection": sortDirection	
			}
		}
		fetch_drafts(message)
	}

	const handleCloneDraft = async(id) =>{		
		let message = {
				"draftId": id,
				"itemsPerPage":""
		}

		let response = await cloneDraft(message)
		if(response.status === 200){
			toast.success("Draft Cloned.", { position: toast.POSITION.TOP_CENTER, autoClose: 1000});
			message = {
				"pageNo": page,
				"itemsPerPage": limitPerPage,
				"searchText": searchText,
				"sortField": sortField,
				"sortDirection": sortDirection	
			}
			
			fetch_drafts(message)
		}
		else if(response.status === 401){
			localStorage.clear()
			Cookies.remove('token');
			setTimeout(() => {
				setStatus(2)   
			}, 1000);
			toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
		}
		else{
			toast.info("server error", { position: toast.POSITION.TOP_CENTER})
		}
	}

	if(status === 2){
		// return <Navigate to = '/login' />
		window.location.href = '/login';
	}


  return (
    <div className='right_draft_container'>
		{/* <PageAnimations /> */}
		<div className="draft_header_bar">
			<div className="draft_header">
				Draft Header
				<img src={newDraft} alt="new draft icon" className="new_img" onClick={()=>setIsOpenDraft(true)}/>
			</div>
			<div className="sort_wrapper">
				<div className="sort_main_container" onClick={()=>setSortDrop(!sortDrop)}>
					<div className="sort_tag">
						Sort
					</div>
					<img src={sortDropdownLogo} alt="" className={!sortDrop ? "sort_dropdown_logo_open": "sort_dropdown_logo_close"} />
					{sortDrop &&
					<div className="dropdown_container">
						<div className={'ct_1 dropdown_main_category' + (sortField === "draftName"? " dropdown_main_category_selected": "")} onClick={()=>handle_sort("draftName")}>
							Draft Name
							<div className="sort_up_down_logo">
								<img src={sortUp} alt="" className="up_down_sort_logo" />
								<img src={sortDown} alt="" className="up_down_sort_logo" />								
							</div>
						</div>
						<div className={'ct_2 dropdown_main_category' + (sortField === "createdTime"? " dropdown_main_category_selected": "")} onClick={()=>handle_sort("createdTime")}>
							Created Date
							<div className="sort_up_down_logo">
								<img src={sortUp} alt="" className="up_down_sort_logo" />
								<img src={sortDown} alt="" className="up_down_sort_logo" />								
							</div>
						</div>
						<div className={'ct_3 dropdown_main_category' + (sortField === "updatedTime"? " dropdown_main_category_selected": "")} onClick={()=>handle_sort("updatedTime")}>
							Updated Date
							<div className="sort_up_down_logo">
								<img src={sortUp} alt="" className="up_down_sort_logo" />
								<img src={sortDown} alt="" className="up_down_sort_logo" />								
							</div>
						</div>
					</div>
				}
				</div>
				{/* {sortDrop &&
					<div className="dropdown_container">
						<div className={'ct_1 dropdown_main_category'}>
							By Article
						</div>
						<div className={'ct_2 dropdown_main_category'}>
							Created At
						</div>
						<div className={'ct_3 dropdown_main_category'}>
							Updated At
						</div>
					</div>
				} */}
			</div>
			<div className="crud_search">
				<img src={searchIcon} alt="" className="search_icon" />
				<input type="text" className="crud_add" placeholder='Search' value={draftSearch} onChange = {(e)=>handleSearch(e)}/>
			</div>
		</div>
        <div className="draft_cards_container">
			
		{draftItems.length !==0  && draftItems.map((row, index) => (
            <div key = {index} className="draft_card" onClick={()=>handleArticlesRedirect(row)}>
				<div className="card_header">
					<div className="header">{row["draftName"]}</div>
					<img src = {edit_profile_key} alt = "draft rename" className="draft_rename" onClick={(e)=>{e.stopPropagation();setRenameDraftId(row["_id"]);setSelectedDraftRenameValue(row["draftName"]);setIsOpenDraftRename(true)}}/>
					{/* <span className='draft_type'>{row.draftType}</span> */}
				</div>
				<div className="middle_card_content">
					{row.sample_article_title ?
						<div className="draft_article_title">
							<img className="draft_article_logo" src={draftArticleIcon} alt='article_logo'/>		
								{row.sample_article_title.slice(0, 60)}{row.sample_article_title.length <= 60 ? '' : '...'}<span>{row["totalArticlesCount"] > 1 ? '+'+ (row["totalArticlesCount"]-1) +' more': ""} </span>	
						</div>:
						<div className="draft_article_title">
							<img className="draft_article_logo" src={draftArticleIcon} alt='article_logo'/>		
								No Articles Present	
						</div>
					}
					<div className="bottom_content">
						<div className="created_at_time">
							Created : <span>{moment(row["createdTime"]).format('D MMMM YYYY, h:mm A')}</span>
						</div>
						<div className="totalArticles">
							Updated :  <span>{moment(row["updatedTime"]).format('D MMMM YYYY, h:mm A')}</span>
						</div>
						</div>
						<div className="view_card_content">
							{/* {row.draftType === "Auto Generated" &&  */}
								<button className='view_card_content_button btn btn-primaary btn-sm' onClick={(e)=>{e.stopPropagation();handleCloneDraft(row["_id"])}}>Clone</button>
							{/* } */}
							<span className='draft_type'>{row.draftType}</span>
						</div>
					</div>
            </div>
		))} 
        </div>
		<div className="draft_pagination_container">
				<div className="display_pages">
					Showing &nbsp;<span>{totalPage ? (page-1)*limitPerPage + 1 : 0}</span>-<span>{(page-1)*limitPerPage + currData}</span>&nbsp;from&nbsp;<span>{totalData}</span>&nbsp;data 	
				</div>
				<div className="nav_container">
					<Stack spacing={2}>
						<Pagination count={totalPage} page = {page} color='primary' value={page} onChange={handlePage} variant="outlined" sx={{fontFamily: "Inter"}}/>
					</Stack>
				</div>
		</div>
		<ToastContainer />
		<div className="main_draft_filter_popup">
			<ReactModal
				isOpen={isOpenDraft}
				onRequestClose={closeModalDraft}
				contentLabel="Popup Modal"
				style={customStylesDraft}
			>	
				<div className="new_draft_add_popup_content">
					<div className="article_draft_popup_header">
						Create New Draft
					</div>
					<div className="article_draft_search_bar_container">
						<input type="text" className="new_draft_inp" placeholder="Draft Name" value={selectedValue} onChange={(e) => setSelectedValue(e.target.value)}/>
					</div>
					<div className="article_add_draft_button">
						<button className="cancel btn btn-light" onClick={()=>closeModalDraft()}>
							Cancel
						</button>
						<button className="button_main btn btn-primary" onClick={()=>handle_create_draft()} disabled = {selectedValue?false:true}>
							Create
						</button>
					</div>
				</div>
			</ReactModal>
			<ReactModal
				isOpen={isOpenDraftRename}
				onRequestClose={closeModalDraftRename}
				contentLabel="Popup Modal"
				style={customStylesDraft}
			>	
				<div className="new_draft_add_popup_content">
					<div className="article_draft_popup_header">
						Rename Selected Draft
					</div>
					<div className="article_draft_search_bar_container">
						<input type="text" className="new_draft_inp" placeholder="Draft Name" value={selectedDraftRenameValue} onChange={(e) => setSelectedDraftRenameValue(e.target.value)}/>
					</div>
					<div className="article_add_draft_button">
						<button className="cancel btn btn-light" onClick={()=>closeModalDraftRename()}>
							Cancel
						</button>
						<button className="button_main btn btn-primary" onClick={()=>handle_rename_draft()} disabled = {selectedDraftRenameValue?false:true}>
							Rename
						</button>
					</div>
				</div>
			</ReactModal>		
		</div>	
    </div>
  )
}
