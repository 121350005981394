import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import '../../staticFiles/cssFiles/Dashboard/left_dashboard.css'
import h_logo from "../../staticFiles/images/home_logo.svg"
import a_logo from "../../staticFiles/images/analytics_logo.svg"
import ond_logo from "../../staticFiles/images/report_history_logo.svg"
// import rh_logo from "../../staticFiles/images/Report_History_Icon.svg"
import pro_logo from "../../staticFiles/images/profile_logo.svg"
import ap_logo from "../../staticFiles/images/Admin_Panel_Icon.svg"
import d_logo from "../../staticFiles/images/Collections_Icon.svg"
import logout_logo from "../../staticFiles/images/logout_logo.svg"
import fact_logo from "../../staticFiles/images/fact_check_icon.svg"

// import { Navigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import open_icon from "../../staticFiles/images/dashboard_article_drop_open.svg"

export const LeftDashboard = ({id}) => {
    const [redirect, setRedirect] = React.useState(0)
    const [openReport, setOpenReport] = React.useState(false)

    useEffect(()=>{
        if(id ==='4_1' || id === '4_2' || id === '4_3' || id === '4_4'){
            setOpenReport(true)
        }
    }, [])

    const myStyles = {
        color: '#3B89FE',
        borderLeft: '3px solid #3B89FE',
        background: '#ECF5FF'
    };

    const myStyles2 = {
        filter: 'invert(45%) sepia(77%) saturate(2348%) hue-rotate(199deg) brightness(101%) contrast(99%)'
    }

    const myStyles3 = {
        backgroundColor: "#FFFFFF",
        color:"rgba(36, 113, 229, 1)",
        fontWeight: "bold",
        borderRadius: "6px"
    };

    const logoutHandler = () =>{

        localStorage.clear()
        Cookies.remove('token');
        // alert("Logging you out....")
        setTimeout(() => {
            setRedirect(1)    
        }, 1000);
        toast.info("Logging you out....",  { position: toast.POSITION.TOP_CENTER});
    }


    if(redirect === 1){
        window.location.href = '/login';
        // return <Navigate to = "/login" />
    }

    return (
        <div className="left_dashboard_container">
            <div className="topic_container">
                <h3>Newsmon</h3>
            </div>
            <div className="elements_wrapper">
                <div className="elements_container">
                    <Link to="/dashboard" style={{ textDecoration: 'none', color: '#000000'}}>
                        <div className="elements element1" style={id ==='1' ? myStyles : null}>
                            <img src={h_logo} alt="home_logo" className="icon1 icons"  style={id ==='1' ? myStyles2 : null}/>
                            Dashboard
                        </div> 
                    </Link>
                    <Link to="/analytics" style={{ textDecoration: 'none', color: '#000000'}}>
                        <div className="elements element2" style={id ==='2' ? myStyles : null}>
                            <img src={a_logo} alt="home_logo" className="icon2 icons"style={id ==='2' ? myStyles2 : null} />
                            Statistics
                        </div>
                    </Link>
                    <Link to="/factCheck" style={{ textDecoration: 'none', color: '#000000'}}>
                        <div className="elements element2" style={id ==='3' ? myStyles : null}>
                            <img src={fact_logo} alt="home_logo" className="icon2 icons"style={id ==='3' ? myStyles2 : null} />
                            Fact Check
                        </div>
                    </Link>
                    {/* <Link to="/onDemandPdf" style={{ textDecoration: 'none', color: '#000000'}}> */}
                        <div className="elements element4" style={id ==='4_1' || id === '4_2' || id === '4_3' || id === '4_4' || id === '4_5' ? myStyles : null} onClick={()=>setOpenReport(!openReport)}>
                            <img src={ond_logo} alt="home_logo" className="icon3 icons" style={id ==='4_1' || id === "4_2" || id === "4_3" || id === "4_4" || id === '4_5' ? myStyles2 : null}/>
                            Report
                            <img className={! openReport ? "open_icon": "close_icon"} src={open_icon}/>
                        </div> 
                        {openReport &&
                                <div className="popup">
                                    {/* <Link to="/onDemandPdf" style={{ textDecoration: 'none', color: '#000000'}}>
                                        <div className="dropdown_1 dropdown_content" style={id ==='4_1' ? myStyles3 : null}>Category</div>
                                    </Link> */}
                                    <Link to="/reportsPreview" style={{ textDecoration: 'none', color: '#000000'}}>
                                        <div className="dropdown_1 dropdown_content" style={id ==='4_1' ? myStyles3 : null}>Generation</div>
                                    </Link>
                                    {/* <Link to="/keywordsReport" style={{ textDecoration: 'none', color: '#000000'}}>
                                        <div className="dropdown_2 dropdown_content" style={id ==='4_2' ? myStyles3 : null}>Keywords</div>
                                    </Link> */}
                                    <Link to="/scheduledReports" style={{ textDecoration: 'none', color: '#000000'}}>
                                        <div className="dropdown_3 dropdown_content" style={id ==='4_3' ? myStyles3 : null}>Scheduled</div>
                                    </Link>
                                    <Link to="/history" style={{ textDecoration: 'none', color: '#000000'}}>
                                        <div className="dropdown_4 dropdown_content" style={id ==='4_4' ? myStyles3 : null}>Report History</div>
                                    </Link>
                                    {/* <Link to="/keywordsReport/preview_1.0" style={{ textDecoration: 'none', color: '#000000'}}>
                                        <div className="dropdown_4 dropdown_content" style={id ==='4_5' ? myStyles3 : null}>Report Get 1.0</div>
                                    </Link> */}
                                </div>
                            }
                    {/* </Link> */}
                    {/* <Link to="/history" style={{ textDecoration: 'none', color: '#000000'}}>
                        <div className="elements element3" style={id ==='3' ? myStyles : null}>
                            <img src={rh_logo} alt="home_logo" className="icon3 icons" style={id ==='3' ? myStyles2 : null}/>
                            Report History
                        </div> 
                    </Link> */}
                    <Link to="/draft" style={{ textDecoration: 'none', color: '#000000'}}>
                        <div className="elements element3" style={id ==='7' ? myStyles : null}>
                            <img src={d_logo} alt="home_logo" className="icon3 icons" style={id ==='7' ? myStyles2 : null}/>
                            Draft
                        </div> 
                    </Link>
                    {/* {Cookies.get("userOrg") === "AP-INT" && 
                    <Link to="/keywordsReport" style={{ textDecoration: 'none', color: '#000000'}}>
                        <div className="elements element3" style={id ==='8' ? myStyles : null}>
                            <img src={ond_logo} alt="home_logo" className="icon3 icons" style={id ==='8' ? myStyles2 : null}/>
                            Keywords Report
                        </div> 
                    </Link>
                    } */}
                    {/* <Link to="/scheduledReports" style={{ textDecoration: 'none', color: '#000000'}}>
                        <div className="elements element4" style={id ==='9' ? myStyles : null}>
                            <img src={ond_logo} alt="home_logo" className="icon3 icons" style={id ==='9' ? myStyles2 : null}/>
                                Scheduled Reports
                        </div> 
                    </Link> */}
                    <Link to="/profile" style={{ textDecoration: 'none', color: '#000000'}}>
                        <div className="elements element4" style={id ==='5' ? myStyles : null}>
                            <img src={pro_logo} alt="home_logo" className="icon3 icons" style={id ==='5' ? myStyles2 : null}/>
                            Profile
                        </div> 
                    </Link>
                    {Cookies.get('userRole') !== "user" &&
                    <Link to={Cookies.get('userRole') === 'admin'? "/adminPanel/" + Cookies.get('userOrg'): "/adminPanel"} style={{ textDecoration: 'none', color: '#000000'}}>
                        <div className="elements element4" style={id ==='6' ? myStyles : null}>
                            <img src={ap_logo} alt="home_logo" className="icon3 icons" style={id ==='6' ? myStyles2 : null}/>
                            Admin Panel
                        </div> 
                    </Link>}
                </div>
                {/* <div className="button_container " style={{ position : {Cookies.get('userRole') === "AP-INT"}?"relative": "absolute"}} > */}
                <div className="button_container">
                    {/* <div className="logout" onClick={()=>logoutHandler()}>
                        <img src={logout_logo} alt="logout logo" className="logout_logo mr-2" />
                        Logout
                    </div> */}
                    <button className='btn btn-outline-primary' onClick={()=>logoutHandler()}>
                        <img src={logout_logo} alt="logout logo" className="logout_logo mr-3" />
                        Logout
                    </button>
                </div>
            </div>     
            {/* <div className="button_container ">
                <button className='btn btn-primary'>Logout</button>
            </div>    */}
			<ToastContainer />

        </div>
    )
}
