import React from 'react'
import '../../staticFiles/cssFiles/Analytics/analytics.css'
import { LeftDashboard } from '../Dashboard/left_dashboard'
import Cookies from 'js-cookie'
import { Navigate } from 'react-router-dom'
import { StatsRightView } from './StatsRightView'

export const Analytics = () => {
  if(Cookies.get('token') === undefined){
    console.log("working")
    // return <Navigate to  = "/login" />
    window.location.href = '/login';
}
  return (
    <div className = "analytics_container">
        <LeftDashboard id={'2'}/>
        <StatsRightView />
    </div>
  )
}
