import React from 'react'
import "../../staticFiles/cssFiles/rootDashboard/rightRootDashboard.css"
import { startOfDay, endOfDay, addDays, subDays } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store';
import moment from 'moment/moment';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { AddArticleToDraft, categoriesDefaultGet, createDraft, fetchCountryMapData, fetchEventBubbleData, fetchStateMapData, getArticles, getArticlesFromPrompt, getCategories, getDomains, getDraftsList, getFrequentKeywords, getVerificationCoordinates, graphData, heatmapData, rootTimeSeriesGraphFetch } from '../../endppoints/endpoint';
import dropDown_open from "../../staticFiles/images/dashboard_article_drop_open.svg"
import { Navigate, useParams } from 'react-router-dom';
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from 'rsuite';
import CreatableSelect from 'react-select/creatable'; 
import Select from 'react-select'
import { RootArticles } from './RootArticles/rootArticles';
import ReactModal from 'react-modal';
import { RootAnalytics } from './RootAnalytics/rootAnalytics';

import { LeafletMaps } from '../test3/leaflet_maps';
import approved_tick from  "../../staticFiles/images/approved-round-svgrepo-com.svg"
import no_location from  "../../staticFiles/images/no_location_map.svg"
import Slider from '@mui/material/Slider';
import { PageAnimations } from '../GlobalPageAnimations.jsx/pageAnimations';

import analytics_logo from  "../../staticFiles/images/analytics_root_logo.svg"
import articles_logo from  "../../staticFiles/images/articles_root_logo.svg"


export const RightRootDashboard = () => {
    const { state, date1, date2 } = useParams();

    const dispatch = useDispatch()
	const {updateStates} = bindActionCreators(actionCreators, dispatch);

    const {
		isOpen,
		fromDate,
		toDate,
		fromDatePage,
		toDatePage,
        selectedDateRange,
        selectedDateRangeApplied,
		page,
		sample_rows,
		status,
		articleId,
		total_data,
		curr_data,
		total_page,
		selectedOptions,
		expKey,
		map,
		section,
        sectionApplied,
		sampleOptions,
        selectedOptionsApplied,
		fetchedDomains,
		logicToggle,
		sequentialToggle,
		matchToggle,
		logicToggleApplied,
		sequentialToggleApplied,
		matchToggleApplied,
		selectedOptionsDomain,
		selectedOptionsDomainApplied,
		selectedOptionsLocation,
		selectedOptionsLocationApplied,
		dropdownCatergory,
		categories,
        category,
		appliedCategory,
		promptToggle,
		promptQuery,
		finalPromptQuery,
		fetchedDrafts,
		selectedValue,
		isOpenDraft,
		defaultCategory,
		uniqueArticlesToggle,
		appliedUniqueArticlesToggle,
		showVerifyMap,
		inputVerifyLocation,
		inputVerifyLocationApplied,
		fetchedCoordinates,
		pinMapData,
		inputVerifyLat,
		inputVerifyLan,
		inputVerifyLatApplied,
		inputVerifyLanApplied,
		invalidLocationError,
		approvedLocation,
		approvedLocationApplied,
		inputVerifyLatLanByLocation,
		inputVerifyLatLanByLocationApplied,
		locationRadius,
		locationRadiusApplied,
		selectedOptionsSentiment,
		selectedOptionsSentimentApplied,
		chartData,
        keywordsCategory,
        appliedKeywordsCategory,
        keywordsDropdownCatergory,
        articlesAnalyticsSwitch,
        filtersEditMode,
        showDraftIcons,
        selectedArticles, 

        regionDropdownCatergory,
        regionCategory,
        analyticMap,
        dataMap,

        bubbleChartTopStat,
        dataFetched,
        countPerPage,
        translateToggle,
        selectedOptionsStateFilter,
        selectedOptionsStateFilterApplied,
        selectedOptionsLanguages,
        selectedOptionsLanguagesApplied,

        selectedOptionsCategory ,
        selectedOptionsCategoryApplied,
        sampleOptionsCategory,
        sampleOptionsCountryFilter,
        selectedOptionsCountryFilter,
        selectedOptionsCountryFilterApplied
    } = useSelector(state => state.dashboardState);

	const fetchedLocations = [{value: "By Location", label: "By Location"}, {value: "By Coordinates", label: "By Coordinates"}, {value: "By States", label: "By States"}, {value: "By Country", label: "By Country"}]
	const fetchedSentiments = [{value: "POSITIVE", label: "POSITIVE"}, {value: "NEUTRAL", label: "NEUTRAL"}, {value: "NEGATIVE", label: "NEGATIVE"}]
    const sampleOptionsStateFilter = [
        {"label":"ANDHRA PRADESH","value":"AP"},
        {"label":"ARUNACHAL PRADESH","value":"AR"},
        {"label":"ASSAM","value":"AS"},
        {"label":"BIHAR","value":"BR"},
        {"label":"CHHATTISGARH","value":"CT"},
        {"label":"GOA","value":"GA"},
        {"label":"GUJARAT","value":"GJ"},
        {"label":"HARYANA","value":"HR"},
        {"label":"HIMACHAL PRADESH","value":"HP"},
        {"label":"JHARKHAND","value":"JH"},
        {"label":"KARNATAKA","value":"KA"},
        {"label":"KERALA","value":"KL"},
        {"label":"MADHYA PRADESH","value":"MP"},
        {"label":"MAHARASHTRA","value":"MH"},
        {"label":"MANIPUR","value":"MN"},
        {"label":"MEGHALAYA","value":"ML"},
        {"label":"MIZORAM","value":"MZ"},
        {"label":"NAGALAND","value":"NL"},
        {"label":"ODISHA","value":"OR"},
        {"label":"PUNJAB","value":"PB"},
        {"label":"RAJASTHAN","value":"RJ"},
        {"label":"SIKKIM","value":"SK"},
        {"label":"TAMIL NADU","value":"TN"},
        {"label":"TELANGANA","value":"TG"},
        {"label":"TRIPURA","value":"TR"},
        {"label":"UTTARAKHAND","value":"UT"},
        {"label":"UTTAR PRADESH","value":"UP"},
        {"label":"WEST BENGAL","value":"WB"},
        {"label":"ANDAMAN AND NICOBAR ISLANDS","value":"AN"},
        {"label":"CHANDIGARH","value":"CH"},
        {"label":"DADRA AND NAGAR HAVELI","value":"DN"},
        {"label":"DAMAN AND DIU","value":"DD"},
        {"label":"DELHI","value":"DL"},
        {"label":"JAMMU AND KASHMIR","value":"JK"},
        {"label":"LADAKH","value":"LA"},
        {"label":"LAKSHADWEEP","value":"LD"},
        {"label":"PUDUCHERRY","value":"PY"}
    ]

    const sampleOptionsLanguagesFilter = [
        {"label":"ENGLISH","value":"en"},
        {"label":"HINDI","value":"hi"},
        {"label":"TELUGU","value":"te"},
        {"label":"PUNJABI","value":"pa"},
        {"label":"MARATHI","value":"mr"},
        {"label":"FRENCH","value":"fr"},
        {"label":"MALAY","value":"ms"},
        {"label":"ORIYA","value":"or"},
        {"label":"SANSKRIT","value":"sa"},
        {"label":"TAGALOG","value":"tl"},
        {"label":"SERBIAN","value":"sr"},
        {"label":"TAMIL","value":"ta"},
        {"label":"DANISH","value":"da"},
        {"label":"KHASI","value":"kha"},
        {"label":"INDONESIAN","value":"id"},
        {"label":"PORTUGUESE","value":"pt"},
        {"label":"ARABIC","value":"ar"},
        {"label":"GREEK","value":"el"},
        {"label":"SPANISH","value":"es"},
        {"label":"MAURITIAN_CREOLE","value":"mfe"},
        {"label":"SWAHILI","value":"sw"},
        {"label":"VIETNAMESE","value":"vi"},
        {"label":"DUTCH","value":"nl"},
        {"label":"MALAYALAM","value":"ml"},
        {"label":"URDU","value":"ur"},
        {"label":"ESTONIAN","value":"et"},
        {"label":"SCOTS","value":"sco"},
        {"label":"SLOVENIAN","value":"sl"},
        {"label":"GERMAN","value":"de"},
        {"label":"KANNADA","value":"kn"},
        {"label":"TURKISH","value":"tr"},
        {"label":"WARAY_PHILIPPINES","value":"war"},
        {"label":"ZULU","value":"zu"},
        {"label":"MALAGASY","value":"mg"},
        {"label":"HAUSA","value":"ha"},
        {"label":"NEPALI","value":"ne"},
        {"label":"HEBREW","value":"iw"},
        {"label":"ZHUANG","value":"za"},
        {"label":"KINYARWANDA","value":"rw"},
        {"label":"Chinese","value":"zh"},
        {"label":"MANX","value":"gv"},
        {"label":"BENGALI","value":"bn"},
        {"label":"CZECH","value":"cs"},
        {"label":"JAVANESE","value":"jw"},
        {"label":"ITALIAN","value":"it"},
        {"label":"Japanese","value":"ja"},
        {"label":"UZBEK","value":"uz"},
        {"label":"POLISH","value":"pl"},
        {"label":"ChineseT","value":"zh-Hant"},
        {"label":"SESELWA","value":"crs"},
        {"label":"WELSH","value":"cy"},
        {"label":"AYMARA","value":"ay"},
        {"label":"BASQUE","value":"eu"},
        {"label":"LATIN","value":"la"},
        {"label":"UKRAINIAN","value":"uk"},
        {"label":"X_KLINGON","value":"tlh"},
        {"label":"X_PIG_LATIN","value":"zzp"},
        {"label":"RUSSIAN","value":"ru"},
        {"label":"NORWEGIAN","value":"no"},
        {"label":"WOLOF","value":"wo"},
        {"label":"HUNGARIAN","value":"hu"},
        {"label":"AFRIKAANS","value":"af"},
        {"label":"NORWEGIAN_N","value":"nn"},
        {"label":"RUNDI","value":"rn"},
        {"label":"SOMALI","value":"so"},
        {"label":"BIHARI","value":"bh"},
        {"label":"YORUBA","value":"yo"},
        {"label":"Korean","value":"ko"},
        {"label":"AFAR","value":"aa"},
        {"label":"OROMO","value":"om"},
        {"label":"TONGA","value":"to"},
        {"label":"GUJARATI","value":"gu"},
        {"label":"XHOSA","value":"xh"},
        {"label":"THAI","value":"th"},
        {"label":"CORSICAN","value":"co"},
        {"label":"BRETON","value":"br"},
        {"label":"OCCITAN","value":"oc"},
        {"label":"SLOVAK","value":"sk"},
        {"label":"TURKMEN","value":"tk"},
        {"label":"SCOTS_GAELIC","value":"gd"},
        {"label":"MAORI","value":"mi"},
        {"label":"CATALAN","value":"ca"},
        {"label":"QUECHUA","value":"qu"},
        {"label":"PERSIAN","value":"fa"},
        {"label":"ASSAMESE","value":"as"},
        {"label":"IRISH","value":"ga"},
        {"label":"AZERBAIJANI","value":"az"},
        {"label":"X_Buginese","value":"xx-Bugi"},
        {"label":"CEBUANO","value":"ceb"},
        {"label":"ROMANIAN","value":"ro"},
        {"label":"LATVIAN","value":"lv"},
        {"label":"ESPERANTO","value":"eo"},
        {"label":"INUKTITUT","value":"iu"},
        {"label":"HAITIAN_CREOLE","value":"ht"},
        {"label":"GREENLANDIC","value":"kl"},
        {"label":"ICELANDIC","value":"is"},
        {"label":"CROATIAN","value":"hr"},
        {"label":"VENDA","value":"ve"},
        {"label":"INTERLINGUA","value":"ia"},
        {"label":"FINNISH","value":"fi"},
        {"label":"NYANJA","value":"ny"},
        {"label":"IGBO","value":"ig"},
        {"label":"GALICIAN","value":"gl"},
        {"label":"AKAN","value":"ak"},
        {"label":"SWEDISH","value":"sv"},
        {"label":"ALBANIAN","value":"sq"},
        {"label":"TIBETAN","value":"bo"},
        {"label":"SUNDANESE","value":"su"},
        {"label":"TSWANA","value":"tn"},
        {"label":"SANGO","value":"sg"},
        {"label":"SISWANT","value":"ss"},
        {"label":"CHEROKEE","value":"chr"},
        {"label":"GUARANI","value":"gn"},
        {"label":"MALTESE","value":"mt"},
        {"label":"FAROESE","value":"fo"},
        {"label":"FRISIAN","value":"fy"},
        {"label":"SINDHI","value":"sd"},
        {"label":"SESOTHO","value":"st"},
        {"label":"INTERLINGUE","value":"ie"},
        {"label":"PASHTO","value":"ps"},
        {"label":"TSONGA","value":"ts"},
        {"label":"TATAR","value":"tt"},
        {"label":"SAMOAN","value":"sm"},
        {"label":"INUPIAK","value":"ik"},
        {"label":"KHMER","value":"km"},
        {"label":"FIJIAN","value":"fj"},
        {"label":"HMONG","value":"hmn"},
        {"label":"LINGALA","value":"ln"},
        {"label":"ARMENIAN","value":"hy"},
        {"label":"RHAETO_ROMANCE","value":"rm"},
        {"label":"BISLAMA","value":"bi"},
        {"label":"GANDA","value":"lg"},
        {"label":"LUXEMBOURGISH","value":"lb"},
        {"label":"SYRIAC","value":"syr"},
        {"label":"NAURU","value":"na"},
        {"label":"AMHARIC","value":"am"},
        {"label":"SHONA","value":"sn"},
        {"label":"HAWAIIAN","value":"haw"},
        {"label":"BULGARIAN","value":"bg"},
        {"label":"VOLAPUK","value":"vo"},
        {"label":"BURMESE","value":"my"},
        {"label":"DHIVEHI","value":"dv"}
    ]
      
    const check_error = (response) =>{
        if(response.status === 401){
            localStorage.clear()
            Cookies.remove('token');
            setTimeout(() => {
                updateStates({
					"type": "reducerUpdates",
					"payload": {
						status: 2
					}
				})
            }, 1000);
            toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
        }
        else{
            toast.info("server error", { position: toast.POSITION.TOP_CENTER})
        }
    }

    React.useEffect(()=>{
        if(!dataFetched){
            console.log("here")
            fetch_default_category();
            fetch_frequent_keywords();
            fetch_categories()
            fetch_domains();
        }
    }, []);

    const fetch_default_category = async()=>{
        updateStates({
            "type": "reducerUpdates",
            "payload": {
                "dataFetched": true
            }
        })
		let response = await categoriesDefaultGet()
		if(response.status === 200){
			updateStates({
				"type": "reducerUpdates",
				"payload": {
					defaultCategory: response.data.defaultCategory,
                    appliedCategory: response.data.defaultCategory,
                    category: response.data.defaultCategory,
                    selectedOptionsCategory: {value: response.data.defaultCategory, label: response.data.defaultCategory}
				}
			})
			sendrequest(response.data.defaultCategory);
		}
		else{
            check_error(response)
        }
	}

    const sendrequest =(cat)=>{
		updateStates({
			"type": "reducerUpdates",
			"payload": {
				category: cat
			}
		})
		let allFilters = []
		if(state === undefined){
			for(let i = 0; i < selectedOptionsApplied.length; i++){
				allFilters.push(selectedOptionsApplied[i].value)
			}
		}
		else{
			updateStates({
				"type": "reducerUpdates",
				"payload": {
					selectedOptions: [...selectedOptions, {value: state, label: state}]
				}
			})
			allFilters = [state]
			updateStates({
				"type": "reducerUpdates",
				"payload": {
					expKey: allFilters,
					fromDate: date1,
					toDate: date2,
					fromDatePage: date1,
					toDatePage: date2
				}
			})
		}

        let allDomainFilters = []
        for(let i = 0; i < selectedOptionsDomainApplied.length; i++){
            allDomainFilters.push(selectedOptionsDomainApplied[i].value)
        }

        let allFiltersLanguages = []
        for(let i = 0; i < selectedOptionsLanguagesApplied.length; i++){
            allFiltersLanguages.push(selectedOptionsLanguagesApplied[i].value)
        }
        
        const message = {
            "pageNo": "1",
            "countPerPage": countPerPage,
            'fromDate': selectedDateRangeApplied? moment(selectedDateRangeApplied[0]).format("YYYY-MM-DD"): '',
            'toDate': selectedDateRangeApplied? moment(selectedDateRangeApplied[1]).format("YYYY-MM-DD"): '',
            "searchText": allFilters.join(' '),
            "category": sectionApplied === 0 ? "": cat,
            "allWordMatch": logicToggleApplied,
            "sequentialMatch": sequentialToggleApplied,
            "exactMatch": matchToggleApplied,
            "domain_name": allDomainFilters,
            "languages": allFiltersLanguages,
            "location": null,
            "sentiment": (selectedOptionsSentiment === null || selectedOptionsSentiment === '') ? null: selectedOptionsSentiment.value
        }
        if(section === 1){
            message["uniqueArticles"] = appliedUniqueArticlesToggle 
        }
        get_Articles(message);

        const message_heatmap = {
            'fromDate': selectedDateRangeApplied? moment(selectedDateRangeApplied[0]).format("YYYY-MM-DD"): '',
            'toDate': selectedDateRangeApplied? moment(selectedDateRangeApplied[1]).format("YYYY-MM-DD"): '',
            "searchText": allFilters.join(' '),
            "category": sectionApplied === 0 ? "": cat,
        }

        heatmap_data_fetch(message_heatmap);
        heat_map_country_data_fetch(message_heatmap)

        if(allFilters.length !== 0 || sectionApplied === 1){
            get_event_bubble_data(message_heatmap)
        }
        
        time_series_data_fetch(message_heatmap)
	}

	const fetch_domains = async()=>{
		let response = await getDomains()
		// console.log(response, response.data.length)
		if(response.status === 200){
			let test_array = []
			for(let i = 0; i < response.data.length; i++){
			 test_array.push(response.data[i])
			}

			const temportary_options = test_array.map(item => {
			 return {value: item, label: item}
		 	});
		 
			updateStates({
				"type": "reducerUpdates",
				"payload": {
					fetchedDomains: temportary_options
				}
			})
	 	}
	 	else {
            check_error(response)
        }
	}

    
	const finalSubmit_section = (section_value, cat) =>{
		updateStates({
			"type": "reducerUpdates",
			"payload": {
				dropdownCatergory: false,
				page: 1,
				section: section_value
			}
		})
	}

    
	const get_Articles = async(message)=>{
		let response = {};
		updateStates({
			"type": "reducerUpdates",
			"payload": {
				map: 2
			}
		})
        response = await getArticles(message)
		// console.log(response)
		
		if(response.status === 200){
			updateStates({
				"type": "reducerUpdates",
				"payload": {
					map: 1
				}
			})

            const newValues = new Array(response.data['data'].length).fill(true);

			updateStates({
				"type": "reducerUpdates",
				"payload": {
					sample_rows: response.data.data,
					total_data: response.data['totalCount'],
					curr_data: response.data['data'].length,
                    translateToggle: newValues
				}
			})

			if(response.data['totalCount'] % countPerPage === 0){
				updateStates({
					"type": "reducerUpdates",
					"payload": {
						total_page: Math.floor(response.data['totalCount'] / countPerPage)
					}
				})
			}
			else{
				updateStates({
					"type": "reducerUpdates",
					"payload": {
						total_page: Math.floor(Math.floor(response.data['totalCount'] / countPerPage) + 1)
					}
				})
			}
			fetch_frequent_keywords();
		}

		else {
            check_error(response);
        }
	}

    const fetch_frequent_keywords = async() =>{
        let response = await getFrequentKeywords()
        // console.log(response, response.data.length)
        if(response.status === 200){
		   	let test_array = []
		   	for(let i = 0; i < response.data.length; i++){
				test_array.push(response.data[i].keyword.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()))
		   	}

		   	const temportary_options = test_array.map(item => {
				return {value: item, label: item}
			});
			
			updateStates({
				"type": "reducerUpdates",
				"payload": {
					sampleOptions: temportary_options
				}
			})
        }
        else {
            check_error(response)
        }
    }

    const get_event_bubble_data = async(event_message) =>{
		let response = await fetchEventBubbleData(event_message)
		if(response.status === 200){
			// console.log(response)
			let temp_arr = []
			for(let i = 0; i < response.data.length; i++){
				temp_arr.push([response.data[i]["x2"], response.data[i]["y"], response.data[i]["z"], response.data[i]["cluster_info"]["title"], response.data[i]])
			}

            if(temp_arr.length > 0){
                const maxArticlesElement = response.data.reduce((max, current) => {
                    return current.y > max.y ? current : max;
                }, response.data[0]);

                updateStates({
                    "type": "reducerUpdates",
                    "payload": {
                        bubbleChartTopStat: maxArticlesElement
                    }
                })
            }

			updateStates({
				"type": "reducerUpdates",
				"payload": {
					chartData: temp_arr,
                    bubbleChartResultLoading: 1
				}
			})
		}
        else if(response.status === 401){
            localStorage.clear()
            Cookies.remove('token');
            setTimeout(() => {
                updateStates({
					"type": "reducerUpdates",
					"payload": {
						status: 2
					}
				})
            }, 1000);
            toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
        }
        else{
            // setTimeout(() => {
            updateStates({
                "type": "reducerUpdates",
                "payload": {
                    bubbleChartResultLoading: 0
                }
            })
            // }, 1000);
        }
	}

    const test_dropdown_click = ()=>{
		updateStates({
			"type": "reducerUpdates",
			"payload": {
				dropdownCatergory: ! dropdownCatergory
			}
		})
		fetch_categories()
	}

    const fetch_categories = async() => {
		let response = await getCategories()
		if(response.status === 200){
             const temporary_options = response.data.map(item => {
				return {value: item, label: item}
			});

            temporary_options.push({value: "", label: "Universal Repository"})

			updateStates({
				"type": "reducerUpdates",
				"payload": {
					categories: response.data,
                    sampleOptionsCategory: temporary_options
				}
			})
		 	// console.log(response.data)
	 	}
	 	else {
            check_error(response)
        }
	}

    const apply_category_filter = (ct)=>{
		updateStates({
			"type": "reducerUpdates",
			"payload": {
				category: ct,
                dropdownCatergory: false,
				section: 1
			}
		})
		// finalSubmit_section(1, ct)
	}

    const apply_news_corpus_filter = () => {
        updateStates({
			"type": "reducerUpdates",
			"payload": {
				category: "",
				section: 0
			}
		})
    }

    const handleKeywords = (selectedOption) =>{
        updateStates({
			"type": "reducerUpdates",
			"payload": {
				selectedOptions: selectedOption
			}
		})
        handle_sync_filters("keywords", selectedOption)
    }

    const handleCategory = (selectedOption) =>{
        console.log(selectedOption)
        updateStates({
			"type": "reducerUpdates",
			"payload": {
				selectedOptionsCategory: selectedOption
			}
		})
        if(selectedOption.value === "" && uniqueArticlesToggle){
            updateStates({
                "type": "reducerUpdates",
                "payload": {
                    uniqueArticlesToggle: false
                }
            })   
            toast.info("Unique Articles filter cannot be used with Universal Repository", { position: toast.POSITION.TOP_CENTER, autoClose: 1000});
        }
        handle_sync_filters("category", selectedOption)
    }

    const handleLanguages = (selectedOption) =>{
        updateStates({
			"type": "reducerUpdates",
			"payload": {
				selectedOptionsLanguages: selectedOption
			}
		})
        handle_sync_filters("languages", selectedOption)
    }

    const handleCountryFilter = (selectedOption) =>{
        updateStates({
			"type": "reducerUpdates",
			"payload": {
				selectedOptionsCountryFilter: selectedOption
			}
		})
        handle_sync_filters("country", selectedOption)
    }

    const handleStateFilter = (selectedOption) =>{
        updateStates({
			"type": "reducerUpdates",
			"payload": {
				selectedOptionsStateFilter: selectedOption
			}
		})
        handle_sync_filters("state", selectedOption)
    }

    const handleDomains = (selectedOptionDomain) => {
		updateStates({
			"type": "reducerUpdates",
			"payload": {
				selectedOptionsDomain: selectedOptionDomain
			}
		})
        handle_sync_filters("domains", selectedOptionDomain)
	}

    const handleSentiment = (selectedSentiment) =>{
        updateStates({
            "type": "reducerUpdates",
            "payload": {
                selectedOptionsSentiment: selectedSentiment
            }
        })
        handle_sync_filters("sentiment", selectedSentiment)
    }

    const handleDateRangeChange = (value) =>{
        updateStates({
			"type": "reducerUpdates",
			"payload": {
                selectedDateRange: value
			}
		})
        if(value === null){
            toast.error("date range can not be null", { position: toast.POSITION.TOP_CENTER});
        }
        else{
            handle_sync_filters("date range", value)
        }
    }

    function createData(article, sentiment, date, region, id) {
        return { article, sentiment, date, region, id };
    }

    const loadArticleTabContents = async(message) =>{
        let response = {};
		updateStates({
			"type": "reducerUpdates",
			"payload": {
				map: 2
			}
		})
        response = await getArticles(message)
		// console.log(response)
		
		if(response.status === 200){
			updateStates({
				"type": "reducerUpdates",
				"payload": {
					map: 1
				}
			})
			let test_rows = []
			for(let i = 0; i < response.data.data.length; i++){
				test_rows.push(createData(response.data.data[i].Title, response.data.data[i].sentiment,  response.data.data[i].published_date, response.data.data[i].keywords_p.Location, response.data.data[i]._id))
			}

			updateStates({
				"type": "reducerUpdates",
				"payload": {
					sample_rows: test_rows,
					total_data: response.data['totalCount'],
					curr_data: response.data['data'].length
				}
			})

			if(response.data['totalCount'] % countPerPage === 0){
				updateStates({
					"type": "reducerUpdates",
					"payload": {
						total_page: Math.floor(response.data['totalCount'] / countPerPage)
					}
				})
			}
			else{
				updateStates({
					"type": "reducerUpdates",
					"payload": {
						total_page: Math.floor(Math.floor(response.data['totalCount'] / countPerPage) + 1)
					}
				})
			}
			fetch_frequent_keywords()
		}
    }

    const heatmap_data_fetch = async(message) =>{
        let response = {}
        updateStates({
            "type": "reducerUpdates",
            "payload": {
                analyticMap: 2
            }
        })
        response = await fetchStateMapData(message);

        if(response.status === 200){
            // console.log(response.data)
            // setMap(1) 
            // setDataMap(response.data)
            updateStates({
                "type": "reducerUpdates",
                "payload": {
                    analyticMap: 1,
                    dataMap: response.data
                }
            })
        }

        else {
            check_error(response)
        }
    }

    const heat_map_country_data_fetch = async(message)=>{
        let response = {}
        updateStates({
            "type": "reducerUpdates",
            "payload": {
                analyticMap: 2
            }
        })
        response = await fetchCountryMapData(message);

        if(response.status === 200){
            // console.log(response.data)
            updateStates({
                "type": "reducerUpdates",
                "payload": {
                    analyticMap: 1,
                    dataCountryMap: response.data
                }
            })
        }

        else {
            check_error(response)
        }
    }

    const time_series_data_fetch = async(message)=>{
        let response = {}
        // response = await graphData("days")
        response = await rootTimeSeriesGraphFetch(message)
        console.log("required data",response)

        if(response.status === 200){
            let required_data = response.data
            required_data.sort((a, b) => moment(a.x).diff(moment(b.x)));
            // console.log(response.data)
            let sample = []
            for(let i = 0; i < required_data.length; i++){
                sample.push({"name": required_data[i].x, "Articles": required_data[i].y, "data": required_data[i]})
            }
            updateStates({
                "type": "reducerUpdates",
                "payload": {
                    timeSeriesData: sample,
                    timeSeriesResultLoading: 1
                }
            })
            if(sample.length > 0){
                const maxArticlesElement = required_data.reduce((max, current) => {
                    return current.y > max.y ? current : max;
                }, required_data[0]);

                updateStates({
                    "type": "reducerUpdates",
                    "payload": {
                        timeSeriesDataTopStat: maxArticlesElement
                    }
                })
            }
        }
        else {
            check_error(response)
        }
    }

    const handle_sync_filters = (field, val)=>{
        updateStates({
            "type": "reducerUpdates",
            "payload": {
                invalidLocationError: false,
                page: 1
            }
        })

        let date_range_value = []
        if(field === "date range"){
            if(val){
                date_range_value.push(moment(val[0]).format("YYYY-MM-DD"))
                date_range_value.push(moment(val[1]).format("YYYY-MM-DD"))
            }
            else{
                date_range_value.push("")
                date_range_value.push("")
            }
        }

        let temp_dct = {}
        if(field === "approve location"){
            if((selectedOptionsLocation?.value === "By Location" || selectedOptionsLocation?.value  === "By Coordinates")) {
                temp_dct = {
                    "searchType": "radius",
                    "searchInput":{
                        "coordinates": [parseFloat(pinMapData[0]["Latitude"]), parseFloat(pinMapData[0]["Longitude"])],
                        "radius": locationRadius
                    }
                }
            }
            else{
                temp_dct = null
            }

    
            if(selectedOptionsLocation?.value === "By Location" || selectedOptionsLocation?.value  === "By Coordinates"){				
                updateStates({
                    "type": "reducerUpdates",
                    "payload": {
                        approvedLocationApplied: true,
                        selectedOptionsLocationApplied: selectedOptionsLocation,
                        locationRadiusApplied: locationRadius
                    }
                })
    
                if(selectedOptionsLocation.value === "By Location"){					
                    updateStates({
                        "type": "reducerUpdates",
                        "payload": {
                            inputVerifyLocationApplied: inputVerifyLocation,
                            inputVerifyLatLanByLocationApplied: pinMapData
                        }
                    })
    
                }
                else if(selectedOptionsLocation.value === "By Coordinates"){
                    updateStates({
                        "type": "reducerUpdates",
                        "payload": {
                            inputVerifyLatApplied: parseFloat(inputVerifyLat),
                            inputVerifyLanApplied :parseFloat(inputVerifyLan),
                            inputVerifyLatLanByLocationApplied: pinMapData
                        }
                    })
                }
                else{
                    console.log("CHECK THIS CASE")
                }
            } 
        }
        else{
            let temp_dct = {}
            if((selectedOptionsLocationApplied?.value === "By Location" || selectedOptionsLocationApplied?.value  === "By Coordinates") && approvedLocationApplied) {
				temp_dct = {
                    "searchType": "radius",
                    "searchInput":{
                    "coordinates": [parseFloat(inputVerifyLatLanByLocationApplied[0]["Latitude"]), parseFloat(inputVerifyLatLanByLocationApplied[0]["Longitude"])],
                        "radius": locationRadiusApplied
                    }
                }
            }
            else{
                temp_dct = null
            }

            if(selectedOptionsLocationApplied.value === "By Location"){
                updateStates({
                    "type": "reducerUpdates",
                    "payload": {
                        inputVerifyLocation: inputVerifyLocationApplied,
                        inputVerifyLanApplied: "",
                        inputVerifyLatApplied: "",
                        approvedLocation: approvedLocationApplied
                    }
                })
            }
            else if(selectedOptionsLocation === "By Coordinates"){
                updateStates({
                    "type": "reducerUpdates",
                    "payload": {
                        inputVerifyLocationApplied: "",
                        inputVerifyLanApplied: inputVerifyLan,
                        inputVerifyLatApplied: inputVerifyLat,
                    }
                })
            }
        }

        let state_dct = {}
        if(field === "state"){
            if(selectedOptionsLocation?.value === "By States" && Object.keys(val).length > 0){
                state_dct = {
                    "searchType": "stateCode",
                    "searchInput":{
                        "stateCodes" : val.map(item=>item.value)
                    }
                }
            }
        }
        else{
            if(selectedOptionsLocation?.value === "By States" && Object.keys(selectedOptionsStateFilter).length > 0){
                state_dct = {
                    "searchType": "stateCode",
                    "searchInput":{
                        "stateCodes" : selectedOptionsStateFilter.map(item=>item.value)
                    }
                }
            }
        }

        let country_dct = {}
        if(field === "country"){
            if(selectedOptionsLocation?.value === "By Country" && Object.keys(val).length > 0){
                country_dct = {
                    "searchType": "countryCode",
                    "searchInput":{
                        "countryCodes" : val.map(item=>item.value)
                    }
                }
            }
        }
        else{
            if(selectedOptionsLocation?.value === "By Country" && Object.keys(selectedOptionsCountryFilter).length > 0){
                country_dct = {
                    "searchType": "countryCode",
                    "searchInput":{
                        "countryCodes" : selectedOptionsCountryFilter.map(item=>item.value)
                    }
                }
            }
        }

        // if(field === "location"){
        //     updateStates({
        //         "type": "reducerUpdates",
        //         "payload": {
        //             approvedLocation: false,
        //             pinMapData: [],
        //             invalidLocationError: false,
        //             locationRadius: 50,
        //             inputVerifyLocation: "",
        //             inputVerifyLan: undefined,
        //             inputVerifyLat: undefined,
        //             inputVerifyLatLanByLocation: [],
        //             selectedOptionsStateFilter: []
        //         }
        //     })
        // }

        const message = {
            "pageNo": "1",
            "countPerPage": countPerPage,
            'fromDate': field === "date range" ? date_range_value[0] : selectedDateRange? moment(selectedDateRange[0]).format("YYYY-MM-DD"): '',
            'toDate': field === "date range" ? date_range_value[1] : selectedDateRange? moment(selectedDateRange[1]).format("YYYY-MM-DD"): '',
            "searchText": field === "keywords" ? val.map(item => item.value).join(' ') : selectedOptions.map(item => item.value).join(' '),
            "category": field === "category" ? val.value : selectedOptionsCategory.value,
            "allWordMatch": field === "logic toggle" ? val : logicToggle,
            "sequentialMatch": field === "sequential toggle" ? val : sequentialToggle,
            "exactMatch": field === "match toggle" ? val : matchToggle,
            "domain_name": field === "domains" ? val.map(item => item.value) : selectedOptionsDomain.map(item => item.value),
            "languages": field === "languages" ? val.map(item=>item.value): selectedOptionsLanguages.map(item => item.value),
            "location":
            field === "location" ? null :
            Object.keys(state_dct).length > 0?
            state_dct
            :
            Object.keys(country_dct).length > 0?
            country_dct
            :
            temp_dct,
            "sentiment": field === "sentiment"? ((val === null || val === '')? null:val.value) : (selectedOptionsSentiment === null || selectedOptionsSentiment === '') ? null: selectedOptionsSentiment.value
        }

        if(field === "category"){
            if(val.value !== ""){
                message["uniqueArticles"] = uniqueArticlesToggle
            }
        }
        else{
            if(selectedOptionsCategory.value !== ""){
                if(field === "unique articles"){
                    message["uniqueArticles"] = val
                }
                else{
                    message["uniqueArticles"] = uniqueArticlesToggle
                }
            }
        }

        if(field === "location"){
            console.log("here")
            updateStates({
                "type": "reducerUpdates",
                "payload": {
                    approvedLocation: false,
                    pinMapData: [],
                    invalidLocationError: false,
                    locationRadius: 50,
                    inputVerifyLocation: "",
                    inputVerifyLan: undefined,
                    inputVerifyLat: undefined,
                    inputVerifyLatLanByLocation: [],
                    selectedOptionsStateFilter: []
                }
            })
        }

        get_Articles(message);

        if(field === "prompt_switch"){
            updateStates({
                "type": "reducerUpdates",
                "payload": {
                    promptQuery : ""
                }
            })
        }
        

        const message_heatmap = {
            'fromDate': field === "date range" ? date_range_value[0] : selectedDateRange? moment(selectedDateRange[0]).format("YYYY-MM-DD"): '',
            'toDate': field === "date range" ? date_range_value[1] : selectedDateRange? moment(selectedDateRange[0]).format("YYYY-MM-DD"): '',
            "searchText": field === "keywords" ? val.map(item => item.value).join(' ') : selectedOptions.map(item => item.value).join(' '),
            "category": field === "category" ? (val.value === "" ? null : val.value) : (selectedOptionsCategory.value === ""? null : selectedOptionsCategory.value),
        }

        heatmap_data_fetch(message_heatmap);
        heat_map_country_data_fetch(message_heatmap)

        if(((field === "keywords" && val.length !== 0) || (field !== "keywords" && selectedOptions.length !== 0)) || ((field === "category" && val.value !== "") || (field !== "category" && selectedOptionsCategory.value !== ""))){
            get_event_bubble_data(message_heatmap)
        }
        time_series_data_fetch(message_heatmap)
    }


    const handleSaveFilters = () =>{
        updateStates({
            "type": "reducerUpdates",
            "payload": {
                invalidLocationError: false
            }
        })
        updateStates({
			"type": "reducerUpdates",
			"payload": {
				sectionApplied: section,
                appliedCategory : category,
                selectedDateRangeApplied: selectedDateRange,
                appliedKeywordsCategory : keywordsCategory,
                selectedOptionsApplied: selectedOptions,
                logicToggleApplied: logicToggle,
                sequentialToggleApplied: sequentialToggle,
                matchToggleApplied: matchToggle,
                selectedOptionsDomainApplied: selectedOptionsDomain,
                appliedUniqueArticlesToggle: uniqueArticlesToggle,
                page: 1,
                filtersEditMode: false,
                selectedOptionsLocationApplied: selectedOptionsLocation,
                approvedLocationApplied: approvedLocation,
                selectedOptionsLanguagesApplied: selectedOptionsLanguages
                // approvedLocationApplied: approvedLocation,
                // inputVerifyLatLanByLocationApplied: inputVerifyLatLanByLocation,
                // locationRadiusApplied: locationRadius
			}
		})
        
        // if(articlesAnalyticsSwitch === "articles"){

        let allFilters = []
        for(let i = 0; i < selectedOptions.length; i++){
            allFilters.push(selectedOptions[i].value)
        }

        let allFiltersLanguages = []
        for(let i = 0; i < selectedOptionsLanguages.length; i++){
            allFiltersLanguages.push(selectedOptionsLanguages[i].value)
        }

        let allDomainFilters = []
        for(let i = 0; i < selectedOptionsDomain.length; i++){
            allDomainFilters.push(selectedOptionsDomain[i].value)
        }
        
        const message = {
            "pageNo": "1",
            "countPerPage": countPerPage,
            'fromDate': selectedDateRange? moment(selectedDateRange[0]).format("YYYY-MM-DD"): '',
            'toDate': selectedDateRange? moment(selectedDateRange[1]).format("YYYY-MM-DD"): '',
            "searchText": allFilters.join(' '),
            "category": section === 0 ? "": category,
            "allWordMatch": logicToggle,
            "sequentialMatch": sequentialToggle,
            "exactMatch": matchToggle,
            "domain_name": allDomainFilters,
            "languages": allFiltersLanguages,
            "location": 
            (selectedOptionsLocation?.value === "By States" && selectedOptionsStateFilter.length > 0)?
            {
                "searchType": "stateCode",
                "searchInput":{
                    "stateCodes" : selectedOptionsStateFilter.map(item => item.value)
                }
            }:
            (selectedOptionsLocation?.value === "By Country" && selectedOptionsCountryFilter.length > 0)?
            {
                "searchType": "countryCode",
                "searchInput":{
                    "countryCodes" : selectedOptionsCountryFilter.map(item => item.value)
                }
            }
            :
            (selectedOptionsLocation?.value === "By Location" || selectedOptionsLocation?.value  === "By Coordinates") && approvedLocation ?
                {
                "searchType": "radius",
                "searchInput":{
                    "coordinates": [parseFloat(pinMapData[0]["Latitude"]), parseFloat(pinMapData[0]["Longitude"])],
                    "radius": locationRadius
                }
                }: null,
            "sentiment": (selectedOptionsSentiment === null || selectedOptionsSentiment === '') ? null: selectedOptionsSentiment.value
        }
        if(section === 1){
            message["uniqueArticles"] = uniqueArticlesToggle 
        }

        if(selectedOptionsLocation?.value === "By States"){
            updateStates({
                "type": "reducerUpdates",
                "payload": {
                    selectedOptionsStateFilterApplied: selectedOptionsStateFilter
                }
            })
        }

        else if(selectedOptionsLocation?.value === "By Country"){
            updateStates({
                "type": "reducerUpdates",
                "payload": {
                    selectedOptionsCountryFilterApplied: selectedOptionsCountryFilter
                }
            })
        }

        else if ((selectedOptionsLocation?.value === "By Location" || selectedOptionsLocation?.value  === "By Coordinates") && approvedLocation === true){				
            updateStates({
                "type": "reducerUpdates",
                "payload": {
                    approvedLocationApplied: true,
                    selectedOptionsLocationApplied: selectedOptionsLocation,
                    locationRadiusApplied: locationRadius
                }
            })

            if(selectedOptionsLocation.value === "By Location"){					
                updateStates({
                    "type": "reducerUpdates",
                    "payload": {
                        inputVerifyLocationApplied: inputVerifyLocation,
                        inputVerifyLatLanByLocationApplied: pinMapData
                    }
                })

            }
            else if(selectedOptionsLocation.value === "By Coordinates"){
                let temp_arr = [inputVerifyLat, inputVerifyLan]
                updateStates({
                    "type": "reducerUpdates",
                    "payload": {
                        inputVerifyLatApplied: parseFloat(inputVerifyLat),
                        inputVerifyLanApplied :parseFloat(inputVerifyLan),
                        inputVerifyLatLanByLocationApplied: pinMapData
                    }
                })
            }
            else{
                console.log("CHECK THIS CASE")
            }
        }
        else{
            
        }

        get_Articles(message);
    // }

    // else{
        // fetch heatmap_data
        // allFilters = []
        // for(let i = 0; i < selectedOptions.length; i++){
        // 	allFilters.push(selectedOptions[i].value)
        // }

        const message_heatmap = {
            'fromDate': selectedDateRange? moment(selectedDateRange[0]).format("YYYY-MM-DD"): '',
            'toDate': selectedDateRange? moment(selectedDateRange[1]).format("YYYY-MM-DD"): '',
            "searchText": allFilters.join(' '),
            "category": section === 0 ? "": category,
        }

        heatmap_data_fetch(message_heatmap);
        heat_map_country_data_fetch(message_heatmap)

        if(allFilters.length !== 0 || section === 1){
            get_event_bubble_data(message_heatmap)
        }
        
        time_series_data_fetch(message_heatmap)
        // }
    }

    const handle_draft_popup = async() =>{
        // console.log("working")
		updateStates({
			"type": "reducerUpdates",
			"payload": {
				isOpenDraft: true
			}
		})
        let response = await getDraftsList()
        if(response.status === 200){
            // console.log("drafts fetched")
            let test_array = []
            for(let i = 0; i < response.data.length; i++){
                test_array.push([response.data[i]["draftName"], response.data[i]["_id"]])
            }

            const temportary_options = test_array.map((item) => {
                return {value: item[1], label: item[0]}
                });
            // console.log(temportary_options)            
			updateStates({
				"type": "reducerUpdates",
				"payload": {
					fetchedDrafts: temportary_options
				}
			})
        }
        else{
            check_error(response)
        }
    }

    const closeModalDraft = () => {
		updateStates({
			"type": "reducerUpdates",
			"payload": {
				isOpenDraft: false
			}
		})
    };

    const customStylesDraft = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			minWidth: '25%',
			maxHeight: '70%',
			background: '#F6F7F9',
			borderRadius: "8px",
			paddingTop: '5vh',
			paddingBottom: '5vh',
			paddingLeft: '5vw',
			paddingRight: '5vw',
		},
		overlay: {
			backgroundColor: 'rgba(0, 0, 0, 0.71)',
            backdropFilter: "blur(2.5px)",
            zIndex: 1
		},
    };

    const handleSelectChange = async(newValue, actionMeta) => {
        // console.log(newValue)
        // setSelectedValue(newValue);
		updateStates({
			"type": "reducerUpdates",
			"payload": {
				selectedValue: newValue
			}
		})
	};

	const handle_create_draft = async() =>{
        // console.log(selectedValue)
        if(selectedValue.hasOwnProperty('__isNew__') && selectedValue.__isNew__ === true){
            let message = {
                draftName: selectedValue["value"],
                articleIds: Object.keys(selectedArticles),
                itemsPerPage: 1
            }
            let response = await createDraft(message)
            // console.log(response, response.data.length)
            if(response.status === 200){
               toast.success("Draft "+ selectedValue["value"] + " Created with new Articles", { position: toast.POSITION.TOP_CENTER, autoClose: 1000});
			//    setStatus(3)
			   updateStates({
				"type": "reducerUpdates",
				"payload": {
					status: 3
				}
			})
			   handle_draft_popup()
            }
            else {
                check_error(response)
            }
        }
        else{
            let message = {
                draftId: selectedValue["value"],
                articleIds: Object.keys(selectedArticles)
            }
            let response = await AddArticleToDraft(message)
            // console.log(response, response.data.length)
            if(response.status === 200){
               toast.success("Articles Added to Draft "+ selectedValue["label"], { position: toast.POSITION.TOP_CENTER, autoClose: 1000});
			   updateStates({
				"type": "reducerUpdates",
				"payload": {
					status: 3
				}
			})
               handle_draft_popup()
            }
            else {
                check_error(response)
            }
        }
    }

    const handleLocations = (selectedOptionLocation) => {
        console.log(inputVerifyLocation)
		updateStates({
			"type": "reducerUpdates",
			"payload": {
				approvedLocation: false,
				pinMapData: [],
				invalidLocationError: false,
				locationRadius: 50,
				selectedOptionsLocation: selectedOptionLocation,
                inputVerifyLocation: "",
                inputVerifyLan: undefined,
                inputVerifyLat: undefined,
                inputVerifyLatLanByLocation: [],
                selectedOptionsStateFilter: []
			}
		})
        handle_sync_filters("location", null)
	}
	
    const handleVerifyLocation = async(type) =>{
		if(type === "location"){
			let response = await getVerificationCoordinates(inputVerifyLocation)
			if(response.status === 200){
				if(response.data.data.length === 0){
					updateStates({
						"type": "reducerUpdates",
						"payload": {
							invalidLocationError: true
						}
					})
				}
				else{
					updateStates({
						"type": "reducerUpdates",
						"payload": {
							invalidLocationError: false
						}
					})
					let tempCoordinates = [{
						"Id": 0, "Location": inputVerifyLocation, "Latitude": response.data.data[0], "Longitude": response.data.data[1]
					}] 
					updateStates({
						"type": "reducerUpdates",
						"payload": {
							pinMapData: tempCoordinates
						}
					})
					// console.log(response.data.data)
				}
			 }
			 else{
				 check_error(response)
			}
		}
		else if(type === "coordinates"){
			let tempCoordinates = [{
				"Id": 0, "Location": "Unknown Location", "Latitude": inputVerifyLat, "Longitude": inputVerifyLan
			}]
			updateStates({
				"type": "reducerUpdates",
				"payload": {
					invalidLocationError: false,
					pinMapData: tempCoordinates
				}
			})
		}
	}

    const get_Articles_From_Prompt = async(message)=>{
		let response = {};
		updateStates({
			"type": "reducerUpdates",
			"payload": {
				map: 2
			}
		})
        response = await getArticlesFromPrompt(message)
		// console.log(response)
		
		if(response.status === 200){
			updateStates({
				"type": "reducerUpdates",
				"payload": {
					map: 1
				}
			})
			let test_rows = []
			// for(let i = 0; i < response.data.data.length; i++){
			// 	test_rows.push(createData(response.data.data[i].Title, response.data.data[i].sentiment,  response.data.data[i].published_date, response.data.data[i].keywords_p.Location, response.data.data[i]._id))
			// }

			updateStates({
				"type": "reducerUpdates",
				"payload": {
					sample_rows: response.data.data,
					total_data: response.data['totalCount'],
					curr_data: response.data['data'].length
				}
			})

			if(response.data['totalCount'] % countPerPage === 0){
				updateStates({
					"type": "reducerUpdates",
					"payload": {
						total_page: Math.floor(response.data['totalCount']/countPerPage)
					}
				})
			}
			else{
				updateStates({
					"type": "reducerUpdates",
					"payload": {
						total_page: Math.floor(response.data['totalCount']/countPerPage) + 1
					}
				})
			}
		}
		else{
			check_error(response)
		}
	}

    const filter_with_prompt = (event) =>{
		if (event.key === 'Enter'){
			if(promptQuery === ''){
				toast.error("Enter some prompt to search", { position: toast.POSITION.TOP_CENTER});
			}
			else{
				updateStates({
					"type": "reducerUpdates",
					"payload": {
						page: 1
					}
				})
				const message = {
					"pageNo": 1,
					"countPerPage": countPerPage,
					"query": promptQuery
				}
				get_Articles_From_Prompt(message)
				updateStates({
					"type": "reducerUpdates",
					"payload": {
						finalPromptQuery: promptQuery,
						// chartData: []
					}
				})
			}
		}
	}

    const handle_edit = () =>{
        updateStates({
            "type": "reducerUpdates",
            "payload": {
                filtersEditMode: true,
                approvedLocation: false,
                section: sectionApplied,
                category: appliedCategory,
                selectedDateRange: selectedDateRangeApplied,
                keywordsCategory: appliedKeywordsCategory,
                selectedOptions: selectedOptionsApplied,
                logicToggle: logicToggleApplied,
                sequentialToggle: sequentialToggleApplied,
                matchToggle: matchToggleApplied,
                selectedOptionsDomain: selectedOptionsDomainApplied,
                uniqueArticlesToggle: appliedUniqueArticlesToggle,
                selectedOptionsStateFilter: selectedOptionsStateFilterApplied,
                selectedOptionsLanguages: selectedOptionsLanguagesApplied
            }
        })
    }

    
    const handle_reset = () =>{

        const currentDate = new Date();
        const initialDate = new Date();
        initialDate.setDate(currentDate.getDate() - 2);
        updateStates({
            "type": "reducerUpdates",
            "payload": {
                approvedLocation: false,
                approvedLocationApplied: false,
                section: 1,
                sectionApplied: 1,
                category: defaultCategory,
                appliedCategory: defaultCategory,
                selectedDateRange: [initialDate, new Date()],
                selectedDateRangeApplied: [initialDate, new Date()],
                keywordsCategory: "Title & Body",
                appliedKeywordsCategory: "Title & Body",
                selectedOptions: [],
                selectedOptionsApplied: [],
                logicToggle: false,
                logicToggleApplied: false,
                sequentialToggle: false,
                sequentialToggleApplied: false,
                matchToggle: false,
                matchToggleApplied: false,
                selectedOptionsDomain: [],
                selectedOptionsDomainApplied: [],
                uniqueArticlesToggle: false,
                appliedUniqueArticlesToggle: false,
                selectedOptionsStateFilter: [],
                selectedOptionsStateFilterApplied: [],
                selectedOptionsLanguages: [],
                selectedOptionsLanguagesApplied: []
            }
        })
        
        const message = {
            "pageNo": "1",
            "countPerPage": countPerPage,
            'fromDate': moment(initialDate).format("YYYY-MM-DD"),
            'toDate': moment(new Date()).format("YYYY-MM-DD"),
            "searchText": "",
            "category": defaultCategory,
            "allWordMatch": false,
            "sequentialMatch": false,
            "exactMatch": false,
            "domain_name": "",
            "location": null,
            "sentiment": ''
        }
        if(section === 1){
            message["uniqueArticles"] = false 
        }
        get_Articles(message);

        const message_heatmap = {
            'fromDate': moment(initialDate).format("YYYY-MM-DD"),
            'toDate': moment(new Date()).format("YYYY-MM-DD"),
            "searchText": '',
            "category": defaultCategory,
        }

        heatmap_data_fetch(message_heatmap);
        heat_map_country_data_fetch(message_heatmap)

        get_event_bubble_data(message_heatmap)
        
        time_series_data_fetch(message_heatmap)
    }

    const handle_prompt_switch = () =>{
        handle_sync_filters("prompt_switch")
    }

    if(status === 1){
		console.log("worknig")
		return <Navigate to = {'/article/' + articleId} />
	}

	else if(status === 2){
		// return <Navigate to = '/login' />
        window.location.href = '/login';
	}	

	else if(status === 3){
		return <Navigate to = {'/draft'} />
	}

	else{

        return (
        <div className='right_root_dashboard_container'>
            {/* <PageAnimations /> */}
            {/* <div className="root_dashboard_header">
                <div className="prompt_toggler">
                    <div className="toggler_main_ele">
                        <div className='toggler_left_ele'>Prompt Mode</div>
                        <div className="form-check form-switch">
                            <input className="form-check-input toggle_input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked = {promptToggle} onChange={() => {
                                if(promptToggle){
                                    updateStates({
                                    "type": "reducerUpdates",
                                    "payload": {
                                        promptToggle: !promptToggle,
                                    }
                                    })
                                }
                                else{
                                    updateStates({
                                        "type": "reducerUpdates",
                                        "payload": {
                                            promptToggle: !promptToggle,
                                            articlesAnalyticsSwitch: "articles"
                                        }
                                    }) 
                                }
                            }} />
                        </div>
                    </div>
                </div>	
            </div> */}

            <div className="root_dashboard_filters">
                <div className="filter_header">
                    <div className="header">
                        {promptToggle ? 
                            "Prompt Mode" :
                            "Filters"
                        }    
                    </div>
                    <div className="prompt_toggler">
                    <div className="toggler_main_ele">
                        <div className='toggler_left_ele'>Prompt Mode</div>
                        <div className="form-check form-switch toggle_input_switch">
                            <input className="form-check-input toggle_input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked = {promptToggle} onChange={() => {
                                if(promptToggle){
                                    updateStates({
                                    "type": "reducerUpdates",
                                    "payload": {
                                        promptToggle: !promptToggle,
                                    }
                                    })
                                    handle_prompt_switch()
                                }
                                else{
                                    updateStates({
                                        "type": "reducerUpdates",
                                        "payload": {
                                            promptToggle: !promptToggle,
                                            articlesAnalyticsSwitch: "articles",
                                        }
                                    }) 
                                }
                            }} />
                        </div>
                    </div>
                </div>	
                </div>
                <div className="main_filters_wrapper">
                {promptToggle ?
                    <div className="prompt_container">
                    
                        <textarea rows="3" cols="50" type="text" value={promptQuery} className="input_prompt" placeholder='Type the prompt and hit Enter to Search' onChange={(e)=>
                            updateStates({
                                "type": "reducerUpdates",
                                "payload": {
                                    promptQuery: e.target.value
                                }
                            })
                        } onKeyDown={(e) => filter_with_prompt(e)}
                        >
                            </textarea>
                    </div>
                    :
                    <>
                        <div className="filters_new_container">
                            <div className="filter_layer_1">
                                <div className="category_new">
                                    <span>Category</span>
                                    <div className="category_value_new">
                                        <Select
                                            value={selectedOptionsCategory}
                                            onChange={handleCategory}
                                            options={sampleOptionsCategory}
                                            placeholder = "Search for Category"
                                        />
                                    </div>
                                </div>
                                <div className="date_range_new">
                                    <span>
                                        Date Range
                                    </span>
                                    <div className="date_range_value_new">
                                        <DateRangePicker 
                                            block
                                            placeholder="Select a Date Range"
                                            value={selectedDateRange}
                                            onChange={handleDateRangeChange}
                                            ranges={[
                                                
                                                {
                                                    label: 'today',
                                                    value: [startOfDay(new Date()), endOfDay(new Date())]
                                                },
                                                {
                                                    label: 'yesterday',
                                                    value: [startOfDay(addDays(new Date(), -1)), endOfDay(addDays(new Date(), -1))]
                                                },
                                                {
                                                    label: 'last7Days',
                                                    value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())]
                                                },
                                                {
                                                    label: 'last 2 Days',
                                                    value: [startOfDay(subDays(new Date(), 1)), endOfDay(new Date())]
                                                }
                                            ]}
                                            showOneCalendar
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="filter_layer_2">
                                <div className="keywords_new">
                                    <span>keyword</span>
                                    <div className="keywords_value_new">
                                        <div className="title_body_value_new" onClick={()=>{
                                            updateStates({
                                                "type": "reducerUpdates",
                                                "payload": {
                                                    keywordsDropdownCatergory: ! keywordsDropdownCatergory
                                                }
                                            })
                                        }}>
                                            <div className="keywords_main_text filter_value_items_text" >
                                                {keywordsCategory}
                                            </div>
                                            <img src={dropDown_open} alt="exp_arw" className={keywordsDropdownCatergory === true ? "keywords_dropdown_close":"keywords_dropdown"}/>
                                            {keywordsDropdownCatergory === true && 
                                                <div className="dropdown_container">
                                                    {	
                                                        ["Title & Body", "Body"].map((ctgry, index) => (
                                                            <div className={`ct_${index+1} dropdown_main_category`} key={index} onClick={()=>{
                                                                updateStates({
                                                                        "type": "reducerUpdates",
                                                                        "payload": {
                                                                            keywordsCategory: ctgry
                                                                        }
                                                                    })
                                                                    // if(selectedOptions.length > 0){
                                                                    //     handle_sync_filters("keywords category", ctgry)
                                                                    // }
                                                                }}>
                                                                {ctgry}
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            }
                                            
                                        </div>
                                        <div className="keywords_root_value_new">
                                            <CreatableSelect
                                                value={selectedOptions}
                                                onChange={handleKeywords}
                                                options={sampleOptions}
                                                placeholder = "Search for keywords"
                                                isMulti
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="conditions_new">
                                    <span>Conditions</span>
                                    <div className="conditions_value_new">
                                        <div className="condition_1_value_new conditions_value_new_main">
                                            <div className="left_new radio_wrapper">
                                                <input type="radio" className="left_radio" checked = {!logicToggle} onChange={()=>{
                                                    updateStates({
                                                        "type": "reducerUpdates",
                                                        "payload": {
                                                            logicToggle: ! logicToggle
                                                        }
                                                    })
                                                    handle_sync_filters("logic toggle", !logicToggle)
                                                    
                                                }}/>
                                                Or
                                            </div>
                                            <div className="right_new radio_wrapper">
                                                <input type="radio" className="right_radio" checked = {logicToggle} onChange={()=>{
                                                updateStates({
                                                    "type": "reducerUpdates",
                                                    "payload": {
                                                        logicToggle: ! logicToggle
                                                    }
                                                })
                                                    handle_sync_filters("logic toggle", !logicToggle)
                                                
                                                }}/>
                                                And
                                            </div>
                                        </div>
                                        <div className="condition_2_value_new conditions_value_new_main">
                                            <div className="left_new radio_wrapper">
                                                <input type="radio" className="left_radio" checked = {sequentialToggle} onChange={() =>{ 
                                            updateStates({
                                                "type": "reducerUpdates",
                                                "payload": {
                                                    sequentialToggle: !sequentialToggle
                                                }
                                            })
                                                handle_sync_filters("sequential toggle", !sequentialToggle)
                                            }}/>
                                                Sequential
                                            </div>
                                            <div className="right_new radio_wrapper">   
                                                <input type="radio" className="right_radio" checked = {!sequentialToggle} onChange={() =>{ 
                                            updateStates({
                                                "type": "reducerUpdates",
                                                "payload": {
                                                    sequentialToggle: !sequentialToggle
                                                }
                                            })
                                                handle_sync_filters("sequential toggle", !sequentialToggle)
                                            }}/>
                                                Non-Sequential                                         
                                            </div>
                                        </div>
                                        <div className="condition_3_value_new conditions_value_new_main">
                                            <div className="left_new radio_wrapper">
                                                <input type="radio" className="left_radio" checked = {matchToggle} onChange={() =>{ 
                                            updateStates({
                                                "type": "reducerUpdates",
                                                "payload": {
                                                    matchToggle: !matchToggle
                                                }
                                            })
                                                handle_sync_filters("match toggle", !matchToggle)
                                            }}/>
                                                Exact Match
                                            </div>
                                            <div className="right_new radio_wrapper"> 
                                                <input type="radio" className="right_radio" checked = {!matchToggle} onChange={() =>{ 
                                            updateStates({
                                                "type": "reducerUpdates",
                                                "payload": {
                                                    matchToggle: !matchToggle
                                                }
                                            })
                                                handle_sync_filters("match toggle", !matchToggle)
                                            }}/>
                                                Similar Match                                                             
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="filter_layer_3">
                                <div className="domains_new">
                                    <span>Domain</span>
                                    <div className="domains_value_new">
                                        <Select
                                            value={selectedOptionsDomain}
                                            onChange={handleDomains}
                                            options={fetchedDomains}
                                            placeholder = "Search for Domains"
                                            isMulti
                                        />
                                    </div>
                                </div>
                                <div className="languages_new">
                                    <span>Language</span>
                                    <div className="languages_value_new">
                                        <Select
                                            value={selectedOptionsLanguages}
                                            onChange={handleLanguages}
                                            options={sampleOptionsLanguagesFilter}
                                            placeholder = "Search for Languages"
                                            isMulti
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="filter_layer_4">
                                <div className="sentiment_new">
                                    <span className='sentiment_label_new'>Sentiment</span>
                                    <div className="sentiment_value_new">
                                        <Select
                                            value={selectedOptionsSentiment}
                                            onChange={handleSentiment}
                                            options={fetchedSentiments}
                                            placeholder = "Search for sentiment"
                                            isClearable= {true}
                                        />
                                    </div>
                                </div>
                                <div className="location_new">
                                    <span className='location_label_new'>
                                        Location
                                    </span>
                                    <div className="location_value_new">
                                        <div className="location_popup_input input_search_filter_input">						
                                        <div className="select_location_dropdown">
                                            <Select
                                                isClearable 
                                                value={selectedOptionsLocation}
                                                options={fetchedLocations} 
                                                placeholder = "Select Search Type for Location"
                                                onChange={handleLocations}
                                            />
                                        </div>
                                        {
                                        selectedOptionsLocation?.value === "By Location" && 
                                            <div className="verify_location_inputs_container">
                                                <input type="text" className="location_inputs" placeholder='Enter Location Name' value = {inputVerifyLocation} onChange={(e)=>
                                                    updateStates({
                                                        "type": "reducerUpdates",
                                                        "payload": {
                                                            inputVerifyLocation: e.target.value
                                                        }
                                                    })
                                                    }/>
                                                <button className="verify_location_btn btn btn-primary btn-sm" onClick={()=>handleVerifyLocation("location")} disabled={inputVerifyLocation === null || inputVerifyLocation === "" ? true: false}>
                                                    Verify
                                                </button>
                                            </div>
                                        }	
                                        {
                                        selectedOptionsLocation?.value === "By Coordinates" && 
                                            <div className="verify_coordinates_inputs_container">
                                                <input type="text" className="coordinates_inputs" placeholder='Enter x-coordinate' value = {inputVerifyLat} onChange={(e)=> 
                                                    updateStates({
                                                        "type": "reducerUpdates",
                                                        "payload": {
                                                            inputVerifyLat: e.target.value
                                                        }
                                                    })
                                                    }/>
                                                <input type="text" className="coordinates_inputs" placeholder='Enter y-coordinate'value = {inputVerifyLan} onChange={(e)=>
                                                    updateStates({
                                                        "type": "reducerUpdates",
                                                        "payload": {
                                                            inputVerifyLan: e.target.value
                                                        }
                                                    })
                                                    }/>
                                                <button className="verify_location_btn btn btn-outline-primary btn-sm" onClick={()=>handleVerifyLocation("coordinates")} disabled = {((inputVerifyLan === null || inputVerifyLan === "" || inputVerifyLan === undefined) || (inputVerifyLat === null || inputVerifyLat === "" || inputVerifyLat === undefined)) ? true:false}>
                                                    Verify
                                                </button>
                                            </div>
                                        }
                                        {
                                        selectedOptionsLocation?.value === "By States" && 
                                            <div className="verify_states_inputs_container">
                                                <div className="select_location_dropdown">
                                                    <Select
                                                        className='abcabc'
                                                        value={selectedOptionsStateFilter}
                                                        onChange={handleStateFilter}
                                                        options={sampleOptionsStateFilter}
                                                        placeholder = "Search for States"
                                                        isMulti
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {
                                        selectedOptionsLocation?.value === "By Country" && 
                                            <div className="verify_states_inputs_container">
                                                <div className="select_location_dropdown">
                                                    <Select
                                                        className='abcabc'
                                                        value={selectedOptionsCountryFilter}
                                                        onChange={handleCountryFilter}
                                                        options={sampleOptionsCountryFilter}
                                                        placeholder = "Search for Countries"
                                                        isMulti
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {
                                            invalidLocationError && <div className='invalideLocationError'>Please Enter a valid Location....</div>
                                        }
                                        {pinMapData.length !== 0 ?
                                            <>		
                                                <div className="radius_selector">
                                                    <div className="radius_tag">
                                                        Radius
                                                    </div>
                                                    <Slider value={locationRadius} aria-label="Default" valueLabelDisplay="auto" max={200} sx={{padding: "initial", margin: "initial"}} onChange={(e)=>{
                                                        updateStates({
                                                            "type": "reducerUpdates",
                                                            "payload": {
                                                                approvedLocation: false,
                                                                locationRadius: e.target.value
                                                            }
                                                        })
                                                    }
                                                    }/>
                                                </div>
                                                <div className='Map_approval_button'>
                                                    <div className="heat_map_verify_location">
                                                        <LeafletMaps key={JSON.stringify(pinMapData)} dataForMap = {pinMapData} radius = {locationRadius} zoom = {6}/>
                                                    </div>
                                                    <div className="approval_locaion_wrapper">
                                                        {/* {approvedLocation ?<img src={approved_tick} alt="" className="approval_tick" /> :<></>} */}
                                                        <button className="approval_locaion_wrapper btn btn-primary btn-sm" onClick={() =>{ 
                                                            updateStates({
                                                                "type": "reducerUpdates",
                                                                "payload": {
                                                                    approvedLocation: true,
                                                                    approvedLocationApplied: true,
                                                                    selectedOptionsLocationApplied: selectedOptionsLocation
                                                                }
                                                            })
                                                            if(selectedOptionsLocation.value === "By Location"){
                                                                updateStates({
                                                                    "type": "reducerUpdates",
                                                                    "payload": {
                                                                        inputVerifyLocationApplied: inputVerifyLocation,
                                                                        inputVerifyLanApplied: "",
                                                                        inputVerifyLatApplied: "",
                                                                    }
                                                                })
                                                            }
                                                            else if(selectedOptionsLocation === "By Coordinates"){
                                                                updateStates({
                                                                    "type": "reducerUpdates",
                                                                    "payload": {
                                                                        inputVerifyLocationApplied: inputVerifyLocation,
                                                                        inputVerifyLanApplied: inputVerifyLan,
                                                                        inputVerifyLatApplied: inputVerifyLat,
                                                                    }
                                                                })
                                                            }

                                                            handle_sync_filters("approve location", "location")
                                                        }}>Confirm Location</button>
                                                    </div>												
                                                </div>
                                            </>
                                                :
                                            <></>
                                        }	
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="filter_layer_5">
                                <div className="unique_articles_new">
                                    <span>Unique Articles</span>
                                    <div className="unique_articles_value_new">
                                        <div className="form-check form-switch">
                                            <input className="form-check-input toggle_input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked = {uniqueArticlesToggle} onChange={(e) =>{
                                                if(selectedOptionsCategory?.value === ""){
                                                        updateStates({
                                                            "type": "reducerUpdates",
                                                            "payload": {
                                                                uniqueArticlesToggle: false,
                                                            }
                                                        })
                                                        toast.info("Unique Articles cannot be used with Universal Repository", { position: toast.POSITION.TOP_CENTER, autoClose: 1000});
                                                }
                                                else{
                                                    updateStates({
                                                        "type": "reducerUpdates",
                                                        "payload": {
                                                            uniqueArticlesToggle: e.target.checked,
                                                        }
                                                    })
                                                    handle_sync_filters("unique articles", e.target.checked)
                                                }
                                                }
                                            } />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}
                </div>
            </div>

            <div className="root_dashboard_article_analytics_tabs_container">
                <div className="left_tabs">
                    <div className={"articles_tab left_tabs_content tabs_value_items_text" + (articlesAnalyticsSwitch === "articles" ? " left_tab_highlight": " left_tab_normal")} onClick={()=>{
                        updateStates({
                            "type": "reducerUpdates",
                            "payload": {
                                articlesAnalyticsSwitch: "articles"
                            }
                        })
                    }}>
                        <img src={articles_logo} alt="articles logo" className="articles_logo mr-2" />
                        Articles
                    </div>
                    <div className={"analytics_tab left_tabs_content tabs_value_items_text" + (articlesAnalyticsSwitch === "analytics" ? " left_tab_highlight": " left_tab_normal")} onClick={()=>{
                        if(!promptToggle){
                            updateStates({
                                "type": "reducerUpdates",
                                "payload": {
                                    articlesAnalyticsSwitch: "analytics"
                                }
                            })
                        }
                    }}>
                        <img src={analytics_logo} alt="analytics logo" className="analytics_logo mr-2" />
                        Analytics
                    </div>
                    {/* <div className={"articles_tab left_tabs_content tabs_value_items_text" + (articlesAnalyticsSwitch === "articles" ? " left_tab_highlight": "")} onClick={()=>{
                        updateStates({
                            "type": "reducerUpdates",
                            "payload": {
                                articlesAnalyticsSwitch: "articles"
                            }
                        })
                    }}>
                        Articles
                    </div> */}
                </div>
                {Object.keys(selectedArticles).length !== 0 && 
                    <div className="right_tabs">
                        {/* <button className="drafts_button btn btn-sm btn-outline-primary">
                            Cancel
                        </button> */}
                        <button className="drafts_button btn btn-sm btn-primary" onClick={()=>handle_draft_popup()}>
                            Push to Drafts
                        </button>
                    </div>
                }
            </div>

            <div className="analytics_articlels_main_wrapper">
                {articlesAnalyticsSwitch === "articles" 
                ? 
                    <div className="articles_wrapper">
                        <RootArticles />
                    </div>
                :
                    <div className="analytics_wrapper" title={promptToggle?'Disable Prompt Toggle to view Analytics':''}>
                        <RootAnalytics />    
                    </div>
                }
            </div>
            <ToastContainer/>
            <div className="dashboard_draft_filter_popup">
					<ReactModal
						isOpen={isOpenDraft}
						onRequestClose={closeModalDraft}
						contentLabel="Popup Modal"
						style={customStylesDraft}
					>	
					<div className="article_draft_popup_content_from_root">
						<div className="article_draft_popup_header">
							Add Articles to Draft
						</div>
						<div className="article_draft_search_bar_container">
							<CreatableSelect
								// className='abcabc'
								classNamePrefix="custom-dropdown"
								options={fetchedDrafts}
								placeholder="Select Existing Draft or Create New"
								isClearable
								value={selectedValue}
								onChange={handleSelectChange}
								styles={{
									menu: (provided) => ({
									...provided,
										maxHeight: '20vh', // Set the desired height for the dropdown
									overflowY: 'hidden',  // Enable scrolling if options exceed maxHeight
									}),
								}}
						
							/>
						</div>
						<div className="root_draft_button">
                            <button className="cancel btn btn-light btn-sm" onClick={()=>closeModalDraft()}>
                                Cancel
							</button>
							<button className="button_main btn btn-primary btn -sm" onClick={()=>handle_create_draft()} disabled = {selectedValue?false:true}>
								Add to Draft
							</button>
						</div>
					</div>
					</ReactModal>	
            </div>	
        </div>
        )
}
}
