import React from 'react'
import "../../../staticFiles/cssFiles/rootDashboard/rootArticles/rootArticles.css"
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../../store'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getArticles, getArticlesFromPrompt, getFrequentKeywords } from '../../../endppoints/endpoint'
import moment from 'moment/moment'

import { ToastContainer, toast } from 'react-toastify'

import loading from "../../../staticFiles/images/loading.svg"
import no_result_logo from "../../../staticFiles/images/No_results.svg"

import { Checkbox, Pagination, Tooltip, Stack } from '@mui/material';
import Cookies from 'js-cookie'
import { useEffect } from 'react'

import translate_icon from "../../../staticFiles/images/translate_icon.svg"

export const RootArticles = () => {
	// const [selectedArticles, setSelectedArticles] = React.useState({})

    const dispatch = useDispatch()
	const {updateStates} = bindActionCreators(actionCreators, dispatch);

    const {
		isOpen,
		fromDate,
		toDate,
		fromDatePage,
		toDatePage,
        selectedDateRange,
        selectedDateRangeApplied,
		page,
		sample_rows,
		status,
		articleId,
		total_data,
		curr_data,
		total_page,
		selectedOptions,
		expKey,
		map,
		section,
        sectionApplied,
		sampleOptions,
        selectedOptionsApplied,
		fetchedDomains,
		logicToggle,
		sequentialToggle,
		matchToggle,
		logicToggleApplied,
		sequentialToggleApplied,
		matchToggleApplied,
		selectedOptionsDomain,
		selectedOptionsDomainApplied,
		selectedOptionsLocation,
		selectedOptionsLocationApplied,
		dropdownCatergory,
		categories,
        category,
		appliedCategory,
		promptToggle,
		promptQuery,
		finalPromptQuery,
		fetchedDrafts,
		selectedValue,
		isOpenDraft,
		defaultCategory,
		uniqueArticlesToggle,
		appliedUniqueArticlesToggle,
		showVerifyMap,
		inputVerifyLocation,
		inputVerifyLocationApplied,
		fetchedCoordinates,
		pinMapData,
		inputVerifyLat,
		inputVerifyLan,
		inputVerifyLatApplied,
		inputVerifyLanApplied,
		invalidLocationError,
		approvedLocation,
		approvedLocationApplied,
		inputVerifyLatLanByLocation,
		inputVerifyLatLanByLocationApplied,
		locationRadius,
		locationRadiusApplied,
		selectedOptionsSentiment,
		selectedOptionsSentimentApplied,
		chartData,
        keywordsCategory,
        appliedKeywordsCategory,
        keywordsDropdownCatergory,
        articlesAnalyticsSwitch,
        filtersEditMode,
        showDraftIcons,
        selectedArticles,
		countPerPage,
		translateToggle,
		selectedOptionsStateFilter,
		selectedOptionsStateFilterApplied,
		selectedOptionsLanguages,
        selectedOptionsLanguagesApplied,

		selectedOptionsCategory,
		
    } = useSelector(state => state.dashboardState);

    // useEffect(()=>{
    //     if(Object.keys(selectedArticles).length > 0){
    //         updateStates({
    //             "type": "reducerUpdates",
    //             "payload": {
    //                 showDraftIcons: true
    //             }
    //         }) 
    //     }
    //     else{
    //         updateStates({
    //             "type": "reducerUpdates",
    //             "payload": {
    //                 showDraftIcons: false
    //             }
    //         })  
    //     }
    // }, [selectedArticles])

    const check_error = (response) =>{
        if(response.status === 401){
            localStorage.clear()
            Cookies.remove('token');
            setTimeout(() => {
                updateStates({
					"type": "reducerUpdates",
					"payload": {
						status: 2
					}
				})
            }, 1000);
            toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
        }
        else{
            toast.info("server error", { position: toast.POSITION.TOP_CENTER})
        }
    }

	const get_Articles = async(message)=>{
		let response = {};
		updateStates({
			"type": "reducerUpdates",
			"payload": {
				map: 2
			}
		})
        response = await getArticles(message)
		console.log(response)
		
		if(response.status === 200){
			updateStates({
				"type": "reducerUpdates",
				"payload": {
					map: 1
				}
			})
			const newValues = new Array(response.data['data'].length).fill(true);

			updateStates({
				"type": "reducerUpdates",
				"payload": {
					sample_rows: response.data.data,
					total_data: response.data['totalCount'],
					curr_data: response.data['data'].length,
					translateToggle: newValues
				}
			})

			if(response.data['totalCount'] % countPerPage === 0){
				updateStates({
					"type": "reducerUpdates",
					"payload": {
						total_page: Math.floor(response.data['totalCount'] / countPerPage)
					}
				})
			}
			else{
				updateStates({
					"type": "reducerUpdates",
					"payload": {
						total_page: Math.floor(Math.floor(response.data['totalCount'] / countPerPage) + 1)
					}
				})
			}
			fetch_frequent_keywords();
		}

		else {
            check_error(response);
        }
	}

    const fetch_frequent_keywords = async() =>{
        let response = await getFrequentKeywords()
        console.log(response, response.data.length)
        if(response.status === 200){
		   	let test_array = []
		   	for(let i = 0; i < response.data.length; i++){
				test_array.push(response.data[i].keyword.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()))
		   	}

		   	const temportary_options = test_array.map(item => {
				return {value: item, label: item}
			});
			
			updateStates({
				"type": "reducerUpdates",
				"payload": {
					sampleOptions: temportary_options
				}
			})
        }
        else {
            check_error(response)
        }
    }


    const get_Articles_From_Prompt = async(message)=>{
		let response = {};
		updateStates({
			"type": "reducerUpdates",
			"payload": {
				map: 2
			}
		})
        response = await getArticlesFromPrompt(message)
		console.log(response)
		
		if(response.status === 200){
			updateStates({
				"type": "reducerUpdates",
				"payload": {
					map: 1
				}
			})
			let test_rows = []
			// for(let i = 0; i < response.data.data.length; i++){
			// 	test_rows.push(createData(response.data.data[i].Title, response.data.data[i].sentiment,  response.data.data[i].published_date, response.data.data[i].keywords_p.Location, response.data.data[i]._id))
			// }

			updateStates({
				"type": "reducerUpdates",
				"payload": {
					sample_rows: response.data.data,
					total_data: response.data['totalCount'],
					curr_data: response.data['data'].length
				}
			})

			if(response.data['totalCount'] % countPerPage === 0){
				updateStates({
					"type": "reducerUpdates",
					"payload": {
						total_page: Math.floor(response.data['totalCount']/countPerPage)
					}
				})
			}
			else{
				updateStates({
					"type": "reducerUpdates",
					"payload": {
						total_page: Math.floor(response.data['totalCount']/countPerPage) + 1
					}
				})
			}
		}
		else{
			check_error(response)
		}
	}

    const handlePage = (event, value) =>{
        if (promptToggle){
			if(finalPromptQuery === ""){
				toast.error("Enter some prompt to search", { position: toast.POSITION.TOP_CENTER});
			}
			else{
				// setPage(value)
				updateStates({
					"type": "reducerUpdates",
					"payload": {
						page: value
					}
				})
				const message = {
					"pageNo": value,
					"countPerPage": countPerPage,
					"query": finalPromptQuery
				}
				get_Articles_From_Prompt(message)
				// setChartData([])
				updateStates({
					"type": "reducerUpdates",
					"payload": {
						chartData: []
					}
				})
			}
		}
        else{


            updateStates({
                "type": "reducerUpdates",
                "payload": {
                    page: value
                }
            })
            let allFilters = []
            for(let i = 0; i < selectedOptionsApplied.length; i++){
                allFilters.push(selectedOptionsApplied[i].value)
            }
            let allDomainFilters = []
            for(let i = 0; i < selectedOptionsDomainApplied.length; i++){
                allDomainFilters.push(selectedOptionsDomainApplied[i].value)
            }

            let allFiltersLanguages = []
            for(let i = 0; i < selectedOptionsLanguagesApplied.length; i++){
                allFiltersLanguages.push(selectedOptionsLanguagesApplied[i].value)
            }

            const message = {
                "pageNo": value,
				"countPerPage": countPerPage,
                'fromDate': selectedDateRange? moment(selectedDateRange[0]).format("YYYY-MM-DD"): '',
                'toDate': selectedDateRange? moment(selectedDateRange[1]).format("YYYY-MM-DD"): '',
                "searchText": selectedOptions.map(item => item.value).join(' '),
                "category": selectedOptionsCategory.value,
                "allWordMatch": logicToggle,
                "sequentialMatch": sequentialToggle,
                "exactMatch": matchToggle,
                "domain_name": selectedOptionsDomain.map(item => item.value),
				"languages": selectedOptionsLanguages.map(item => item.value),
                "location": 
				(selectedOptionsLocation?.value === "By States" && selectedOptionsStateFilter?.length > 0)?
                {
                    "searchType": "stateCode",
                    "searchInput":{
                        "stateCodes" : selectedOptionsStateFilter.map(item => item.value)
                    }
                }
                :
                (selectedOptionsLocation?.value === "By Location" || selectedOptionsLocation?.value  === "By Coordinates")?
				{
                    "searchType": "radius",
                    "searchInput":{
                    "coordinates": [parseFloat(inputVerifyLatLanByLocationApplied[0]["Latitude"]), parseFloat(inputVerifyLatLanByLocationApplied[0]["Longitude"])],
                        "radius": locationRadiusApplied
                    }
                    }: null,
                "sentiment": (selectedOptionsSentiment === null || selectedOptionsSentiment === '') ? null: selectedOptionsSentiment
            }
            

			if(selectedOptionsCategory.value !== ""){
				message["uniqueArticles"] = uniqueArticlesToggle
			}
			console.log(message)

            get_Articles(message);
        }
    }

    const handleSyncCheckBox = () =>{
		let flag = true
		for(let i =0; i < sample_rows.length; i++){
			if(selectedArticles[sample_rows[i]["_id"]] === undefined){
				flag = false
				break;
			}
		}
		return flag
	}
    
    const handleCheckBox = (e, id) =>{

		const isChecked = e.target.checked;

		if (isChecked) {
			// setSelectedArticles(prevSelectedIds => ({
			//   ...prevSelectedIds,
			//   [id]: true,
			// }));

            updateStates({
                "type": "reducerUpdates",
                "payload": {
                    selectedArticles: {
                        ...selectedArticles,
                        [id]: true,
                    }
                }
            })

			console.log("checked");
		  } else {
            const { [id]: removedItem, ...updatedSelectedArticles } = selectedArticles;

			// setSelectedArticles(prevSelectedIds => {
			//   const { [id]: _, ...restIds } = prevSelectedIds;
			//   return restIds; // This is needed to update the state correctly
			// });

            updateStates({
                "type": "reducerUpdates",
                "payload": {
                    selectedArticles: updatedSelectedArticles
                }
            })

			console.log("unchecked");
		  }
	}

    const handleCheckBoxAll = (e) => {
		const isChecked = e.target.checked;
	  
		if (isChecked) {
		//   setSelectedArticles(prevSelectedIds => {
		// 	const updatedSelectedIds = { ...prevSelectedIds };
		// 	sample_rows.forEach(item => {
		// 	  updatedSelectedIds[item._id] = true;
		// 	});
		// 	return updatedSelectedIds;
		//   });
          
          const updatedSelectedIds = selectedArticles
          sample_rows.forEach(item => {
            updatedSelectedIds[item._id] = true;
          });

          updateStates({
            "type": "reducerUpdates",
            "payload": {
                selectedArticles: updatedSelectedIds
            }
        })

	  
		  console.log("checked");
		} else {
           const updatedSelectedIds = selectedArticles

		//   setSelectedArticles(prevSelectedIds => {
		// 	const updatedSelectedIds = { ...prevSelectedIds };
		// 	sample_rows.forEach(item => {
		// 	  if (updatedSelectedIds[item._id]) {
		// 		delete updatedSelectedIds[item._id];
		// 	  }
		// 	});
		// 	return updatedSelectedIds;
		//   });

            sample_rows.forEach(item => {
			  if (updatedSelectedIds[item._id]) {
				delete updatedSelectedIds[item._id];
			  }
			});
          updateStates({
            "type": "reducerUpdates",
            "payload": {
                selectedArticles: updatedSelectedIds
            }
        })
	  
		  console.log("unchecked");
		}
	};

    const navArticle =(art_id)=>{
		// console.log(art_id);
		// // setArticleId(art_id);
		updateStates({
			"type": "reducerUpdates",
			"payload": {
				articleId: art_id,
				status: 1
			}
		})
		// // console.log(window.location.origin)
		// window.open(window.location.origin + '/article/' + art_id, '_blank', 'noreferrer');
		// setStatus(1)
	}
	
	const handleToggleTranslate = (index) =>{
        const updatedArray = [...translateToggle];
        updatedArray[index] = !translateToggle[index]; 
		updateStates({
            "type": "reducerUpdates",
            "payload": {
                translateToggle: updatedArray
            }
        })
    }

    return (
        <div className='root_articles_container'>
            <div className="table_container">
                {   map === 0 ? <div>No data to show</div>: 
                    map === 1 ? 
                    sample_rows.length === 0? 
                        <div className='no_results_container '> 
                            <img src={no_result_logo} alt="" className="no_results child_no_results" /> 
                            <div className="no_results_header child_no_results">
                                No results found
                            </div>
                            <div className="no_results_subheader child_no_results">
                                Try adjusting your filters to find out what you are looking for 
                            </div>
                        </div> :
                        <TableContainer component={Paper} sx={{  height: '100%' , boxShadow: '0px 20px 50px 0px #0000001A', borderRadius: '8px 8px 0px 0px', backdropFilter: 'blur(10px)', background: "transparent"}}>
                            <Table stickyHeader>
                                <TableHead sx = {{ fontFamily: "Inter"}}>
                                    <TableRow>
                                        <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '17.5px'}} align="left"><Checkbox checked= {handleSyncCheckBox()} onChange = {(event)=>handleCheckBoxAll(event)}/></TableCell>
                                        <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px', width: "45%"}} align="left">Article</TableCell>
                                        {Cookies.get("userOrg") === "SEBI" && <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px'}} align="left">Sentiment</TableCell>}
                                        <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px', width: "30%", paddingLeft: "5vw"}} align="left">Date</TableCell>
                                        <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px', width: "25%"}} align="left">Region</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ height: "max-content" }}>
                                    {sample_rows.map((row, index) => (
                                        <TableRow 
                                            key={row._id}
                                            sx={{ background: 'rgba(233, 233, 233, 0.35)', cursor: "pointer", '&:hover': {borderLeft: "4px solid #3B89FE !important"}}}
                                            onClick={(event) => {
                                                // navArticle(row.id)
                                                if (!event.target.closest('.unique_checkbox')) {
                                                    navArticle(row._id);
                                                }
                                            }
                                            }
                                            >	
                                            <TableCell><Checkbox className='unique_checkbox' onChange = {(event)=>handleCheckBox(event, row._id)} checked = {selectedArticles[row._id] === undefined? false: true}/></TableCell>
                                            <TableCell component="th" scope="row" 
                                                sx = {{fontFamily: "Inter",fontSize: '16px', fontWeight: "600", '&:hover': {borderLeft: "4px solid #3B89FE !important"}}}  
                                                >
												{row.Title_translated && <><img src={translate_icon} alt="translate_icon" className="translate_text_icon" onClick={(e)=>{e.stopPropagation();handleToggleTranslate(index);}}/><>&nbsp;&nbsp;</></>}
												{(row.Title_translated && translateToggle[index]) ? 
													<>{row.Title_translated.slice(0, 88)}{(row.Title_translated.length <= 88 ? '' : '...')}</>
													: 
													<>{row.Title.slice(0, 88)}{(row.Title.length <= 88 ? '' : '...')}
													</>
												}
                                                {/* {row.Title.slice(0, 88)}{row.Title.length <= 88 ? '' : '...'} */}
                                                
                                            </TableCell>
                                            {Cookies.get("userOrg") === "SEBI" &&
                                            <TableCell align="left"  
                                                sx = {{ fontFamily: "Inter", fontWeight: "600", fontSize: '16px'}}>

                                                {row.sentiment==='NEGATIVE' && <div className="dot_negative">{row.sentiment.charAt(0).toUpperCase() + row.sentiment.slice(1).toLowerCase()}</div>}
                                                {row.sentiment==='POSITIVE' && <div className="dot">{row.sentiment.charAt(0).toUpperCase() + row.sentiment.slice(1).toLowerCase()}</div>}
                                                {row.sentiment==='NEUTRAL' && <div className="dot_neutral">{row.sentiment.charAt(0).toUpperCase() + row.sentiment.slice(1).toLowerCase()}</div>}
                                                {/* {row.sentiment} */}

                                            </TableCell>
                                            }
                                            <TableCell align="left" 
                                                sx = {{ fontFamily: "Inter", fontSize: '16px', paddingLeft: "5vw" }}>
                                                    
                                                    <div>{(moment(row.published_date.slice(0, 10)).format('DD/MM/YYYY'))}&nbsp;&nbsp;{row.published_date.slice(11, 16)}</div>
                                                    
                                            </TableCell>
                                            <TableCell align="left" 
                                                sx = {{ fontFamily: "Inter", fontWeight: "600" ,fontSize: '16px' }}>
                                                <Tooltip title={ 
                                                    // <div style={{ backgroundColor: '#ffffff', fontFamily: "Inter", fontWeight: "600" ,fontSize: '18px', color: "#000000", marginTop: "1.7vh", padding: "1vh 1vh 0vh 0vh", overflowY: "auto", maxHeight: "30vh"}}>
                                                    <div style={{ backgroundColor: '#ffffff', fontFamily: "Inter", fontWeight: "600" ,fontSize: '18px', color: "#000000", padding: "1vh 1vh 0vh 0vh", overflowY: "auto", maxHeight: "30vh"}}>
                                                        <ul >
                                                            {row.keywords_p.Location.map((region, index) => (
                                                            <li key={index}>{region}</li>
                                                            ))}
                                                        </ul>
                                                    </div>		
                                                    } placement="top-start" arrow  >
                                                    
                                                    
                                                    <div>
                                                        {row.keywords_p.Location.length !== 0 ? (row.keywords_p.Location.length > 1 ? row.keywords_p.Location[0] + " ..." : row.keywords_p.Location[0]) : "No Regions"}
                                                    </div>
                                                </Tooltip>	
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>:
                    map === 2 ? <div style={{display: "flex" , alignItems: "center", justifyContent: "center", height: '65vh'}}><img src={loading} alt="laoding svg" className="loading" /></div>: 
                    <div>some error ....try refreshing the page</div>   
                }

                {	sample_rows.length === 0 ? <></>:
                        <div className="pagination_container">
                            <div className="display_pages">
                                Showing &nbsp;<span>{total_page !== 0 ? (page-1)*countPerPage + 1 : 0}</span>-<span>{(page-1)*countPerPage + curr_data}</span>&nbsp;from&nbsp;<span>{total_data}</span>&nbsp;data 	
                            </div>
                            <div className="nav_container">
                                <Stack spacing={2}>
                                    <Pagination count={total_page} page = {page} color='primary' value={page} onChange={handlePage} variant="outlined" sx={{fontFamily: "Inter"}}/>
                                </Stack>
                            </div>
                        </div>
                }
            </div>
            <ToastContainer />
        </div>
    )
}
