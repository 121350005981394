import React from 'react'
import "../../staticFiles/cssFiles/adminUsersPanel/rightAdminUsersPanel.css"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import { Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';
import { addUsers, deleteUsers, fetchUsers } from '../../endppoints/endpoint';

export const RightAdminUsersPanel = () => {
	const { redirectOrg } = useParams();
	const [addUser, setAddUser] = React.useState(0)
	const [redirect, setRedirect] = React.useState(0)
	const [userRows, setUserRows] = React.useState([])

	const [newUserName, setNewUserName] = React.useState('')
	const [newUserEmail, setNewUserEmail] = React.useState('')
	const [newUserRole, setNewUserRole] = React.useState('')
	const [newUserPassword, setNewUserPassword] = React.useState('')

	React.useEffect(()=>{
		fetch_users()
	}, []);

	const create_payload = (pN, lI, sD, sT)=>{
		let message = {
			"pageNo":pN,
			"limit": lI,
			"sortDirection": sD,
			"searchText": sT
		}
		return message;
	}

	function createData(crud_username, crud_email, crud_role) {
        return { crud_username, crud_email, crud_role};
    }

	const fetch_users = async()=>{
		let message = create_payload(1, 10, 1, "")
		message["orgId"] = redirectOrg;

		let response = await fetchUsers(message);
		console.log()
		if(response.status === 200){
			let test_array = []
			console.log(response.data)
			for(let i = 0; i < response.data.data.length; i++){
				test_array.push(createData(response.data.data[i].userName, response.data.data[i].userEmail, response.data.data[i].role))
			}
			console.log(test_array)
			setUserRows(test_array)
		}
		else if(response.status === 401){
			// localStorage.clear()
            // Cookies.remove('token');
            // setTimeout(() => {
            //     setRedirect(1)    
            // }, 1000);
            toast.error("Unauthorized", { position: toast.POSITION.TOP_CENTER});
        }
        else{
            toast.info("server error", { position: toast.POSITION.TOP_CENTER})
        }
	}

	const handle_add_user = (event) =>{
        event.preventDefault();
		add_user()
	}

	const add_user = async()=>{
		let message = create_payload(1, 10, 1, "")
		message["orgId"] = redirectOrg;
		message["userName"] = newUserName
		message["role"] = newUserRole
		message["userEmail"] = newUserEmail
		message["password"] = newUserPassword
		let response = await addUsers(message)
		if(response.status === 200){
			let test_array = []
			console.log(response.data)
			for(let i = 0; i < response.data.data.length; i++){
				test_array.push(createData(response.data.data[i].userName, response.data.data[i].userEmail, response.data.data[i].role))
			}
			console.log(test_array)
			setUserRows(test_array)
			setAddUser(0)
            toast.info("ADDED "+ newUserName, { position: toast.POSITION.TOP_CENTER});
		}
		else if(response.status === 401){
			localStorage.clear()
            Cookies.remove('token');
            setTimeout(() => {
                setRedirect(1)    
            }, 1000);
            toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
        }
        else{
            toast.info("server error", { position: toast.POSITION.TOP_CENTER})
        }
	}

	// const rows = [
    //     createData('Organzation A'),
    //     createData('Organzation B'),
    //     createData('Organzation C'),
    //     createData('Organzation D'),
    //     createData('Organzation E'),
    //     createData('Organzation F'),
    //     createData('Organzation G'),
    //     createData('Organzation H'),
    //     createData('Organzation I'),
    //     createData('Organzation J'),
	// ];

	const handle_delete_users = (user_name) =>{
		delete_users(user_name)
	}

	const delete_users = async(user_name) =>{
		let message = create_payload(1, 10, 1, "")
		message["userName"] = user_name
		message["orgId"] = Cookies.get('userOrg')
		let response = await deleteUsers(message)
		if(response.status === 200){
			let test_array = []
			console.log(response.data)
			for(let i = 0; i < response.data.data.length; i++){
				test_array.push(createData(response.data.data[i].userName, response.data.data[i].userEmail, response.data.data[i].role))
			}
			console.log(test_array)
			setUserRows(test_array)
			setAddUser(0)
            toast.info("DELETED "+ user_name, { position: toast.POSITION.TOP_CENTER});
		}
		else if(response.status === 401){
			localStorage.clear()
            Cookies.remove('token');
            setTimeout(() => {
                setRedirect(1)    
            }, 1000);
            toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
        }
        else{
            toast.info("server error", { position: toast.POSITION.TOP_CENTER})
        }
	}

	if(redirect === 1){
		// return <Navigate to = "/login" />
		window.location.href = '/login';
	}

  return (
    <div className='right_admin_users_panel_container'>
        <div className="admin_panel_data_container">
			{ addUser === 0 ?
				<>
					<div className="admin_panel_filters_container">
					<button className={"btn btn-primary toggle_1 toggle_buttons"}>
						Users
					</button>
					<input type="text" className="search_admin_panel" placeholder='Search' />
					<button className="add_organzation btn btn-sm btn-primary" onClick={()=>setAddUser(1)}>
						Add User
					</button>
					</div>
					<div className="admin_panel_table_container">
					{/* <TableContainer sx={{  height: "60vh", minHeight: "21vh", maxHeight: "62vh"}}> */}
					<TableContainer sx={{ minHeight: "21vh", maxHeight: "62vh"}}>
						<Table stickyHeader >
							<TableHead sx = {{ fontFamily: "Inter"}}>
								<TableRow>
									<TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px'}}>Username</TableCell>
									<TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px'}} align="left">Email</TableCell>
									<TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px'}} align="left">Role</TableCell>
									<TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px'}} align="left"></TableCell>
								</TableRow>
							</TableHead>
							<TableBody sx={{ height: "max-content" }}>
								{userRows.map((row, index) => (
									<TableRow 
									key={index}
									>   
										{/* <TableCell component="th" scope="row" sx = {{ fontFamily: "Inter", fontWeight: "bold",  fontSize: '15px', color: "#3B89FE", cursor: "pointer"}}  onClick={()=>handle_toggle(row.crud_organization)}> */}
										<TableCell component="th" scope="row" sx = {{ fontFamily: "Inter", fontWeight: "bold",  fontSize: '15px', color: "rgba(0, 0, 0, 0.87)"}}>
											{row.crud_username}
										</TableCell>
										<TableCell align="left" sx = {{ fontFamily: "Inter", fontWeight: "bold",  fontSize: '15px', color: "rgba(0, 0, 0, 0.87)"}}>
											{row.crud_email}
										</TableCell>
										<TableCell align="left" sx = {{ fontFamily: "Inter", fontWeight: "bold",  fontSize: '15px', color: "rgba(0, 0, 0, 0.87)"}}>
											{row.crud_role}
										</TableCell>
										<TableCell align="left" sx = {{ fontFamily: "Inter" }}>
											{/* <button type="button" className="btn btn-outline-danger btn-sm delete_button" aria-label="Close" onClick={()=>handle_delete_organization(row.crud_organization)}>Delete</button> */}
											<button type="button" className="btn btn-outline-danger btn-sm delete_button" aria-label="Close" onClick={()=>handle_delete_users(row.crud_username, )}>Delete</button>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					</div>
				</>:
				<div className="add_organization_container">
                    <form onSubmit={handle_add_user}>

						<div className="new_user_inputs">
							<div className="new_inputs">
                            	<label htmlFor="exampleInputPassword1">User Name</label>
								<input type="text" placeholder='Enter Username' className="input_organization common_input_add" onChange={(event)=>setNewUserName(event.target.value)} required/>
							</div>
							<div className="new_inputs">
								<label htmlFor="exampleInputPassword1">User Role</label>
								<input type="text" placeholder='admin/user' className="input_organization common_input_add" onChange={(event)=>setNewUserRole(event.target.value)} required/>
							</div>
							<div className="new_inputs">
								<label htmlFor="exampleInputPassword1">User Email</label>
								<input type="email" placeholder='Enter User Email' className="input_organization common_input_add" onChange={(event)=>setNewUserEmail(event.target.value)} required/>
							</div>
							<div className="new_inputs">
								<label htmlFor="exampleInputPassword1">User Password</label>
								<input type="password" placeholder='Enter User Password' className="input_organization common_input_add" onChange={(event)=>setNewUserPassword(event.target.value)} required/>
							</div>
						</div>

						{/* <button className="btn btn-primary add_input_organization common_input_add" onClick={()=>handle_add_organization()}>Add Organization</button> */}		
						<button type="submit" className="btn btn-primary add_input_organization common_input_add">Add User</button>
					</form>
				</div>	
			}
        </div>
		<ToastContainer />

    </div>
  )
}
