import React from 'react'
import { LeftDashboard } from '../Dashboard/left_dashboard'
import Cookies from 'js-cookie'
import { RightReportGenArticles } from './rightReportGenArticles'
import "../../staticFiles/cssFiles/reportGenArticles/reportGenArticles.css"

export const ReportGenArticles = () => {
  if(Cookies.get('token') === undefined){
    console.log("working")
    window.location.href = '/login';
}
  return (
    <div className='report_gen_articles_container'>
        <LeftDashboard id={'4_1'}/>
        <RightReportGenArticles/>
    </div>
  )
}
