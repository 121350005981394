import React from 'react'
import { Navigate } from 'react-router'
import Cookies from 'js-cookie'
import '../../staticFiles/cssFiles/Profile/right_profile.css'
// import exp_arw from '../../staticFiles/images/profile_dropdown.svg'
// import col_arw from '../../staticFiles/images/profile_dropdown_up.svg'
import { addFrequentKeywords, addOrgKeywords, categoriesDefault, categoriesFavourite, changeConcernDomains, deleteFrequentKeywords, deleteOrgKeywords, fetchOrgKeywords, getFrequentKeywords, getProfileCategories, getProfileDomains, getUserDetails, resetPassword, updateOrgKeywords } from '../../endppoints/endpoint'
import eye_icon from '../../staticFiles/images/eye_icon.svg'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import cross from "../../staticFiles/images/x-circle.svg"
import delete_profile_key from "../../staticFiles/images/delete_profile_key.svg"
import add_profile_key from "../../staticFiles/images/add_keyword.svg"
import edit_profile_key from "../../staticFiles/images/edit_org_keywords.svg"
// import edit from "../../staticFiles/images/edit_org_keyword.svg"
import ReactModal from 'react-modal';
// import add_org_keyword_icon from "../../staticFiles/images/add_org_keyword.svg"
import { Pagination, Stack } from '@mui/material'

import { PageAnimations } from '../GlobalPageAnimations.jsx/pageAnimations';
import searchIcon from "../../staticFiles/images/searchIcon.svg"


// import { Checkbox } from '@mui/material';

export const RightProfile = () => {
    // const [dropdown, setDropdown] = React.useState(true)
    const [redirect, setRedirect] = React.useState(0)
    const [userName, setUserName] = React.useState('')
    const [userEmail, setUserEmail] = React.useState('')
    const [userOrg, setUserOrg] = React.useState('')
    const [type_psw, setType_Psw] = React.useState('password')
    const [type_psw_1, setType_Psw_1] = React.useState('password')
    const [errorStatus, setErrorStatus] = React.useState('') 
    const [isVisible, setIsVisible] = React.useState(0)

    const [currPass, setCurrPass] = React.useState('')
    const [newPass, setNewPass] = React.useState('')
    const [confirmPass, setConfirmPass] = React.useState('')
    const [rows, setRows] = React.useState([])
    const [addKeyword, setAddKeyword] = React.useState('')
    const [totalKeywords, setTotalKeywords] = React.useState(0)
    const [headerActive, setHeaderActive] = React.useState(0)
    const [rowsSources, setRowsSources] = React.useState([])
    const [rowsCategories, setRowsCategories] = React.useState([])
    const [totalSources, setTotalSources] = React.useState(0)
    const [totalCategories, setTotalCategories] = React.useState(0)
    const [domainPage, setDomainPage] = React.useState(1)
    const [categoryPage, setCategoryPage] = React.useState(1)
    const [domainTotalPage, setDomainTotalPage] = React.useState(0)
    const [categoryTotalPage, setCategoryTotalPage] = React.useState(0)
    const [domainSearchText, setDomainSearchText] = React.useState("")
    const [finalDomainSearchText, setFinalDomainSearchText] = React.useState("")
    const [categorySearchText, setCategorySearchText] = React.useState("")
    const [finalCategorySearchText, setFinalCategorySearchText] = React.useState("")
    const [rowsOrgKeywords, setRowsOrgKeywords] = React.useState([])
    const [totalOrgKeywords, setTotalOrgKeywords] = React.useState(0)
    const [orgKeywordsPage, setOrgKeywordsPage] = React.useState(1)
    const [orgKeywordsTotalPage, setOrgKeywordsTotalPage] = React.useState(0)
    const [orgKeywordsSearchText, setOrgKeywordsSearchText] = React.useState("")
    const [finalOrgKeywordsSearchText, setFinalOrgKeywordsSearchText] = React.useState("")
    const [isOpenUpdateModal, setIsOpenUpdateModal] = React.useState(false)
    const [selectedOrgKeywordValue, setSelectedOrgKeywordValue] = React.useState("") 
	// const [selectedKeywords, setSelectedKeywords] = React.useState({})
    const [isModalAdd, setIsModalAdd] = React.useState(false)
    const [selectedKeywordForUpdate, setSelectedKeywordForUpdate] = React.useState(null)


    React.useEffect(()=>{
        sendRequest();
        fetch_frequent_keywords();
    }, []);

    const closeUpdateModal = () => {
        setSelectedKeywordForUpdate(null)
        setSelectedOrgKeywordValue("")
		setIsOpenUpdateModal(false);
	};

    const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			minWidth: '30%',
			minHeight: '30%',
			background: '#FFFFFF',
			borderRadius: "14px",
			paddingTop: '5vh',
			paddingBottom: '5vh',
			paddingLeft: '5vw',
			paddingRight: '5vw'
		},
		overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.71)',
            backdropFilter: "blur(2.5px)",
            zIndex: 1
		},
	  };

    function createData(crud_keyword, id) {
        return { crud_keyword, id};
    }

    function createDataSources(source, concern){
        return {source, concern}
    }

    function createDataCategories(category, interested, dflt){
        console.log(dflt)
        return {category, interested, dflt}
    }

    function createDataOrgKeywods(key){
        return {key}
    }

    const fetch_frequent_keywords = async() =>{
        let response = await getFrequentKeywords()
        console.log(response, response.data.length)
        if(response.status === 200){
            let test_rows = []
            for(let i = 0; i < response.data.length; i++){
                test_rows.push(createData(response.data[i].keyword.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()), response.data[i]._id))
            }
            console.log(test_rows)
            setRows(test_rows)
            setTotalKeywords(test_rows.length)
        }
        else if(response.status === 401){
            localStorage.clear()
            Cookies.remove('token');
            setTimeout(() => {
                setRedirect(1)    
            }, 1000);
            toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
        }
        else{
            toast.info("server error", { position: toast.POSITION.TOP_CENTER})
        }
    }

    const sendRequest = async() => {
        let response = {}
        response = await getUserDetails();
        console.log(response)
        if(response.status === 200){
            setUserName(response.data.userName)
            setUserEmail(response.data.userEmail)    
            setUserOrg(response.data.userOrg)       
        }
        else if(response.status === 500){
            console.log("Server Error !!!! Try refreshing the page")
        }
        else if(response.status === 401){
            // alert("Session Time Out")
            // Cookies.remove('token');
            // setRedirect(1)

            localStorage.clear()
            Cookies.remove('token');
            setTimeout(() => {
                setRedirect(1)    
            }, 1000);
            toast.info("invalid token or token expired",  { position: toast.POSITION.TOP_CENTER});
        }
        else{
            alert("please check the network")
        }
    }

    // const handleLogout = () =>{
    //     // alert("Logging you out....");
    //     // Cookies.remove('token');
    //     // setRedirect(1)

    //     localStorage.clear()
    //     Cookies.remove('token');
    //     setTimeout(() => {
    //         setRedirect(1)    
    //     }, 1000);
    //     toast.info("Logging You Out",  { position: toast.POSITION.TOP_CENTER});
    // }   

    const mySubmitHandler = (event) => {
        event.preventDefault();
        console.log(currPass, newPass, confirmPass);
        if(newPass !== confirmPass){
            setIsVisible(1);
            setErrorStatus('New Password and Confirm New Password field should be same');
        }
        else{
            updatePassword();
        }
    }

    const updatePassword = async() =>{
        let message = {
            "currentPassword": currPass,
            "newPassword": newPass
        }
        let response = {}
        response = await resetPassword(message)
        console.log(response)
        // if(response.hasOwnProperty('status') && response['status'] === 500){
        //     alert('Token expired...login again !!!!')
        // }
        // else if(response.hasOwnProperty('status') && response['status'] === 401){
        //     setIsVisible(1);
        //     setErrorStatus(response.message);
        // }
        // else{
        //     setIsVisible(2);
        //     setErrorStatus("Password Updated Successfully");
        // }
        if(response.status === 200){
            setIsVisible(2);
            setErrorStatus("Password Updated Successfully");          
        }
        else if(response.status === 500){
            console.log("Server Error !!!! Try refreshing the page")
        }
        else if(response.status === 400){
            setIsVisible(1);
            setErrorStatus(response.data.message);
        }
        else if(response.status === 401){
            localStorage.clear()
            Cookies.remove('token');
            setTimeout(() => {
                setRedirect(1)    
            }, 1000);
            toast.info("Session TimeOut",  { position: toast.POSITION.TOP_CENTER});
        }
        else{
            alert("please check the network")
        }
    }

    const handleKeywords = (event)=>{
        event.preventDefault();
        add_frequent_keywords()
    }

    const add_frequent_keywords = async() =>{
        let message = {
            "keyword": addKeyword
        }
        let response = await addFrequentKeywords(message)
        console.log(response)
        if(response.status === 200){
            let test_rows = []
            for(let i = 0; i < response.data.length; i++){
                test_rows.push(createData(response.data[i].keyword.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())))
            }
            console.log(test_rows)
            setRows(test_rows)
            toast.info("ADDED " + addKeyword, { position: toast.POSITION.TOP_CENTER})
            setTotalKeywords(test_rows.length)
            setAddKeyword('')
        }
        else if(response.status === 401){
            localStorage.clear()
            Cookies.remove('token');
            setTimeout(() => {
                setRedirect(1)    
            }, 1000);
            toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
        }
        else{
            toast.info("server error", { position: toast.POSITION.TOP_CENTER})
        }
    }

    const handleDeleteKeywords = (e, keyword)=>{
        e.preventDefault()
        del_frequent_keywords(keyword)
    }
    const del_frequent_keywords = async(keyword)=>{
        let message = {
            "keyword" : keyword
        }
        let response = await deleteFrequentKeywords(message)
        console.log(response)
        if(response.status === 200){
            let test_rows = []
            for(let i = 0; i < response.data.length; i++){
                test_rows.push(createData(response.data[i].keyword.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())))
            }
            console.log(test_rows)
            setRows(test_rows)
            toast.info("DELETED " + keyword , { position: toast.POSITION.TOP_CENTER})
            setTotalKeywords(test_rows.length)
        }
        else if(response.status === 401){
            localStorage.clear()
            Cookies.remove('token');
            setTimeout(() => {
                setRedirect(1)    
            }, 1000);
            toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
        }
        else{
            toast.info("server error", { position: toast.POSITION.TOP_CENTER})
        }
    }

    const header_active_handler =(idx) =>{
        if (idx === 1){
            setHeaderActive(1)
            const message = {
                "pageNo": domainPage,
                "itemsPerPage": 10,
                "searchText": finalDomainSearchText
            }
            fetch_sources(message)
        }
        else if (idx === 2 ){
            console.log("working_2")
            setHeaderActive(2)
            const message = {
                "pageNo": categoryPage,
                "limitPerPage": 10,
                "searchText": finalCategorySearchText
            }
            fetch_categories(message)
        }   
        else if(idx === 3){
            console.log("working_3")
            setHeaderActive(3)
            fetch_frequent_keywords()
        }
        else{
            console.log("working_4")
            setHeaderActive(4)
            const message = {
                "pageNo": orgKeywordsPage,
                "limitPerPage": 10,
                "searchText": finalOrgKeywordsSearchText
            }
            fetch_org_keywords(message)
        }
        console.log("workin1")
    }

    const fetch_sources = async(message)=>{
        
        let response = await getProfileDomains(message)
        if(response.status === 200){
            let test_rows = []
            for(let i = 0; i < response.data.sources.length; i++){
                test_rows.push(createDataSources(response.data.sources[i]["domain"], response.data.sources[i]["blackListed"]))
            }
            console.log(test_rows)
            setRowsSources(test_rows)
            setTotalSources(response.data.totalCount)
            if(response.data['totalCount'] % 10 === 0){
				setDomainTotalPage(Math.floor(response.data['totalCount'] / 10))
			}
			else{
				setDomainTotalPage(Math.floor(response.data['totalCount'] / 10) + 1)
			}
        }
        else if(response.status === 401){
            localStorage.clear()
            Cookies.remove('token');
            setTimeout(() => {
                setRedirect(1)    
            }, 1000);
            toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
        }
        else{
            toast.info("server error", { position: toast.POSITION.TOP_CENTER})
        }
    }
    
    const fetch_categories = async(message)=>{
        console.log("working_2")
        let response = await getProfileCategories(message)
        if(response.status === 200){
            console.log(response.data)
            let test_rows = []
            for(let i = 0; i < response.data.sources.length; i++){
                test_rows.push(createDataCategories(response.data.sources[i]["category"], response.data.sources[i]["interested"], response.data.sources[i]["default"]?true: false))
            }
            console.log(test_rows)
            setRowsCategories(test_rows)
            setTotalCategories(response.data.totalCount)
            if(response.data['totalCount'] % 10 === 0){
				setCategoryTotalPage(Math.floor(response.data['totalCount'] / 10))
			}
			else{
				setCategoryTotalPage(Math.floor(response.data['totalCount'] / 10) + 1)
			}
        }
        else if(response.status === 401){
            localStorage.clear()
            Cookies.remove('token');
            setTimeout(() => {
                setRedirect(1)    
            }, 1000);
            toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
        }
        else{
            toast.info("server error", { position: toast.POSITION.TOP_CENTER})
        }
    }

    const handleSourcePage = (e, val)=>{
        setDomainPage(val)
        const message = {
            "pageNo": val,
            "itemsPerPage": 10,
            "searchText": finalDomainSearchText
        }
        fetch_sources(message)
    }


    const concern_change_handler = async(message)=>{
        let response = await changeConcernDomains(message)
        if(response.status === 200){
            toast.info("Concern Type Changed Successfully", { position: toast.POSITION.TOP_CENTER});
        }
        else if(response.status === 401){
            localStorage.clear()
            Cookies.remove('token');
            setTimeout(() => {
                setRedirect(1)    
            }, 1000);
            toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
        }
        else{
            toast.info("server error", { position: toast.POSITION.TOP_CENTER})
        }
        message = {
            "pageNo": domainPage,
            "itemsPerPage": 10,
            "searchText": finalDomainSearchText
        }
        fetch_sources(message)
    }

    const change_concern_domain = (domain, concern) =>{
        let message = {
            "domain_name": domain,
            "blacklistFlag": concern
        }
        concern_change_handler(message)
        // message = {
        //     "pageNo": domainPage,
        //     "itemsPerPage": 10,
        //     "searchText": finalDomainSearchText
        // }
        // fetch_sources(message)
    }

    const handleDomainSearch = (event) =>{
        if (event.key === 'Enter'){
            setDomainPage(1)
            let message = {
                "pageNo": 1,
                "itemsPerPage": 10,
                "searchText": domainSearchText
            }
            fetch_sources(message)  
            setFinalDomainSearchText(domainSearchText) 
        }
    }
    

    const handleCategoryPage = (e, val)=>{
        setCategoryPage(val)
        const message = {
            "pageNo": val,
            "itemsPerPage": 10,
            "searchText": finalCategorySearchText
        }
        fetch_categories(message)
    }

    const handleCategorySearch = (event) =>{
        if (event.key === 'Enter'){
            setDomainPage(1)
            let message = {
                "pageNo": 1,
                "itemsPerPage": 10,
                "searchText": categorySearchText
            }
            fetch_categories(message)  
            setFinalCategorySearchText(categorySearchText) 
        }
    }

    const favourite_change_handler = async(message)=>{
        let response = await categoriesFavourite(message)
        if(response.status === 200){
            toast.info("Interest Changed Successfully", { position: toast.POSITION.TOP_CENTER});
        }
        else if(response.status === 401){
            localStorage.clear()
            Cookies.remove('token');
            setTimeout(() => {
                setRedirect(1)    
            }, 1000);
            toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
        }
        else{
            toast.info("server error", { position: toast.POSITION.TOP_CENTER})
        }
        message = {
            "pageNo": categoryPage,
            "itemsPerPage": 10,
            "searchText": finalCategorySearchText
        }
        fetch_categories(message)
    }

    const change_favourite_category = (category, interest) =>{
        let message = {
            "category": category,
            "interestedFlag": interest
        }
        favourite_change_handler(message)
        // message = {
        //     "pageNo": categoryPage,
        //     "itemsPerPage": 10,
        //     "searchText": finalCategorySearchText
        // }
        // fetch_categories(message)
    }

    const default_change_handler = async(message)=>{
        let response = await categoriesDefault(message)
        if(response.status === 200){
            toast.info("Default Category Changed Successfully", { position: toast.POSITION.TOP_CENTER, autoClose: 500});
        }
        else if(response.status === 401){
            localStorage.clear()
            Cookies.remove('token');
            setTimeout(() => {
                setRedirect(1)    
            }, 1000);
            toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
        }
        else{
            toast.info("server error", { position: toast.POSITION.TOP_CENTER})
        }
        message = {
            "pageNo": categoryPage,
            "itemsPerPage": 10,
            "searchText": finalCategorySearchText
        }
        fetch_categories(message)
    }

    const change_default_category = (category) =>{
        let message = {
            "category": category
        }
        default_change_handler(message)
        // message = {
        //     "pageNo": categoryPage,
        //     "itemsPerPage": 10,
        //     "searchText": finalCategorySearchText
        // }
        // fetch_categories(message)
    }

    const del_org_keywords = async(keyword)=>{
        let message = {
            "keyword" : keyword
        }
        console.log("kdsfjdsklfjlk")
        let response = await deleteOrgKeywords(message)
        console.log(response)
        if(response.status === 200){
            console.log("working")
            toast.info("Keyword "+ keyword + " Deleted.", { position: toast.POSITION.TOP_CENTER, autoClose: 1000});
            let message = {
				"pageNo": orgKeywordsPage,
				"limitPerPage": 10,
				"searchText": orgKeywordsSearchText	
			}
			fetch_org_keywords(message)
        }
        else if(response.status === 401){
            localStorage.clear()
            Cookies.remove('token');
            setTimeout(() => {
                setRedirect(1)    
            }, 1000);
            toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
        }
        else{
            toast.info("server error", { position: toast.POSITION.TOP_CENTER})
        }
    }

    const handleDeleteOrgKeywords = (e, keyword)=>{
        e.preventDefault()
        del_org_keywords(keyword)
    }

    const fetch_org_keywords = async(message)=>{
        console.log("working_4")
        let response = await fetchOrgKeywords(message)
        if(response.status === 200){
            console.log("keywords", response.data)
            let test_rows = []
            for(let i = 0; i < response.data.data.length; i++){
                test_rows.push(createDataOrgKeywods(response.data.data[i]))
            }
            console.log(test_rows)
            setRowsOrgKeywords(test_rows)
            setTotalOrgKeywords(response.data.totalCount)
            if(response.data['totalCount'] % 10 === 0){
				setOrgKeywordsTotalPage(Math.floor(response.data['totalCount'] / 10))
			}
			else{
				setOrgKeywordsTotalPage(Math.floor(response.data['totalCount'] / 10) + 1)
			}
        }
        else if(response.status === 401){
            localStorage.clear()
            Cookies.remove('token');
            setTimeout(() => {
                setRedirect(1)    
            }, 1000);
            toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
        }
        else{
            toast.info("server error", { position: toast.POSITION.TOP_CENTER})
        }
    }

    const handleOrgKeywordsPage = (e, switch_page)=>{
        setOrgKeywordsPage(switch_page)
        const message = {
            "pageNo": switch_page,
            "itemsPerPage": 10,
            "searchText": finalOrgKeywordsSearchText
        }
        fetch_org_keywords(message)
    }

    const handleOrgKeywordsSearch = (event) =>{
        if (event.key === 'Enter'){
            setOrgKeywordsPage(1)
            let message = {
                "pageNo": 1,
                "itemsPerPage": 10,
                "searchText": orgKeywordsSearchText
            }
            fetch_org_keywords(message)  
            setFinalOrgKeywordsSearchText(orgKeywordsSearchText) 
        }
    }

    const handle_update_add_org_keyword = async() =>{
        console.log(selectedOrgKeywordValue, selectedKeywordForUpdate)
        let response = {}
        if(isModalAdd){
            let message = {
                "keyword": selectedOrgKeywordValue
            }
            response = await addOrgKeywords(message)
            console.log(response, response.data.length)
        }
        else{
            let message = {
                "oldKeyword": selectedKeywordForUpdate, 
                "newKeyword": selectedOrgKeywordValue
            }
            response = await updateOrgKeywords(message)
            console.log(response, response.data.length)
        }
		if(response.status === 200){
            if(isModalAdd){
			    toast.info("Keyword "+ selectedOrgKeywordValue + " Added.", { position: toast.POSITION.TOP_CENTER, autoClose: 1000});
            }
            else{
			    toast.info("Keyword "+ selectedKeywordForUpdate + " Updated to " + selectedOrgKeywordValue + ".", { position: toast.POSITION.TOP_CENTER, autoClose: 1000});
            }
			let message = {
				"pageNo": orgKeywordsPage,
				"limitPerPage": 10,
				"searchText": orgKeywordsSearchText	
			}
			fetch_org_keywords(message)
            setSelectedOrgKeywordValue("")
			setIsOpenUpdateModal(false)
		}
		else if(response.status === 401){
			localStorage.clear()
			Cookies.remove('token');
			setTimeout(() => {
				setRedirect(1)   
			}, 1000);
			toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
		}
		else{
			toast.info("server error", { position: toast.POSITION.TOP_CENTER})
		}
    }

    // const handleSyncCheckBoxKeywords = () =>{
	// 	let flag = true
	// 	for(let i =0; i < rows.length; i++){
	// 		if(selectedKeywords[rows[i]["id"]] === undefined){
	// 			flag = false
	// 			break;
	// 		}
	// 	}
	// 	return flag
	// }

    // const handleCheckBoxAll = (e) => {
	// 	const isChecked = e.target.checked;
	  
	// 	if (isChecked) {
	// 	  setSelectedKeywords(prevSelectedIds => {
	// 		const updatedSelectedIds = { ...prevSelectedIds };
	// 		rows.forEach(item => {
	// 		  updatedSelectedIds[item.id] = true;
	// 		});
	// 		return updatedSelectedIds;
	// 	  });
	  
	// 	  console.log("checked");
	// 	} else {
	// 	  setSelectedKeywords(prevSelectedIds => {
	// 		const updatedSelectedIds = { ...prevSelectedIds };
	// 		rows.forEach(item => {
	// 		  if (updatedSelectedIds[item.id]) {
	// 			delete updatedSelectedIds[item.id];
	// 		  }
	// 		});
	// 		return updatedSelectedIds;
	// 	  });
	  
	// 	  console.log("unchecked");
	// 	}
	//   };

    //   const handleCheckBox = (e, id) =>{

	// 	const isChecked = e.target.checked;

	// 	if (isChecked) {
	// 		setSelectedKeywords(prevSelectedIds => ({
	// 		  ...prevSelectedIds,
	// 		  [id]: true,
	// 		}));
	// 		console.log("checked");
	// 	  } else {
	// 		setSelectedKeywords(prevSelectedIds => {
	// 		  const { [id]: _, ...restIds } = prevSelectedIds;
	// 		  return restIds; // This is needed to update the state correctly
	// 		});
	// 		console.log("unchecked");
	// 	  }
	// }
    
    const handleOpenOrgKeywordUpdateModal = (val, row_keyword) =>{
        if(val === "update"){
            setIsModalAdd(false)
            setSelectedKeywordForUpdate(row_keyword)
        }
        else{
            setIsModalAdd(true)
        }
        setIsOpenUpdateModal(true)
    }
    
    if(redirect === 1){
        // return <Navigate to  = "/login" />
        window.location.href = '/login';
    }

    return (
        <div className='right_profile_container'>
            <PageAnimations />
            <div className="headers">
                <div className={headerActive === 0? "header_container_profile header_container activeHeader": "header_container_profile header_container"} onClick={()=>setHeaderActive(0)}>
                    My Profile
                </div>
                <div className={headerActive === 1? "header_container_sources header_container activeHeader": "header_container_sources header_container"} onClick={()=>header_active_handler(1)}>
                    Sources
                </div>
                <div className={headerActive === 2? "header_container_category header_container activeHeader": "header_container_category header_container"} onClick={()=>header_active_handler(2)}>
                    Category
                </div>
                {/* {Cookies.get('userRole') === "admin" && */}
                <div className={headerActive === 4? "header_container_org_keyword header_container activeHeader": "header_container_org_keyword header_container"} onClick={()=>header_active_handler(4)}>
                    Keywords
                </div>
                {/* } */}
                <div className={headerActive === 3? "header_container_keyword header_container activeHeader": "header_container_keyword header_container"} onClick={()=>header_active_handler(3)}>
                    Search History
                </div>
            </div>
            {
                headerActive === 0 &&
                <div className="profile_page_wrapper">
                    <div className="right_profile_1">
                        <div className="user_details_container">
                            <div className="subheader_user_profile">
                                User Details
                            </div>
                            <div className="user_profile_logo">
                                <div className="ellipse_logo">
                                    {userName.slice(0, 1).toUpperCase()}
                                </div>
                            </div>
                            <div className="user_details">
                                <div className="user_details_main">
                                    <div className="user_details_content">
                                        User Name<span>:&nbsp;&nbsp;{userName}</span>
                                    </div>
                                    <div className="user_details_content">
                                        User ID<span>:&nbsp;&nbsp;{userEmail}</span>
                                    </div>
                                    <div className="user_details_content">
                                        Organization <span>:&nbsp;&nbsp;{userOrg}</span>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="first_layer_profile">
                            <div className="pass_reset_container">
                                <div className="subheader_user_profile">
                                    Security and Passwords
                                </div>
                                <form onSubmit={mySubmitHandler}>

                                    <div className="grid_password">
                                        <div className="curr_pass_profile elems">
                                            <label htmlFor="profile_password">Current Password</label>
                                            <input type={type_psw} className="form-control profile_inputs" id="profile_password" aria-describedby="emailHelp" placeholder="****************" value = {currPass} onChange={(event)=>setCurrPass(event.target.value)} required/>
                                            <img src={eye_icon} alt="eye icon" className="eye_icon_container" onClick={()=>type_psw === 'text' ? setType_Psw('password'): setType_Psw('text')}/>
                                        </div>
                                        <div className="new_pass_profile elems">
                                            <label htmlFor="profile_new_password">Enter New Password</label>
                                            <input type={type_psw_1} className="form-control profile_inputs" id="profile_new_password" aria-describedby="emailHelp" placeholder="Enter new password" value = {newPass} onChange={(event)=>setNewPass(event.target.value)} required/>
                                            <img src={eye_icon} alt="eye icon" className="eye_icon_container" onClick={()=>type_psw_1 === 'text' ? setType_Psw_1('password'): setType_Psw_1('text')}/>
                                        </div>
                                        <div className="again_new_pass_profile elems">
                                            <label htmlFor="profile_confirm_password">Confirm New Password</label>
                                            <input type="text" className="form-control profile_inputs" id="profile_confirm_password" aria-describedby="emailHelp" placeholder="Enter new password" value = {confirmPass} onChange={(event)=>setConfirmPass(event.target.value)} required/>
                                        </div>
                                    </div>
                                    <div className="error_container" style={{ display: isVisible !==0 ? "block" : "none", color: isVisible === 1 ? "red":"green", fontWeight: "bold", fontFamily: "Inter", marginTop: "0.5vh"}} >
                                        {errorStatus}
                                    </div>
                                    <div className="submit_container" >
                                        <button type="button" className="btn btn-light mt-4 cancel">Cancel</button>
                                        <button type="submit" className="btn btn-primary mt-4 update">Update</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    
                </div>
            }
            {
                headerActive === 1 && 
                <div className="sources_page_wrapper">
                    <div className="crud_sources">
                        <div className="sources_header">
                            <div className="subheader_user_profile">
                                Domains/Sources
                            </div>
                            <div className="crud_search">
                                <img src={searchIcon} alt="" className="search_icon" />
                                <input type="text" className="crud_add" placeholder='Search Domain/Source' value={domainSearchText} onChange={(e)=>setDomainSearchText(e.target.value)} onKeyDown={(e)=>handleDomainSearch(e)}/>
                            </div>
                        </div>
                        <div className="crud_table_sources">
                            <TableContainer sx={{  height: "52vh", borderRadius: '8px 8px 0px 0px', backdropFilter: 'blur(10px)', background: "transparent"}}>
                                <Table stickyHeader >
                                    <TableHead sx = {{ fontFamily: "Inter"}}>
                                        <TableRow>
                                            <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px'}} align = 'center'>Name</TableCell>
                                            <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px'}} align = 'center'>Concern</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody sx={{ height: "max-content" }}>
                                        {rowsSources.map((row, index) => (
                                            <TableRow 
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, background: "rgba(233, 233, 233, 0.35)"}}
                                            >   
                                                <TableCell component="th" scope="row" sx = {{ fontFamily: "Inter", fontWeight: "bold",  fontSize: '15px', color: "#1C70C8"}} align='center'>
                                                    {row.source}
                                                </TableCell>
                                                <TableCell component="th" scope="row" sx = {{ fontFamily: "Inter", fontWeight: "bold",  fontSize: '15px', color: "#000000", display: "flex", justifyContent: "center"}} align = 'center'>
                                                    <div className={row.concern ? "whitelist_concern_cell concern_cell": "blacklist_concern_cell concern_cell"} onClick={()=>change_concern_domain(row.source, ! row.concern )}>{row.concern ? "blacklisted": "blacklist" }</div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        {/* <div className="total_count_container">
                            <div className="total_count">
                                Total Keywords : <span>{totalKeywords}</span>
                            </div>
                        </div> */}
                        {/* <div className="display_pages_sources">
                            Showing &nbsp;<span className='pages_pagination'>{domainTotalPage !== 0 ? (domainPage-1)*10 + 1 : 0}</span>-<span className='pages_pagination'>{(domainPage-1)*10 + rowsSources.length}</span>&nbsp;from&nbsp;<span className='pages_pagination'>{totalSources}</span>&nbsp;data 	
                        </div>
                        <div className="prev_next_sources">
                            <button className="prev prev_next_buttons" onClick={()=>handleSourcePage(-1)} disabled = {domainPage > 1? false:true}>
                                Previous
                            </button>
                            <button className="next prev_next_buttons" onClick={()=>handleSourcePage(1)} disabled = {domainPage < domainTotalPage? false:true}>
                                Next
                            </button>
                        </div> */}
                    </div>
                    
                    <div className="sources_pagination_container">
                        <div className="display_pages">
                            Showing &nbsp;<span className='pages_pagination'>{domainTotalPage !== 0 ? (domainPage-1)*10 + 1 : 0}</span>-<span className='pages_pagination'>{(domainPage-1)*10 + rowsSources.length}</span>&nbsp;from&nbsp;<span className='pages_pagination'>{totalSources}</span>&nbsp;data 	
                        </div>
                        <div className="nav_container">
                            <Stack spacing={2}>
                                <Pagination count={domainTotalPage} color='primary' page = {domainPage} value={domainPage} onChange={handleSourcePage} variant="outlined" sx={{fontFamily: "'Fredoka', sans-serif"}} />
                            </Stack>
                        </div>
                    </div>

                </div>
            }
            {
                headerActive === 2 && 
                <div className="categories_page_wrapper">
                    <div className="crud_categories">
                        <div className="categories_header">
                            <div className="subheader_user_profile">
                                Categories
                            </div>
                            <div className="crud_search">
                                <img src={searchIcon} alt="" className="search_icon" />                            
                                <input type="text" className="crud_add" placeholder='Search Category' value={categorySearchText} onChange={(e)=>setCategorySearchText(e.target.value)} onKeyDown={(e)=>handleCategorySearch(e)}/>
                            </div>
                        </div>
                        <div className="crud_table_categories">
                            <TableContainer sx={{  height: "52vh", borderRadius: '8px 8px 0px 0px', backdropFilter: 'blur(10px)', background: "transparent"}}>
                                <Table stickyHeader >
                                    <TableHead sx = {{ fontFamily: "Inter"}}>
                                        <TableRow>
                                            <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px'}} align='center'>Name</TableCell>
                                            <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px'}} align='center'>Interest</TableCell>
                                            <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px'}} align='center'>default</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody sx={{ height: "max-content"}}>
                                        {rowsCategories.map((row, index) => (
                                            <TableRow 
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 },  background: "rgba(233, 233, 233, 0.35)"}}
                                            >   
                                                <TableCell component="th" scope="row" sx = {{ fontFamily: "Inter", fontWeight: "bold",  fontSize: '15px', color: "#3B89FE"}} align='center'>
                                                    {row.category}
                                                </TableCell>
                                                <TableCell component="th" scope="row" sx = {{ fontFamily: "Inter", fontWeight: "bold",  fontSize: '15px', color: "#000000"}} align='center'>
                                                    <button className={row.interested ? "interested_concern_cell concern_cell": "makeinterested_concern_cell concern_cell"} onClick={()=>change_favourite_category(row.category, ! row.interested )} disabled={row.interested && row.dflt ? true: false}>{row.interested ? "Interested": "Interest" }</button>
                                                    {/* <div className="concern_cell">{row.interested ? "Interested": "Not Interested" }</div> */}
                                                </TableCell>
                                                <TableCell component="th" scope="row" sx = {{ fontFamily: "Inter", fontWeight: "bold",  fontSize: '15px', color: "#000000"}} align='center'>
                                                    <button className={row.dflt? "default_concern_cell concern_cell": "makedefault_concern_cell concern_cell"} onClick={()=>change_default_category(row.category)} disabled={!row.interested && !row.dflt ? true: false}>{row.dflt? "Default": "Make Default"}</button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                    <div className="categories_pagination_container">
                        <div className="display_pages">
                            Showing &nbsp;<span className='pages_pagination'>{categoryTotalPage !== 0 ? (categoryPage-1)*10 + 1 : 0}</span>-<span className = "pages_pagination">{(categoryPage-1)*10 + rowsCategories.length}</span>&nbsp;from&nbsp;<span className='pages_pagination'>{totalCategories}</span>&nbsp;data 	
                        </div>
                        <div className="nav_container">
                            <Stack spacing={2}>
                                <Pagination count={categoryTotalPage} color='primary' page = {categoryPage} value={categoryPage} onChange={handleCategoryPage} variant="outlined" sx={{fontFamily: "'Fredoka', sans-serif"}} />
                            </Stack>
                        </div>
                    </div>
                </div>
            }
            {
                headerActive === 4 && 
                <div className="org_keywords_page_wrapper">
                    <div className="crud_org_keywords">
                        <div className="org_keywords_header">
                            <div className="subheader_user_profile">
                                Keywords
                            </div>
                            <div className="crud_search">
                                <img src={searchIcon} alt="" className="search_icon" />   
                                <input type="text" className="crud_add" placeholder='Search Keywords' value={orgKeywordsSearchText} onChange={(e)=>setOrgKeywordsSearchText(e.target.value)} onKeyDown={(e)=>handleOrgKeywordsSearch(e)}/>
                                {/* <img src={add_org_keyword_icon} alt="" className = "add_org_keyword_icon" title='add new keyword' onClick={(e) => handleOpenOrgKeywordUpdateModal("add")} /> */}
                            </div>
                        </div>
                            
                        <div className="crud_table_org_keywords">
                            <TableContainer sx={{  height: "52vh", borderRadius: '8px 8px 0px 0px', backdropFilter: 'blur(10px)', background: "transparent"}}>
                                <Table stickyHeader >
                                    <TableHead sx = {{ fontFamily: "Inter"}}>
                                        <TableRow sx = {{zIndex: 0}}>
                                            <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px', zIndex: "0"}} align='center'>Keyword <img src = {add_profile_key} alt='add_org_keyword_icon' className="add_org_keyword_icon ml-1" onClick={(e) => handleOpenOrgKeywordUpdateModal("add")}/></TableCell>
                                            <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px', zIndex: "0"}} align='center'>Actions</TableCell>
                                            {/* <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px', zIndex: "0"}}>Edit</TableCell> */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody sx={{ height: "max-content"}}>
                                        {rowsOrgKeywords.map((row, index) => (
                                            <TableRow 
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, background: "rgba(233, 233, 233, 0.35)"}}
                                            >   
                                                <TableCell component="th" scope="row" sx = {{ fontFamily: "Inter", fontWeight: "bold",  fontSize: '15px', color: "#3B89FE"}} align='center'>
                                                    {row.key}
                                                </TableCell>
                                                <TableCell component="th" scope="row" sx = {{ fontFamily: "Inter", fontWeight: "bold",  fontSize: '15px', color: "#000000"}} align='center'>
                                                    <button type="button" className="table_btn cross_button_profile" onClick={(e) => handleDeleteOrgKeywords(e, row.key)} style = {{background: "rgba(233, 233, 233, 0.35)"}}>
                                                        <img src = {delete_profile_key} alt ="close" className="profile_cross mr-3"/>
                                                    </button>
                                                    <button type="button" className="table_btn edit_button_profile" onClick={(e) => handleOpenOrgKeywordUpdateModal("update", row.key)} style={{background: "rgba(233, 233, 233, 0.35)"}}>
                                                        <img src = {edit_profile_key} alt ="close" className="profile_edit" style={{background: "rgba(233, 233, 233, 0.35)"}}/>
                                                    </button>
                                                </TableCell>
                                                {/* <TableCell component="th" scope="row" sx = {{ fontFamily: "Inter", fontWeight: "bold",  fontSize: '15px', color: "#000000"}} >
                                                    <button type="button" className="table_btn edit_button_profile" onClick={(e) => handleOpenOrgKeywordUpdateModal("update", row.key)}>
                                                        <img src = {edit} alt ="close" className="profile_edit"/>
                                                    </button>
                                                </TableCell> */}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                    <div className="org_keywords_pagination_container">
                        <div className="display_pages">
                            Showing &nbsp;<span className='pages_pagination'>{orgKeywordsTotalPage !== 0 ? (orgKeywordsPage-1)*10 + 1 : 0}</span>-<span className = "pages_pagination">{(orgKeywordsPage-1)*10 + rowsOrgKeywords.length}</span>&nbsp;from&nbsp;<span className='pages_pagination'>{totalOrgKeywords}</span>&nbsp;data 	
                        </div>
                        <div className="nav_container">
                            <Stack spacing={2}>
                                <Pagination count={orgKeywordsTotalPage} color='primary' page = {orgKeywordsPage} value={orgKeywordsPage} onChange={handleOrgKeywordsPage} variant="outlined" sx={{fontFamily: "'Fredoka', sans-serif"}} />
                            </Stack>
                        </div>
                    </div>
                </div>
            }
            {
                headerActive === 3 &&
                <div className="keywords_page_wrapper">
                    <div className="crud_keywords">
                        <div className="keywords_header">
                            <div className="subheader_user_profile">
                                Records
                            </div>
                            <div className="crud_search">
                                <img src={searchIcon} alt="" className="search_icon" />                            
                                <input type="text" className="crud_add" placeholder='Enter new word for top occurrences' value = {addKeyword} onChange = {(event) => setAddKeyword(event.target.value)}/>
                                <button className='btn btn-sm btn-primary crud_apply' onClick={handleKeywords} disabled = {addKeyword === ''? true: false} >Add</button>
                            </div>
                        </div>
                        <div className="crud_table">
                            <TableContainer sx={{  height: "52vh", borderRadius: '8px 8px 0px 0px', backdropFilter: 'blur(10px)', background: "transparent"}}>
                                <Table stickyHeader >
                                    <TableHead sx = {{ fontFamily: "Inter"}}>
                                        <TableRow>
                                            <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px'}} align='center'>Searched Words</TableCell>
                                            <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px'}} align='center'></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody sx={{ height: "max-content", background: "rgba(233, 233, 233, 0.35)"}}>
                                        {rows.map((row, index) => (
                                            <TableRow 
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 },  background: "rgba(233, 233, 233, 0.35)"}}
                                            >   
                                                <TableCell component="th" scope="row" sx = {{ fontFamily: "Inter", fontWeight: "bold",  fontSize: '15px', color: "#3B89FE"}} align='center'>
                                                    {row.crud_keyword}
                                                </TableCell>
                                                <TableCell sx = {{ fontFamily: "Inter" }} align='center'>
                                                    <button type="button" className="table_btn cross_button_profile" onClick={(e) => handleDeleteKeywords(e, row.crud_keyword)}><img src = {delete_profile_key} alt ="close" className="profile_cross"/></button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div> 
                    </div>
                    {/* <div className="keywords_pagination_container">
                        <div className="display_pages">
                            Showing &nbsp;<span className='pages_pagination'>{keywordsTotalPage !== 0 ? (keywordsPage-1)*10 + 1 : 0}</span>-<span className = "pages_pagination">{(keywordsPage-1)*10 + rowsKeywords.length}</span>&nbsp;from&nbsp;<span className='pages_pagination'>{totalKeywords}</span>&nbsp;data 	
                        </div>
                        <div className="nav_container">
                            <Stack spacing={2}>
                                <Pagination count={keywordsTotalPage} color='primary' page = {keywordsPage} value={keywordsPage} onChange={handleCategoryPage} variant="outlined" sx={{fontFamily: "'Fredoka', sans-serif"}} />
                            </Stack>
                        </div>
                    </div> */}
                </div>
            }
            
            <ToastContainer />
            <div className="filter_popup">
                <ReactModal
                    isOpen={isOpenUpdateModal}
                    onRequestClose={closeUpdateModal}
                    contentLabel="Popup Modal"
                    style={customStyles}
                >	
                <div className="profile_org_keyword_update_popup_content">
                    <div className="profile_org_keyword_update_popup_header">
                        {isModalAdd ? "Add New Keyword" : "Update Selected Keyword"}
                    </div>
                    <div className="profile_org_keyword_update_input_bar_container">
                        <input value = {selectedOrgKeywordValue} type="text" className="profile_org_keyword_update_input" placeholder='keyword name' onChange={(e) => setSelectedOrgKeywordValue(e.target.value)}/>
                    </div>
                    <div className="profile_org_keyword_update_add_draft_button">
                        <button className="cancel btn btn-light" onClick={()=>closeUpdateModal()}>
                            Cancel
                        </button>
                        <button className="button_main btn btn-primary" onClick={()=>handle_update_add_org_keyword()} disabled = {selectedOrgKeywordValue === ""?true:false}>
                            {isModalAdd ? "Add" : "Update"}
                        </button>
                    </div>
                </div>
                </ReactModal>	
            </div>	
        </div>
    )
}
