import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import "../../staticFiles/cssFiles/reportGenTracker/rightReportGenTracker.css"
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { fetchScheduledReports } from '../../endppoints/endpoint';
import Cookies from 'js-cookie';
import { Navigate } from 'react-router-dom';
import ReactModal from 'react-modal';
import { PageAnimations } from '../GlobalPageAnimations.jsx/pageAnimations';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import loading from "../../staticFiles/images/loading.svg"
import no_result_logo from "../../staticFiles/images/No_results.svg"

export const RightReportGenTracker = () => {
	const [map, setMap] = React.useState(0)
	const [page, setPage] = React.useState(1)
	const [searchText, setSearchText] = React.useState("")
	const [reportGenItems, setReportGenItems] = React.useState([])
	const [limitPerPage, setLimitPerPage] = React.useState(9)
	const [totalData, setTotalData] = React.useState(null)
	const [currData, setCurrData] = React.useState(null)
	const [totalPage, setTotalPage] = React.useState(null)
	const [status, setStatus] = React.useState(0)
	const [isOpen, setIsOpen] = React.useState(false)
	const [popupReportGenItems, setPopUpReportGenItems] = React.useState([])
	const [selectedScheduleId, setSelectedScheduleId] = React.useState()

    React.useEffect(()=>{
      let message = {
        "pageNo": page,
        "limitPerPage": limitPerPage,
        "searchText": searchText	
      }
      fetch_scheduled_reports(message)
    }, []);

	const closeModal = () => {
		setIsOpen(false);
	};

	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			minWidth: '25%',
			minHeight: '30%',
			maxWidth: '50%',
			background: '#F6F7F9',
			borderRadius: "8px",
			paddingTop: '4vh',
			paddingBottom: '4vh',
			paddingLeft: '1vw',
			paddingRight: '1vw'
		},
		overlay: {
			backgroundColor: 'rgba(45, 45, 45, 0.3)', // Customize overlay background color
		},
		};

    const fetch_scheduled_reports = async(message)=>{
		setMap(2)	
      let response = await fetchScheduledReports(message);
      if(response.status === 200){
		setMap(1)
        console.log(response.data.data)
        setReportGenItems(response.data.data)
        setTotalData(response.data.totalCount)
        setCurrData(response.data.data.length)
  
        if(response.data['totalCount'] % limitPerPage === 0){
          setTotalPage(Math.floor(response.data['totalCount'] / limitPerPage))
        }
        else{
          setTotalPage(Math.floor(response.data['totalCount'] / limitPerPage) + 1)
        }
      }
      else if(response.status === 401){
        localStorage.clear()
        Cookies.remove('token');
        setTimeout(() => {
          setStatus(2)    
        }, 1000);
        toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
      }
      else{
        toast.info("server error", { position: toast.POSITION.TOP_CENTER})
      }
    }

	const handlePage = (event, value) =>{
		setPage(value)
		console.log(value, page)
		const message = {
			"pageNo": value,
			"limitPerPage": limitPerPage,
			"searchText": searchText
		}
		fetch_scheduled_reports(message);
	}

	const handleScheduledReportsPopup = (event, items) =>{
		console.log(items)
		setSelectedScheduleId(items._id)
		setStatus(1)
		// setPopUpReportGenItems(items);
		// setIsOpen(true);
	}

	if(status === 2){
		// return <Navigate to = '/login' />
		window.location.href = '/login';
	}
	if(status === 1){
		return <Navigate to = {"/scheduledReports/report/details/" + selectedScheduleId} />
	}

  return (
    <div className='right_report_gen_tracker_container'>
		<PageAnimations />
		<div className="main_header">
			Scheduled Reports
		</div>
        
		<div className="report_gen_table_table_and_content">
            <div className="report_gen_table_table_container">
                {map === 0 ? <div>No data to show</div>: 
                    map === 1 ? 
                    reportGenItems.length === 0? 
                        <div className='no_results_container '> 
                            <img src={no_result_logo} alt="" className="no_results child_no_results" /> 
                            <div className="no_results_header child_no_results">
                                No results found
                            </div>
                            <div className="no_results_subheader child_no_results">
                                Try adjusting your filters to find out what you are looking for 
                            </div>
                        </div>:
                    <TableContainer sx={{  height: "70vh", boxShadow: '0px 20px 50px 0px #0000001A', borderRadius: '8px 8px 0px 0px', backdropFilter: 'blur(10px)', background: "transparent"}}>
                     <Table stickyHeader >
                         <TableHead sx = {{ fontFamily: "Inter"}}>
                             <TableRow>
                                 <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px'}}>Name</TableCell>
                                 <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px'}}>Scheduled Time</TableCell>
                                 <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px'}} align="center">Scheduled Interval</TableCell>
                                 <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px'}} align="center">Scheduled Frequency</TableCell>
                                 <TableCell sx = {{ color:  '#8092A2', fontWeight: 'bold', fontFamily: "Inter", fontSize: '18px'}} align="center">Report Generation Mode</TableCell>
                             </TableRow>
                         </TableHead>
                         <TableBody sx={{ height: "max-content" }}>
                             {reportGenItems.map((row, index) => (
                                 <TableRow 
                                 key={index}
                                 sx={{ '&:last-child td, &:last-child th': { border: 0 }, background: "rgba(233, 233, 233, 0.35)", cursor: "pointer"}}
								 onClick={(e)=>handleScheduledReportsPopup(e, row)}
                                 >   
                                        <TableCell component="th" scope="row" sx = {{ fontFamily: "Inter", fontWeight: "bold",  fontSize: '15px'}}>
											{row.scheduleName}
                                        </TableCell>
										<TableCell component="th" scope="row" sx = {{ fontFamily: "Inter", fontWeight: "bold",  fontSize: '15px'}}>
											{row.scheduledTime}
                                        </TableCell>
                                        <TableCell align="center"  sx = {{ fontFamily: "Inter", fontWeight: "bold",  fontSize: '15px'}}>
                                            {row.scheduledInterval}
										</TableCell>
                                        <TableCell align="center" sx = {{ fontFamily: "Inter",fontWeight: "bold", fontSize: '15px' }}>{row.scheduledFrequency}</TableCell>
                                        <TableCell align="center" sx = {{ fontFamily: "Inter",fontWeight: "bold", fontSize: '15px' }}>{row.reportGenerationMode}</TableCell>
                                 </TableRow>
                             ))}
                         </TableBody>
                     </Table>
                    </TableContainer>:
                    map === 2 ? <div style={{display: "flex" , alignItems: "center", justifyContent: "center", height: '70vh'}}><img src={loading} alt="laoding svg" className="loading" /></div>: 
                    <div>some error ....try refreshing the page</div>   
                }
                
                {reportGenItems.length === 0? 
                    <></>:
                <div className="pagination_container">
                    <div className="display_pages">
                        Showing &nbsp;<span>{totalPage !== 0 ? (page-1)*10 + 1 : 0}</span>-<span>{(page-1)*10 + currData}</span>&nbsp;from&nbsp;<span>{totalData}</span>&nbsp;data 
                    </div>
                    <div className="nav_container">
                        <Stack spacing={2}>
                            <Pagination count={totalPage} color='primary' page = {page} value={page} onChange={handlePage} variant="outlined" sx={{fontFamily: "'Fredoka', sans-serif"}} />
                        </Stack>
                    </div>
                </div>}
            </div>
        </div>
		{/* <div className="report_gen_pagination_container">
			<div className="display_pages">
				Showing &nbsp;<span>{totalPage ? (page-1)*limitPerPage + 1 : 0}</span>-<span>{(page-1)*limitPerPage + currData}</span>&nbsp;from&nbsp;<span>{totalData}</span>&nbsp;data 	
			</div>
			<div className="nav_container">
				<Stack spacing={2}>
					<Pagination count={totalPage} page = {page} color='primary' value={page} onChange={handlePage} variant="outlined" sx={{fontFamily: "Inter"}}/>
				</Stack>
			</div>
		</div> */}
		<ToastContainer />
		<div className="main_scheduled_reports_popup">
			<ReactModal
				isOpen={isOpen}
				onRequestClose={closeModal}
				contentLabel="Popup Modal"
				style={customStyles}
			>	
			<div className="scheduled_reports_popup_content">
				<div className="card_header">
					{popupReportGenItems.scheduleName}
				</div>
				<div className="middle_card_content">
					<div className="middle_card_content_left">
						<div className="middle_card_content_child">
							Scheduled Time
						</div>
						<div className="middle_card_content_child">
							Scheduled Interval
						</div>
						<div className="middle_card_content_child">
							Scheduled Frequency
						</div>
						<div className="middle_card_content_child">
							Report Generation Mode
						</div>
						{popupReportGenItems.reportGenerationMode === "Keywords" && 
							<div className="middle_card_content_child">
								No. of Keywords
							</div>
						}
						{popupReportGenItems.reportGenerationMode === "Keywords" && 
							<div className="middle_card_content_child">
								Keywords
							</div>
						}
					</div>
					<div className="middle_card_content_right">
							<div className="middle_card_content_child">
								: <span>{popupReportGenItems.scheduledTime}</span>
							</div>
							<div className="middle_card_content_child">
								: <span>{popupReportGenItems.scheduledInterval}</span>
							</div>
							<div className="middle_card_content_child">
								: <span>{popupReportGenItems.scheduledFrequency}</span>
							</div>
							<div className="middle_card_content_child">
								: <span>{popupReportGenItems.reportGenerationMode}</span>
							</div>
							{popupReportGenItems.reportGenerationMode === "Keywords" && 
								<div className="middle_card_content_child">
									: <span>{popupReportGenItems.reportInput.keywords.length}</span>
								</div>
							}
							{popupReportGenItems.reportGenerationMode === "Keywords" && 
								<div className="middle_card_content_child middle_card_content_child_keywords">
									:{popupReportGenItems.reportInput.keywords.map((keyword, index)=>(
										<div className='middle_card_content_child_keyword'>
											{keyword}
										</div>
									))}
								</div>
							}
					</div>
				</div>
			</div>
			</ReactModal>	
		</div>	
    </div>
  )
}
