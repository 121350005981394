import React from 'react'
import '../../staticFiles/cssFiles/Dashboard/dashboard.css'
import { LeftDashboard } from './left_dashboard'
// import { RightDashboard } from './right_dashboard'
import Cookies from 'js-cookie' 
// import { Navigate } from 'react-router-dom'

export const Dashboard = () => {

  if(Cookies.get('token') === undefined){
    // return <Navigate to = "/login" /> 
    window.location.href = '/login';
  }
  return (
    <div className='dashboard_container'>
        <LeftDashboard id={'1'}/>
        {/* <RightDashboard /> */}
    </div>
  )
}
