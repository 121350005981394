import React from 'react'
import { LeftDashboard } from '../Dashboard/left_dashboard'

import '../../staticFiles/cssFiles/reports/reports.css'
import { Navigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { RightReports } from './rightReports'

export const Reports = () => {

    if(Cookies.get('token') === undefined){
        console.log("working")
        // return <Navigate to  = "/login" />
        window.location.href = '/login';
    }

    return (
        <div className='reports_container'>
            <LeftDashboard id={'4'}/>
            <RightReports/>
        </div>
  )
}
