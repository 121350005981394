const currentDate = new Date();
const initialDate = new Date();
initialDate.setDate(currentDate.getDate() - 2);

const countryArr = [
    { label: "Afghanistan", value: "AFG" },
    { label: "Albania", value: "ALB" },
    { label: "Algeria", value: "DZA" },
    { label: "American Samoa", value: "ASM" },
    { label: "Andorra", value: "AND" },
    { label: "Angola", value: "AGO" },
    { label: "Anguilla", value: "AIA" },
    { label: "Antarctica", value: "ATA" },
    { label: "Antigua and Barbuda", value: "ATG" },
    { label: "Argentina", value: "ARG" },
    { label: "Armenia", value: "ARM" },
    { label: "Aruba", value: "ABW" },
    { label: "Australia", value: "AUS" },
    { label: "Austria", value: "AUT" },
    { label: "Azerbaijan", value: "AZE" },
    { label: "Bahamas", value: "BHS" },
    { label: "Bahrain", value: "BHR" },
    { label: "Bangladesh", value: "BGD" },
    { label: "Barbados", value: "BRB" },
    { label: "Belarus", value: "BLR" },
    { label: "Belgium", value: "BEL" },
    { label: "Belize", value: "BLZ" },
    { label: "Benin", value: "BEN" },
    { label: "Bermuda", value: "BMU" },
    { label: "Bhutan", value: "BTN" },
    { label: "Bolivia", value: "BOL" },
    { label: "Bosnia and Herzegovina", value: "BIH" },
    { label: "Botswana", value: "BWA" },
    { label: "Brazil", value: "BRA" },
    { label: "British Indian Ocean Territory", value: "IOT" },
    { label: "British Virgin Islands", value: "VGB" },
    { label: "Brunei", value: "BRN" },
    { label: "Bulgaria", value: "BGR" },
    { label: "Burkina Faso", value: "BFA" },
    { label: "Burundi", value: "BDI" },
    { label: "Cambodia", value: "KHM" },
    { label: "Cameroon", value: "CMR" },
    { label: "Canada", value: "CAN" },
    { label: "Cape Verde", value: "CPV" },
    { label: "Cayman Islands", value: "CYM" },
    { label: "Central African Republic", value: "CAF" },
    { label: "Chad", value: "TCD" },
    { label: "Chile", value: "CHL" },
    { label: "China", value: "CHN" },
    { label: "Christmas Island", value: "CXR" },
    { label: "Cocos Islands", value: "CCK" },
    { label: "Colombia", value: "COL" },
    { label: "Comoros", value: "COM" },
    { label: "Cook Islands", value: "COK" },
    { label: "Costa Rica", value: "CRI" },
    { label: "Croatia", value: "HRV" },
    { label: "Cuba", value: "CUB" },
    { label: "Curacao", value: "CUW" },
    { label: "Cyprus", value: "CYP" },
    { label: "Czechia", value: "CZE" },
    { label: "Democratic Republic of the Congo", value: "COD" },
    { label: "Denmark", value: "DNK" },
    { label: "Djibouti", value: "DJI" },
    { label: "Dominica", value: "DMA" },
    { label: "Dominican Republic", value: "DOM" },
    { label: "East Timor", value: "TLS" },
    { label: "Ecuador", value: "ECU" },
    { label: "Egypt", value: "EGY" },
    { label: "El Salvador", value: "SLV" },
    { label: "Equatorial Guinea", value: "GNQ" },
    { label: "Eritrea", value: "ERI" },
    { label: "Estonia", value: "EST" },
    { label: "Ethiopia", value: "ETH" },
    { label: "Falkland Islands", value: "FLK" },
    { label: "Faroe Islands", value: "FRO" },
    { label: "Fiji", value: "FJI" },
    { label: "Finland", value: "FIN" },
    { label: "France", value: "FRA" },
    { label: "French Polynesia", value: "PYF" },
    { label: "Gabon", value: "GAB" },
    { label: "Gambia", value: "GMB" },
    { label: "Georgia", value: "GEO" },
    { label: "Germany", value: "DEU" },
    { label: "Ghana", value: "GHA" },
    { label: "Gibraltar", value: "GIB" },
    { label: "Greece", value: "GRC" },
    { label: "Greenland", value: "GRL" },
    { label: "Grenada", value: "GRD" },
    { label: "Guam", value: "GUM" },
    { label: "Guatemala", value: "GTM" },
    { label: "Guernsey", value: "GGY" },
    { label: "Guinea", value: "GIN" },
    { label: "Guinea-Bissau", value: "GNB" },
    { label: "Guyana", value: "GUY" },
    { label: "Haiti", value: "HTI" },
    { label: "Honduras", value: "HND" },
    { label: "Hong Kong", value: "HKG" },
    { label: "Hungary", value: "HUN" },
    { label: "Iceland", value: "ISL" },
    { label: "India", value: "IND" },
    { label: "Indonesia", value: "IDN" },
    { label: "Iran", value: "IRN" },
    { label: "Iraq", value: "IRQ" },
    { label: "Ireland", value: "IRL" },
    { label: "Isle of Man", value: "IMN" },
    { label: "Israel", value: "ISR" },
    { label: "Italy", value: "ITA" },
    { label: "Côte d'Ivoire", value: "CIV" },
    { label: "Jamaica", value: "JAM" },
    { label: "Japan", value: "JPN" },
    { label: "Jersey", value: "JEY" },
    { label: "Jordan", value: "JOR" },
    { label: "Kazakhstan", value: "KAZ" },
    { label: "Kenya", value: "KEN" },
    { label: "Kiribati", value: "KIR" },
    { label: "Kosovo", value: "XKX" },
    { label: "Kuwait", value: "KWT" },
    { label: "Kyrgyzstan", value: "KGZ" },
    { label: "Laos", value: "LAO" },
    { label: "Latvia", value: "LVA" },
    { label: "Lebanon", value: "LBN" },
    { label: "Lesotho", value: "LSO" },
    { label: "Liberia", value: "LBR" },
    { label: "Libya", value: "LBY" },
    { label: "Liechtenstein", value: "LIE" },
    { label: "Lithuania", value: "LTU" },
    { label: "Luxembourg", value: "LUX" },
    { label: "Macau", value: "MAC" },
    { label: "Macedonia", value: "MKD" },
    { label: "Madagascar", value: "MDG" },
    { label: "Malawi", value: "MWI" },
    { label: "Malaysia", value: "MYS" },
    { label: "Maldives", value: "MDV" },
    { label: "Mali", value: "MLI" },
    { label: "Malta", value: "MLT" },
    { label: "Marshall Islands", value: "MHL" },
    { label: "Mauritania", value: "MRT" },
    { label: "Mauritius", value: "MUS" },
    { label: "Mayotte", value: "MYT" },
    { label: "Mexico", value: "MEX" },
    { label: "Micronesia", value: "FSM" },
    { label: "Moldova", value: "MDA" },
    { label: "Monaco", value: "MCO" },
    { label: "Mongolia", value: "MNG" },
    { label: "Montenegro", value: "MNE" },
    { label: "Montserrat", value: "MSR" },
    { label: "Morocco", value: "MAR" },
    { label: "Mozambique", value: "MOZ" },
    { label: "Myanmar", value: "MMR" },
    { label: "Namibia", value: "NAM" },
    { label: "Nauru", value: "NRU" },
    { label: "Nepal", value: "NPL" },
    { label: "Netherlands", value: "NLD" },
    { label: "Netherlands Antilles", value: "ANT" },
    { label: "New Caledonia", value: "NCL" },
    { label: "New Zealand", value: "NZL" },
    { label: "Nicaragua", value: "NIC" },
    { label: "Niger", value: "NER" },
    { label: "Nigeria", value: "NGA" },
    { label: "Niue", value: "NIU" },
    { label: "North Korea", value: "PRK" },
    { label: "Northern Mariana Islands", value: "MNP" },
    { label: "Norway", value: "NOR" },
    { label: "Oman", value: "OMN" },
    { label: "Pakistan", value: "PAK" },
    { label: "Palau", value: "PLW" },
    { label: "Palestine", value: "PSE" },
    { label: "Panama", value: "PAN" },
    { label: "Papua New Guinea", value: "PNG" },
    { label: "Paraguay", value: "PRY" },
    { label: "Peru", value: "PER" },
    { label: "Philippines", value: "PHL" },
    {label: "Pitcairn", value: "PCN"},
    {label: "Poland", value: "POL"},
    {label: "Portugal", value: "PRT"},
    {label: "Puerto Rico", value: "PRI"},
    {label: "Qatar", value: "QAT"},
    {label: "Republic of the Congo", value: "COG"},
    {label: "Reunion", value: "REU"},
    {label: "Romania", value: "ROU"},
    {label: "Russia", value: "RUS"},
    {label: "Rwanda", value: "RWA"},
    {label: "Saint Barthelemy", value: "BLM"},
    {label: "Saint Helena", value: "SHN"},
    {label: "Saint Kitts and Nevis", value: "KNA"},
    {label: "Saint Lucia", value: "LCA"},
    {label: "Saint Martin", value: "MAF"},
    {label: "Saint Pierre and Miquelon", value: "SPM"},
    {label: "Saint Vincent and the Grenadines", value: "VCT"},
    {label: "Samoa", value: "WSM"},
    {label: "San Marino", value: "SMR"},
    {label: "Sao Tome and Principe", value: "STP"},
    {label: "Saudi Arabia", value: "SAU"},
    {label: "Senegal", value: "SEN"},
    {label: "Serbia", value: "SRB"},
    {label: "Seychelles", value: "SYC"},
    {label: "Sierra Leone", value: "SLE"},
    {label: "Singapore", value: "SGP"},
    {label: "Sint Maarten", value: "SXM"},
    {label: "Slovakia", value: "SVK"},
    {label: "Slovenia", value: "SVN"},
    {label: "Solomon Islands", value: "SLB"},
    {label: "Somalia", value: "SOM"},
    {label: "South Africa", value: "ZAF"},
    {label: "South Korea", value: "KOR"},
    {label: "South Sudan", value: "SSD"},
    {label: "Spain", value: "ESP"},
    {label: "Sri Lanka", value: "LKA"},
    {label: "Sudan", value: "SDN"},
    {label: "Suriname", value: "SUR"},
    {label: "Svalbard and Jan Mayen", value: "SJM"},
    {label: "Eswatini", value: "SWZ"},
    {label: "Sweden", value: "SWE"},
    {label: "Switzerland", value: "CHE"},
    {label: "Syria", value: "SYR"},
    {label: "Taiwan", value: "TWN"},
    {label: "Tajikistan", value: "TJK"},
    {label: "Tanzania", value: "TZA"},
    {label: "Thailand", value: "THA"},
    {label: "Togo", value: "TGO"},
    {label: "Tokelau", value: "TKL"},
    {label: "Tonga", value: "TON"},
    {label: "Trinidad and Tobago", value: "TTO"},
    {label: "Tunisia", value: "TUN"},
    {label: "Turkey", value: "TUR"},
    {label: "Turkmenistan", value: "TKM"},
    {label: "Turks and Caicos Islands", value: "TCA"},
    {label: "Tuvalu", value: "TUV"},
    {label: "U.S. Virgin Islands", value: "VIR"},
    {label: "Uganda", value: "UGA"},
    {label: "Ukraine", value: "UKR"},
    {label: "United Arab Emirates", value: "ARE"},
    {label: "United Kingdom", value: "GBR"},
    {label: "United States", value: "USA"},
    {label: "Uruguay", value: "URY"},
    {label: "Uzbekistan", value: "UZB"},
    {label: "Vanuatu", value: "VUT"},
    {label: "Vatican", value: "VAT"},
    {label: "Venezuela", value: "VEN"},
    {label: "Vietnam", value: "VNM"},
    {label: "Wallis and Futuna", value: "WLF"},
    {label: "Western Sahara", value: "ESH"},
    {label: "Yemen", value: "YEM"},
    {label: "Zambia", value: "ZMB"},
    {label: "Zimbabwe", value: "ZWE"}
  ]

const initialState = {
    isOpen: false,
    fromDate: initialDate,
    toDate: new Date(),
    fromDatePage: initialDate,
    toDatePage: new Date(),
    selectedDateRange : [initialDate, new Date()],
    selectedDateRangeApplied : [initialDate, new Date()],
    page: 1,
    sample_rows: [],
    status: 0,
    articleId: undefined,
    total_data: 0,
    curr_data: 0,
    total_page: 0,
    selectedOptions: [],
    selectedOptionsApplied: [],
    expKey: [],
    map: 0,
    section: 1,
    sectionApplied: 1,
    sampleOptions: [],
    fetchedDomains: [],
    logicToggle: false,
    sequentialToggle: false,
    matchToggle: false,
    logicToggleApplied: false,
    sequentialToggleApplied: false,
    matchToggleApplied: false,
    selectedOptionsDomain: [],
    selectedOptionsDomainApplied: [],
    selectedOptionsLocation: '',
    selectedOptionsLocationApplied: '',
    dropdownCatergory: false,
    categories: [],
    category: "",
    categoryApplied: "",

    selectedOptionsCategory : {},
    selectedOptionsCategoryApplied: {},
    sampleOptionsCategory: [],

    promptToggle: false,
    promptQuery: '',
    finalPromptQuery: '',
    // selectedArticles: {},
    fetchedDrafts: null,
    selectedValue: null,
    isOpenDraft: false,
    defaultCategory: '',
    uniqueArticlesToggle: false,
    appliedUniqueArticlesToggle: false,
    showVerifyMap: false,
    inputVerifyLocation: '',
    inputVerifyLocationApplied: '',
    fetchedCoordinates: [],
    pinMapData: [],
    inputVerifyLat: undefined,
    inputVerifyLan: undefined,
    inputVerifyLatApplied: undefined,
    inputVerifyLanApplied: undefined,
    invalidLocationError: false,
    approvedLocation: false,
    approvedLocationApplied: false,
    inputVerifyLatLanByLocation: [],
    inputVerifyLatLanByLocationApplied: [],
    locationRadius: 50,
    locationRadiusApplied: 50,
    selectedOptionsSentiment: '',
    selectedOptionsSentimentApplied: '',
    chartData: [],
    keywordsCategory: "Title & Body",
    appliedKeywordsCategory: "Title & Body",
    keywordsDropdownCatergory: false,
    articlesAnalyticsSwitch: "articles",
    filtersEditMode: false,
    showDraftIcons: false,
    selectedArticles: {},

    regionDropdownCatergory: false,
    regionCategory: "India",
    analyticMap: 0,
    dataMap: [],
    gradData: {},

    dataCountryMap: [],
    gradCountryData: {},

    timeSeriesData: [],
    timeSeriesDataTopStat: {},

    bubbleChartResultLoading: 0,
    timeSeriesResultLoading: 0,

    bubbleChartTopStat: {},
    bubbleChartHighlightBubble: null,

    dataFetched: false,
    countPerPage: 10,
    translateToggle: [],

    selectedOptionsStateFilter: [],
    selectedOptionsStateFilterApplied: [],

    articlesDataForRearrangeComponent: [],
    selectedOptionsLanguages: [],
    selectedOptionsLanguagesApplied: [],

    sampleOptionsCountryFilter: countryArr,
    selectedOptionsCountryFilter: [],
    selectedOptionsCountryFilterApplied: []
}

const reducer = (state = initialState, action) =>{ //take initial state, what action to take
    if(action.type === 'reducerUpdates'){
        return {...state, ...action.payload}
    }
    else{
        return state
    }
}

export default reducer