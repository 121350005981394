import React from 'react'
import { Navigate } from 'react-router-dom'
import { handle_auth } from '../../endppoints/login'
import '../../staticFiles/cssFiles/Authentication/login.css'
import eye_icon from '../../staticFiles/images/eye_icon.svg'
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { Link } from 'react-router-dom'
import { getUserDetails } from '../../endppoints/endpoint'

export const Login = () => {
    const [username, setUserName] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [type_psw, setType_Psw] = React.useState('password')
    const [redirect, setRedirect ] = React.useState(0);
    const [isVisible, setIsVisible] = React.useState(false)
    const [isVisibleText, setIsVisibleText] = React.useState('')

    React.useEffect(() => {
        if(Cookies.get('token') !== undefined){
            setRedirect(1)
        }
    }, []);

    const mySubmitHandler = (event) => {
        event.preventDefault();
        // console.log(username, password);
        handleAuth();
    }

    const handleAuth = async() =>{
        let message = {
            "userName": username,
            "password": password
        }
        let response = {}
        response = await handle_auth(message)
        // console.log(response)
        if(response.status === 200){
            const token = response.token;
            const secretKey = 'some_secret_key';
            const ciphertext = CryptoJS.AES.encrypt(token, secretKey);
            Cookies.set('token', ciphertext);
            fetch_user_role()
            // setRedirect(1);              
        }
        else if(response.status === 500){
            setIsVisible(true);
            setIsVisibleText("Server Error");
        }
        else{
            setIsVisible(true);
            setIsVisibleText(response.data.message);
        }
    }

    const fetch_user_role = async()=>{
        let response = await getUserDetails()
        if(response.status === 200){
            console.log(response.data)   
            Cookies.set('userRole', response.data.userRole);
            Cookies.set('userOrg', response.data.userOrg)
            setRedirect(1);              
        }
        else if(response.status === 500){
            console.log("Server Error !!!! Try refreshing the page")
        }
        else if(response.status === 401){
            localStorage.clear()
            Cookies.remove('token');
            setTimeout(() => {
                setRedirect(1)    
            }, 1000);
            alert("invalid token or token expired")
        }
        else{
            alert("please check the network")
        }
    }

    if(redirect === 1){
        return <Navigate to = "/home" />
    }

    return (
        <div className='Login_container'>
            <div className="corner top-right"></div>
            <div className="corner bottom-left"></div>
            <div className="center">
                <div className="header_auth">
                    Newsmon
                </div>
                <div className="auth_form_container">
                    <form onSubmit={mySubmitHandler}>
                        <div className="form-group username_auth">
                            <label htmlFor="exampleInputEmail1">Email/Mobile Number</label>
                            <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter your email or Mobile Number" value={username} onChange={(e) => setUserName(e.target.value)} required/>
                        </div>
                        <div className="form-group password_auth">
                            <label htmlFor="exampleInputPassword1">Password</label>
                            <input type={type_psw} className="form-control input_pass" id="exampleInputPassword1" placeholder="Enter your password" value={password} onChange={(e) => setPassword(e.target.value)} required/>
                            <img src={eye_icon} alt="eye icon" className="eye_icon_container" onClick={()=>type_psw === 'text' ? setType_Psw('password'): setType_Psw('text')}/>
                        </div>
                        <div className="error_container" style={{ display: isVisible ? "block" : "none" }}>
                            {isVisibleText}
                        </div>
                        <button type="submit" className="btn btn-primary btn-lg mt-4 btn-block">Login</button>
                        <Link to="/resetPassword" style={{ textDecoration: 'none', color: '#000000'}}>
                            <div className="forgot_password">
                                forgot password ?
                            </div>
                        </Link>
                    </form>
                </div>             
            </div>
        </div>
    )
}
