import React from 'react'
import { forgotPassword } from '../../endppoints/forgotPassword'
import '../../staticFiles/cssFiles/Authentication/reset.css'
import lock_logo from "../../staticFiles/images/lock_logo.svg"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Reset = () => {
    const [username, setUserName] = React.useState('')
    const [isVisible, setIsVisible] = React.useState(false)


    const mySubmitHandler = (event) => {
        event.preventDefault();
        console.log(username);
        // handleAuth();
        // setIsVisible(true)
        forgot_password(username);

    }

    const forgot_password = async(user) =>{
        let message = {
            "email": user
        }
        let response = await forgotPassword(message)  
        console.log(response)  
        if(response.status === 200){
            setIsVisible(true)
        }
        else if(response.status === 401){
			toast.error("Enter a valid email",  { position: toast.POSITION.TOP_CENTER});
            // alert("Enter a valid email")
        }
        else{
			toast.error("some internal error",  { position: toast.POSITION.TOP_CENTER});
            // alert("some internal error")
        }
    }

  return (
        <div className='reset_container'>
            <div className="corner top-right"></div>
            <div className="corner bottom-left"></div>
            <div className="center">
                <div className="content_center">  
                    <div className="header_reset">
                        Newsmon
                    </div>
                    <img src={lock_logo} alt="lock_logo" className="lock_logo" />  
                    <div className="sub_header_reset">
                        Reset Password
                    </div>
                    <div className="sub_header_reset_content" style={{ display: isVisible ? "block" : "none" }}>
                        Your password reset link has been sent your email. Click on the link to reset the password
                    </div>  
                    <div className="reset_form_container">
                    {!isVisible ? 
                        <form onSubmit={mySubmitHandler}>
                            <div className="form-group username_reset">
                                <label htmlFor="exampleInputEmail1">Email</label>
                                <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter your email to get link" value={username} onChange={(e) => setUserName(e.target.value)} required/>
                            </div>
                            {/* <div className="error_container" style={{ display: isVisible ? "block" : "none" }}>
                                {isVisibleText}
                            </div> */}
                            <button type="submit" className="btn btn-primary btn-lg mt-4 btn-block">Get Link</button>
                        </form>
                        :
                        <>  
                            <div className="form-group username_reset">
                                <a href="https://mail.google.com/mail/" target = '_blank' rel='noreferrer'><button className="btn btn-primary btn-lg mt-2 btn-block">Open Email</button></a>
                                <button className="btn btn-outline btn-lg mt-2 btn-block" onClick={()=>setIsVisible(false)}>Go Back</button>
                            </div>
                        </>
                    }
                    </div>
                </div>
            </div>
			<ToastContainer />
            
        </div>
    )
}
