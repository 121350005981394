import React from 'react'
import '../../staticFiles/cssFiles/adminPanel/adminPanel.css'
import { LeftDashboard } from '../Dashboard/left_dashboard'
import { RightAdminPanel } from './rightAdminPanel'

export const AdminPanel = () => {
  return (
    <div className='admin_panel_container '>
		<LeftDashboard id = {'6'}/>
		<RightAdminPanel />
	</div>
  )
}
