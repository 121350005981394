import React from 'react'



export const RightReports = () => {
  return (
        <div className='onDemandPdfRightView_container'> 
            reports
        </div>
  )
}
