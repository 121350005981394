import React from 'react'
import '../../staticFiles/cssFiles/onDemandPdf/viewArticle.css'
import art_logo from "../../staticFiles/images/art_logo.svg"
import { getParticularArticle, getRelatedArticles, getCategories } from '../../endppoints/endpoint'
import moment from 'moment/moment'
import { LeafletMaps } from '../test3/leaflet_maps'
import { Navigate } from 'react-router-dom'
import prev_btn from "../../staticFiles/images/carousal_prev.svg"
import Cookies from 'js-cookie'
import { ToastContainer, toast } from 'react-toastify';
import translate_icon from "../../staticFiles/images/translate_icon.svg"


export const ViewArticle = ({sample_id, keys}) => {
    const { article_id } = sample_id;

    const [date, setDate] = React.useState()
    const [title, setTitle] = React.useState()
    const [summary, setSummary] = React.useState()
    const [text, setText] = React.useState()
    const [locations, setLocations] = React.useState([])
    const [organizations, setOrganizations] = React.useState([])
    const [persons, setPersons] = React.useState([])
    const [articleLink, setArticleLink] = React.useState('')
    const [domainName, setDomainName] = React.useState('')
    // const [keyword, setKeyword] = React.useState('')

    const [pinMapData, setPinMapData] = React.useState([])
    const [redirect, setRedirect] = React.useState(0)
    const [relatedArticles, setRelatedArticles] = React.useState([])
    const [startIndex, setStartIndex] = React.useState(0)
    const [totalMatches, setTotalMatches] = React.useState(new Set());
    const [categories, setCategories] = React.useState([]);
    const [reportKeywords, setReportKeywords] = React.useState(keys ? keys : null)

    const [translateToggleTitle, setTranslateToggleTitle] = React.useState(true)
    const [translateToggleText  , setTranslateToggleText  ] = React.useState(true)
    const [translateTitle, setTranslateTitle] = React.useState(null)
    const [translateText  , setTranslateText] = React.useState(null)

    React.useEffect(()=>{
        get_particular_article(sample_id);
        get_related_articles(sample_id);
    }, [article_id]);

    const get_particular_article = async(id)=>{
        let response = {}   
        response = await getParticularArticle(id)
        console.log(response);

        if(response.status === 200){
            setDate(response.data.published_date)
            setTitle(response.data.Title)
            setSummary(response.data.Summary)
            setText(response.data.Text)
            setLocations(response.data.keywords_p.Location)
            setOrganizations(response.data.keywords_p.Organization)
            setPersons(response.data.keywords_p.Person)
            setArticleLink(response.data._source.source)
            setDomainName(response.data.domain_name)
            setTranslateTitle(response.data.Title_translated)
            setTranslateText(response.data.Text_translated)
            // setKeyword(response.keywords_n[0])

            let samplePinArray = []
            // for(let i = 0; i < response.data.lat_long.length; i++){
            //     samplePinArray.push({"Location": response.data.keywords_p.Location[i], "Latitude": response.data.lat_long[i][0], "Longitude": response.data.lat_long[i][1]})
            // }

            Object.entries(response.data.lat_long_map).map((entry, index) => {
                samplePinArray.push({"Id": index, "Location": entry[0], "Latitude": entry[1][0], "Longitude": entry[1][1]})
            });

            setPinMapData(samplePinArray)
            if(reportKeywords){
                // fetch_client_keywords(reportKeywords, response.data.Text)
                fetch_client_keywords(reportKeywords, response.data.Text_translated ? response.data.Text_translated : response.data.Text)
            }
            else{
                fetch_client_keywords_category(response.data.filter_info, response.data.Text_translated ? response.data.Text_translated : response.data.Text)
            }
        }

        else if(response.status === 401){
            alert("invalid token or token expired")
            setRedirect(1)
        }
        else{
            alert("server error")
            // setRedirect(1)
        }


        // setDate(response.published_date)
        // setTitle(response.Title)
        // setSummary(response.Summary)
        // setText(response.Text)
        // setLocations(response.keywords_p.Location)
        // setOrganizations(response.keywords_p.Organization)
        // setPersons(response.keywords_p.Person)
        // setArticleLink(response._source.source)
        // setDomainName(response.domain_name.substring(4, response.domain_name.length - 4))
        // // setKeyword(response.keywords_n[0])

        // let samplePinArray = []
        // for(let i = 0; i < response.lat_long.length; i++){
        //     samplePinArray.push({"Location": response.keywords_p.Location[i], "Latitude": response.lat_long[i][0], "Longitude": response.lat_long[i][1]})
        // }
        // setPinMapData(samplePinArray)
    }

    if(redirect === 1){
        // return <Navigate to = "/login" />
        window.location.href = '/login';
    }

    const fetch_client_keywords = async(final_partial, text) => {
        
        // let keyAll = client123Array[i]["keywords"];
        // final_partial = [...keyAll["partial_match_keywords"]]
        // final_exact = [...keyAll["word_bound_match_keywords"]]
                
        //  perform logic for partialMatches

        const matchingPhrasesSet = new Set();
        
        // Loop through each partial match
        for (const pm of final_partial) {
            // Create a regular expression pattern for the partial match
            const pattern = new RegExp('\\b' + escapeRegExp(pm) + '\\w*\\b', 'gi');
            
            // Find all matches in the text using the pattern
            const matches = text.match(pattern);
            
            // Add the found matches to the matchingPhrasesSet
            if (matches) {
                console.log(matches)
                matches.forEach(match => matchingPhrasesSet.add(match));
            }
        }

        // Function to escape special characters for regex
        function escapeRegExp(string) {
            return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
        }
        let matchingPhrases = []
        matchingPhrases = Array.from(matchingPhrasesSet);        
        //  setPartialMatches([...matchingPhrases]);
        //  setExactMatches([...final_exact])
        const mergedArray = [...final_partial, ...matchingPhrases];
        const uniqueSet = new Set(mergedArray);
        setTotalMatches(uniqueSet);
    }

    const fetch_client_keywords_category = async(filter_info_details, text) => {
        let response = await getCategories()
		if(response.status === 200){
		 	setCategories(response.data)
		 	let cat = response.data
            if(filter_info_details && filter_info_details.length > 0){
                 const client123Array = filter_info_details.filter(item => cat.includes(item.client));
                 console.log(client123Array);
                 if(client123Array.length <= 0){
                     console.log("No Keywords Found")
                 }
                 else{
                     console.log("here")
                     let final_partial = []
                     let final_exact = []
                     for(let i = 0; i < client123Array.length; i++){
                         console.log(cat.includes(client123Array[i]["client"]))
                         console.log(client123Array[i])
                         if(cat.includes(client123Array[i]["client"])){
                             let keyAll = client123Array[i]["keywords"];
                             final_partial = [...keyAll["partial_match_keywords"]]
                             final_exact = [...keyAll["word_bound_match_keywords"]]
                         }
                     }
                    //  perform logic for partialMatches

                    const matchingPhrasesSet = new Set();
                    
                    // Loop through each partial match
                    for (const pm of final_partial) {
                        // Create a regular expression pattern for the partial match
                        const pattern = new RegExp('\\b' + escapeRegExp(pm) + '\\w*\\b', 'gi');
                        
                        // Find all matches in the text using the pattern
                        const matches = text.match(pattern);
                        
                        // Add the found matches to the matchingPhrasesSet
                        if (matches) {
                            console.log(matches)
                            matches.forEach(match => matchingPhrasesSet.add(match));
                        }
                    }

                    // Function to escape special characters for regex
                    function escapeRegExp(string) {
                        return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
                    }
                    let matchingPhrases = []
                    matchingPhrases = Array.from(matchingPhrasesSet);

                    
                    //  setPartialMatches([...matchingPhrases]);
                    //  setExactMatches([...final_exact])
                    const mergedArray = [...final_exact, ...matchingPhrases];
                    const uniqueSet = new Set(mergedArray);
                    setTotalMatches(uniqueSet);
                    // setTotalMatches([...final_exact, ...matchingPhrases])
                 }
             }
	 	}
	 	else if(response.status === 401){
		 	localStorage.clear()
		 	Cookies.remove('token');
		 	setTimeout(() => {
			 	setRedirect(1)    
		 	}, 1000);
		 	toast.error("invalid token or token expired", { position: toast.POSITION.TOP_CENTER});
	 	}
	 	else{
		 	toast.info("server error", { position: toast.POSITION.TOP_CENTER})
		}
      }
             
    const get_related_articles = async(id)=>{
        console.log("working")
        let message = {
            'articleId': id
        }
        let response = {}
        response = await getRelatedArticles(message)
        console.log(response);

        if(response.status === 200){
            setRelatedArticles(response.data)
        }

        else if(response.status === 401){
            alert("invalid token or token expired")
            setRedirect(1)
        }
        else{
            alert("server error")
            // setRedirect(1)
        }
    }

    const handlePrev = () => {
        console.log("prev")
        if (startIndex > 0) {
            // setDirection('prev');
            setStartIndex(startIndex - 4);
        }
      };
    
    const handleNext = () => {
        console.log("next")
        if (startIndex < relatedArticles.length - 4) {
            // setDirection('next');       
            setStartIndex(startIndex + 4);
        }
    };

    const highlightedSummary_exact = (text) => {
        let keywords = Array.from(totalMatches);
        console.log(keywords);
            // keywords= ["stresses on", "bal un"]
            const regexPattern = new RegExp(`\\b(${keywords.map(keyword => keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|')})\\b`, 'gi');
            const parts = text.split(regexPattern);
            const highlightedParts = parts.map((part, index) =>
              regexPattern.test(part) ? <span key={index} style= {{background: "yellow"}} className="highlighted">{part}</span> : part
            );
            return highlightedParts;
            
      };

  return (
    <div className='right_article_container_pdf'>
        <div className="article_content_container">
            <div className="left_article_content_container">
                <div className="tags_date_container">
                    <div className="tags_container">
                        {/* <div className="tag">
                            {keyword}
                        </div> */}
                    </div>
                    <div className="date_container">
                        {date !== undefined? moment(date.slice(0, 10)).format('DD/MM/YYYY'): ''}&nbsp;&nbsp;{date !== undefined? date.slice(11, 16): ''}  
                    </div>
                </div>

                <div className="article_topic_container">
                    {console.log((translateTitle && translateToggleTitle))}
                {translateTitle && <><img src={translate_icon} alt="translate_icon" className="translate_text_icon" onClick={()=>setTranslateToggleTitle(!translateToggleTitle)}/><>&nbsp;&nbsp;</></>}
                    {(translateTitle && translateToggleTitle) ? translateTitle: title }
                </div>
                {
                    reportKeywords ?
                    <div className="article_keywords_container">
                        <div className="exact_matched_words article_keywords_container_wrapper">
                            <div className='key'>{reportKeywords ? "Selected Preview Keywords" : "Matched Keywords"}</div> : 
                            {
                                reportKeywords.map((key, index) =>(
                                    <div className="keywords" key={index}>
                                        {key}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    :
                    <div className="article_keywords_container">
                        <div className="exact_matched_words article_keywords_container_wrapper">
                            <div className='key'>{reportKeywords ? "Selected Preview Keywords" : "Matched Keywords"}</div> : 
                            {
                                Array.from(totalMatches).map((key, index) =>(
                                    <div className="keywords" key={index}>
                                        {key}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                }

                <div className="source_link_container">
                    <div className="source">
                        {/* <img src={art_logo} alt="article logo" /> */}
                        {domainName}
                        <a href={articleLink} target = '_blank' rel='noreferrer'>Article Link</a>
                    </div>
                </div>

                <div className="article_summary">
                    <h4 className="summary_header header">
                        Article Summary
                    </h4>
                    <div className="summery_content">
                        {/* <pre>{Array.from(totalMatches).length <= 0 ? summary : highlightedSummary_exact(summary, totalMatches)}</pre> */}
                        <pre>{reportKeywords && summary? highlightedSummary_exact(summary) : 
                        Array.from(totalMatches).length > 0 ? highlightedSummary_exact(summary): summary}</pre>
                    </div>
                </div>

                <div className="full_article">
                        <h4 className="article_header header">
                            Full Article
                        </h4>
                        <div className="full_article_content">
                            {translateText && <><img src={translate_icon} alt="translate_icon" className="translate_text_icon" onClick={()=>setTranslateToggleText(!translateToggleText)}/><>&nbsp;&nbsp;</></>}
                            {/* <pre>{Array.from(totalMatches).length <= 0 ? text : highlightedSummary_exact(text, totalMatches)}</pre> */}
                            <pre>{
                                    Array.from(totalMatches).length <= 0
                                        ? ((translateText && translateToggleText) ? translateText : text)
                                        : 
                                        (translateText && translateToggleText) ? highlightedSummary_exact(
                                            translateText,totalMatches)
                                        :
                                        highlightedSummary_exact(
                                            text,totalMatches)
                                }
                            </pre>
                            {/* <pre>{reportKeywords && text ? highlightedSummary_exact(text) : (translateToggleText? translateText:text)}</pre> */}
                        </div>
                </div>

                <div className="heat_map_article">
                    {pinMapData.length !== 0 ?
                        <LeafletMaps dataForMap = {pinMapData}/>:
                    <></>}
                    {/* <LeafletMaps dataForMap = {pinMapData}/> */}
                </div>
                
            </div>


            <div className="right_article_conatainer">
                <div className="locations_container lop_containers">
                    <div className="heading">
                        Identified Locations   
                    </div>
                    <hr/>

                    {   locations.length !== 0 ?
                            <div className="locations lops">
                                {
                                    locations.map((l, index)=>(
                                        // <div className="lops_wrapper" key = {index}>
                                        //     <div className="bullet_lops">

                                        //     </div>
                                        //     <div className="content_lops">
                                        //         {l}
                                        //     </div>
                                        // </div>
                                        <li key = {index}>{l}</li>
                                    ))
                                }
                            </div>:
                            <div className = "no_report_type">No Relevant Location</div>
                    }
                </div>
                <div className="organizations_container lop_containers">
                    <div className="heading">
                        Identified organizations 
                    </div>
                    <hr/>
                    {   organizations.length !== 0 ?
                        <div className="organizations lops">
                            {
                                organizations.map((o, index)=>(
                                    <li key = {index}>{o}</li>
                                ))
                            }
                        </div>:
                        <div className = "no_report_type">No Relevant Organizations</div>
                    }
                </div>
                <div className="persons_container lop_containers">
                    <div className="heading">
                        Identified persons
                    </div>
                    <hr/>
                    {   persons.length !== 0 ?
                        <div className="persons lops">
                            {
                                persons.map((p, index)=>(
                                    <li key = {index}>{p}</li>
                                ))
                            }
                        </div>:
                        <div className = "no_report_type">No Relevant Persons</div>
                    }
                </div>
            </div>
        </div>
        <div className="related_articles_container">
            {relatedArticles.length !==0 && (
                <h4 className="related_articles_header header">
                    Related Articles
                </h4>
            )}
            <div className="related_articles_cards">
                <button className={relatedArticles.length === 0 ? "prev_next_wrapper_hidden" :"prev_next_wrapper"} onClick={handlePrev} disabled = {startIndex === 0}>
                    <img src = {prev_btn} className = {startIndex === 0 ? "prev_next_button prev_button pre_next_faded": "prev_next_button prev_button"}  />
                </button>
                <div className="all_cards">
                {   
                    relatedArticles.slice(startIndex, startIndex + 4).map((art, index)=>(
                    // <a href={'/article/' + art._id} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#000000'}} key = {index}>
                        <a href={'/article/' + art._id} style={{ textDecoration: 'none', color: '#000000'}} className="article_card" key = {index} >
                            {/* <a href={'/article/' + art._id} style={{ textDecoration: 'none', color: '#000000'}}> */}
                            {/* <div className="card_text"> */}
                                <div className="card_title card_contents">
                                    {art.Title}
                                </div>
                                <div className="card_domain_name card_contents">
                                    {art.domain_name}
                                </div>
                                <div className="card_published_date card_contents">
                                    {/* {art.published_date} */}
                                    {(moment(art.published_date.slice(0, 10)).format('DD/MM/YYYY'))}
                                </div>
                            {/* </div> */}
                        </a>
                        // </div>
                    ))
                } 
                </div>
                <button className={relatedArticles.length === 0 ? "prev_next_wrapper_hidden" :"prev_next_wrapper"} disabled = {startIndex >= relatedArticles.length - 4} onClick={handleNext}>  
                    <img src = {prev_btn} className = {startIndex >= relatedArticles.length - 4 ? "prev_next_button next_button pre_next_faded": "prev_next_button next_button"} />
                </button>
            </div>
        </div>
        <ToastContainer />
    </div>
  )
}
