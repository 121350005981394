import React from 'react'
import { LeftDashboard } from '../Dashboard/left_dashboard'
import '../../staticFiles/cssFiles/DraftArticles/draftArticles.css'
import { RightDraftArticles } from './rightDraftArticles'
import { Navigate } from 'react-router-dom'
import Cookies from 'js-cookie'

export const DraftArticles = ({preview}) => {
  if(Cookies.get('token') === undefined){
    console.log("working")
    // return <Navigate to  = "/login" />
    window.location.href = '/login';
}
  return (
    <div className='draft_articles_container'>
        <LeftDashboard id={preview ? '4_2' : '7'}/>
        <RightDraftArticles/>
    </div>
  )
}
