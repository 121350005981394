import React from 'react'
import { Link, Navigate, useParams } from 'react-router-dom'
import "../../staticFiles/cssFiles/Authentication/uniqueReset.css"
import reset_success_logo from "../../staticFiles/images/reset_success_logo.svg"
import eye_icon from '../../staticFiles/images/eye_icon.svg'
import { newPassword } from '../../endppoints/newPassword'
import { validateToken } from '../../endppoints/validate_reset_pass_link'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ResetForm = () => {
    const { id } = useParams();

    const [password1, setPassword1] = React.useState('')
    const [password2, setPassword2] = React.useState('')
    const [type_psw1, setType_Psw1] = React.useState('password')
    const [type_psw2, setType_Psw2] = React.useState('password')
    const [error, setError] = React.useState(false)
    const [success, setSuccess] = React.useState(false)
    const [redirect, setRedirect] = React.useState(0)

    React.useEffect(()=>{
        validate_token()
    }, [])

    const validate_token = async()=>{
        let response = await validateToken(id);
        if(response.status === 200){
            console.log("token validated")
        }
        else if(response.status === 401){
            setTimeout(() => {
                setRedirect(1)    
            }, 1000);
            toast.error('link expired',  { position: toast.POSITION.TOP_CENTER});

            // setRedirect(1)
        }
        else{
			toast.error("server error",  { position: toast.POSITION.TOP_CENTER});
        }
    }

    const mySubmitHandler = (event) => {
        event.preventDefault();
        // console.log(password1, password2);
        if(password1 !== password2){
            setError(true)
        }
        else{
            setError(false)
            new_password();
        }
    }



    const new_password = async() =>{
        let message = {
            'newPassword': password1
        }
        let response = await newPassword(message, id);
        if(response.status === 200){
            alert("password reset success")
            setSuccess(true)
        }
        else if(response.status === 401){
            alert("unauthorized")
        }
        else{
            alert("some internal error")
        }
    }

    if(redirect === 1){
        // return <Navigate to = "/login" />
        window.location.href = '/login';
    }

  return (
    <div className='unique_reset_container'>
        <div className="corner top-right"></div>
        <div className="corner bottom-left"></div>
        <div className="center">
            <div className="header_unique_reset">
                Newsmon
            </div>

        
            { !success ?
            <div className="unique_reset_form_container">
                <form onSubmit={mySubmitHandler}>
                    <div className="form-group password_unique_reset">
                        <label htmlFor="exampleInputPassword1">New Password</label>
                        <input type={type_psw1} className="form-control input_pass" id="exampleInputPassword1" placeholder="Enter your password" value={password1} onChange={(e) => setPassword1(e.target.value)} required/>
                        <img src={eye_icon} alt="eye icon" className="eye_icon_container" onClick={()=>type_psw1 === 'text' ? setType_Psw1('password'): setType_Psw1('text')}/>
                    </div>
                    <div className="form-group password_unique_reset">
                        <label htmlFor="exampleInputPassword2">Confirm New Password</label>
                        <input type={type_psw2} className="form-control input_pass" id="exampleInputPassword2" placeholder="Enter your password" value={password2} onChange={(e) => setPassword2(e.target.value)} required/>
                        <img src={eye_icon} alt="eye icon" className="eye_icon_container" onClick={()=>type_psw2 === 'text' ? setType_Psw2('password'): setType_Psw2('text')}/>
                    </div>
                    <div className="error_container" style={{ display: error ? "block" : "none" }}>
                        Both the password fields should be same
                    </div>
                    <button type="submit" className="btn btn-primary btn-lg mt-4 btn-block">Reset</button>
                </form>
            </div>:
            
            <>
                <img src={reset_success_logo} alt="reset_success_logo" className="reset_success_logo" />  
                <div className="sub_header_unique_reset">
                    Reset was a success
                </div>
                <div className="sub_header_unique_reset_content">
                    Your password reset process was a success. Kindly login with the new password and access your account.
                </div>  
                <div className="form-group">
                <Link to="/login" style={{ textDecoration: 'none', color: '#000000'}}>
                    <button className="btn btn-primary btn-lg mt-4 btn-block">Sign In</button>
                </Link>
                </div>
            </>

            }             
        </div>
			<ToastContainer />

    </div>
  )
}
