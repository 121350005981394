import React from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { SignUp } from './MyComponents/auth/signUp'
import { Login } from './MyComponents/Authentication/login'
import { Profile1, } from './MyComponents/Type1/profile1';
import { Profile2 } from './MyComponents/Type2/profile2';
import { Profile3 } from './MyComponents/Type3/profile3';
import { Error } from './MyComponents/Error/Error';
// import { Test } from './MyComponents/test/test';
// import { Test3 } from './MyComponents/test3/test3';
import { LeafletMaps } from './MyComponents/test3/leaflet_maps';
import { Dashboard } from './MyComponents/Dashboard/dashboard';
// import { HeatMaps } from './MyComponents/test4/heat_maps';
import { Article } from './MyComponents/Article/article';
import { HistoryPage } from './MyComponents/History/history';
import { OnDemandPdf } from './MyComponents/onDemandPdf/OnDemandPdf';
import { Analytics } from './MyComponents/Analytics/Analytics';
import { Test2 }  from './MyComponents/test2/test2';
import { Profile } from './MyComponents/Profile/profile';
import { Reset } from './MyComponents/Authentication/reset';
import { ResetForm } from './MyComponents/Authentication/resetForm';
import { AdminPanel } from './MyComponents/AdminPanel/adminPanel';
import { AdminUsersPanel } from './MyComponents/AdminUsersPanel/adminUsersPanel';
import { Draft } from './MyComponents/Draft/draft';
import { DraftArticles } from './MyComponents/DraftArticles/draftArticles';
import { KeywordsReport } from './MyComponents/KeywordsReport/keywordsReport';
import { ReportGenTracker } from './MyComponents/ReportGenTracker/reportGenTracker';
import { WorldMapComponent } from "./MyComponents/worldMap/worldMap"
import { Reports } from './MyComponents/Reports/reports';
import { DetailsKeywordsReport } from './MyComponents/ReportGenTracker/detailsKeywordReport';
import { RootDashboard } from './MyComponents/RootDashboard/rootDashboard';
import { FactCheck } from './MyComponents/FactCheck/factCheck';
import { ReportGenArticles } from './MyComponents/ReportGenArticles/reportGenArticles';
// import "leaflet/dist/leaflet.css"


export const App = () => {
  return (
    <div>
      	<Router>
			<Routes>
				<Route path='/' element={<Login />} />
				<Route path='/login' element={<Login />} />
				<Route path='/signup' element={<SignUp />} />
				<Route path='/resetPassword' element={<Reset />} />
				<Route path='/resetPassword/:id' element={<ResetForm />} />

				<Route path='/profile1' element={<Profile1 />} />
				<Route path='/profile2' element={<Profile2 />} />
				<Route path='/profile3' element={<Profile3 />} />

				{/* <Route path='/test' element={<Test />} /> */}
				{/* <Route path='/test3' element={<Test3 />} /> */}
				{/* <Route path='/test2' element={<Test2 />} /> */}
				<Route path='/maps' element={<LeafletMaps />} />
				{/* <Route path='/heatmaps' element={<HeatMaps />} /> */}

				<Route path='/dashboard' element={<RootDashboard />} />
				<Route path='/dashboard/:state/:date1/:date2' element={<Dashboard />} />
				
				<Route path='/home' element={<RootDashboard />} />

				<Route path='/root_dashboard' element={<RootDashboard />} />

				{/* <Route path='/article' element={<Article />} /> */}
				<Route path="article/:article_id" element = {<Article/>} />
				<Route path="/history" element = {<HistoryPage/>} />
				

				<Route path='/reports' element = {<Reports/>} />

				{/* <Route path='/ondemandpdf' element = {<OnDemandPdf/>} /> */}
				<Route path='/draft'element = {<Draft/>} />
				<Route path='/draft/articles/:draft_id'element = {<DraftArticles preview = {false}/>} />

				{/* <Route path='/keywordsReport' element = {<KeywordsReport/>} /> */}
				{/* <Route path='/keywordsReport/preview' element = {<DraftArticles preview = {true}/>} /> */}
				<Route path='/reportsPreview' element = {<ReportGenArticles/>} />
				

				<Route path='/scheduledReports' element = {<ReportGenTracker/>} />
				<Route path='/scheduledReports/report/details/:schedule_id' element = {<DetailsKeywordsReport/>} />
				
				<Route path="/factCheck" element = {<FactCheck/>} />
				<Route path="/analytics" element = {<Analytics/>} />
				<Route path="/profile" element = {<Profile/>} />
				<Route path="/adminPanel" element = {<AdminPanel/>} />
				<Route path="/adminPanel/:redirectOrg" element = {<AdminUsersPanel/>} />

				<Route path="/auto_complete" element = {<Test2/>} />
				<Route path="/worldMap" element = {<WorldMapComponent/>} />

				<Route path='*' element={<Error />}/>
			</Routes>
		</Router>
    </div>
  )
}
