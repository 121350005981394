import { applyMiddleware, compose } from "redux";
import { createStore } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducers";

// const composeEnhancers =
//   typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//     ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//         // Specify extension’s options like name, actionsDenylist, actionsCreators, serialize...
//       })
//     : compose;

// const enhancer = composeEnhancers(
//   applyMiddleware(thunk)
// );
// const store = createStore(reducer, enhancer);

export const store = createStore(reducers, {}, applyMiddleware(thunk))